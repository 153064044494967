import React from "react";
import { Button, Modal, notification, Select } from "antd";
import FacebookModal from "./facebookModel";
import InstagramModal from "./instagramModel";
import TwitterModal from "./twitterModel";
import ZendeskTicketsModal from "./zendeskTicketsModel";
import ZendeskChatModal from "./zendeskChatModel";
import SlackView from "./slackView";
import TrelloView from "./trelloView";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const Wrapper = ({ title, subtitle, children }) => (
  <div className={styles.wrapper}>
    <h4 className={styles.wrapperTitle}>{title}</h4>
    <h7 className={styles.wrapperSubtitle}>{subtitle}</h7>
    {children}
  </div>
);

const CustomDropDown = ({ onChange, selectedOption, options, placeholder }) => (
  <Select
    className={styles.dropdown}
    placeholder={placeholder}
    onChange={onChange}
    value={selectedOption}
    showSearch
    optionFilterProp="title"
  >
    <Select.Option style={{ display: "none" }}>{placeholder}</Select.Option>
    {options?.map((option) => (
      <Select.Option key={option} value={option} title={option}>
        {option}
      </Select.Option>
    ))}
  </Select>
);

const Card = ({ title, subtitle, integrated, onClick, asset }) => (
  <div className={styles.card}>
    <div className={styles.cardContent}>
      <div className={styles.cardImage}>{asset}</div>
      <div className={styles.cardText}>
        <p className={styles.cardTitle}>{title}</p>
        <p className={styles.cardSubtitle}>{subtitle}</p>
      </div>
    </div>
    <Button
      className={`${styles.btn} ${integrated ? styles.outlined : ""}`}
      onClick={onClick}
    >
      {" "}
      {integrated ? i18n.t("button.22") : i18n.t("button.21")}{" "}
    </Button>
  </div>
);

const Representation = ({
  user,
  integrations,
  selectedFilter,
  setSelectedFilter,
  showFacebookModal,
  setShowFacebookModal,
  showInstagramModal,
  setShowInstagramModal,
  showTwitterModal,
  setShowTwitterModal,
  showZendeskTicketsModal,
  setShowZendeskTicketsModal,
  showZendeskChatModal,
  setShowZendeskChatModal,
  subscriptionStatus,
  setDeleteModal,
  deleteModalText,
  deleteKeys,
  activateClickup,
  activateJira,
  activateMetaPosts,
  activateMetaAds,
  activateTiktok,
  activateLinkedIn,
  showSlackView,
  setShowSlackView,
  showTrelloView,
  setShowTrelloView,
  getSubscribtionStatus,
  theme,
}) => {
  const ModalStyle = {
    padding: "45px",
    borderRadius: "15px",
    background: theme === "dark" ? "#1C193B" : "#ffffff",
    overflow: "hidden",
  };
  const { t } = useTranslation();

  return (
    <>
      {!showSlackView && !showTrelloView ? (
        <Wrapper title={t("settings.54")} subtitle={t("settings.55")}>
          <div className={styles.integrationContainer}>
            <CustomDropDown
              onChange={(value) => setSelectedFilter(value)}
              selectedOption={selectedFilter}
              options={Object.keys(integrations)}
            />
            <div className={styles.container}>
              {integrations[selectedFilter].includes("Slack") &&
                subscriptionStatus?.slack_integration_status !== "hidden" && (
                  <Card
                    title="Slack"
                    subtitle={t("settings.66")}
                    asset={
                      <img
                        width="40"
                        height="40"
                        src="/assets/settings/slack-color.png"
                        alt=""
                      />
                    }
                    integrated={
                      subscriptionStatus?.slack_integration_status === "active"
                    }
                    onClick={() =>
                      subscriptionStatus?.slack_integration_status === "active"
                        ? setDeleteModal("Slack")
                        : [78, 79, 100].includes(subscriptionStatus?.PackageId)
                        ? setShowSlackView(true)
                        : notification.error({ message: t("settings.67") })
                    }
                  />
                )}
              {integrations[selectedFilter].includes("meta Posts") &&
                subscriptionStatus?.instagram_search_token_status !==
                  "hidden" && (
                  <Card
                    title={t("settings.58")}
                    subtitle={t("settings.59")}
                    asset={
                      <img
                        width="40"
                        height="40"
                        src="/assets/settings/meta.png"
                        alt=""
                      />
                    }
                    integrated={
                      subscriptionStatus?.meta_posts_access === "active"
                    }
                    onClick={() =>
                      subscriptionStatus?.meta_posts_access === "active"
                        ? setDeleteModal("Facebook and Instagram Posts")
                        : [78, 79, 77, 76, 100].includes(
                            subscriptionStatus?.PackageId
                          )
                        ? activateMetaPosts()
                        : notification.error({ message: t("settings.67") })
                    }
                  />
                )}
              {integrations[selectedFilter].includes("LinkedIn") && (
                <Card
                  title="LinkedIn"
                  subtitle={t("settings.222")}
                  asset={
                    <img
                      width="40"
                      height="40"
                      src={"/assets/linkedin.svg"}
                      alt=""
                    />
                  }
                  integrated={subscriptionStatus?.linkedin_status === "active"}
                  onClick={() =>
                    subscriptionStatus?.linkedin_status === "active"
                      ? setDeleteModal("LinkedIn")
                      : [78, 79, 77, 76, 100].includes(
                          subscriptionStatus?.PackageId
                        )
                      ? activateLinkedIn()
                      : notification.error({ message: t("settings.67") })
                  }
                />
              )}
              {/* 
{integrations[selectedFilter].includes("meta Ads") &&
                  subscriptionStatus?.facebook_page_token_status !== "hidden" &&
                  <Card
                    title={t("settings.56")}
                    subtitle={t("settings.57")}
                    asset={<img width="40" height="40" src="/assets/settings/meta.png" alt="" />}
                    integrated={subscriptionStatus?.meta_ads_access === "active"}
                    onClick={() => subscriptionStatus?.meta_ads_access === "active" ?
                    setDeleteModal("Facebook and Instagram ads") : ([78, 79, 77, 76, 100].includes(subscriptionStatus?.PackageId) ?
                      activateMetaAds() : notification.error({ message: t("settings.67") }))}
                  />} */}
              {/* {integrations[selectedFilter].includes("Twitter") &&
                  subscriptionStatus?.twitter_status !== "hidden" &&
                  <Card
                    title={t("settings.60")}
                    subtitle={t("settings.61")}
                    asset={<TwitterOutlined style={{ fontSize: '40px' }} />}
                    integrated={subscriptionStatus?.twitter_status === "active"}
                    onClick={() => subscriptionStatus?.twitter_status === "active" ?
                      setDeleteModal("Twitter") : setShowTwitterModal(true)}
                  />} */}
              {/* {integrations[selectedFilter].includes("TikTok") &&
                subscriptionStatus?.tiktok_status !== "hidden" &&
                <Card
                  title="TikTok"
                  subtitle="Analyze Account video comments in few taps"
                  asset={<img width="40" height="40" src={"/assets/settings/tiktok.png"} alt="" />}
                  integrated={subscriptionStatus?.tiktok_status === "active"}
                  onClick={() => subscriptionStatus?.tiktok_status === "active" ?
                    setDeleteModal("tiktok") : ([78, 79, 77, 76, 100].includes(subscriptionStatus?.PackageId) ?
                      activateTiktok() : notification.error({ message: t("settings.67") }))}
                />} */}
              {integrations[selectedFilter].includes("Zendesk Tickets") &&
                subscriptionStatus?.zendesk_ticket_token_status !==
                  "hidden" && (
                  <Card
                    title={t("settings.62")}
                    subtitle={t("settings.63")}
                    asset={
                      <img
                        width="40"
                        height="40"
                        src="/assets/settings/zendesk.svg"
                        alt=""
                      />
                    }
                    integrated={
                      subscriptionStatus?.zendesk_ticket_token_status ===
                      "active"
                    }
                    onClick={() =>
                      subscriptionStatus?.zendesk_ticket_token_status ===
                      "active"
                        ? setDeleteModal("Zendesk Tickets")
                        : setShowZendeskTicketsModal(true)
                    }
                  />
                )}
              {integrations[selectedFilter].includes("Zendesk Live Chat") &&
                subscriptionStatus?.zendesk_livechat_token_status !==
                  "hidden" && (
                  <Card
                    title={t("settings.64")}
                    subtitle={t("settings.65")}
                    asset={
                      <img
                        width="40"
                        height="40"
                        src="/assets/settings/zendesk.svg"
                        alt=""
                      />
                    }
                    integrated={
                      subscriptionStatus?.zendesk_livechat_token_status ===
                      "active"
                    }
                    onClick={() =>
                      subscriptionStatus?.zendesk_livechat_token_status ===
                      "active"
                        ? setDeleteModal("Zendesk Live Chat")
                        : setShowZendeskChatModal(true)
                    }
                  />
                )}
              {user.plan !== 19 &&
                user.plan !== 65 &&
                user.plan !== 66 &&
                user.plan !== 67 && <></>}
              {integrations[selectedFilter].includes("Trello") &&
                subscriptionStatus?.trello_status !== "hidden" && (
                  <Card
                    title="Trello"
                    subtitle={t("settings.68")}
                    asset={
                      <img
                        width="40"
                        height="40"
                        src="/assets/settings/trello-color.png"
                        alt=""
                      />
                    }
                    integrated={subscriptionStatus?.trello_status === "active"}
                    onClick={() =>
                      subscriptionStatus?.trello_status === "active"
                        ? setDeleteModal("Trello")
                        : [78, 79, 77, 76, 100].includes(
                            subscriptionStatus?.PackageId
                          )
                        ? setShowTrelloView(true)
                        : notification.error({ message: t("settings.67") })
                    }
                  />
                )}
              {integrations[selectedFilter].includes("ClickUp") &&
                subscriptionStatus?.clickup_status !== "hidden" && (
                  <Card
                    title="ClickUp"
                    subtitle={t("settings.68")}
                    asset={
                      <img
                        width="40"
                        height="40"
                        src={"/assets/settings/clickup.png"}
                        alt=""
                      />
                    }
                    integrated={subscriptionStatus?.clickup_status === "active"}
                    onClick={() =>
                      subscriptionStatus?.clickup_status === "active"
                        ? setDeleteModal("ClickUp")
                        : [78, 79, 77, 76, 100].includes(
                            subscriptionStatus?.PackageId
                          )
                        ? activateClickup()
                        : notification.error({ message: t("settings.67") })
                    }
                  />
                )}
              {integrations[selectedFilter].includes("Jira") &&
                subscriptionStatus?.jira_status !== "hidden" && (
                  <Card
                    title="Jira"
                    subtitle={t("settings.68")}
                    asset={
                      <img
                        width="40"
                        height="40"
                        src={"/assets/settings/jira.svg"}
                        alt=""
                      />
                    }
                    integrated={subscriptionStatus?.jira_status === "active"}
                    onClick={() =>
                      subscriptionStatus?.jira_status === "active"
                        ? setDeleteModal("jira")
                        : [78, 79, 77, 76, 100].includes(
                            subscriptionStatus?.PackageId
                          )
                        ? activateJira()
                        : notification.error({ message: t("settings.67") })
                    }
                  />
                )}
            </div>
            <FacebookModal
              showModal={showFacebookModal}
              setShowModal={setShowFacebookModal}
              user={user}
              getSubscribtionStatus={getSubscribtionStatus}
            />
            <InstagramModal
              showModal={showInstagramModal}
              setShowModal={setShowInstagramModal}
              user={user}
              getSubscribtionStatus={getSubscribtionStatus}
            />
            <TwitterModal
              showModal={showTwitterModal}
              setShowModal={setShowTwitterModal}
              user={user}
              getSubscribtionStatus={getSubscribtionStatus}
            />
            <ZendeskTicketsModal
              showModal={showZendeskTicketsModal}
              setShowModal={setShowZendeskTicketsModal}
              user={user}
              getSubscribtionStatus={getSubscribtionStatus}
            />
            <ZendeskChatModal
              showModal={showZendeskChatModal}
              setShowModal={setShowZendeskChatModal}
              user={user}
              getSubscribtionStatus={getSubscribtionStatus}
            />
            <Modal
              open={deleteModalText}
              bodyStyle={ModalStyle}
              footer={null}
              onCancel={() => setDeleteModal(null)}
              style={{
                overflow: "hidden",
                borderRadius: "15px",
                padding: "0px",
              }}
            >
              <h7 className={styles.modalTitle}>
                {" "}
                {t("settings.69", { deleteModalText })}
              </h7>
              <p></p>
              <div className={styles.row}>
                <Button
                  className={`${styles.btn} ${styles.modalBtn}`}
                  onClick={() => setDeleteModal(null)}
                >
                  {t("settings.72")}
                </Button>
                <Button
                  className={`${styles.btn} ${styles.modalOutlined}`}
                  onClick={() => deleteKeys(deleteModalText)}
                >
                  {t("button.22")}
                </Button>
              </div>
            </Modal>
          </div>
        </Wrapper>
      ) : (
        <>
          {showSlackView ? (
            <SlackView user={user} />
          ) : (
            <TrelloView user={user} />
          )}
        </>
      )}
    </>
  );
};

export default Representation;
