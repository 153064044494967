import React from "react";
import moment from "moment";
import { Spin } from "antd";
import {
  FilePdfFilled,
  InfoCircleFilled,
  CheckCircleFilled,
  SyncOutlined,
  LoadingOutlined,
  DeleteFilled
} from '@ant-design/icons';
import Header from "./header";
import styles from "./style.module.scss";

const PlatformStatus = ({ status }) => {
  switch (status) {
    case "Ready":
      return <>
        <CheckCircleFilled style={{ color: "lawngreen" }} />
        <span style={{ color: "lawngreen" }}>{status}</span>
      </>
    case "Progress":
      return <>
        <SyncOutlined spin style={{ color: "yellow" }} />
        <span style={{ color: "yellow" }}>{status}</span>
      </>
    default:
      return <>
        <InfoCircleFilled style={{ color: "red" }} />
        <span style={{ color: "red" }}>{status}</span>
      </>
  }
};

const ReportItem = ({ title, status, dashboardName, createdAt, dashboardId, csvLink, pdfLink, deleteReport, deleteInProgress }) => (
  <div className={styles.tableRow}>
    <p className={styles.nameRow}>{title}</p>
    <div className={styles.statusContainer}>
      <PlatformStatus status={status} />
    </div>
    <p className={styles.nameRow}>{dashboardName}</p>
    <p className={styles.createdAtRow}>{moment(createdAt).format("DD MMM YYYY")}</p>
    <div className={styles.downloadRow}>
      {pdfLink && (status === "Ready" || status === "InProgress") &&
        <FilePdfFilled style={{ fontSize: "28px" }} onClick={() => window.open(pdfLink, '_blank')} />}
      {csvLink && (status === "Ready" || status === "InProgress") &&
        <a href={csvLink} target="_blank" rel="noopener noreferrer"><img width="30" src="/assets/csvFile.png" alt="" /></a>}
    </div>
    <div className={styles.deleteRow}>
      {deleteInProgress === dashboardId
        ? <LoadingOutlined spin style={{ fontSize: "28px" }} />
        : <DeleteFilled style={{ fontSize: "28px" }} onClick={() => deleteReport(dashboardId)} />}
    </div>
  </div>
)

const Representation = ({ user, reports, getAllReports, deleteReport, loading, deleteInProgress }) => (
  <div className={styles.container}>
    <Header user={user} getAllReports={getAllReports} />
    <div className={styles.cardContent}>
      <FilePdfFilled style={{ fontSize: "48px" }} />
      <p className={styles.cardTitle}>By generating reports from your dashboard you can save your data and insights </p>
    </div>
    <div className={styles.tableHeader}>
      <p className={styles.nameRow}>Name</p>
      <p className={styles.statusContainer}>Status</p>
      <p className={styles.nameRow}>Dashboard Name</p>
      <p className={styles.createdAtRow}>Created At</p>
      <p className={styles.downloadRow}>Download</p>
      <p className={styles.deleteRow}>Delete</p>
    </div>
    <Spin spinning={loading} />
    {reports?.map((elem) => (
      <ReportItem
        title={elem.Name}
        status={elem.Status}
        dashboardName={elem.DashboardName}
        createdAt={elem.CreationDate}
        pdfLink={elem.ReportLink}
        csvLink={elem.CSVLink}
        dashboardId={elem.DashboardId}
        deleteReport={deleteReport}
        deleteInProgress={deleteInProgress}
      />
    ))}
  </div>
)

export default Representation;