import React, { useState, useEffect, useRef } from "react";
import GaugeChart from "react-gauge-chart";
import SelectMetric from "../SelectMetric";
import { apiRequest } from "util/services";
import {
  Spin,
  notification,
  Select,
  Button,
  Drawer,
  Pagination,
  Input,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ReviewsList from "pages/IndustryDashboard/helperComponents/ReviewsList";
import { useHistory, useLocation } from "react-router";
import { Modal } from "components/General/ModalFormComponents";
import {
  faAngleLeft,
  faAngleRight,
  faDownload,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ChartDataLabels from "chartjs-plugin-datalabels";
import RenameDeletePopup from "helperComponents/RenameDeletePopup";
import styles from "../../../pages/GlobalExecutiveDashboard/style.module.scss";
import { useTranslation } from "react-i18next";
import InfoPopup from "../InfoPopup";

import AlertPopup from "../Alert";
import ReviewCard from "../Review/reviewCard";
import Icon from "helperComponents/Icon";
import DropdownSelect from "helperComponents/CustomDropdown";
import CustomChart from "components/Charts";
const { Option } = Select;

const ChartsContainer = ({
  mode,
  showOption,
  setShowOption,
  showBelowChartIndex,
  setShowBelowChartIndex,
  authenticated,
  selectedTeamItem,
  user,
  showDataLabel,
  chartPeriod,
  dataLoading,
  setDataLoading,
}) => {
  const [chartItems, setChartItems] = useState([]);
  const [emptyChartItems, setEmptyChartItems] = useState([]);
  const [updateWidth, setUpdateWidth] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [progressBarUpdate, setProgressBarUpdate] = useState(0);
  const carouselRef = useRef();
  const [cardChart, setCardChart] = useState([]);
  const [selectedItemKey, setSelectedItemKey] = useState(0);
  const [showOptionIndex, setShowOptionIndex] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const chartsContainerRef = useRef();
  const carouselContainerRef = useRef();
  const titleRef = useRef();
  const [metricName, setMetricName] = useState([]);
  const [fixedChartItems, setFixedChartItems] = useState(null);
  const [chartsLoading, setChartsLoading] = useState(false);
  const [mobileViewCount, setMobileViewCount] = useState(0);
  const [bubbleChartItems, setBubbleChartItems] = useState([]);
  const [showRenamePopup, setShowRenamePopup] = useState(false);
  const [renameKpiValue, setRenameKpiValue] = useState("");
  const [metricLocation, setMetricLocation] = useState("");
  const [showAddKpiGroupName, setShowAddKpiGroupName] = useState(false);
  const [kpiGroupName, setKpiGroupName] = useState("");
  const [addKpiGroupName, setAddKpiGroupName] = useState("");
  const [showRenameKpiGroup, setShowRenameKpiGroup] = useState(false);
  const [isKpiGroupName, setIsKpiGroupName] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [kpiGroupNameList, setKpiGroupNameList] = useState([]);
  const [presentationPageCounter, setPresentationPageCounter] = useState(1);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [selectedKpiInfo, setSelectedKpiInfo] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [dashboardIds, setDashboardIds] = useState([]);
  const [metricList, setMetricList] = useState([]);
  const [selectedBubbleIndex, setSelectedBubbleIndex] = useState("");
  const [showEditKPIPopup, setShowEditKPIPopup] = useState(false);
  const [sources, setSources] = useState([]);
  const [selectedDashboards, setSelectedDashboards] = useState([]);
  const [generalMetrics, setGeneralMetrics] = useState([]);
  const [customMetrics, setCustomMetrics] = useState([]);
  const [editChartLocation, setEditChartLocation] = useState("");
  const [selectedMetricList, setSelectedMetricList] = useState([]);
  const [selectedCustomMetric, setSelectedCustomMetric] = useState(null);
  const [generalCustomMetric, setGeneralCustomMetric] = useState([]);
  const [aiTopics, setAiTopics] = useState([]);
  const [selectedAITopics, setSelectedAITopics] = useState([]);
  const [customTitle, setCustomTitle] = useState("");
  const [sumMean, setSumMean] = useState("sum");
  const [selectedMetricID, setSelectedMetricID] = useState("");
  const [showPosts, setShowPosts] = useState(false);
  const [randomPosts, setRandomPosts] = useState([]);
  const [postsLoading, setPostsLoading] = useState(false);
  const [activeMetric, setActiveMetric] = useState({});
  const [savingPosts, setSavingPosts] = useState(false);
  const [selectedDashboardIds, setSelectedDashboardIds] = useState([]);
  const [selectedTopicLabels, setSelectedTopicLabels] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [allReviews, setAllReviews] = useState([]);
  const [reviewsStartIndex, setReviewsStartIndex] = useState(0);
  const [numOfDocs, setNumOfDocs] = useState(10);
  const [numReviews, setNumReviews] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const [resetReviews, setResetReviews] = useState(false);
  const [searchKeywords, setSearchKeywords] = useState([]);
  const [selectedKPITitle, setSelectedKPITitle] = useState("");
  const [colorMap, setColorMap] = useState(null);
  const stopInitSearchRef = useRef(true);
  const history = useHistory();
  const [totalTopMetrics, setTotalTopMetrics] = useState(0);
  const [topMetricsLoading, setTopMetricsLoading] = useState(true);
  const [incDecLoading, setIncDecLoading] = useState(true);
  const [statisticsLoading, setStatisticsLoading] = useState(true);
  const [currPage, setCurrPage] = useState(0);
  const [totalMetrics, setTotalMetrics] = useState(0);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();
  const location = useLocation()?.search;
  const teamID = new URLSearchParams(location);
  const [cache, setCache] = useState({});
  const [allTopicsList, setAllTopicsList] = useState([]);
  const [searchedTopic, setSearchedTopic] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [filteredAllTopicsList, setFilteredAllTopicsList] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [lineChartDependency, setLineChartDependency] = useState(1);
  if (!mode && window.innerWidth < 1200)
    window.location = window.location.origin + "/login";

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "point",
      intersect: false,
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          // Tooltip Element
          var tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<div></div>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            if (tooltipEl) {
              tooltipEl.remove();
            }
            return;
          }
          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          tooltipEl.classList.add("above");

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            let innerHtml = "<div class=" + styles.driversTooltip + ">";
            tooltipModel.dataPoints.forEach((dataPoint) => {
              const dataset = dataPoint.dataset;
              const dataIndex = dataPoint.dataIndex;
              const datasetLabels = dataset.labels;
              const datasetValue = dataPoint.raw;
              innerHtml += `
                <div class=${styles.topicLabelInTooltip}>
                  <span class=${styles.topicLabel}>${datasetLabels[dataIndex]}: ${datasetValue}</span>
                </div>`;
            });
            innerHtml += "</div>";
            const tooltipRoot = tooltipEl.querySelector("div");
            tooltipRoot.innerHTML = innerHtml;
          }
          // this will be the overall tooltip
          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left +
            window.pageXOffset +
            tooltipModel.caretX -
            40 +
            "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY - 60 + "px";
          // tooltipEl.style.padding =
          //   tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: "start",
        align: "top",
        color: theme === "dark" ? "rgba(255, 255, 255, 0.5)" : "#000000",
        display: showDataLabel || window.showDataLabel === true ? true : false,
      },
    },
    scales: {
      x: {
        color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "#000000",
        grid: {
          display: false,
          drawOnChartArea: false,
        },
        ticks: {
          display: true,
          color: theme === "dark" ? "rgba(255, 255, 255, 1)" : "#000000",
          font: {
            size: 12,
          },
          // padding: 20,
        },
      },

      y: {
        display: false,
        afterDataLimits: (axis) => {
          const max = axis.max;
          let addVal = 0;
          if (max < 5) {
            addVal = 2;
          } else if (max < 10) {
            addVal = 5;
          } else if (max < 100) {
            addVal = 10;
          } else if (max < 1000) {
            addVal = 50;
          } else if (max < 10000) {
            addVal = 500;
          } else if (max < 100000) {
            addVal = 1000;
          } else if (max < 1000000) {
            addVal = 5000;
          } else {
            addVal = 10000;
          }
          axis.max += addVal;
        },
      },
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 3,
      },
    },
  };

  const pieOption = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
        position: "right",
        labels: {
          font: {
            size: 11, //labels font size
          },
          color: theme === "dark" ? "#fff" : "#000",
          boxWidth: 10,
        },
      },
      datalabels: {
        display: true,
        color: "#fff",
        font: {
          weight: "bold",
          size: mode ? 12 : 16,
        },
        formatter: (value) => {
          let sum = 0;
          let dataArr = fixedChartItems?.thirdCol?.platform_dist;
          dataArr.map((data) => {
            sum += data;
          });
          if (((value * 100) / sum).toFixed(0) < 2) {
            return "";
          }
          let percentage = ((value * 100) / sum).toFixed(0) + "%";
          return percentage;
        },
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const pieColors =
    theme === "dark"
      ? ["#942FA1", "#48224D", "#BD7BC6", "#5B0966"]
      : theme === "light"
      ? ["#5D20B9", "#6B4F96", "#9074B9", "#8048D4"]
      : ["#222222", "#666666", "#999999", "#cccccc"];

  function formatLabel(str, maxwidth) {
    var sections = [];
    var words = str.split(" ");
    var temp = "";

    words.forEach(function(item, index) {
      if (temp.length > 0) {
        var concat = temp + " " + item;

        if (concat.length > maxwidth) {
          sections.push(temp);
          temp = "";
        } else {
          if (index == words.length - 1) {
            sections.push(concat);
            return;
          } else {
            temp = concat;
            return;
          }
        }
      }

      if (index == words.length - 1) {
        sections.push(item);
        return;
      }

      if (item.length < maxwidth) {
        temp = item;
      } else {
        sections.push(item);
      }
    });

    return sections;
  }

  const bubbleOption = {
    responsive: true,
    legend: { position: "left", display: false },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          let index = tooltipItem.datasetIndex;
          // let representatives = data.datasets[index].tweet.slice(0, 10);
          return data?.datasets[index]?.data[0]?.name;
        },
        footer: function(tooltipItems, data) {
          let representatives = data?.datasets[
            tooltipItems[0]?.datasetIndex
          ]?.top_words?.slice(0, 10);
          return representatives.join(", ");
        },
      },
      backgroundColor: "rgba(0,0,0,0.8)",
      bodyAlign: "left",
      custom: function(tooltipModel) {},
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            max: 100,
            min: -100,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            max: 100,
            min: -100,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        formatter: function(toolTipItem, data) {
          if (
            data?.dataset?.data[0].name?.length * 4.2 <=
            data?.dataset?.data[0].r
          )
            return toolTipItem.name;
          else {
            return formatLabel(
              data?.dataset?.data[0].name,
              Math.trunc(data?.dataset?.data[0].r / 4.5)
            );
          }
        },
        color: "White",
        textAlign: "center",
        font: {
          weight: 300,
          size: !mode ? 16 : 14,
        },
      },
    },
    maintainAspectRatio: false,
  };

  const handleFetchRandomPosts = async (item) => {
    setPostsLoading(true);
    const result = await apiRequest(
      "executive_summary/v2/generate_reviews",
      "POST",
      {
        card_id: selectedMetricID,
      }
    );
    setPostsLoading(false);
    if (!result.error) {
      let reviews = result.reviews;
      if (reviews.length == 0 || reviews === undefined) setRandomPosts([]);
      let positive = [];
      let negative = [];
      reviews.map((info) => {
        if (info.sentiment == 0) {
          negative.push(info);
        } else {
          positive.push(info);
        }
      });

      let positiveReview = [];

      reviews.map((info, index) => {
        if (index % 2 == 0) {
          positiveReview.push(positive.pop());
        } else {
          positiveReview.push(negative.pop());
        }
      });
      setRandomPosts([...positiveReview]);
    }
  };

  const handleSaveReviews = async () => {
    let metric = activeMetric;
    setSavingPosts(true);
    const result = await apiRequest("executive_summary/save_reviews", "POST", {
      card_id: activeMetric.id,
      reviews: randomPosts,
    });
    setSavingPosts(false);
    if (!result.error) {
      notification.success({ message: "Saved!" });
    }
  };

  const handleDeleteChart = async (id) => {
    if (metricLocation === "bottom1" || metricLocation === "bottom2") {
      setIncDecLoading(true);
    } else if (metricLocation === "bottom3") {
      setStatisticsLoading(true);
    } else {
      setTopMetricsLoading(true);
    }
    const result = await apiRequest("executive_summary/deleteChart", "POST", {
      team: selectedTeamItem,
      block_location: metricLocation,
      id: selectedMetricID,
    });
    if (result?.success) {
      notification.success({ message: t("notifications.21") });
      if (metricLocation === "bottom1" || metricLocation === "bottom2") {
        getTopIncDecTopicsData();
      } else if (metricLocation === "bottom3") {
        getStatisticsData();
      } else {
        getTopChartsData();
      }
      setStartIndex(0);
      setUpdateWidth(0);
      carouselContainerRef.current.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    } else {
      notification.error({
        message: result?.error,
      });
      if (metricLocation === "bottom1" || metricLocation === "bottom2") {
        setIncDecLoading(false);
      } else if (metricLocation === "bottom3") {
        setStatisticsLoading(false);
      } else {
        setTopMetricsLoading(false);
      }
    }
    if (setDataLoading) setDataLoading(false);
  };

  const getTopChartsData = async (page) => {
    setTopMetricsLoading(true);
    let payload = {
      team: selectedTeamItem || teamID.get("id"),
      period: chartPeriod || teamID.get("period"),
      show_reviews: false,
      start_index: page >= 0 ? page : startIndex,
      num_top_kpis: 4,
    };
    const result = await apiRequest(
      "executive_summary/v2/top_data",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      if (page >= 0 && teamID.get("id")) {
        setCache((prev) => {
          const updated = { ...prev };
          updated[page] = result.success;
          return updated;
        });
        setTotalMetrics(result.success?.total_top_metrics);
      }
      setChartItems(result.success?.top);
      setTotalTopMetrics(result.success?.total_top_metrics);
      setAllTopicsList(result.success.all_kpis_list);
      setBubbleChartItems(result.success?.bubbles);
      updateChartItems(result.success?.top, result.success?.total_top_metrics);
      setDeleteModalOpen(false);
      setShowRenamePopup(false);
      setKpiGroupNameList(result.success?.pages_subtitle);
    } else {
      notification.error({
        message: result?.error ? result.error : "Unable to get top KPIs data",
      });
    }
    setTopMetricsLoading(false);
    setChartsLoading(false);
    if (setDataLoading) setDataLoading(false);
  };
  const getTopIncDecTopicsData = async () => {
    setIncDecLoading(true);
    let payload = {
      team: selectedTeamItem || teamID.get("id"),
      period: chartPeriod || teamID.get("period"),
      show_reviews: false,
    };

    const result = await apiRequest(
      "executive_summary/v2/top_inc_dec_topics",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      setFixedChartItems((prev) => {
        const updated = { ...prev };
        updated.firstCol = result.success.bottom.firstCol;
        updated.secondCol = result.success.bottom.secondCol;
        return updated;
      });
      setDeleteModalOpen(false);
      setShowRenamePopup(false);
    } else {
      notification.error({
        message: result?.error
          ? result.error
          : "Unable to get Top Increased and Decreased topics data",
      });
    }
    setIncDecLoading(false);
    setChartsLoading(false);
    if (setDataLoading) setDataLoading(false);
  };

  const getStatisticsData = async () => {
    setStatisticsLoading(true);
    let payload = {
      team: selectedTeamItem || teamID.get("id"),
      period: chartPeriod || teamID.get("period"),
      show_reviews: false,
    };

    const result = await apiRequest(
      "executive_summary/v2/data_statistics",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      setDeleteModalOpen(false);
      setShowRenamePopup(false);
      setFixedChartItems((prev) => {
        const updated = { ...prev };
        updated.thirdCol = result.success.bottom.thirdCol;
        return updated;
      });
    } else {
      notification.error({
        message: result?.error ? result.error : "Unable to get Statistics data",
      });
    }
    setStatisticsLoading(false);
    setChartsLoading(false);
    if (setDataLoading) setDataLoading(false);
  };
  const getGlobalChartsData = async () => {
    getTopChartsData();
    getTopIncDecTopicsData();
    getStatisticsData();
  };

  useEffect(() => {
    if (!chartsLoading && selectedTeamItem) {
      setEmptyChartItems([]);
      setStartIndex(0);
      getGlobalChartsData();
    }
  }, [selectedTeamItem]);

  useEffect(() => {
    if (!chartsLoading && selectedTeamItem) {
      setEmptyChartItems([]);
      getGlobalChartsData();
    }
  }, [chartPeriod, cardChart]);
  useEffect(() => {
    if (!chartsLoading && selectedTeamItem) {
      setEmptyChartItems([]);
      getTopChartsData();
    }
  }, [startIndex]);
  // const handlePrevSection = () => {
  //   if (startIndex === 0) return;
  //   else {
  //     carouselContainerRef.current.scrollTo({
  //       left: updateWidth - (window.innerWidth > 1350 ? 1000 : 840),
  //       behavior: "smooth",
  //     });
  //     window.innerWidth > 1350
  //       ? setUpdateWidth((prev) => prev - 1000)
  //       : setUpdateWidth((prev) => prev - 840);
  //     setStartIndex((prev) => prev - 1);
  //   }
  // };
  // const handleNextSection = () => {
  //   if (
  //     startIndex + 1 !==
  //     Math.ceil(totalTopMetrics / 4) +
  //       Math.floor(emptyChartItems.length / 4) +
  //       bubbleChartItems.length
  //   ) {
  //     carouselContainerRef.current.scrollTo({
  //       left: updateWidth + (window.innerWidth > 1350 ? 1000 : 840),
  //       behavior: "smooth",
  //     });
  //     window.innerWidth > 1350
  //       ? setUpdateWidth((prev) => prev + 1000)
  //       : setUpdateWidth((prev) => prev + 840);
  //     setStartIndex((prev) => prev + 1);
  //   } else if (
  //     Math.ceil(totalTopMetrics / 4) +
  //       Math.floor(emptyChartItems.length / 4) +
  //       bubbleChartItems.length ===
  //     startIndex + 1
  //   ) {
  //     return;
  //   } else return;
  // };
  const updateChartItems = (result, totalMetrics) => {
    if (result?.length === 0) {
      let items = [];
      for (let i = 1; i <= 4; i++) {
        let item = React.createElement(
          "div",
          {
            className: "chart-item-global empty-chart-item",
            key: i + startIndex * 4,
          },
          [
            <SelectMetric
              itemKey={startIndex * 4 + i}
              setSelectedItemKey={setSelectedItemKey}
              setCardChart={setCardChart}
              blockLocation="Top"
              selectedTeam={selectedTeamItem}
              getGlobalChartsData={() => {
                getTopChartsData(Math.ceil(totalMetrics / 4) - 1);
              }}
            />,
          ]
        );
        items.push(item);
      }
      setEmptyChartItems([...items]);
    } else if (result?.length !== 0 && result?.length < 4) {
      const num = result.length + 1;
      let items = [];

      for (let i = num; i <= 4; i++) {
        let item = React.createElement(
          "div",
          {
            className: "chart-item-global empty-chart-item",
            key: i + startIndex * 4,
          },
          [
            <SelectMetric
              itemKey={startIndex * 4 + i}
              setSelectedItemKey={setSelectedItemKey}
              setCardChart={setCardChart}
              blockLocation="Top"
              selectedTeam={selectedTeamItem}
              getGlobalChartsData={() => {
                getTopChartsData(Math.ceil(totalMetrics / 4) - 1);
              }}
            />,
          ]
        );
        items.push(item);
      }
      setEmptyChartItems([...items]);
    } else if (result.length === 4 && (startIndex + 1) * 4 === totalMetrics) {
      setTotalTopMetrics((prev) => prev + 4);
    }
    setTopMetricsLoading(false);
  };

  // useEffect(() => {
  //   if (!mode) {
  //     setIsKpiGroupName(true);
  //     let interval;
  //     carouselRef.current.style.transition = "transform 0.7s linear";
  //     if (chartItems?.length > 4 && chartItems?.length % 4 !== 0) {
  //       interval = setInterval(() => {
  //         carouselRef.current.style.transform = `translateX(${-updateWidth -
  //           1188}px)`;
  //         setUpdateWidth((prev) => prev + 1177);
  //       }, 15000);
  //     } else if (totalTopMetrics >= 8 && totalTopMetrics % 4 === 0) {
  //       interval = setInterval(() => {
  //         carouselRef.current.style.transform = `translateX(${-updateWidth -
  //           1188}px)`;
  //         setUpdateWidth((prev) => prev + 1177);
  //       }, 15000);
  //     } else if (chartItems?.length === 0 && bubbleChartItems?.length === 1) {
  //       return;
  //     } else if (chartItems?.length <= 4 && bubbleChartItems?.length !== 0) {
  //       interval = setInterval(() => {
  //         carouselRef.current.style.transform = `translateX(${-updateWidth -
  //           1188}px)`;
  //         setUpdateWidth((prev) => prev + 1177);
  //       }, 15000);
  //     } else return;
  //     return () => clearInterval(interval);
  //   }
  // }, [chartItems, emptyChartItems, bubbleChartItems]);

  const returnEmptyCharts = (items) => {
    let elem = [];
    let num = items.length % 4;
    for (let i = num; i < 4; i++) {
      elem.push(<div className="chart-item-global empty-kpi-chart-box"></div>);
    }
    return elem;
  };

  const handleTransitionEnd = () => {
    let item1 = carouselRef.current.children[0];
    let item2 = carouselRef.current.children[1];
    let item3 = carouselRef.current.children[2];
    let item4 = carouselRef.current.children[3];
    if (item1.classList.contains("kpi-ai-bubble-chart")) {
      carouselRef.current.appendChild(item1);
    } else {
      carouselRef.current.appendChild(item1);
      carouselRef.current.appendChild(item2);
      carouselRef.current.appendChild(item3);
      carouselRef.current.appendChild(item4);
    }

    carouselRef.current.style.transition = "none";
    carouselRef.current.style.transform = "translateX(0)";
    setTimeout(() => {
      carouselRef.current.style.transition = "transform 0.7s linear";
    });
    setPresentationPageCounter((prev) => prev + 1);
  };

  // useEffect(() => {
  //   if (!mode) {
  //     setInterval(() => {
  //       setChartItems([]);
  //       const boxes = Array.from(
  //         document.getElementsByClassName("empty-kpi-chart-box")
  //       );
  //       boxes.forEach((box) => {
  //         box.remove();
  //       });
  //       getGlobalChartsData();
  //       setPresentationPageCounter(1);
  //       setUpdateWidth(0);
  //       //7200000 for 2hrs
  //     }, 7202000);
  //   }
  // }, []);

  function openFullscreen() {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
      setFullscreenMode(true);
    } else if (document.documentElement.webkitRequestFullscreen) {
      /* Safari */
      document.documentElement.webkitRequestFullscreen();
      setFullscreenMode(true);
    } else if (document.documentElement.msRequestFullscreen) {
      /* IE11 */
      document.documentElement.msRequestFullscreen();
      setFullscreenMode(true);
    }
  }

  function closeFullscreen() {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
      setFullscreenMode(false);
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
      setFullscreenMode(false);
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
      setFullscreenMode(false);
    }
  }

  const updateChartItemOrder = async (start, end) => {
    if (start === end) return;
    setChartsLoading(true);
    const payload = {
      destination_index: end,
      id: chartItems[start].id,
    };
    const result = await apiRequest(
      "executive_summary/orderChart",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      if (end > start) {
        carouselContainerRef.current.scrollTo(0, 0);
        setTimeout(() => {
          setStartIndex(Math.trunc(end / 4));
          carouselContainerRef.current.scrollTo(
            (window.innerWidth > 1350 ? 1000 : 840) * Math.trunc(end / 4),
            0
          );
          setUpdateWidth(
            (window.innerWidth > 1350 ? 1000 : 840) * Math.trunc(end / 4)
          );
        });
      } else if (start > end) {
        let num = Math.trunc(start / 4) - Math.trunc(end / 4);
        carouselContainerRef.current.scrollTo(
          (window.innerWidth > 1350 ? 1000 : 840) * Math.trunc(start / 4),
          0
        );
        setTimeout(() => {
          setStartIndex((prev) => prev - num);
          carouselContainerRef.current.scrollTo(
            (window.innerWidth > 1350 ? 1000 : 840) * Math.trunc(start / 4) -
              1000 * num,
            0
          );
          setUpdateWidth(
            (window.innerWidth > 1350 ? 1000 : 840) * Math.trunc(start / 4) -
              (window.innerWidth > 1350 ? 1000 : 840) * num
          );
        });
      }
      getGlobalChartsData();
    } else {
      notification.error({
        message: "Action not allowed",
      });
      getGlobalChartsData();
    }
    if (setDataLoading) setDataLoading(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragComplete = (result) => {
    if (!result.destination) return;
    // const items = reorder(
    //   chartItems,
    //   result.source.index,
    //   result.destination.index
    // );
    // const start = result?.source.index;
    // const end = result?.destination.index;

    // //Updating the list
    // setChartItems([...items]);

    // updateChartItemOrder(start, end);
    // setDragId('');
  };

  const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    return (inst) => {
      for (const ref of filteredRefs) {
        if (typeof ref === "function") {
          ref(inst);
        } else if (ref) {
          ref.current = inst;
        }
      }
    };
  };

  const handleMobilePrev = () => {
    if (mobileViewCount === 0) return;
    else {
      carouselRef.current.style.transition = "transform 0.4s linear";
      carouselRef.current.style.transform = `translateX(${-updateWidth +
        210}px)`;
      setUpdateWidth((prev) => prev - 210);
      setMobileViewCount((prev) => prev - 1);
    }
  };
  const handleMobileNext = () => {
    if (mobileViewCount === 4) return;
    else if (mobileViewCount === startIndex * 4 + 3) {
      carouselRef.current.style.transition = "transform 0.4s linear";
      carouselRef.current.style.transform = `translateX(${updateWidth +
        3 * 210}px)`;
      setStartIndex((prev) => prev + 1);
      setUpdateWidth((prev) => prev - 3 * 210);
      setMobileViewCount(0);
    } else {
      carouselRef.current.style.transition = "transform 0.4s linear";
      carouselRef.current.style.transform = `translateX(${-updateWidth -
        210}px)`;
      setUpdateWidth((prev) => prev + 210);
      setMobileViewCount((prev) => prev + 1);
    }
  };

  const handleRenameKPI = async () => {
    setDisableBtn(true);
    if (!renameKpiValue.trim()) {
      notification.error({
        message: "Field cannot be empty!",
      });
    } else {
      const payload = {
        team: selectedTeamItem,
        new_metric: renameKpiValue,
        block_location: metricLocation,
        id: selectedMetricID,
      };
      const result = await apiRequest(
        "executive_summary/rename",
        "POST",
        payload,
        true
      );

      if (result.success) {
        notification.success({
          message: t("notifications.22"),
        });
        setDisableBtn(false);
        if (metricLocation === "bottom1" || metricLocation === "bottom2") {
          getTopIncDecTopicsData();
        } else if (metricLocation === "bottom3") {
          getStatisticsData();
        } else {
          getTopChartsData();
        }
      } else {
        notification.error({
          message: result.error,
        });
        setDisableBtn(false);
      }
    }
    if (setDataLoading) setDataLoading(false);
  };

  useEffect(() => {
    if (kpiGroupNameList?.length > 0) {
      for (let i = 0; i < kpiGroupNameList.length; i++) {
        if (startIndex + 1 === kpiGroupNameList[i].page) {
          setIsKpiGroupName(true);
          setKpiGroupName(kpiGroupNameList[i].name);
          return;
        } else {
          setIsKpiGroupName(false);
        }
      }
    } else {
      setIsKpiGroupName(false);
    }
  }, [kpiGroupNameList, startIndex]);
  const tooltipLine = {
    id: "tooltipLine",
    beforeDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.setLineDash([4, 6]);
        ctx.moveTo(activePoint?._view?.x, chart.chartArea.top);
        ctx.lineTo(activePoint?._view?.x, chart.chartArea.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "rgba(155, 155, 155)";
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  useEffect(() => {
    if (!mode) {
      if (totalTopMetrics !== 0 && kpiGroupNameList.length !== 0) {
        for (let i = 0; i < kpiGroupNameList.length; i++) {
          if (currPage + 1 === kpiGroupNameList[i].page) {
            setKpiGroupName(kpiGroupNameList[i].name);
            return;
          } else {
            setKpiGroupName("");
          }
        }
      } else {
        setKpiGroupName("");
      }
    }
  }, [currPage, kpiGroupNameList]);

  const handleTabletView = async () => {
    if (window.innerWidth <= 968) {
      if (carouselContainerRef.current.scrollLeft < 840) {
        setStartIndex(0);
      } else {
        setStartIndex(
          Math.trunc(
            (carouselContainerRef.current.scrollWidth -
              (carouselContainerRef.current.scrollWidth -
                carouselContainerRef.current.scrollLeft)) /
              840
          )
        );
      }
    }
  };

  const handleAddKpiGroupName = async () => {
    setDisableBtn(true);
    const payload = {
      page: teamID.get("id") ? currPage + 1 : startIndex + 1,
      name: addKpiGroupName,
      team: teamID.get("id") ? teamID.get("id") : selectedTeamItem,
    };
    const response = await apiRequest(
      "executive_summary/subtitles/add",
      "POST",
      payload,
      true
    );

    if (response.success) {
      notification.success({
        message: t("notifications.23"),
      });
      if (teamID.get("id")) {
        setCache({});
        getTopChartsData(0);
      } else {
        getTopChartsData();
      }
      setDisableBtn(false);
      setShowRenameKpiGroup(false);
      setShowAddKpiGroupName(false);
    } else {
      notification.error({
        message: response.error,
      });
      setDisableBtn(false);
      setShowRenameKpiGroup(false);
      setShowAddKpiGroupName(false);
    }
    if (setDataLoading) setDataLoading(false);
  };

  const handleAddAlert = async (
    condition,
    threshold,
    users,
    frequency,
    smsBoxChecked
  ) => {
    setDisableBtn(true);
    const payload = {
      team: selectedTeamItem,
      alert_title: Array.isArray(metricName)
        ? metricName.join(" ")
        : metricName,
      metrics: metricList,
      condition: condition,
      threshold: parseInt(threshold, 10),
      source: dashboardIds,
      send_to: users,
      frequency: frequency,
      id: selectedMetricID,
      sms_checked: smsBoxChecked,
    };
    const response = await apiRequest(
      "notice/alerts/create",
      "POST",
      payload,
      true
    );

    if (response.success) {
      notification.success({
        message: t("notifications.144"),
      });
      setShowAlert(false);
      setDisableBtn(false);
      getGlobalChartsData();
    } else {
      notification.error({
        message: response.error,
      });
      setDisableBtn(false);
    }
  };

  const getBubbleDetails = (bubble) => {
    if (bubble?.length !== 0) {
      let item = bubble?.map((item) => {
        return {
          label: item.topic_name,
          data: [
            {
              x: item.x,
              y: item.y,
              r: item.radius,
              name: item.topic_name,
            },
          ],
          backgroundColor: item.color,
          top_words: item.top_words,
        };
      });
      return {
        datasets: item,
      };
    }
  };

  const getTopChartsPresentationData = async (page) => {
    //Data already present => Load data from cacheRef and return
    if (cache && cache[page]) {
      carouselRef.current.style.transition = "transform 0.4s linear";
      // carouselRef.current.style.transform = `translateX(${-updateWidth -
      //   1188}px)`;
      console.log(`KPIs loaded from cache for page ${page}`);
      setChartItems(cache[page]?.top);
      setTotalMetrics(cache[page]?.total_top_metrics);
      setBubbleChartItems(cache[page]?.bubbles);
      updateChartItems(cache[page]?.top, cache[page]?.total_top_metrics);
      setDeleteModalOpen(false);
      setShowRenamePopup(false);
      setKpiGroupNameList(cache[page]?.pages_subtitle);
      mode && setShowBelowChartIndex("");
      return;
    }
    //Fetch data from the API for that page
    console.log(`KPIs loaded from API for page ${page}`);
    getTopChartsData(page);
  };

  const getChartsDataForPresentationMode = async (page) => {
    getTopChartsPresentationData(page);
    getTopIncDecTopicsData();
    getStatisticsData();
  };

  // Fetch the data (either from cache or the API for every page)
  useEffect(() => {
    if (teamID.get("id")) {
      getChartsDataForPresentationMode(currPage);
    }
  }, []);
  const onlyOnceRef = useRef(true);
  useEffect(() => {
    if (teamID.get("id")) {
      if (onlyOnceRef.current) {
        onlyOnceRef.current = false;
        return;
      }
      getTopChartsPresentationData(currPage);
    }
  }, [currPage]);
  //Automate the pagination
  useEffect(() => {
    let intervalId;
    if (
      totalMetrics &&
      !topMetricsLoading &&
      !incDecLoading &&
      !statisticsLoading
    ) {
      intervalId = setInterval(() => {
        setCurrPage(
          (prev) =>
            (prev + 1) %
            (totalMetrics % 4 === 0
              ? totalMetrics / 4
              : Math.ceil(totalMetrics / 4))
        ); // Loop through pages by incrementing one by one and getting back to start(0) when totalPages are traversed.
      }, 15000); //15 seconds
    }
    return () => {
      clearInterval(intervalId);
      intervalId = null;
    };
  }, [totalMetrics, topMetricsLoading, incDecLoading, statisticsLoading]);

  //Clear cacheRef after 2 hours
  useEffect(() => {
    if (teamID.get("id")) {
      let intervalId;
      const clearCache = () => {
        setCache({});
        setTopMetricsLoading(true);
        getChartsDataForPresentationMode(0);
      };
      intervalId = setInterval(clearCache, 2 * 60 * 60 * 1000); // 2 hours
      return () => {
        clearInterval(intervalId);
        intervalId = null;
      };
    }
  }, []);

  const getSources = async () => {
    const result = await apiRequest("executive_summary/source", "POST", true);
    if (result.success) {
      setSources(result?.success);
    } else {
      notification.error({
        message: t("others.2"),
      });
    }
  };

  const getMetricsList = async (value, topics, location) => {
    let include_topics = "";
    if (topics) include_topics = "Choose your own topics";
    const payload = {
      dashboard_ids: value.map((str) => parseInt(str, 10)),
      block_location: location
        ? location
        : editChartLocation === "bubble"
        ? "Top"
        : editChartLocation,
      custom_metric: include_topics,
    };
    const result = await apiRequest(
      "executive_summary/v2/metricList",
      "POST",
      payload,
      true
    );
    if (result.success) {
      setAiTopics(result?.success?.ai_topics);
      setCustomMetrics(result?.success?.custom_metrics);
      if (topics) setGeneralCustomMetric(result?.success?.general_metrics);
      else setGeneralMetrics(result?.success?.general_metrics);
    }
  };

  const handleOpenEdit = (location, item) => {
    getSources();
    setSelectedMetricID(item.id);
    setEditChartLocation(location);
    setShowEditKPIPopup(true);
    if (item) {
      if (item.topics && item.topics.length > 0) {
        setSelectedMetricList(item.topics);
        getMetricsList(JSON.parse(item.dashboard_ids), item.topics, location);
        setCustomTitle(item?.name || "");
      } else {
        getMetricsList(JSON.parse(item.dashboard_ids), "", location);
      }
      setSelectedDashboards(JSON.parse(item.dashboard_ids));
      if (location === "Top" || location === "bottom3")
        setSelectedMetricList(item.metric);
      else if (location === "bottom1" || location === "bottom2")
        setSelectedCustomMetric(item.metric);
    }
  };
  const handleCloseEdit = () => {
    setShowEditKPIPopup(false);
    setSelectedAITopics([]);
    setSelectedDashboards([]);
    setSelectedMetricList([]);
    setSelectedCustomMetric(null);
  };

  const handleSourceChange = (value) => {
    getMetricsList(value);
    setSelectedMetricList([]);
    setSelectedDashboards(value);
  };
  const bgColors = [
    "#4400AA",
    "#FF5280",
    "#9E1684",
    "#4582C4",
    "#5C2A70",
    "#D41C1C",
    "#87050D",
    "#836DDE",
    "#FF056D",
    "#BD02FF",
    "#6E6980",
    "#671D9E",
    "#DE7070",
    "#0027FF",
    "#46ABBF",
    "#FF0C32",
    "#D6336B",
    "#0C3F54",
    "#C9A479",
    "#140A80",
  ];

  useEffect(() => {
    if (selectedDashboardIds && selectedDashboardIds.length > 0) {
      const clrMap = {};
      selectedDashboardIds.map((id, index) => {
        clrMap[id] = bgColors[index % bgColors.length];
      });
      setColorMap(clrMap);
    } else {
      setReviewsStartIndex(0);
      setNumOfDocs(10);
      setColorMap({});
    }
  }, [selectedDashboardIds]);
  const onMetricsChange = (value) => {
    if (value.length > 4 && selectedCustomMetric === "Choose your own topics") {
      notification.error({
        message: t("notifications.24"),
      });
    } else setSelectedMetricList(value);
  };

  const onCustomMetricsChange = (value) => {
    setSelectedCustomMetric(value);
    if (value === "Choose your own topics")
      getMetricsList(selectedDashboards, value);
    else setSelectedMetricList([]);
  };

  const handleEditKPI = async () => {
    if (selectedDashboards === null || selectedDashboards.length === 0) {
      notification.error({
        message: t("notifications.26"),
      });
      return;
    } else if (selectedMetricList === null && editChartLocation !== "bubble") {
      notification.error({
        message: t("notifications.25"),
      });
      return;
    }
    setDisableBtn(true);
    const payload = {
      dashboard_ids: selectedDashboards?.map((str) => parseInt(str, 10)),
      metric: selectedMetricList,
      bottom_metric: selectedCustomMetric,
      block_location: editChartLocation,
      operation: sumMean,
      team: selectedTeamItem,
      custom_title: customTitle,
      ai_bubbles: selectedAITopics,
      id: selectedMetricID,
    };
    const result = await apiRequest(
      "executive_summary/editKpi",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      // setCardChart(result?.success);
      if (editChartLocation === "bottom1" || editChartLocation === "bottom2") {
        getTopIncDecTopicsData();
      } else if (editChartLocation === "bottom3") {
        getStatisticsData();
      } else {
        getTopChartsData();
      }
      setDisableBtn(false);
      setShowEditKPIPopup(false);
    } else {
      notification.error({
        message: result?.error,
      });
      setDisableBtn(false);
    }
    if (setDataLoading) setDataLoading(false);
  };
  const handleSearchWord = (tag) => {
    const selectedTags = searchKeywords;
    const nextSelectedTags = tag ? [...selectedTags, tag] : selectedTags;
    setSearchWord("");
    setReviewsStartIndex(0);
    setNumOfDocs(10);
    setSearchKeywords(nextSelectedTags);
  };

  const getKPIReviews = async (updateReviewsIndex, reset) => {
    setReviewsLoading(true);
    const payload = {
      dashboard_ids: selectedDashboardIds,
      metric: selectedTopicLabels,
      period: chartPeriod || teamID?.get("period"),
      page_size: numOfDocs,
      start_index: reset
        ? 0
        : updateReviewsIndex
        ? updateReviewsIndex > 1
          ? updateReviewsIndex
          : reviewsStartIndex + updateReviewsIndex
        : 0,
    };
    if (searchKeywords && searchKeywords.length > 0) {
      payload.keyword = searchKeywords;
    }
    const res = await apiRequest(
      "executive_summary/get_kpi_reviews",
      "POST",
      payload,
      true
    );
    if (res && res.success) {
      setAllReviews(res.success.docs);
      setNumReviews(res.success.total_num_docs);
      setReviewsStartIndex(
        reset
          ? 0
          : updateReviewsIndex
          ? updateReviewsIndex > 1
            ? updateReviewsIndex
            : reviewsStartIndex + updateReviewsIndex
          : reviewsStartIndex
      );
      setReviewsLoading(false);
    } else {
      notification.error({
        message: res?.error ? res?.error : "Unable to get KPI reviews",
      });
      setReviewsLoading(false);
    }
  };
  useEffect(() => {
    if (!selectedDashboardIds.length > 0 && !selectedTopicLabels.length > 0) {
      setStartIndex(0);
      setNumOfDocs(10);
    }
  }, [selectedDashboardIds, selectedTopicLabels]);
  useEffect(() => {
    if (selectedDashboardIds.length > 0 && selectedTopicLabels.length > 0) {
      if (stopInitSearchRef.current) {
        stopInitSearchRef.current = false;
        return;
      }
      getKPIReviews();
    } else if (!stopInitSearchRef.current) {
      stopInitSearchRef.current = true;
      setSearchKeywords([]);
    }
  }, [selectedDashboardIds, selectedTopicLabels, searchKeywords]);
  useEffect(() => {
    if (totalTopMetrics === 0 && startIndex !== 0) {
      setTotalTopMetrics((startIndex + 1) * 4);
    }
  }, [startIndex, totalTopMetrics]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    if (value !== "") {
      const filtered = allTopicsList.filter((item) => {
        const nameMatch =
          item.name && item.name.toLowerCase().includes(value.toLowerCase());
        const metricArray = JSON.parse(item.metric.replace(/'/g, '"'));
        const metricMatch = metricArray.some((metric) =>
          metric.toLowerCase().includes(value.toLowerCase())
        );
        return nameMatch || metricMatch;
      });
      setFilteredAllTopicsList(filtered);
    }
  };
  useEffect(() => {
    const handleOuterClick = (e) => {
      if (
        !e.target.closest(
          `.${styles.DropdownContainer}, .ant-input, .ant-dropdown, .ant-dropdown-menu-item`
        )
      ) {
        setFilteredAllTopicsList([]);
        setSearchInput("");
      }
    };
    document.addEventListener("click", handleOuterClick);
    return () => {
      document.removeEventListener("click", handleOuterClick);
    };
  }, []);
  useEffect(() => {
    if (searchedTopic && searchedTopic?.id !== "-1") {
      const clickedKPI = allTopicsList.find(
        (item) => item.id?.toString() === searchedTopic.id?.toString()
      );
      if (
        clickedKPI &&
        startIndex !== Math.floor((clickedKPI.order || 0) / 4)
      ) {
        setStartIndex(Math.floor((clickedKPI.order || 0) / 4));
        setSearchInput("");
        setFilteredAllTopicsList([]);
        setSearchedTopic(null);
      }
    }
  }, [searchedTopic]);
  useEffect(() => {
    setLineChartDependency(lineChartDependency === 1 ? 2 : 1);
  }, [chartItems, showDataLabel]);
  return authenticated === "logged" ? (
    <div
      className={
        theme === "dark"
          ? "default-theme-executive"
          : theme === "light"
          ? "light-theme-executive"
          : "brand-theme-executive"
      }
      style={{ height: !mode ? "100vh" : "auto" }}
    >
      {!mode ? (
        <div ref={titleRef} className="presentation-mode-title">
          <img src="/assets/pivony-logo.png" alt="" className="logo" />
          <span>
            {theme === "brand" && user.logo_url ? (
              <img src={user?.logo_url} alt="" />
            ) : (
              ""
            )}
            {window.title ? window.title.trim() : "Global Executive Dashboard"}
          </span>

          {fullscreenMode ? (
            <img
              src="/assets/minimize-2.svg"
              alt=""
              onClick={() => {
                closeFullscreen();
              }}
            />
          ) : (
            <img
              src="/assets/maximize-2.svg"
              alt=""
              onClick={() => {
                openFullscreen();
              }}
            />
          )}
        </div>
      ) : (
        <div className={styles.pagination}>
          <Pagination
            simple
            responsive
            showSizeChanger={false}
            pageSizeOptions={false}
            disabled={chartsLoading}
            current={startIndex + 1}
            pageSize={4}
            total={
              totalTopMetrics === 0 && startIndex !== 0
                ? startIndex + 1
                : totalTopMetrics || totalMetrics || 1
            }
            onChange={(page) => {
              setStartIndex(page - 1);
            }}
          />
        </div>
      )}

      <div
        ref={chartsContainerRef}
        className={`kpi-charts-loader-global ${
          !mode ? "presentation-mode-container" : ""
        }`}
      >
        <Spin spinning={chartsLoading} size="middle">
          <div className="charts-container-global" id="KPIs-container">
            <div className="mobile-view-next-prev">
              <span className="prev-icon" onClick={handleMobilePrev}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect width="24" height="24" rx="12" fill="#D9D9D9" />
                  <path
                    d="M14 8L10 12L14 16"
                    stroke="#4400AA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span className="next-icon" onClick={handleMobileNext}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    width="24"
                    height="24"
                    rx="12"
                    transform="matrix(-1 0 0 1 24 0)"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M10 8L14 12L10 16"
                    stroke="#4400AA"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <Spin spinning={topMetricsLoading}>
              <div
                className="charts-carousel-global"
                ref={carouselContainerRef}
                onScroll={handleTabletView}
              >
                <div className={styles.kpiGroupNameContainer}>
                  <div
                    className="add-kpi-group-name-container"
                    style={{ color: theme === "light" ? "#000000" : "#FFFFFF" }}
                  >
                    {isKpiGroupName ? (
                      <div
                        onClick={() => {
                          setAddKpiGroupName(kpiGroupName);
                          setShowRenameKpiGroup(true);
                        }}
                        title={kpiGroupName}
                      >
                        {kpiGroupName}{" "}
                        {mode && (
                          <img
                            src={
                              theme === "dark"
                                ? "/assets/rename.svg"
                                : "/assets/rename_black.svg"
                            }
                            alt=""
                          />
                        )}
                      </div>
                    ) : (
                      mode && (
                        <div
                          className="add"
                          onClick={() => {
                            setAddKpiGroupName("");
                            setShowAddKpiGroupName(true);
                          }}
                        >
                          <i className="fa-solid fa-plus"></i>
                          {t("team_kpi_view.4")}
                        </div>
                      )
                    )}
                  </div>
                  {chartItems?.length > 0 && !topMetricsLoading && mode && (
                    <>
                      {showSearchBar && (
                        <div className={styles.searchBar}>
                          <DropdownSelect
                            options={
                              searchInput !== ""
                                ? filteredAllTopicsList.length > 0
                                  ? filteredAllTopicsList.map((item) => ({
                                      id: item.id,
                                      label: item.name
                                        ? item.name
                                        : JSON.parse(
                                            item.metric.replace(/'/g, '"')
                                          ).join(" "),
                                      value: item.id,
                                      img: "",
                                    }))
                                  : [
                                      {
                                        id: "-1",
                                        label: "No Data",
                                        value: "-1",
                                        img: "",
                                      },
                                    ]
                                : []
                            }
                            selectedOptions={searchedTopic}
                            setSelectedOptions={(val) => setSearchedTopic(val)}
                            hiddenTriggerLabel={
                              <div
                                className={`${styles.searchBox} ${styles.showSearch} `}
                              >
                                <img
                                  src={
                                    theme === "dark"
                                      ? "/assets/search.svg"
                                      : "/assets/search_black.svg"
                                  }
                                  alt=""
                                  width={22}
                                  height={22}
                                />
                                <Input
                                  placeholder={t("team_kpi_view.39")}
                                  value={searchInput}
                                  onChange={handleSearch}
                                  onKeyPress={handleSearch}
                                />
                              </div>
                            }
                            maxMenuHeight={"200px"}
                            hideImg={true}
                            triggerEvent={"click"}
                            menuWidth={
                              window.innerWidth > 750
                                ? "20rem"
                                : window.innerWidth > 500
                                ? "15rem"
                                : "10rem"
                            }
                          />
                        </div>
                      )}
                      <button
                        className={styles.search}
                        onClick={() => setShowSearchBar(!showSearchBar)}
                      >
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/search.svg"
                              : "/assets/search_black.svg"
                          }
                          alt=""
                          width={22}
                          height={22}
                        />
                      </button>
                    </>
                  )}
                </div>
                <DragDropContext onDragEnd={onDragComplete}>
                  <Droppable
                    droppableId="drag-drop-list"
                    direction="horizontal"
                    isDropDisabled
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={mergeRefs(carouselRef, provided.innerRef)}
                        {...provided.droppableProps}
                        className="charts-wrapper"
                        id="only-tablet-view"
                        onTransitionEnd={() => !mode && handleTransitionEnd()}
                      >
                        {chartItems?.map((item, index) => {
                          let labels_3months = item.month_labels;
                          // let labels_quarter = ["Q1", "Q2", "Q3", "Q4"];
                          let data_3_months = {
                            labels: labels_3months,
                            datasets: [
                              {
                                data: labels_3months.map(
                                  (x) => item.last_3_month_change[x]
                                ),
                                fill: false,
                                backgroundColor: "rgba(75,192,192,0.2)",
                                borderColor:
                                  theme === "dark"
                                    ? "rgba(217, 217, 217, 0.4)"
                                    : "rgba(74, 77, 78, 0.8)",
                                lineTension: 0,
                                borderWidth: 3,
                                pointRadius: 1,
                                pointBackgroundColor: "rgba(217, 217, 217, 1)",
                                pointBorderColor:
                                  theme === "dark"
                                    ? "rgba(217, 217, 217, 1)"
                                    : "#000000",
                                labels: item.interval,
                              },
                            ],
                          };
                          return (
                            <Draggable
                              key={`item-${index + 1}`}
                              isDragDisabled={true}
                              draggableId={`item-${index + 1}`}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className={`chart-item-global`}
                                  key={index + 1}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {item.alerted && mode ? (
                                    <img
                                      src={
                                        theme === "dark"
                                          ? "/assets/alert_icon.svg"
                                          : "/assets/alert_icon_black.svg"
                                      }
                                      className={styles.markAlert}
                                      alt=""
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  <span className="drag-item-icon">
                                    <svg
                                      width="42"
                                      height="20"
                                      viewBox="0 0 40 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        width="40"
                                        height="24"
                                        rx="3"
                                        fill="#D9D9D9"
                                      />
                                      <circle
                                        cx="12"
                                        cy="8"
                                        r="2"
                                        fill="#942FA1"
                                      />
                                      <circle
                                        cx="12"
                                        cy="16"
                                        r="2"
                                        fill="#942FA1"
                                      />
                                      <circle
                                        cx="20"
                                        cy="8"
                                        r="2"
                                        fill="#942FA1"
                                      />
                                      <circle
                                        cx="20"
                                        cy="16"
                                        r="2"
                                        fill="#942FA1"
                                      />
                                      <circle
                                        cx="28"
                                        cy="8"
                                        r="2"
                                        fill="#942FA1"
                                      />
                                      <circle
                                        cx="28"
                                        cy="16"
                                        r="2"
                                        fill="#942FA1"
                                      />
                                    </svg>
                                  </span>
                                  <div
                                    className="topic-name-more"
                                    style={{
                                      justifyContent: mode
                                        ? "space-between"
                                        : "center",
                                    }}
                                  >
                                    <p
                                      className="topic-name"
                                      title={
                                        item.name
                                          ? item.name
                                          : Array.isArray(item.metric)
                                          ? item.metric.join(" ")
                                          : item.metric
                                      }
                                      onClick={() => {
                                        setSelectedDashboardIds(
                                          JSON.parse(item.dashboard_ids)
                                        );
                                        setSelectedTopicLabels(item.metric);
                                        setSelectedKPITitle(
                                          item.name
                                            ? item.name.length > 35
                                              ? item.name.slice(0, 35) + "..."
                                              : item.name
                                            : Array.isArray(item.metric)
                                            ? item.metric.join(" ").length > 35
                                              ? item.metric
                                                  .join(" ")
                                                  .slice(0, 35) + "..."
                                              : item.metric.join(" ")
                                            : item.metric.length > 35
                                            ? item.metric.slice(0, 35) + "..."
                                            : item.metric
                                        );
                                      }}
                                    >
                                      {item.name
                                        ? item.name
                                        : Array.isArray(item.metric)
                                        ? item.metric.join(" ")
                                        : item.metric}
                                    </p>
                                    {mode ? (
                                      <span
                                        className="chart-more-option"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedMetricID(item.id);
                                          setShowOption(!showOption);
                                          setShowOptionIndex(index + 1);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          className="more-icon"
                                          icon={faEllipsisV}
                                        />
                                        {showOption &&
                                          showOptionIndex === index + 1 && (
                                            <div className="more-option-list">
                                              <span
                                                onClick={(e) => {
                                                  setShowInfoPopup(true);
                                                  setSelectedKpiInfo(item);
                                                }}
                                              >
                                                <img
                                                  src={
                                                    theme === "dark"
                                                      ? "/assets/info1.svg"
                                                      : "/assets/info1_black.svg"
                                                  }
                                                  alt=""
                                                />
                                                {t("team_kpi_view.25")}
                                              </span>
                                              <span
                                                onClick={() => {
                                                  handleOpenEdit("Top", item);
                                                }}
                                              >
                                                <img
                                                  src={
                                                    theme === "dark"
                                                      ? "/assets/edit.svg"
                                                      : "/assets/edit_black.svg"
                                                  }
                                                  alt=""
                                                />
                                                {t("team_kpi_view.27")}
                                              </span>
                                              {/* <span
                                              onClick={() => {
                                                setActiveMetric(item);
                                                handleFetchRandomPosts(item);
                                                setShowPosts(true);
                                              }}
                                              style={{
                                                display: "flex",
                                                columnGap: "5px",
                                              }}
                                            >
                                              <Icon name={"posts"} size={18} />
                                              See Posts
                                            </span> */}
                                              {item.alerted ? (
                                                <span
                                                  onClick={() => {
                                                    history.push(
                                                      "/console/alerts"
                                                    );
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      theme === "dark"
                                                        ? "/assets/alert_icon.svg"
                                                        : "/assets/alert_icon_black.svg"
                                                    }
                                                    alt=""
                                                  />
                                                  {t("team_kpi_view.24")}
                                                </span>
                                              ) : (
                                                <span
                                                  onClick={() => {
                                                    setShowAlert(true);
                                                    setMetricName(
                                                      item.name
                                                        ? item.name
                                                        : item.metric
                                                    );
                                                    setDashboardIds(
                                                      item.dashboard_ids
                                                    );
                                                    setMetricList(item.metric);
                                                  }}
                                                >
                                                  <img
                                                    src={
                                                      theme === "dark"
                                                        ? "/assets/add_alert.svg"
                                                        : "/assets/add_alert_black.svg"
                                                    }
                                                    alt=""
                                                  />
                                                  {t("team_kpi_view.23")}
                                                </span>
                                              )}
                                              <span
                                                onClick={(e) => {
                                                  setShowRenamePopup(true);
                                                  setSelectedMetricID(item.id);
                                                  setRenameKpiValue(
                                                    item.name
                                                      ? item.name
                                                      : item.metric
                                                  );
                                                  setMetricLocation("Top");
                                                }}
                                              >
                                                <img
                                                  src={
                                                    theme === "dark"
                                                      ? "/assets/rename.svg"
                                                      : "/assets/rename_black.svg"
                                                  }
                                                  alt=""
                                                />
                                                {t("button.1")}
                                              </span>
                                              <span
                                                onClick={(e) => {
                                                  setDeleteModalOpen(true);
                                                  setSelectedMetricID(item.id);
                                                  setMetricLocation("Top");
                                                }}
                                              >
                                                <img
                                                  src={
                                                    theme === "dark"
                                                      ? "/assets/trash.svg"
                                                      : "/assets/trash_black.svg"
                                                  }
                                                  alt=""
                                                />
                                                {t("button.2")}
                                              </span>
                                            </div>
                                          )}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                  {item.metric_type === "custom" ? (
                                    <div className="custom-topic-chart">
                                      {/* <span className='custom-points-change'>{item.number_change}</span> */}
                                      <div className="percent-indicator-wrapper">
                                        {item.percentage_change ===
                                        "No change" ? (
                                          <span
                                            className="no-change-custom-percentage"
                                            style={{
                                              color: `${
                                                theme === "dark"
                                                  ? "#fff"
                                                  : "#000"
                                              }`,
                                            }}
                                          >
                                            {t("label.21")}
                                          </span>
                                        ) : (
                                          <span
                                            className="custom-percentage-change"
                                            style={{
                                              color: `${
                                                item?.color === "green"
                                                  ? "#009900"
                                                  : "rgba(225, 74, 71, 1)"
                                              }`,
                                            }}
                                          >
                                            {item.percentage_change + "%"}
                                          </span>
                                        )}
                                        {item.percentage_change === 0 ||
                                        item.percentage_change ===
                                          "No change" ? (
                                          ""
                                        ) : mode ? (
                                          item.is_increased ? (
                                            item.color === "green" ? (
                                              <img
                                                src="/assets/up_indicator_green.svg"
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src="/assets/up_indicator_red.svg"
                                                alt=""
                                              />
                                            )
                                          ) : item.color === "green" ? (
                                            <img
                                              src="/assets/down_indicator_green.svg"
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              src="/assets/down_indicator_red.svg"
                                              alt=""
                                            />
                                          )
                                        ) : item.is_increased ? (
                                          item.color === "green" ? (
                                            <img
                                              src="/assets/up_indicator_present_green.svg"
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              src="/assets/up_indicator_present_red.svg"
                                              alt=""
                                            />
                                          )
                                        ) : item.color === "green" ? (
                                          <img
                                            src="/assets/down_indicator_present_green.svg"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src="/assets/down_indicator_present_red.svg"
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <span className="data-month-label">
                                        {`vs`} {item?.compared_to}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="gauge-chart">
                                      <GaugeChart
                                        id={`chart${String(index + 1)}`}
                                        textColor={"transparent"}
                                        nrOfLevels={3}
                                        arcsLength={item?.arc_length}
                                        colors={[
                                          `${
                                            theme === "brand"
                                              ? "#4A4D4E"
                                              : theme === "light"
                                              ? "#4400AA"
                                              : "rgba(137, 17, 153, 1)"
                                          }`,
                                          `${
                                            item.color_variation === "green"
                                              ? "#009900"
                                              : "rgba(225, 74, 71, 1)"
                                          }`,
                                          `${
                                            theme === "dark"
                                              ? "rgba(28, 25, 59, 1)"
                                              : "#AFAFAF"
                                          }`,
                                        ]}
                                        percent={""}
                                        arcPadding={0}
                                        cornerRadius={0}
                                        arcWidth={0.15}
                                        needleColor="transparent"
                                        needleBaseColor="transparent"
                                        style={{ width: "100%" }}
                                      />
                                      <span className="points start">
                                        {item.inf_born}
                                      </span>
                                      <span className="points end">
                                        {item.sup_born}
                                      </span>
                                      <div className="gauge-percent-indicator-wrapper">
                                        {item.percentage_change ===
                                        "No change" ? (
                                          <span
                                            className="no-change-custom-percentage"
                                            style={{
                                              color: `${
                                                theme === "dark"
                                                  ? "#fff"
                                                  : "#000"
                                              }`,
                                            }}
                                          >
                                            {t("label.21")}
                                          </span>
                                        ) : (
                                          <span
                                            className="custom-percentage-change"
                                            style={{
                                              color: `${
                                                item?.color_variation ===
                                                "green"
                                                  ? "#009900"
                                                  : "rgba(225, 74, 71, 1)"
                                              }`,
                                            }}
                                          >
                                            {item.percentage_change + "%"}
                                          </span>
                                        )}
                                        {item.percentage_change === 0 ||
                                        item.percentage_change ===
                                          "No change" ? (
                                          ""
                                        ) : mode ? (
                                          item.is_increased ? (
                                            item.color_variation === "green" ? (
                                              <img
                                                src="/assets/up_indicator_small_green.svg"
                                                alt=""
                                              />
                                            ) : (
                                              <img
                                                src="/assets/up_indicator_small_red.svg"
                                                alt=""
                                              />
                                            )
                                          ) : item.color_variation ===
                                            "green" ? (
                                            <img
                                              src="/assets/down_indicator_small_green.svg"
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              src="/assets/down_indicator_small_red.svg"
                                              alt=""
                                            />
                                          )
                                        ) : item.is_increased ? (
                                          item.color_variation === "green" ? (
                                            <img
                                              src="/assets/up_indicator_green.svg"
                                              alt=""
                                            />
                                          ) : (
                                            <img
                                              src="/assets/up_indicator_red.svg"
                                              alt=""
                                            />
                                          )
                                        ) : item.color_variation === "green" ? (
                                          <img
                                            src="/assets/down_indicator_green.svg"
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src="/assets/down_indicator_red.svg"
                                            alt=""
                                          />
                                        )}
                                      </div>
                                      <span className="data-month-label">
                                        {`vs`} {item?.compared_to}
                                      </span>
                                    </div>
                                  )}

                                  <div className="line-chart">
                                    <CustomChart
                                      type={"line"}
                                      data={data_3_months}
                                      options={options}
                                      theme={theme}
                                      dependency={lineChartDependency}
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        {mode && emptyChartItems.map((item) => <>{item}</>)}

                        {chartItems?.length !== 0 &&
                          chartItems?.length % 4 !== 0 &&
                          !mode &&
                          returnEmptyCharts(chartItems)}

                        {bubbleChartItems?.map((bubble, index) => {
                          if (bubble?.bubbles?.length !== 0) {
                            return (
                              <>
                                <div
                                  key={`bubble${index + 1}`}
                                  className={`kpi-ai-bubble-chart ${
                                    styles.kpiAIBubbleChart
                                  } ${
                                    theme === "light"
                                      ? styles.lightBubbleChart
                                      : theme === "brand"
                                      ? styles.brandBubbleChart
                                      : undefined
                                  } ${
                                    !mode
                                      ? styles.bubblePresentation
                                      : undefined
                                  }`}
                                >
                                  {/* <Bubble
                                    data={getBubbleDetails(bubble?.bubbles)}
                                    options={bubbleOption}
                                    plugins={ChartDataLabels}
                                  /> */}
                                  {mode ? (
                                    <span
                                      className={styles.bubbleMoreOption}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowOption(!showOption);
                                        setShowOptionIndex(`bubbleChart${1}`);
                                      }}
                                    >
                                      <img
                                        src={
                                          theme === "dark"
                                            ? "/assets/more-vertical.svg"
                                            : "/assets/more-vertical-black.svg"
                                        }
                                        alt=""
                                      />
                                      {showOption &&
                                        showOptionIndex ===
                                          `bubbleChart${1}` && (
                                          <div
                                            className={
                                              styles.bubbleMoreOptionPopup
                                            }
                                          >
                                            <span
                                              onClick={() =>
                                                handleOpenEdit(
                                                  "bubble",
                                                  bubble.id
                                                )
                                              }
                                            >
                                              <img
                                                src={
                                                  theme === "dark"
                                                    ? "/assets/edit.svg"
                                                    : "/assets/edit_black.svg"
                                                }
                                                alt=""
                                              />
                                              {t("team_kpi_view.27")}
                                            </span>
                                            <span
                                              onClick={(e) => {
                                                setDeleteModalOpen(true);
                                                setMetricLocation("bubble");
                                                setSelectedMetricID(bubble.id);
                                              }}
                                            >
                                              <img
                                                src={
                                                  theme === "dark"
                                                    ? "/assets/trash.svg"
                                                    : "/assets/trash_black.svg"
                                                }
                                                alt=""
                                              />
                                              {t("button.2")}
                                            </span>
                                          </div>
                                        )}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            );
                          }
                        })}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </Spin>
            {/* <div className="progress-bar-container">
              <span
                className="progress-bar"
                style={{ transform: `translateX(${progressBarUpdate}px)` }}
              ></span>
            </div> */}
            <div className="overall-details-container-global">
              {fixedChartItems &&
              fixedChartItems.firstCol &&
              Object.keys(fixedChartItems?.firstCol)?.length > 0 ? (
                <Spin spinning={incDecLoading}>
                  <div className="box negative-topics-block">
                    <>
                      <div className="below-topic-name-more">
                        <p className="overall-block-heading">
                          {fixedChartItems.firstCol.name
                            ? fixedChartItems.firstCol.name.length > 35
                              ? fixedChartItems.firstCol.name.slice(0, 35) +
                                "..."
                              : fixedChartItems.firstCol.name
                            : fixedChartItems.firstCol.metric.length > 35
                            ? fixedChartItems.firstCol.metric.slice(0, 35) +
                              "..."
                            : fixedChartItems.firstCol.metric}
                        </p>
                        {mode ? (
                          <span
                            className="chart-more-option"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowBelowChartIndex("static1");
                              setShowOptionIndex(1);
                            }}
                          >
                            <FontAwesomeIcon
                              className="more-icon"
                              icon={faEllipsisV}
                            />
                            {showBelowChartIndex === "static1" && (
                              <div
                                className="more-option-list"
                                style={{ right: "0" }}
                              >
                                <span
                                  onClick={(e) => {
                                    setShowRenamePopup(true);
                                    setRenameKpiValue(
                                      fixedChartItems.firstCol.metric
                                    );
                                    setSelectedMetricID(
                                      fixedChartItems.firstCol.id
                                    );
                                    setMetricLocation("bottom1");
                                  }}
                                >
                                  {t("button.1")}
                                </span>
                                <span
                                  onClick={() => {
                                    handleOpenEdit(
                                      "bottom1",
                                      fixedChartItems?.firstCol
                                    );
                                  }}
                                >
                                  {t("team_kpi_view.27")}
                                </span>
                                <span
                                  onClick={(e) => {
                                    setDeleteModalOpen(true);
                                    setSelectedMetricID(
                                      fixedChartItems.firstCol.id
                                    );
                                    setMetricLocation("bottom1");
                                  }}
                                >
                                  {t("button.2")}
                                </span>
                              </div>
                            )}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>

                      {fixedChartItems?.firstCol?.metric ===
                      "Choose your own topics" ? (
                        <ul className="top-listed-items custom">
                          {fixedChartItems?.firstCol?.topics.map((item) => (
                            <li>
                              <span>
                                {item.split(">").slice(-1).length > 40
                                  ? item.slice(-1).slice(0, 40) + "..."
                                  : item.split(">").slice(-1)}
                              </span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <ul className="top-listed-items">
                          <li className="top-listed-title">
                            {fixedChartItems?.firstCol?.change_flag}
                          </li>
                          {fixedChartItems?.firstCol?.topics?.length > 0 ? (
                            fixedChartItems?.firstCol?.topics.map((item) => (
                              <li>
                                <span>
                                  {item.label.split(">").slice(-1).length > 40
                                    ? item.label
                                        .split(">")
                                        .slice(-1)
                                        .slice(0, 40) + "..."
                                    : item.label.split(">").slice(-1)}
                                </span>
                                <span
                                  className={`${
                                    fixedChartItems.firstCol.color === "red"
                                      ? "pointsr"
                                      : "pointsg"
                                  }`}
                                  style={{
                                    color:
                                      item.color === "red"
                                        ? "rgba(194, 76, 73, 1)"
                                        : "#009900",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item.change}
                                </span>
                              </li>
                            ))
                          ) : (
                            <span className={styles.fixedChartsEmptyMsg}>
                              No significant change in this time period
                            </span>
                          )}
                        </ul>
                      )}
                    </>
                  </div>
                </Spin>
              ) : (
                <Spin spinning={incDecLoading}>
                  <div
                    className={`box negative-topics-block ${mode &&
                      "below-empty-block1"}`}
                  >
                    {mode && (
                      <SelectMetric
                        blockLocation="bottom1"
                        setCardChart={setCardChart}
                        selectedTeam={selectedTeamItem}
                        getGlobalChartsData={getTopIncDecTopicsData}
                      />
                    )}
                  </div>
                </Spin>
              )}
              {fixedChartItems &&
              fixedChartItems.secondCol &&
              Object.keys(fixedChartItems?.secondCol)?.length > 0 ? (
                <Spin spinning={incDecLoading}>
                  <div className="box platform-distribution-block">
                    <>
                      <div className="below-topic-name-more">
                        <p className="overall-block-heading">
                          {fixedChartItems.secondCol.name
                            ? fixedChartItems.secondCol.name.length > 35
                              ? fixedChartItems.secondCol.name.slice(0, 35) +
                                "..."
                              : fixedChartItems.secondCol.name
                            : fixedChartItems.secondCol.metric.length > 35
                            ? fixedChartItems.secondCol.metric.slice(0, 35) +
                              "..."
                            : fixedChartItems.secondCol.metric}
                        </p>
                        {mode ? (
                          <span
                            className="chart-more-option"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowBelowChartIndex("static2");
                              setShowOptionIndex(2);
                            }}
                          >
                            <FontAwesomeIcon
                              className="more-icon"
                              icon={faEllipsisV}
                            />
                            {showBelowChartIndex === "static2" && (
                              <div
                                className="more-option-list"
                                style={{ right: "0" }}
                              >
                                <span
                                  onClick={(e) => {
                                    setShowRenamePopup(true);
                                    setRenameKpiValue(
                                      fixedChartItems.secondCol.metric
                                    );
                                    setSelectedMetricID(
                                      fixedChartItems.secondCol.id
                                    );
                                    setMetricLocation("bottom2");
                                  }}
                                >
                                  {t("button.1")}
                                </span>
                                <span
                                  onClick={() => {
                                    handleOpenEdit(
                                      "bottom2",
                                      fixedChartItems?.secondCol
                                    );
                                  }}
                                >
                                  {t("team_kpi_view.27")}
                                </span>
                                <span
                                  onClick={(e) => {
                                    setDeleteModalOpen(true);
                                    setSelectedMetricID(
                                      fixedChartItems.secondCol.id
                                    );
                                    setMetricLocation("bottom2");
                                  }}
                                >
                                  {t("button.2")}
                                </span>
                              </div>
                            )}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      {fixedChartItems?.secondCol?.metric ===
                      "Choose your own topics" ? (
                        <ul className="top-listed-items custom">
                          {fixedChartItems?.secondCol?.topics.map((item) => (
                            <li>
                              <span>
                                {item.split(">").slice(-1)?.length > 40
                                  ? item
                                      .split(">")
                                      .slice(-1)
                                      .slice(0, 40) + "..."
                                  : item.split(">").slice(-1)}
                              </span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <ul className="top-listed-items">
                          <li className="top-listed-title">
                            {fixedChartItems?.secondCol?.change_flag}
                          </li>
                          {fixedChartItems?.secondCol?.topics?.length > 0 ? (
                            fixedChartItems?.secondCol?.topics.map((item) => (
                              <li>
                                <span>
                                  {item.label.split(">").slice(-1).length > 40
                                    ? item.label.slice(0, 40) + "..."
                                    : item.label.split(">").slice(-1)}
                                </span>
                                <span
                                  className={`${
                                    fixedChartItems.firstCol.color === "red"
                                      ? "pointsr"
                                      : "pointsg"
                                  }`}
                                  style={{
                                    color:
                                      item.color === "red"
                                        ? "rgba(194, 76, 73, 1)"
                                        : "#009900",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item.change}
                                </span>
                              </li>
                            ))
                          ) : (
                            <span className={styles.fixedChartsEmptyMsg}>
                              No significant change in this time period
                            </span>
                          )}
                        </ul>
                      )}
                    </>
                  </div>
                </Spin>
              ) : (
                <Spin spinning={incDecLoading}>
                  <div
                    className={`box platform-distribution-block ${mode &&
                      "below-empty-block1"}`}
                  >
                    {mode && (
                      <SelectMetric
                        blockLocation="bottom2"
                        setCardChart={setCardChart}
                        selectedTeam={selectedTeamItem}
                        getGlobalChartsData={getTopIncDecTopicsData}
                      />
                    )}
                  </div>
                </Spin>
              )}
              {fixedChartItems &&
              fixedChartItems.thirdCol &&
              Object.keys(fixedChartItems?.thirdCol)?.length > 0 ? (
                <Spin spinning={statisticsLoading}>
                  <div className="box data-collected-block">
                    <>
                      <div className="below-topic-name-more">
                        <p className="overall-block-heading">
                          {fixedChartItems.thirdCol.name
                            ? fixedChartItems.thirdCol.name.length > 35
                              ? fixedChartItems.thirdCol.name.slice(0, 35) +
                                "..."
                              : fixedChartItems.thirdCol.name
                            : fixedChartItems.thirdCol.metric.length > 35
                            ? fixedChartItems.thirdCol.metric.slice(0, 35) +
                              "..."
                            : fixedChartItems.thirdCol.metric}
                        </p>
                        {mode ? (
                          <span
                            className="chart-more-option"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowBelowChartIndex("static3");
                              setShowOptionIndex(3);
                            }}
                          >
                            <FontAwesomeIcon
                              className="more-icon"
                              icon={faEllipsisV}
                            />

                            {showBelowChartIndex === "static3" && (
                              <div
                                className="more-option-list"
                                style={{ right: "0" }}
                              >
                                <span
                                  onClick={(e) => {
                                    setShowRenamePopup(true);
                                    setRenameKpiValue(
                                      fixedChartItems.thirdCol.metric
                                    );
                                    setSelectedMetricID(
                                      fixedChartItems.thirdCol.id
                                    );
                                    setMetricLocation("bottom3");
                                  }}
                                >
                                  {t("button.1")}
                                </span>
                                <span
                                  onClick={() => {
                                    handleOpenEdit(
                                      "bottom3",
                                      fixedChartItems?.thirdCol
                                    );
                                  }}
                                >
                                  {t("team_kpi_view.27")}
                                </span>
                                <span
                                  onClick={(e) => {
                                    setDeleteModalOpen(true);
                                    setSelectedMetricID(
                                      fixedChartItems.thirdCol.id
                                    );
                                    setMetricLocation("bottom3");
                                  }}
                                >
                                  {t("button.2")}
                                </span>
                              </div>
                            )}
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        className="platform-data-collected-wrapper"
                        style={{ width: "100%" }}
                      >
                        <CustomChart
                          type={"pie"}
                          data={{
                            labels: fixedChartItems?.thirdCol?.platforms.map(
                              (item) => {
                                if (item.length > 14 && mode) {
                                  return item.substring(0, 14) + "..";
                                } else return item;
                              }
                            ),
                            datasets: [
                              {
                                label: "tests",
                                data: fixedChartItems?.thirdCol?.platform_dist,
                                backgroundColor: pieColors,
                              },
                            ],
                          }}
                          options={pieOption}
                          dependency={chartItems}
                        />
                        <div className="total-useful-data-info">
                          <span>{fixedChartItems?.thirdCol?.datasize}</span>
                          <span>Total useful Data</span>
                        </div>
                      </div>
                    </>
                  </div>
                </Spin>
              ) : (
                <Spin spinning={statisticsLoading}>
                  <div
                    className={`box data-collected-block ${mode &&
                      "below-empty-block2"}`}
                  >
                    {mode && (
                      <SelectMetric
                        blockLocation="bottom3"
                        setCardChart={setCardChart}
                        selectedTeam={selectedTeamItem}
                        getGlobalChartsData={getStatisticsData}
                      />
                    )}
                  </div>
                </Spin>
              )}
            </div>
          </div>
        </Spin>
      </div>

      <Modal
        showModal={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onSave={handleDeleteChart}
        showSaveButton={true}
        showCancelButton={true}
        hideCloseIcon={true}
        textonButton={t("button.2")}
        titleAsset=""
        title={t("team_kpi_view.22")}
        widthofmodal="450px"
        loadingSaveClick={
          chartsLoading ||
          topMetricsLoading ||
          incDecLoading ||
          statisticsLoading
            ? true
            : false
        }
        disabled={
          chartsLoading ||
          topMetricsLoading ||
          incDecLoading ||
          statisticsLoading
            ? true
            : false
        }
        isDeleteModal={true}
      >
        <p>{t("team_kpi_view.18")}</p>
      </Modal>
      <Modal
        showModal={showPosts}
        onClose={() => setShowPosts(false)}
        showSaveButton={true}
        showCancelButton={true}
        hideCloseIcon={true}
        textonButton={"Save"}
        titleAsset=""
        title={"Generate random posts for KPI"}
        description={"10 random posts are generated"}
        widthofmodal={window.innerWidth > 800 ? "650px" : "350px"}
        onSave={handleSaveReviews}
        loadingSaveClick={savingPosts ? true : false}
        disabled={savingPosts ? true : false}
      >
        <div className={styles.showPosts}>
          {/* <Button className={styles.saveButton} onClick={handleFetchRandomPosts} >Generate Posts  <Icon name={"refresh_new"} />
                     </Button> */}

          <Spin spinning={postsLoading} size="middle">
            <div className={styles.posts}>
              {randomPosts.map((info) => {
                return <ReviewCard review={info} />;
              })}
            </div>
          </Spin>
        </div>
      </Modal>

      {showRenamePopup && (
        <div
          className="rename-kpi-modal"
          onClick={() => setShowRenamePopup(false)}
        >
          <div className="platform-content-center-wrapper">
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content-title">
                <p>{t("team_kpi_view.17")}</p>
                <img
                  src={
                    theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"
                  }
                  alt=""
                  onClick={() => setShowRenamePopup(false)}
                />
              </div>
              <input
                type="text"
                value={renameKpiValue}
                onChange={(e) => setRenameKpiValue(e.target.value)}
              />
              <div className="rename-btn-block">
                <button
                  disabled={disableBtn ? true : false}
                  onClick={handleRenameKPI}
                >
                  {t("button.1")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAddKpiGroupName && (
        <div
          className={styles.presentationTitleModal}
          onClick={() => setShowAddKpiGroupName(false)}
        >
          <div className={styles.centerWrapper}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <p>{t("team_kpi_view.4")}</p>
              <input
                type="text"
                value={addKpiGroupName}
                onChange={(e) => setAddKpiGroupName(e.target.value)}
              />
              <div className={styles.proceedBtn}>
                <button onClick={handleAddKpiGroupName}>
                  {t("team_kpi_view.19")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRenameKpiGroup && (
        <RenameDeletePopup
          type="rename"
          closePopup={setShowRenameKpiGroup}
          label={t("team_kpi_view.20")}
          disableBtn={disableBtn}
          setText={setAddKpiGroupName}
          text={addKpiGroupName}
          handleAction={handleAddKpiGroupName}
        />
      )}
      {showInfoPopup && (
        <InfoPopup
          data={selectedKpiInfo}
          theme={theme}
          close={setShowInfoPopup}
        />
      )}
      {showAlert && (
        <AlertPopup
          disable={disableBtn}
          handleAction={handleAddAlert}
          theme={theme}
          close={setShowAlert}
          type="add"
        />
      )}
      {showEditKPIPopup && (
        <div className={styles.presentationTitleModal}>
          <div className={styles.centerWrapper}>
            <div className={styles.editContent}>
              <div className={styles.titleBox}>
                <span>{t("team_kpi_view.26")}</span>
                <img
                  src={
                    theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"
                  }
                  alt=""
                  onClick={handleCloseEdit}
                />
              </div>
              <div className={styles.dropdownSection}>
                <div className={styles.dropdown}>
                  <p className={styles.heading}>{t("team_kpi_view.5")}</p>
                  <Select
                    // onPopupScroll={() => changeScrollbarColor()}
                    dropdownStyle={{
                      backgroundColor: "rgba(38, 34, 80, 1)",
                      color: "#fff",
                      accentColor: "#fff",
                    }}
                    style={{
                      width: "150px",
                    }}
                    placeholder={t("team_kpi_view.6")}
                    placement="bottomLeft"
                    showSearch
                    value={selectedDashboards}
                    onChange={handleSourceChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    mode="multiple"
                    maxTagCount={5}
                    listHeight={192}
                  >
                    {sources?.map((x) => (
                      <Option className="metric-option" key={x.id} value={x.id}>
                        {`${x.Name} (${x.id})`}
                      </Option>
                    ))}
                  </Select>
                </div>
                {editChartLocation === "Top" ||
                editChartLocation === "bottom3" ? (
                  <div className={styles.dropdown}>
                    <p className={styles.heading}>{t("team_kpi_view.13")}</p>
                    <Select
                      // onPopupScroll={() => changeScrollbarColor()}
                      dropdownStyle={{
                        backgroundColor: "rgba(38, 34, 80, 1)",
                        color: "#fff",
                        accentColor: "#fff",
                      }}
                      style={{
                        width: "150px",
                      }}
                      placeholder={t("team_kpi_view.12")}
                      placement="bottomLeft"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode="multiple"
                      onChange={onMetricsChange}
                      value={selectedMetricList}
                      className="kpi-selector"
                      maxTagCount={5}
                      listHeight={192}
                    >
                      {editChartLocation === "Top" ? (
                        <>
                          {customMetrics?.map((x) => (
                            <Option className="metric-option" key={x}>
                              {x}
                            </Option>
                          ))}
                          {generalMetrics.map((x) => (
                            <Option className="metric-option" key={x}>
                              {x}
                            </Option>
                          ))}
                        </>
                      ) : (
                        <>
                          {generalMetrics?.map((x) => (
                            <Option className="metric-option" key={x}>
                              {x}
                            </Option>
                          ))}
                        </>
                      )}
                    </Select>
                  </div>
                ) : (
                  <></>
                )}
                {(editChartLocation === "bottom1" ||
                  editChartLocation === "bottom2") && (
                  <div className={styles.dropdown}>
                    <p className={styles.heading}>{t("team_kpi_view.7")}</p>
                    <Select
                      dropdownStyle={{
                        backgroundColor: "rgba(38, 34, 80, 1)",
                        color: "#fff",
                        accentColor: "#fff",
                      }}
                      placeholder={t("team_kpi_view.8")}
                      placement="bottomLeft"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={onCustomMetricsChange}
                      value={selectedCustomMetric}
                      className="kpi-selector"
                    >
                      {generalMetrics?.map((x) => (
                        <Option className="metric-option" key={x}>
                          {x}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                {selectedCustomMetric === "Choose your own topics" && (
                  <div className={styles.customTitleBox}>
                    <span className={styles.heading}>
                      {t("team_kpi_view.10")}*
                    </span>
                    <input
                      type="text"
                      placeholder={t("placeholder.5")}
                      value={customTitle}
                      onChange={(e) => setCustomTitle(e.target.value)}
                    />
                  </div>
                )}
                {selectedCustomMetric === "Choose your own topics" && (
                  <div className={styles.dropdown}>
                    <p className={styles.heading}>{t("team_kpi_view.11")}</p>
                    <Select
                      // onPopupScroll={() => changeScrollbarColor()}
                      dropdownStyle={{
                        backgroundColor: "rgba(38, 34, 80, 1)",
                        color: "#fff",
                        accentColor: "#fff",
                      }}
                      style={{
                        width: "150px",
                      }}
                      placeholder={t("team_kpi_view.12")}
                      placement="bottomLeft"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode="multiple"
                      onChange={onMetricsChange}
                      value={selectedMetricList}
                      className="kpi-selector"
                    >
                      <>
                        {generalCustomMetric?.map((x) => (
                          <Option className="metric-option" key={x}>
                            {x}
                          </Option>
                        ))}
                      </>
                    </Select>
                  </div>
                )}
                {/* {selectedDashboards?.length > 1 && editChartLocation === "Top" && (
                  <div className={styles.SelectBox}>
                    <p className={styles.heading}>{t("team_kpi_view.14")}</p>
                    <div className={styles.optionsWrapper}>
                      <div className={styles.item}>
                        <input
                          type="radio"
                          checked={sumMean === "sum" ? true : false}
                          id="sum"
                          value="sum"
                          name="operator"
                          onChange={() => setSumMean("sum")}
                        />
                        <label htmlFor="sum">
                          <span className={styles.radioBorder}></span>
                          {t("team_kpi_view.15")}
                        </label>
                      </div>
                      <div className={styles.item}>
                        <input
                          type="radio"
                          checked={sumMean === "mean" ? true : false}
                          id="mean"
                          value="mean"
                          name="operator"
                          onChange={() => setSumMean("mean")}
                        />
                        <label htmlFor="mean">
                          <span className={styles.radioBorder}></span>
                          {t("team_kpi_view.16")}
                        </label>
                      </div>
                    </div>
                  </div>
                )} */}
                {editChartLocation === "bubble" ? (
                  <div className={styles.dropdown}>
                    <p className={styles.heading}>
                      {t("selected_dashboard_sidebar.2")}
                    </p>
                    <Select
                      // onPopupScroll={() => changeScrollbarColor()}
                      dropdownStyle={{
                        backgroundColor: "rgba(38, 34, 80, 1)",
                        color: "#fff",
                        accentColor: "#fff",
                      }}
                      style={{
                        width: "150px",
                      }}
                      placeholder={t("label.13")}
                      placement="bottomLeft"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode="multiple"
                      onChange={(value) => setSelectedAITopics(value)}
                      value={selectedAITopics}
                      className="kpi-selector"
                    >
                      {aiTopics?.map((x) => (
                        <Option className="metric-option" key={x.topic_id}>
                          {x.topic_name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className={styles.btnBlock}>
                <button onClick={handleCloseEdit}>{t("button.3")}</button>
                <button disabled={disableBtn} onClick={handleEditKPI}>
                  {t("button.31")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedDashboardIds?.length > 0 && selectedTopicLabels?.length > 0 && (
        <Drawer
          title={
            <span className={styles.groupTitle} title={selectedKPITitle}>
              {selectedKPITitle}
            </span>
          }
          // title={
          //   <span className={styles.drawerTitleWrapper}>
          //     <span className={styles.groupTitleWrapper}>
          //       <span
          //         className={styles.groupTitle}
          //         title={
          //           selectedGroupForReviews.clickIsFrom === "groups"
          //             ? selectedGroupForReviews.label
          //             : selectedGroupForReviews.topicLabel
          //         }
          //       >
          //         {selectedGroupForReviews.clickIsFrom === "groups" ? (
          //           selectedGroupForReviews.label
          //         ) : (
          //           <span>{selectedGroupForReviews.topicLabel}</span>
          //         )}
          //       </span>
          //     </span>
          //     <span className={styles.extraFilters}>
          //       {selectedGroupForReviews.clickIsFrom === "groups" ? (
          //         <span
          //           className={styles.topicLabel}
          //           title={selectedGroupForReviews.topicLabel}
          //           style={{
          //             backgroundColor: rgbToHex("rgb(116, 45, 224)"),
          //             color: "white",
          //           }}
          //         >
          //           {selectedGroupForReviews.topicLabel}
          //         </span>
          //       ) : (
          //         <MultiSelect
          //           options={dashboardIds.map((id, index) => ({
          //             id: id?.toString(),
          //             label: dashboardLabels[index],
          //             value: id?.toString(),
          //           }))}
          //           enableSelectAll={false}
          //           defaultSelectedOptions={selectedGroupForReviews.id.map(
          //             (id, index) => ({
          //               id: id?.toString(),
          //               label: selectedGroupForReviews.label[index],
          //               value: id?.toString(),
          //             })
          //           )}
          //           allowClear={false}
          //           tagCount={"responsive"}
          //           isLoading={reviewsLoading}
          //           showSearch={true}
          //           minSelection={1}
          //           setSelectedOptions={(values) => {
          //             setStartIndex(0);
          //             setNumOfDocs(10);
          //             setSelectedGroupForReviews((prev) => {
          //               const updated = { ...prev };
          //               updated.id = values.map((v) => v.id);
          //               updated.label = values.map((v) => v.label);
          //               return updated;
          //             });
          //           }}
          //         />
          //       )}
          //     </span>
          //   </span>
          // }
          placement="right"
          closable={window.innerWidth < 500 ? true : false}
          closeIcon={
            <CloseOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          }
          maskClosable={!reviewsLoading}
          onClose={() => {
            setSelectedDashboardIds([]);
            setSelectedTopicLabels([]);
          }}
          width={
            window.innerWidth > 1300
              ? 650
              : window.innerWidth > 500 && window.innerWidth < 1300
              ? 450
              : 350
          }
          open={
            selectedDashboardIds?.length > 0 && selectedTopicLabels?.length > 0
          }
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Spin spinning={reviewsLoading}>
              <ReviewsList
                reviewsLoading={reviewsLoading}
                allReviews={allReviews}
                numReviews={numReviews}
                startIndex={reviewsStartIndex}
                setStartIndex={setReviewsStartIndex}
                getAllReviews={getKPIReviews}
                setNumOfDocs={setNumOfDocs}
                numOfDocs={numOfDocs}
                handleTagSelection={handleSearchWord}
                user={user}
                setReviewsLoading={setReviewsLoading}
                shared={false}
                resetReviews={resetReviews}
                setResetReviews={setResetReviews}
                searchWord={searchWord}
                setSearchWord={setSearchWord}
                handleSearchWord={handleSearchWord}
                searchKeywords={searchKeywords}
                setSearchKeywords={setSearchKeywords}
                colorMap={colorMap}
              />
            </Spin>
          </div>
        </Drawer>
      )}
    </div>
  ) : (
    (window.location = window.location.origin + "/")
  );
};

export default ChartsContainer;
