import * as actions from "redux/user/actions.js";

//Need to be updated to have all the user data
let initialState = {
  advancedAllowed: false,
};

const userReducer = (state = initialState, action) => {
  if (action.type === actions.SET_USER) {
    return {
      ...action.payload,
    };
  } else if (action.type === actions.UPDATE_USER) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export { userReducer };
