import React from "react";
import { Button } from "antd";
import 'react-phone-number-input/style.css'
import ReactInputVerificationCode from 'react-input-verification-code';
import PhoneInput from 'react-phone-number-input'
import styles from "./style.module.scss";
import i18n from 'i18next';

const Wrapper = ({ title, children }) => (
  <div className={styles.wrapperHeader}>
    <h4 className={styles.wrapperTitle}>{title}</h4>
    {children}
  </div>
)

const Representation = ({
  setPhonenumber,
  phonenumber,
  setVerificationCode,
  verificationCode,
  sendSMS,
  verifyCode,
  smsSent,
  timeLeft,
  resend,
  isLoading
}) => (
  <Wrapper title={smsSent ? i18n.t("settings.37") : i18n.t("settings.38")}>
    <p className={styles.subtitle}>{smsSent ? i18n.t("settings.39", { phonenumber }) : i18n.t("settings.40")}</p>
    {smsSent ? (
      <div className={styles.customVerifyCode}>
        <ReactInputVerificationCode
          onChange={(val) => setVerificationCode(val)}
          value={verificationCode}
          length={6}
          placeholder=""
          autoFocus="true"
        />
      </div>
    ) : (
      <div className={styles.customPhoneInput}>
        <PhoneInput
          placeholder={i18n.t("settings.41")}
          value={phonenumber}
          onChange={setPhonenumber} />
      </div>
    )}
    {smsSent && <div className={styles.resendText}>
      {timeLeft === 0 ? (
        <>
          {i18n.t("settings.42")} <Button className={styles.resendBtn} onClick={() => resend()}>{i18n.t("settings.43")}</Button>
        </>
      ) : (
        <i>{i18n.t("settings.44", { timeLeft })}</i>
      )}
    </div>}
    <Button loading={isLoading}
      id="mfa-button" className={styles.btn} onClick={() => smsSent ? verifyCode(true) : sendSMS()}>
      {smsSent ? i18n.t("settings.45") : i18n.t("settings.46")}
    </Button>
  </Wrapper >
)

export default Representation;