import React, { useState } from "react";
import styles from "./style.module.scss";
import { Button, notification, Input } from "antd";
import { apiRequest } from "util/services";
import { getDateString, keyPressed } from "util/common";
import { APP_MESSAGES } from "util/constants";
import { Modal } from "components/General/ModalFormComponents";
const AudienceHeader = ({
  localState,
  setState,
  user,
  filterAudience,
  setAudience,
  audience,
  theme,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [inputAudience, setInputAudience] = useState("");

  const handleAddAudience = async () => {
    if (!inputAudience) {
      notification.error({
        message: "New audience can't be empty",
      });
      return;
    }

    const result = await apiRequest(
      "audiences/create",
      "POST",
      {
        user_id: user.uid,
        name: inputAudience,
      },
      true
    );
    if (!result.error) {
      setAudience([
        ...audience,
        {
          Name: inputAudience,
          Size: 0,
          AddDate: getDateString(new Date()),
          Status: "active",
        },
      ]);
      filterAudience([
        ...audience,
        {
          Name: inputAudience,
          Size: 0,
          AddDate: getDateString(new Date()),
          Status: "active",
        },
      ]);
    } else {
      notification.error({
        message: result.error,
      });
    }
    setModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.title}>
          <div className={styles.text}>
            <h5>{APP_MESSAGES.AUDIENCE.HEADER}</h5>
            <h6>{APP_MESSAGES.AUDIENCE.SUBHEADER}</h6>
          </div>
        </div>
        <div>
          <div className={styles.createBtn}>
            <Button
              icon={<i className="fa fa-plus" />}
              onClick={() => setModalOpen(true)}
            >
              Create Audience
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomBtns}>
          <div
            className={`${styles.navBtn} ${localState.active && styles.navBtnActive
              }`}
          >
            <Button onClick={() => setState({ ...localState, active: true })}>
              Active Audience
            </Button>
          </div>
          <div
            className={`${styles.navBtn} ${!localState.active && styles.navBtnActive
              }`}
          >
            <Button onClick={() => setState({ ...localState, active: false })}>
              Archived Audience
            </Button>
          </div>
        </div>
        <div className={styles.search}>
          <Input
            placeholder="Search Audience"
            prefix={
              <i style={{ marginRight: "5px" }} className="fa fa-search" />
            }
            onChange={(e) =>
              setState({ ...localState, searchTerm: e.target.value })
            }
          />
        </div>
      </div>
      <Modal

        showModal={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleAddAudience}
        showSaveButton={true}
        textonButton="Ok"
        titleAsset=""
        title="Add Audience"
        widthofmodal="450px"
      >

        <Input
          style={{
            height: "35px", marginBottom: "7px", width: "400px", marginLeft: "15px", background: "transparent", color: theme === 'dark' ? "white" : "black", borderColor: "#6f6c99",
            borderRadius: "10px"
          }}
          placeholder="Type audience"
          onKeyPress={(e) => keyPressed(e, handleAddAudience)}
          onChange={(e) => setInputAudience(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default AudienceHeader;
