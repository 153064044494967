import moment from "moment";
import * as htmlToImage from "html-to-image";
import copy from "copy-to-clipboard";

export const hexToRgbA = (hex, opacity) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  return;
};

export const changeOpacity = (colorRgba, opacity) => {
  let rgba = colorRgba.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
  return `rgba(${rgba[0]},${rgba[1]},${rgba[2]}, ${opacity})`;
};

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getParams = (url) => {
  var params = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};

export const copyToClipboard = (str) => {
  // window.Clipboard = (function (window, document, navigator) {
  //   var textArea, copy;

  //   function isOS() {
  //     return navigator.userAgent.match(/ipad|iphone/i);
  //   }

  //   function createTextArea(text) {
  //     textArea = document.createElement("textArea");
  //     textArea.value = text;
  //     document.body.appendChild(textArea);
  //   }

  //   function selectText() {
  //     var range, selection;

  //     if (isOS()) {
  //       range = document.createRange();
  //       range.selectNodeContents(textArea);
  //       selection = window.getSelection();
  //       selection.removeAllRanges();
  //       selection.addRange(range);
  //       textArea.setSelectionRange(0, 999999);
  //     } else {
  //       textArea.select();
  //     }
  //   }

  //   function copyToClipboard() {
  //     document.execCommand("copy");
  //     document.body.removeChild(textArea);
  //   }

  //   copy = function (text) {
  //     createTextArea(text);
  //     selectText();
  //     copyToClipboard();
  //   };

  //   return {
  //     copy: copy,
  //   };
  // })(window, document, navigator);
  // // eslint-disable-next-line
  // Clipboard.copy(str);

  copy(str);
};

export const getDateString = (inputDate, regex) => {
  if (!inputDate) return;
  if (regex) return moment(inputDate).format(regex);
  return moment(inputDate).format("DD MMM YYYY");
};

export const numberWithCommas = (x) => {
  if (!x) return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const setWithExpiry = (key, value, ttl) => {
  // ttl in hours
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl * 60 * 60 * 1000,
  };
  window.localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key) => {
  const itemStr = window.localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
    window.localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const keyPressed = (event, callback) => {
  if (event.key === "Enter") {
    callback();
  }
};

export const verifyDates = (start, end) => {
  if (!start && !end) return { check: true };
  let now = new Date();
  start = new Date(start);
  end = new Date(end);
  if (start > end) {
    return { check: false, msg: "Start date must be after end date" };
  }
  if (now < start || now < end) {
    return { check: false, msg: "Start and end dates must be in the past" };
  }
  return { check: true };
};

export const downloadHtmltoImg = (id) => {
  htmlToImage
    .toJpeg(document.getElementById(id), {
      quality: 0.95,
      filter: (node) => node.tagName !== "i",
      pixelRatio: 1,
    })
    .then(function(dataUrl) {
      var link = document.createElement("a");
      link.download = id + ".jpeg";
      link.href = dataUrl;
      link.click();
    });
};

export const scrollToElement = (id) => {
  let element = document.getElementById(id);
  if (!element) {
    window.scroll({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  } else {
    const y = element.getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - 100,
      behavior: "smooth",
    });
  }
};

export const themeColor = (theme) => {
  if (theme === "dark") {
    return "#262250";
  } else if (theme === "light") {
    return "#4400AA";
  } else if (theme === "brand") {
    return "#E60000";
  }
};

export const themeLayoutColor = (theme) => {
  if (theme === "dark") {
    return "#262250";
  } else if (theme === "light") {
    return "#E8E0F4";
  } else if (theme === "brand") {
    return "#F4F4F4";
  }
};

export const convertDateIntoYYYYMMDD = (dateToConvert) => {
  const date = new Date(dateToConvert);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;
  return `${year}-${month}-${day}`;
};

/**
 * A function to beautify numbers
 * @param {number} number - number to be beautified
 * @param {string} [metric='#'] - metric to be used. Supported metrics are
 *  - `#` for k, M, B notation(default notation)
 *  - `,` for comma separated notation
 * @returns {string} beautified number
 */
export const beautifyNumber = (number, metric = "#") => {
  const k = 1000;
  const m = k * 1000;
  const b = m * 1000;
  if (metric === ",") {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    if (number < k) {
      return number;
    } else if (number < m) {
      let result = (number / k).toFixed(1);
      if (result.split(".")[1] === "0") {
        result = result.split(".")[0];
      }
      return result + "k";
    } else if (number < b) {
      let result = (number / m).toFixed(1);
      if (result.split(".")[1] === "0") {
        result = result.split(".")[0];
      }
      return result + "M";
    } else {
      let result = (number / b).toFixed(1);
      if (result.split(".")[1] === "0") {
        result = result.split(".")[0];
      }
      return result + "B";
    }
  }
};
