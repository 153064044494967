import React from "react";
import config from "Configurations.js";
import Representation from './Representation';

const Modal = ({ user }) => {

  const activateTrello = () => {
    const key = config.TRELLO_KEY;
    const redirectUrl = window.location.href;
    const link = `https://api.trello.com/1/authorize/?key=${key}&expiration=never&name=Pivony&return_url=${redirectUrl}&response_type=fragment&scope=read,write`
    window.location.replace(link);
  }

  return <Representation
    activateTrello={activateTrello}
  />
}

export default Modal;