import React, { useState } from 'react'
import styles from "./styles.module.scss"
import SurveryListItem from './SurveyListItem'
import { useSelector } from 'react-redux'
import { Select, Button } from 'antd';





const Listing = (props) => {
    const theme = useSelector((state) => state.DashboardReducer.theme);
    let { surveys } = props
    return <div className={styles.listing}>
        <div className={styles.listingHeader}>
            {/* <p className={styles.item}>
                <img src={theme === 'dark' ? "/assets/survey_list.svg" : "/assets/survey_list_black.svg"} alt="" />
                Survey Name
            </p>
            <p className={styles.item}>
                <img className={styles.message_img} src={theme === 'dark' ? "/assets/history.svg" : "/assets/history_black.svg"} />

                Last Refreshed Date
            </p> */}
        </div>
        {
            surveys &&
            surveys?.map((item) => {
                return <SurveryListItem survey={item} />
            })
        }


    </div>
}

export default Listing