import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import PopupSequence from "./PopupSequence/PopupSequence";
import AnalysisBoard from "./AnalysisBoard/AnalysisBoard";
import { apiRequest } from "util/services";
import { useHistory } from "react-router-dom";
import { notification, Spin } from "antd";
import { convertDateIntoYYYYMMDD } from "util/common";
import { useTranslation } from "react-i18next";

const IndustryDashboard = ({ authenticated, user, match }) => {
  //init states
  const [comparisonViewEmpty, setComparisonViewEmpty] = useState(true);
  const [data, setData] = useState([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [comparisonName, setComparisonName] = useState("Comparison");
  const [defaultSelectedCategories, setDefaultSelectedCategories] = useState(
    []
  );
  const history = useHistory();
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };
  //get streams and categories
  const fetchDropdownData = async () => {
    const categoryApiResponse = await apiRequest(
      "industry_analysis/categoryStatus",
      "GET",
      null,
      true
    );
    if (categoryApiResponse?.data) {
      setDefaultSelectedCategories(
        categoryApiResponse.data.filter((item) => item.status === 1)
      );
      if (params.get("selected_categories")) {
        const selectedCatIds = JSON.parse(params.get("selected_categories"));
        const newCategories = categoryApiResponse.data.map((item) => {
          return {
            ...item,
            status: selectedCatIds.includes(item.categoryId) ? 1 : 0,
          };
        });
        setCategories(newCategories);
      } else {
        const selectedCat = categoryApiResponse.data.filter(
          (item) => item.status === 1
        );
        updateQParams(
          "selected_categories",
          JSON.stringify(selectedCat?.map((item) => item.categoryId))
        );
        setCategories(categoryApiResponse.data);
      }
    } else {
      notification.error({
        message: `${categoryApiResponse?.error}`,
      });
    }

    const streamApiResponse = await apiRequest(
      "industry_analysis/streams",
      "GET",
      null,
      true
    );
    if (streamApiResponse?.data) {
      setAvailablePlatforms(streamApiResponse.data);
    } else {
      notification.error({
        message: `${streamApiResponse?.error}`,
      });
    }
  };

  const fetchData = async (payload) => {
    setIsLoading(true);
    const apiResponse = await apiRequest(
      "industry_analysis/v2/loadCharts",
      "POST",
      payload,
      true
    );
    if (apiResponse?.success) {
      setIsLoading(false);
      setComparisonViewEmpty(false);
      setData(apiResponse?.success?.data);
      setComparisonName(apiResponse?.success?.data?.viewName);
    } else if (apiResponse?.error === "No configuration found") {
      setIsLoading(false);
      setComparisonViewEmpty(true);
    } else {
      notification.error({
        message: `${apiResponse?.error}`,
      });
      setIsLoading(false);
    }
  };
  //check user comparison view when page loads
  useEffect(() => {
    //if user is not logged in or was logged out redirect out
    if (authenticated !== "logged") {
      history.push("/");
      return;
    }
    //If sources are already saved then load the payload from db
    const payload = {
      filters: {},
    };
    if (params.get("selected_categories")) {
      payload.filters["categories"] = JSON.parse(
        params.get("selected_categories")
      );
    }
    if (params.get("selected_sentiment")) {
      payload.filters["sentiment"] = JSON.parse(
        params.get("selected_sentiment")
      );
    } else {
      payload.filters["sentiment"] = [];
    }
    if (params.get("selected_platforms")) {
      payload.filters["stream"] = JSON.parse(params.get("selected_platforms"));
    } else {
      payload.filters["stream"] = [];
    }
    if (params.get("since") && params.get("until")) {
      payload.filters["date"] = [params.get("since"), params.get("until")];
    } else {
      if (params.get("daysRange") === "allTime") {
        payload.filters["date"] = [];
      } else {
        const untilDate = new Date();
        const sinceDate = new Date(
          untilDate.getTime() - 6 * 24 * 60 * 60 * 1000
        );
        payload.filters["date"] = [
          convertDateIntoYYYYMMDD(sinceDate),
          convertDateIntoYYYYMMDD(untilDate),
        ];
        updateQParams("since", convertDateIntoYYYYMMDD(sinceDate));
        updateQParams("until", convertDateIntoYYYYMMDD(untilDate));
        updateQParams("daysRange", "last7d");
      }
    }
    fetchDropdownData();
    fetchData(payload);
  }, []);

  useEffect(() => {
    //If user doesn't have a saved configuration, Load the charts from the selected configuration
    if (selectedConfiguration) {
      fetchData(selectedConfiguration);
    }
  }, [selectedConfiguration]);

  const clearComparisonView = () => {
    setComparisonViewEmpty(true);
  };
  return (
    <div className={styles.mainWindow}>
      <div className={styles.Heading}>
        <div className={styles.title}>{t("dashboard_sidebar.9")}</div>
      </div>
      <div className={styles.content}>
        {comparisonViewEmpty ? (
          //case 1 comparison view is empty
          <div className={styles.landingDisplay}>
            {isLoading && (
              <div className={styles.Spinner}>
                <Spin
                  className={styles.spin}
                  size="large"
                  spinning={isLoading}
                />
              </div>
            )}
            <div className={styles.start}>
              <h4 className={styles.displayMsg}>
                {t("competitor_analysis.10")}
              </h4>
              <PopupSequence
                setSelectedConfiguration={setSelectedConfiguration}
                setIsLoading={setIsLoading}
              />
            </div>
          </div>
        ) : (
          //case 2 comparison view needs to contain data
          <div className={styles.Analysis}>
            {isLoading && (
              <div className={styles.Spinner}>
                <Spin
                  className={styles.spin}
                  size="large"
                  spinning={isLoading}
                />
              </div>
            )}
            <AnalysisBoard
              data={data}
              dataLoaded={dataLoaded}
              setDataLoaded={setDataLoaded}
              fetchData={(payload) => fetchData(payload)}
              availablePlatforms={availablePlatforms}
              defaultSelectedCategories={defaultSelectedCategories}
              categories={categories}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              clearComparisonView={clearComparisonView}
              comparisonName={comparisonName ? comparisonName : "Comparison"}
              setComparisonName={setComparisonName}
              match={match}
              user={user}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default IndustryDashboard;
