import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
const JourneyStepItem = ({ step, setSelectedStep, active, id }) => {
  const [showDetail, setShowDetail] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 750);
    };
    window.addEventListener("resize", handleResize);
    return window.removeEventListener("resize", handleResize);
  }, [window.innerWidth, isMobileView]);
  return (
    <div className={styles.mainContainer}>
      <div className={styles.stepInfo}>
        <h4 style={step?.time?.length ? {} : { visibility: "hidden" }}>
          Completed at
        </h4>
        <div
          className={styles.info}
          style={step?.time?.length ? {} : { visibility: "hidden" }}
        >
          <div className={styles.item}>
            {" "}
            <img
              src={
                theme === "dark"
                  ? "/assets/calendar.svg"
                  : "/assets/calendar_black.svg"
              }
              alt=""
              width={18}
              height={18}
            />{" "}
            {step?.time?.length ? step?.time?.split(",")[0] : "7/30/2024"}
          </div>
          <div className={styles.item}>
            {" "}
            <img
              src={
                theme === "dark"
                  ? "/assets/clock.svg"
                  : "/assets/clock_black.svg"
              }
              alt=""
              width={18}
              height={18}
            />
            {step?.time?.length ? step?.time?.split(",")[1] : "9:37:39 PM"}
          </div>
        </div>
      </div>
      <div
        className={`${styles.stepNumber} ${
          step?.time?.length ? styles.completed : ""
        }`}
      >
        {step?.time?.length ? (
          <img src="/assets/check_circle_outline.svg" alt="" />
        ) : (
          id
        )}
      </div>
      <div className={styles.stepContainer}>
        <div
          className={`${styles.container} ${active && styles.active}`}
          key={id}
        >
          <div className={styles.icon}>
            {id === 1 ? (
              <img src="/assets/journey_dashboard.svg" alt="" />
            ) : id === 2 ? (
              <img src="/assets/journey_data.svg" alt="" />
            ) : id === 3 ? (
              <img src="/assets/journey_nlp.svg" alt="" />
            ) : id === 4 ? (
              <img src="/assets/journey_topic.svg" alt="" />
            ) : (
              <img src="/assets/journey_results.svg" alt="" />
            )}
          </div>
          {/* <div className={styles.numberWrapper}>
        <span className={styles.number}>{id}</span>
      </div> */}
          <div className={styles.step}>
            <h5>{step.title}</h5>
            <div
              className={styles.detail}
              onClick={() => {
                setShowDetail(!showDetail);
              }}
            >
              Detail
              <i
                className="fa-solid fas fa-caret-down"
                style={{ fontSize: "12px" }}
              ></i>
            </div>
            {showDetail ? <div>{step.info && <h6>{step.info}</h6>}</div> : ""}
          </div>
          {/* <div className={styles.btn}>
        <Button type="primary" onClick={() => setSelectedStep(step)}>
          <i className="fa fa-caret-right" />{" "}
        </Button>
      </div> */}
        </div>
        <div className={styles.stepInfoMobile}>
          {step?.time?.length ? <h4>Completed at</h4> : ""}
          {step?.time?.length ? (
            <div className={styles.info}>
              <div className={styles.item}>
                {" "}
                <img
                  src={
                    theme === "dark"
                      ? "/assets/calendar.svg"
                      : "/assets/calendar_black.svg"
                  }
                  alt=""
                  width={18}
                  height={18}
                />{" "}
                {step.time.split(",")[0]}
              </div>
              <div className={styles.item}>
                {" "}
                <img
                  src={
                    theme === "dark"
                      ? "/assets/clock.svg"
                      : "/assets/clock_black.svg"
                  }
                  alt=""
                  width={18}
                  height={18}
                />
                {step.time.split(",")[1]}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default JourneyStepItem;
