import React from "react";
import styles from "./style.module.scss";

const JourneyHeader = ({ title, subtitle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.title}>
          <div className={styles.text}>
            <h5>{title}</h5>
            <h6>{subtitle}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyHeader;
