import React, { useEffect, useRef } from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';

const SortPopup = ({ sortValue, setSortValue, setShowSortPopup }) => {
   const sortRef = useRef();
   const { t } = useTranslation();
   const dynamicOutClick = (e) => {
      if (sortRef.current && !sortRef.current.contains(e.target)) {
         setShowSortPopup(false)
      }
   }

   useEffect(() => {
      document.addEventListener('click', dynamicOutClick, true);
      return () => document.removeEventListener('click', dynamicOutClick, true)
   }, []);

   return (
      <div className={styles.popup} ref={sortRef}>
         <p className={styles.title}>{t("filter.5")}</p>
         <div className={styles.itemsWrapper}>
            <span className={sortValue === 'newest' ? styles.active : undefined} onClick={() => setSortValue('newest')}>{t("label.1")}</span>
            <span className={sortValue === 'oldest' ? styles.active : undefined} onClick={() => setSortValue('oldest')}>{t("label.2")}</span>
            <span className={sortValue === 'az' ? styles.active : undefined} onClick={() => setSortValue('az')}>A-Z</span>
            <span className={sortValue === 'za' ? styles.active : undefined} onClick={() => setSortValue('za')}>Z-A</span>
         </div>
      </div>
   )
}

export default SortPopup;