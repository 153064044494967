import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ReactInputVerificationCode from "react-input-verification-code";
import { notification, Button } from "antd";
import firebase from "../FirebaseConfig.js";
import firebaseApp from "firebase/app";
import { useHistory } from "react-router-dom";
import config from "../Configurations.js";
import { Link } from "react-router-dom";
import styles from "./Signup/signup.module.scss";
import { authWithGmail, logError, apiRequest, signout } from "../util/services";
import { USER } from "../util/constants.js";
import { getParams } from "util/common.js";
import mixpanel from "mixpanel-browser";

const TIMER = 120;

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checkRecaptcha, setCheckRecaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const redirectURL = getParams(document.location.href).redirectURL;

  const [recaptchaVerifier, setRecaptchaVerifier] = useState();
  const [MFAView, setMFAView] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [verificationId, setVerificationId] = useState();
  const [timeLeft, setTimeLeft] = useState(0);
  const [rememberCheck, setRememberCheck] = useState(true);
  const passwordRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [failedLogin, setFailedLogin] = useState(
    sessionStorage.getItem("loginFailed")
  );
  const [loginErrors, setLoginErrors] = useState({
    email: "",
    password: "",
    captcha: "",
  });

  useEffect(() => {
    window.localStorage.setItem("remember_me", rememberCheck);
  }, [rememberCheck]);

  const updateTime = () => {
    setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft > 0) updateTime();
  }, [timeLeft]);

  useEffect(() => {
    var recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier(
      "mfa-sign-in",
      {
        size: "invisible",
      }
    );
    setRecaptchaVerifier(recaptchaVerifier);
  }, []);

  const signInUser = async () => {
    setLoading(true);
    // if (
    //   !checkRecaptcha &&
    //   document.location.origin !== "http://localhost:3000" && document.location.origin !== "https://demo.pivony.com"
    // ) {
    //   notification.error({
    //     message: "Google Recaptcha",
    //     description: "You must verify with google recaptcha",
    //   });
    //   return;
    // }
    // if (!email || email.length === 0 || !password || password.length === 0) {
    //   notification.error({
    //     message: "Please Fill the required fields!",
    //   });
    //   return;
    // }
    var payload = { email: email, operation_type: "login" };
    // if (firebase.auth().currentUser) {
    //   firebase.auth().currentUser.getIdToken(true).then(() => { }).catch((err) => {
    //     window.location.href = window.location.origin + '/login'
    //   })
    // }
    const authCheckResult = await apiRequest(
      "users/pre_auth_check",
      "POST",
      payload,
      true,
      false,
      true
    );
    if (
      !authCheckResult ||
      (authCheckResult || {}).error ||
      authCheckResult?.cancel === true
    ) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: "Login not authorized",
      });
      setLoading(false);
      try {
        apiRequest(
          "users/client_log",
          "POST",
          { email: email, error: "Pre-Auth Failed" },
          true
        );
      } catch (er) {
        console.log(er);
      }

      return;
    }

    if (authCheckResult.success) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
          const res = response.user.toJSON();
          addLoginDate(res.uid);
        })
        .catch(function(error) {
          if (error.code === "auth/multi-factor-auth-required") {
            const resolver = error.resolver;
            var phoneInfoOptions = {
              multiFactorHint: resolver.hints[0],
              session: resolver.session,
            };
            const phoneAuthProvider = new firebaseApp.auth.PhoneAuthProvider();
            // Send SMS verification code
            phoneAuthProvider
              .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
              .then((verificationId) => {
                setVerificationId(verificationId);
                setMFAView(resolver);
                setTimeLeft(TIMER);
                setLoading(false);
              });
            return;
          }
          // Handle Errors here.
          // var errorCode = error.code;
          var errorMessage = error.message;
          notification.error({
            message: "Error",
            description: errorMessage,
          });
          setLoading(false);
          try {
            apiRequest(
              "users/client_log",
              "POST",
              { email: email, error: errorMessage, message: "Login Failed" },
              true
            );
          } catch (er) {
            console.log(er);
          }
        });
    }
  };

  const onVerifyCode = async () => {
    try {
      setLoading(true);
      var cred = firebaseApp.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      var multiFactorAssertion = firebaseApp.auth.PhoneMultiFactorGenerator.assertion(
        cred
      );
      const userCredential = await MFAView.resolveSignIn(multiFactorAssertion);
      const res = userCredential.user.toJSON();
      addLoginDate(res.uid);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      notification.error({
        message: e.message,
      });
    }
  };

  const Resend = async () => {
    try {
      setTimeLeft(TIMER);
      setLoading(true);
      var cred = firebaseApp.auth.PhoneAuthProvider.credential(
        verificationId,
        "111111"
      );
      var multiFactorAssertion = firebaseApp.auth.PhoneMultiFactorGenerator.assertion(
        cred
      );
      await MFAView.resolveSignIn(multiFactorAssertion);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  function keyPressed(event) {
    if (event.key === "Enter") {
      handleValidation();
    }
  }
  const forgotPassword = () => {
    if (!email) {
      notification.error({
        message: "Email empty",
        description:
          "Enter your email in the email field and click forgot password",
      });
    } else {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(function() {
          notification.success({
            message: "Email sent",
            description: `A reset email has been sent to ${email}`,
          });
          document.location.href =
            document.location.origin + `/reset-password?email=${email}`;
        })
        .catch((error) => {
          logError("Firebase Auth - sendPasswordResetEmail", error);
          notification.error({
            message: "Error occured",
            description: error.message,
          });
        });
    }
  };

  const verifyRecaptcha = (token) => {
    // TODO serverside check recaptcha
    setCheckRecaptcha(true);

    // const data = {
    //   response: token,
    //   secret: config.RECAPTCHA_LOCALHOST_SECRET_KEY
    // }
    // fetch("https://www.google.com/recaptcha/api/siteverify", {
    //   method: "POST", // or 'PUT'
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("Success:", data);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };

  const registerUID = async (uid, authUser, objUser) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ user_id: uid, email: objUser.email }),
    };

    await fetch(config.BASE_URL + "users/create", requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        if (result.error) {
          authUser.delete();
          notification.error({
            message: "Error",
            description: result.error,
          });
        } else {
          firebase
            .database()
            .ref(`/users/${uid}`)
            .set(objUser)
            .then(() => {
              addLoginDate(uid);
            });
        }
      })
      .catch((error) => {
        authUser.delete();
        notification.error({
          message: "Error",
          description: "couldn't create user, please refresh and try again",
        });
      });
  };

  const addLoginDate = async (uid) => {
    await firebase
      .database()
      .ref(`/users/${uid}`)
      .once("value", async (snapshot) => {
        let user = snapshot.val();
        let signInDates = user?.signInDates;
        if (!signInDates) {
          signInDates = {};
        }
        let now = new Date();
        signInDates[now.toString()] = true;

        await firebase
          .database()
          .ref(`/users/${uid}/signInDates`)
          .set(signInDates);
        // document.location.href = redirectURL
        //   ? redirectURL
        //   : document.location.origin + "/console/myDashboards";
      });
  };

  const signInWithGmail = async () => {
    const result = await authWithGmail();
    if (result) {
      var payload = { email: result.email, operation_type: "login" };
      const authCheckResult = await apiRequest(
        "users/pre_auth_check",
        "POST",
        payload,
        true
      );
      if ((authCheckResult || {}).error) {
        setLoading(false);
        notification.error({
          message: "Error",
          description: authCheckResult.error,
        });
        signout(false);
        return;
      }

      firebase
        .database()
        .ref(`/users/${result.uid}`)
        .once("value", (snapshot) => {
          let user = snapshot.val();
          if (!user) {
            const objUser = {
              firstName: result.displayName,
              phone: result.phoneNumber,
              email: result.email,
              type: USER,
              emailVerified: true,
            };
            registerUID(result.uid, result, objUser);
          } else {
            document.location.href = redirectURL
              ? redirectURL
              : document.location.origin + "/console/myDashboards";
          }
        });
    }
  };

  const toggleShowHidePassword = () => {
    passwordRef?.current?.type === "password" && showPassword
      ? (passwordRef.current.type = "text")
      : (passwordRef.current.type = "password");
  };

  useEffect(() => {
    toggleShowHidePassword();
  }, [showPassword]);

  const removeMsg = () => {
    if (failedLogin) {
      setFailedLogin(sessionStorage.removeItem("loginFailed"));
    }
  };

  window.onunload = function() {
    if (failedLogin) {
      sessionStorage.removeItem("loginFailed");
    }
  };

  const handleValidation = () => {
    setLoginErrors({
      email: "",
      password: "",
      captcha: "",
    });
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (
      (!email || email.length === 0) &&
      (!password || password.length === 0) &&
      !checkRecaptcha &&
      document.location.origin !== "http://localhost:3000" &&
      document.location.origin !== "https://demo.pivony.com"
    ) {
      setLoginErrors({
        email: "Enter a valid email address",
        password: "Enter a valid password",
        captcha: "You must verify with google recaptcha",
      });
      return;
    }
    if (
      (!email || email.length === 0) &&
      (!password || password.length === 0)
    ) {
      setLoginErrors({
        email: "Enter a valid email address",
        password: "Enter a valid password",
      });
      return;
    }
    if (!email || email.length === 0) {
      setLoginErrors({ email: "Enter a valid email address" });
      return;
    }
    if (!password || password.length === 0) {
      setLoginErrors({ password: "Enter a valid password" });
      return;
    }
    if (!email.match(validRegex)) {
      setLoginErrors({ email: "Incorrect email format" });
      return;
    }
    if (
      !checkRecaptcha &&
      document.location.origin !== "http://localhost:3000" &&
      document.location.origin !== "https://demo.pivony.com"
    ) {
      setLoginErrors({ captcha: "You must verify with google recaptcha" });
      return;
    }
    signInUser();
  };

  return (
    <div className="MainContainer SignIn">
      <div className="SignIn-Landing-BG"></div>
      <div className="Login-Page-Container">
        <div className="login-page">
          <div className="login-page-header">
            <img src="/assets/TopicScope_logo_only.png" alt="" />
            <h4 className="login-page-header-title">Welcome to Pivony</h4>
            <p className="login-page-header-subtitle">
              Enter your info to login
            </p>
          </div>
          {MFAView ? (
            <div className={styles.verifyContainer}>
              <div className={styles.verifyTitle}>
                Enter code received sent to your phone number via SMS
              </div>
              <div className={styles.customVerifyCode}>
                <ReactInputVerificationCode
                  onChange={(val) => setVerificationCode(val)}
                  value={verificationCode}
                  length={6}
                  placeholder=""
                  autoFocus="true"
                />
              </div>
              <div className={styles.resendText}>
                {timeLeft === 0 ? (
                  <>
                    Didn't recieve a code?{" "}
                    <Button
                      className={styles.resendBtn}
                      onClick={() => Resend()}
                    >
                      Resend
                    </Button>
                  </>
                ) : (
                  <i>{timeLeft} seconds left for verification</i>
                )}
              </div>
              <Button
                loading={loading}
                style={{ height: "auto" }}
                className={`${styles.btn} loginBtn`}
                onClick={onVerifyCode}
              >
                Verify
              </Button>
            </div>
          ) : (
            <>
              <div className="login-title">
                <p className="notRegistered">
                  Not registered? <Link to="/signup">Create an account</Link>
                </p>
              </div>
              {failedLogin && (
                <div className={styles.loginFailedMsg}>
                  <div className={styles.msg}>
                    <span>You have been logged out.</span>
                    <span>Please login back to continue.</span>
                  </div>
                  <img src="/assets/x.svg" onClick={removeMsg} alt="" />
                </div>
              )}
              <div className="form">
                <div className="login-form">
                  <div className={styles.emailBlock}>
                    <input
                      className={styles.inputForm}
                      onKeyPress={keyPressed}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="Enter registered Email*"
                    />
                    {loginErrors.email ? (
                      <p className={styles.loginErrorMsg}>
                        {loginErrors.email}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={styles.passwordBlock}>
                    <input
                      ref={passwordRef}
                      className={styles.inputForm}
                      onKeyPress={keyPressed}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Enter your password*"
                    />
                    {showPassword ? (
                      <img
                        src="/assets/open_eye.svg"
                        className={styles.showHidePassword}
                        alt=""
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <img
                        src="/assets/close_eye.svg"
                        className={styles.showHidePassword}
                        alt=""
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                    {loginErrors.password ? (
                      <p className={styles.loginErrorMsg}>
                        {loginErrors.password}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={styles.forgotPasswordBlock}>
                    <div className={styles.rememberMeBlock}>
                      <input
                        type="checkbox"
                        checked={rememberCheck}
                        onChange={(e) => setRememberCheck(e.target.checked)}
                        className={styles.rememberMeBtn}
                      />
                      <span className={styles.rememberMeLabel}>
                        Remember me
                      </span>
                    </div>
                    <p className={styles.forgotPassword}>
                      <a onClick={forgotPassword}>Forgot password</a>
                    </p>
                  </div>
                  <div className={styles.captchaBlock}>
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      onChange={verifyRecaptcha}
                      sitekey={config.RECAPTCHA_SITE_PUBLIC_KEY}
                    />
                    {loginErrors.captcha ? (
                      <p className={styles.loginErrorMsg}>
                        {loginErrors.captcha}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <Button
                      id="mfa-sign-in"
                      style={{ height: "auto" }}
                      type="primary"
                      loading={loading}
                      className={`${styles.btn} loginBtn`}
                      onClick={handleValidation}
                    >
                      Log In
                    </Button>
                  </div>
                  <h2 className="or">Or</h2>
                  <div>
                    <div>
                      <button
                        onClick={signInWithGmail}
                        className={styles.gmailSignup}
                      >
                        <img src="/assets/google.png" />
                        <span>Continue with Google</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
