import React, { useEffect, useState } from "react";
import { notification, Form } from "antd";
import firebase from "firebase";
import { apiRequest, signout } from "util/services";
import { USER } from "util/constants";
import Representation from './Representation';
import { HIDE_BOARD_TOUR, HIDE_CREATION_TOUR } from "../../util/tourGuide";
import config from "../../Configurations.js";

const InvitedUsers = () => {
  const [checkRecaptcha, setCheckRecaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState();
  const [token, setToken] = useState();

  if (firebase.auth().currentUser) {
    window.location.href = '/';
  }

  useEffect(() => {
    if (document.getElementById("header_to_menu")?.style) {
      document.getElementById("header_to_menu").style.display = "none";
    }
    const path = window.location.href;
    const organization = path.split("users/")[1].split("/user_auth")[0];
    const params = path.split("user_auth?token=")[1];
    const token = params.split("&email=")[0];
    const email = params.split("&email=")[1];
    setOrganizationName(organization);
    setToken(token);
    setEmail(email)
  }, []);

  const signUp = async (values) => {
    if (
      !checkRecaptcha &&
      document.location.origin !== "http://localhost:3000"
    ) {
      notification.error({
        message: "Google Recaptcha",
        description: "You must verify with google recaptcha",
      });
      return;
    }
    if (values.password !== values.confirmPassword) {
      notification.error({
        message: "Confirm password field doesn't match password entered"
      });
      return;
    }

    setLoading(true);

    const payload = {
      token,
      email
    }

    apiRequest(
      "users/organization/handshake",
      "POST",
      payload,
      true
    ).then(async (result) => {
      if (!result || result?.error || result?.cancel === true) {
        setLoading(false);
        notification.error({
          message: "Signup Unauthorized",
        });

      } else {
        await createUserInFirebase(values);
      }

    })

  }

  // const verifyEmail = async () => {
  //   let user = firebase.auth().currentUser;
  //   let customURL = document.location.href;
  //   await user
  //     .sendEmailVerification({ url: customURL })
  //     .then(async (res) => {
  //       notification.info({
  //         message: "Email sent",
  //         description: "A verification mail has been sent to your account.",
  //       });
  //     })
  //     .catch((e) => {
  //       notification.error({
  //         message:
  //           "Verification email not sent, Please wait a few minutes then try again",
  //       });
  //     });
  // };

  const createUserInFirebase = async (values) => {
    window.localStorage.removeItem(HIDE_BOARD_TOUR);
    window.localStorage.removeItem(HIDE_CREATION_TOUR);
    const authCheckResult = await apiRequest("users/pre_auth_check", "POST", { email: email, operation_type: "inv_signup", token: token }, true);
    if ((authCheckResult || {}).error) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: authCheckResult.error,
      });
      signout(false);
      return;
    }
    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email,
      type: USER,
      emailVerified: true,
    };

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, values.password)
      .then(async (response) => {
        const res = response.user.toJSON();
        registerUID(res.uid, response.user, user, true);
      })
      .catch(function (error) {
        var errorMessage = error.message;
        notification.error({
          message: "Error",
          description: errorMessage,
        });
        setLoading(false);
      });
  }

  const registerUID = async (uid, authUser, objUser) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ user_id: uid, email: objUser.email, first_name: objUser.firstName, last_name: objUser.lastName, is_organization_invited: true }),
    };

    await fetch(config.BASE_URL + "users/create", requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        if (!result || result?.error || result?.cancel === true) {
          setLoading(false);
          authUser.delete();
          notification.error({
            message: "Error",
            description: result.error,
          });
        } else {
          // if (sendEmail) {
          //   await verifyEmail();
          // }
          await firebase.database().ref(`/users/${uid}`)
            .set({ ...objUser, plan: 100 });
          document.location.href = document.location.href;
        }
        setLoading(false);
      })
      .catch((error) => {
        authUser.delete();
        notification.error({
          message: "Error",
          description: "couldn't create user, please refresh and try again",
        });
        setLoading(false);
      });
  };

  const verifyRecaptcha = (token) => {
    setCheckRecaptcha(true);
  };

  return <Representation
    verifyRecaptcha={verifyRecaptcha}
    signUp={signUp}
    loading={loading}
    form={form}
    organizationName={organizationName}
  />
}

export default InvitedUsers;
