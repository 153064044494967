import * as actions from "redux/newDashboard/actions.js";

let initialState = {
  currentStep: 0,
  platform: null,
  creationType: null
};

const newDashboardReducer = (state = initialState, action) => {
  if (action.type === actions.SET_NEW_Dashboard) {
    return {
      ...action.payload,
    };
  } else if (action.type === actions.UPDATE_NEW_Dashboard) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

export { newDashboardReducer };
