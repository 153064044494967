import React from "react";
import { Button, Modal, Popover } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import CustomInput from "components/General/CustomInput";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Representation = ({
  setEmail,
  setToken,
  onSubmit,
  showModal,
  setShowModal,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();

  const ModalStyle = {
    padding: "45px",
    borderRadius: "15px",
    background: theme === "dark" ? "#1C193B" : "#ffffff",
    overflow: "hidden",
  };
  return (
    <Modal
      open={showModal}
      bodyStyle={ModalStyle}
      footer={null}
      onCancel={() => setShowModal(false)}
      style={{ overflow: "hidden", borderRadius: "15px", padding: "0px" }}
    >
      <CustomInput
        label={
          <div className={styles.title}>
            {t("settings.116")}
            <Popover content={t("settings.92")} trigger="hover">
              <InfoCircleFilled />
            </Popover>
          </div>
        }
        placeholder={t("settings.117")}
        onChange={setEmail}
        style={{ margin: "25px 0px" }}
      />
      <CustomInput
        label={t("settings.120")}
        placeholder={t("settings.121")}
        onChange={setToken}
        style={{ margin: "25px 0px" }}
      />
      <div className={styles.row}>
        <Button
          className={`${styles.btn} ${styles.outlined}`}
          onClick={() =>
            window.open(
              "https://www.notion.so/Zendesk-live-chat-a81cc36bc6c045c585a7386c27c2f70c",
              "_blank"
            )
          }
        >
          {" "}
          {t("settings.101")}{" "}
        </Button>
        <Button className={styles.btn} onClick={onSubmit}>
          {" "}
          {t("button.4")}{" "}
        </Button>
      </div>
    </Modal>
  );
};

export default Representation;
