import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import config from "./Configurations";
import "bootstrap/dist/css/bootstrap.min.css";
import { hotjar } from "react-hotjar";
import TagManager from "react-gtm-module";
import mixpanel from "mixpanel-browser";
import { Provider } from "react-redux";
import store from "redux/store";
import "./i18next";

if (config.HOTJARID) {
  hotjar.initialize(config.HOTJARID, config.HOTJARVERSION);
}
if (config.GTM_ID) {
  const tagManagerArgs = {
    gtmId: config.GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}
if (config.MIXPANEL_ID) {
  mixpanel.init(config.MIXPANEL_ID);
}

// window.less.modifyVars({
//   "@primary-color": "#100D31",
// });
// window.less.refreshStyles();

// const themes = {
//   dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
//   light: `${process.env.PUBLIC_URL}/light-theme.css`,
// }

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
