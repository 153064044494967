import React from 'react';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
const RenameDeletePopup = ({ text, setText, label, handleAction, closePopup, loading, disableBtn, type, btnColor, element, placeholder }) => {
   const { t } = useTranslation();

   return (
      <div className={styles.modal}>
         <div className={styles.centerWrapper}>
            <div className={styles.popup} onClick={e => e.stopPropagation()}>
               <p className={styles.title}>{label}</p>
               <div className={styles.inputField}>
                  {
                     type === 'delete' ?
                        <p className={styles.deleteInfo}>{t("tags_page.11")}</p>
                        : element && element === 'textarea' ?
                           <textarea placeholder={placeholder?.length ? placeholder : ""} value={text} onChange={e => setText(e.target.value)} />
                           :
                           <input type="text" value={text} onChange={e => setText(e.target.value)} />
                  }
               </div>
               <div className={styles.buttonBlock}>
                  <button disabled={loading} onClick={() => closePopup(false)}>{t("button.3")}</button>
                  <button disabled={disableBtn} onClick={handleAction} className={btnColor ? styles.customBtnColor : undefined}>{type === 'delete' ? loading ? <LoadingOutlined/>: t("button.2") : type === 'submit' ? t("button.4") : type === 'create' ? t("button.7") : type === 'update' ? t("button.30") : t("button.1")}</button>
               </div>
            </div>
         </div>
      </div>
   )
}

export default RenameDeletePopup;