import React, { useEffect, useState } from "react";
import NewDashboardHeader from "components/NewDashboard/NewDashboardHeader";
import StepsWrapper from "components/NewDashboard/StepsWrapper";
import styles from "./style.module.scss";
import { useSelector, useDispatch } from "react-redux";
import DataSource from "components/NewDashboard/DataSource";
import steps from "./steps.js";
import { apiRequest } from "util/services";
import { Spin } from "antd";
import * as actions from "redux/newDashboard/actions.js";
import { notification } from "antd";

const NewDashboard = ({ authenticated, user }) => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [platforms, setPlatforms] = useState([]);
  const [facebookPageObjects, setFacebookPageObjects] = useState([]);
  const [facebookPageObjectsLoading, setFacebookPageObjectsLoading] = useState(
    false
  );
  const [facebookAdObjects, setFacebookAdObjects] = useState([]);
  const [facebookAdObjectsLoading, setFacebookAdObjectsLoading] = useState(
    false
  );
  const [instagramAccounts, setInstagramAccounts] = useState([]);
  const [instagramAccountsLoading, setInstagramAccountsLoading] = useState(
    false
  );
  const [advancedAllowed, setAdvancedAllowed] = useState(false);
  const [trial, setTrial] = useState(false);
  const [forums, setForums] = useState([]);
  const [forumsLoading, setForumsLoading] = useState(false);
  const [linkedinPages, setLinkedinPages] = useState([]);
  const [linkedinPagesLoading, setLinkedPagesLoading] = useState(false);
  const newDashboardReducer = useSelector((state) => state.newDashboardReducer);
  let StepsComponents = [DataSource, StepsWrapper];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: actions.SET_NEW_Dashboard,
    });
    getAllPlatforms();
  }, []);

  useEffect(() => {
    const { platform, creationType } = newDashboardReducer;
    if (platform && creationType) {
      for (let step in steps[platform][creationType]) {
        if (steps[platform][creationType].length === StepsComponents.length)
          break;
        StepsComponents.push(StepsWrapper);
      }
      setStep(1);
    }
  }, [newDashboardReducer.platform, newDashboardReducer.creationType]);

  useEffect(() => {
    const { currentStep, platform } = newDashboardReducer;
    setStep(currentStep);
    if (
      (currentStep === 2 && platform === 9) ||
      (currentStep === 3 && platform === 12)
    ) {
      initLinkedinPages();
      initFormusList();
      initFacebookPages();
      initInstaAccounts();
    } else if (
      (currentStep === 2 && platform === 10) ||
      (currentStep === 3 && platform === 12)
    ) {
      initFacebookAdAccounts();
    }
  }, [newDashboardReducer.currentStep]);

  const initFormusList = async () => {
    setForumsLoading(true);
    const result = await apiRequest("users/forums", "POST", {}, true);
    if (result && !result.error) {
      setForums(result || []);
    }
    setForumsLoading(false);
  };

  const initFacebookPages = async () => {
    setFacebookPageObjectsLoading(true);
    const result = await apiRequest(
      "users/meta/pages",
      "POST",
      {
        firebase_id: user.uid,
      },
      true
    );
    if (result && !result.error && result.success) {
      setFacebookPageObjects(result.success || []);
    }
    setFacebookPageObjectsLoading(false);
  };
  const initInstaAccounts = async () => {
    setInstagramAccountsLoading(true);
    const result = await apiRequest(
      "users/meta/instagram_accounts",
      "POST",
      {
        firebase_id: user.uid,
      },
      true
    );
    if (result && !result.error && result.success) {
      setInstagramAccounts(result.success || []);
    }
    setInstagramAccountsLoading(false);
  };

  const initFacebookAdAccounts = async () => {
    setFacebookAdObjectsLoading(true);
    const result = await apiRequest(
      "users/meta/ad_accounts",
      "POST",
      {
        firebase_id: user.uid,
      },
      true
    );
    if (result && !result.error && result.success) {
      setFacebookAdObjects(result.success || []);
    }
    setFacebookAdObjectsLoading(false);
  };

  const initLinkedinPages = async () => {
    setLinkedPagesLoading(true);
    const result = await apiRequest("users/linkedin/pages", "POST", {}, true);
    if (result?.success) {
      setLinkedinPages(result.success || []);
    }
    setLinkedPagesLoading(false);
  };

  const getAllPlatforms = async () => {
    const result = await apiRequest(
      "users/getAllowedPlatformsOfUser",
      "POST",
      {
        firebase_id: user.uid,
      },
      true
    );
    setLoading(false);
    if (!result.error && result.platforms?.length !== 0) {
      setPlatforms(result.platforms);
      setAdvancedAllowed(result.advanced_allowed);
      setTrial(result.trial);
    }
  };

  const loadStepComponent = () => {
    let DynamicComponent = !step ? DataSource : StepsWrapper;
    return (
      <DynamicComponent
        user={user}
        platforms={platforms}
        advancedAllowed={advancedAllowed}
        trial={trial}
        facebookPageObjects={facebookPageObjects}
        facebookPageObjectsLoading={facebookPageObjectsLoading}
        facebookAdObjects={facebookAdObjects}
        facebookAdObjectsLoading={facebookAdObjectsLoading}
        instagramAccounts={instagramAccounts}
        instagramAccountsLoading={instagramAccountsLoading}
        forums={forums}
        forumsLoading={forumsLoading}
        linkedinPages={linkedinPages}
        linkedPagesLoading={linkedinPagesLoading}
      />
    );
  };

  return (
    <div className={styles.mainWindow}>
      <NewDashboardHeader />
      <div className={styles.stepsContainer}>
        <Spin spinning={loading}>{loadStepComponent()}</Spin>
      </div>
    </div>
  );
};

export default NewDashboard;
