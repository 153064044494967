import React, { useState } from "react";
import styles from "./topicListItem.module.scss";
import { notification, Menu, Dropdown, Button, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Collapse } from "antd";
import { Tree } from "antd";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
dayjs.extend(weekday);
dayjs.extend(localeData);

const MoreOptions = function({
  topic,
  handleRunDashboard,
  handleShowDeleteTopic,
  handleShowRelationPopup,
  handleRenameTopicModal,
  editTopicRule,
  setTopicID,
}) {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Menu className={styles.customMenu} theme="dark">
        <Menu.Item
          className={styles.customMenuItem}
          key={1}
          onClick={() => {
            handleRunDashboard(topic.id, topic.name);
          }}
        >
          <img
            className={styles.more}
            width={20}
            style={{ marginRight: "10px" }}
            src={
              theme === "dark" ? "/assets/send.svg" : "/assets/send_black.svg"
            }
            alt=""
          />
          {t("topic_library.4")}
        </Menu.Item>
        <Menu.Item
          className={styles.customMenuItem}
          key={2}
          onClick={() => handleRenameTopicModal(topic.name, topic.id)}
        >
          <img
            className={styles.more}
            width={20}
            style={{ marginRight: "10px" }}
            src={
              theme === "dark" ? "/assets/edit.svg" : "/assets/edit_black.svg"
            }
            alt=""
          />
          {t("topic_library.5")}
        </Menu.Item>
        <Menu.Item
          className={styles.customMenuItem}
          key={6}
          onClick={() => {
            setTopicID(topic.id);
            editTopicRule(topic);
          }}
        >
          <img
            className={styles.more}
            width={20}
            style={{ marginRight: "10px" }}
            src={
              theme === "dark"
                ? "/assets/custom_words.svg"
                : "/assets/custom_words_black.svg"
            }
            alt=""
          />
          {t("topic_library.37")}
        </Menu.Item>
        <Menu.Item
          className={styles.customMenuItem}
          key={3}
          onClick={() => {
            setTopicID(topic.id);
            handleShowDeleteTopic();
          }}
        >
          <img
            className={styles.more}
            width={20}
            style={{ marginRight: "10px" }}
            src={
              theme === "dark" ? "/assets/trash.svg" : "/assets/trash_black.svg"
            }
            alt=""
          />
          {t("topic_library.6")}
        </Menu.Item>
        {/* <Menu.Item key={4} onClick={() => handleShowRelationPopup('parent', topic.id)}>
                    <img className={styles.more} width={20} style={{ marginRight: "10px" }} src={theme === 'dark' ? "/assets/relation.svg" : "/assets/relation_black.svg"} alt="" />
                    Add Parent Relation
                </Menu.Item> */}
        <Menu.Item
          className={styles.customMenuItem}
          key={5}
          onClick={() => handleShowRelationPopup("child", topic.id)}
        >
          <img
            className={styles.more}
            width={20}
            style={{ marginRight: "10px" }}
            src={
              theme === "dark"
                ? "/assets/relation.svg"
                : "/assets/relation_black.svg"
            }
            alt=""
          />
          {t("topic_library.32")}
        </Menu.Item>
      </Menu>
    </div>
  );
};
const CarryOutOutlined = ({ selected }) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  if (selected) {
    return (
      <img
        className={styles.more}
        width={20}
        src={
          theme === "dark"
            ? "/assets/arrow_down.svg"
            : "/assets/arrow_down_black.svg"
        }
        alt=""
      />
    );
  } else {
    return (
      <img
        className={styles.more}
        width={20}
        src={theme === "dark" ? "/assets/survey.svg" : "/assets/survey.svg"}
        alt=""
      />
    );
  }
};
const SurveryInfopPopUp = ({ close, responseSum, title, dashboards }) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);

  return (
    <div className={styles.surveryInfoModalPopup}>
      <div className={styles.dashboardInfoModalContent}>
        <div className={styles.header}>
          <img
            className={styles.message_img}
            src={
              theme === "dark" ? "/assets/info1.svg" : "/assets/info1_black.svg"
            }
          />
          <div className={styles.content}>
            <p className={styles.title}>Survey File Detail</p>
            <p className={styles.description}>{title}</p>
          </div>
          <div
            className={styles.close}
            onClick={(e) => {
              e.stopPropagation();
              close();
            }}
          >
            <img
              className={styles.message_img}
              src={theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"}
            />
          </div>
        </div>
        <div className={styles.popupContent}>
          <div className={styles.item}>
            <div className={styles.title}>
              <img
                className={styles.more}
                width={20}
                src={
                  theme === "dark"
                    ? "/assets/survey_responses.svg"
                    : "/assets/survey_black.svg"
                }
                alt=""
              />{" "}
              Total Responses
            </div>
            <div className={styles.description}>{responseSum}</div>
          </div>
          {dashboards && dashboards?.length ? (
            <div className={styles.item}>
              <div className={styles.title}>
                <img
                  className={styles.more}
                  width={20}
                  src={
                    theme === "dark"
                      ? "/assets/analyze_dashboards.svg"
                      : "/assets/survey_black.svg"
                  }
                  alt=""
                />{" "}
                Analyzed in Dashboard
              </div>
              <div className={styles.dashItems}>
                {dashboards.map((item) => (
                  <div className={styles.dash}>{item.dashboard_name}</div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
const searchTopics = (topicsArr, id) => {
  for (let tpc of topicsArr) {
    if (tpc.id === parseInt(id, 10)) {
      return tpc;
    }
    if (tpc.child && tpc.child.length > 0) {
      const res = searchTopics(tpc.child, id);
      if (res) {
        return res;
      }
    }
  }
};
const SurveryListItem = (props) => {
  const {
    tree,
    responseSum,
    topicList,
    handleParentCheckboxChange,
    handleSelectTopic,
    editTopicRule,
    handleRunDashboard,
    handleShowRelationPopup,
    handleRenameTopicModal,
    setTopicID,
    handleShowDeleteTopic,
    allTopicsList,
  } = props;
  const theme = useSelector((state) => state.DashboardReducer.theme);

  return (
    <div className={styles.surverylistItem}>
      <i className="fa-solid fa-chevron-right" style={{ fontSize: "12px" }}></i>

      <input
        className={styles.topicCheckBox}
        checked={searchTopics(allTopicsList, tree.id)?.checked}
        id={tree.id}
        type="checkbox"
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(e) => {
          e.stopPropagation();
          handleParentCheckboxChange(e, tree?.child, tree?.query);
        }}
      />
      <p
        className={styles.title}
        onClick={(e) => {
          e.stopPropagation();
          handleSelectTopic(tree);
        }}
      >
        {tree?.name}
      </p>
      <div
        className={styles.surveyContent}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* {
                tree?.runOn?.length ? <div className={styles.dashCount} >
                    <img className={styles.more} src={theme === 'dark' ? "/assets/dashboards.svg" : "/assets/dashboards_black.svg"} alt="" />
                    {tree?.runOn?.length} 

                </div> :

            } */}

        <Dropdown
          overlay={() => (
            <MoreOptions
              editTopicRule={editTopicRule}
              setTopicID={setTopicID}
              handleShowDeleteTopic={handleShowDeleteTopic}
              topic={tree}
              handleRunDashboard={handleRunDashboard}
              handleRenameTopicModal={handleRenameTopicModal}
              handleShowRelationPopup={handleShowRelationPopup}
            />
          )}
          placement="left"
        >
          <img
            className={styles.more}
            src={
              theme === "dark"
                ? "/assets/more-vertical.svg"
                : "/assets/more-vertical-black.svg"
            }
            alt=""
          />
        </Dropdown>
      </div>
    </div>
  );
};
const ItemDetail = ({
  treeData,
  topicList,
  allTopicsList,
  handleSelectTopic,
  handleRunDashboard,
  handleParentCheckboxChange,
  handleShowRelationPopup,
  handleRenameTopicModal,
  handleCheckboxChange,
  editTopicRule,
  handleShowDeleteTopic,
  setTopicID,
}) => {
  return (
    <div className={styles.treeWrapper}>
      <Tree
        titleRender={(props) => (
          <TreeLeaf
            handleSelectTopic={handleSelectTopic}
            handleCheckboxChange={handleCheckboxChange}
            handleParentCheckboxChange={handleParentCheckboxChange}
            editTopicRule={editTopicRule}
            handleRunDashboard={handleRunDashboard}
            handleShowRelationPopup={handleShowRelationPopup}
            handleRenameTopicModal={handleRenameTopicModal}
            setTopicID={setTopicID}
            topicList={topicList}
            allTopicsList={allTopicsList}
            title={props.name}
            {...props}
            topic={props}
            handleShowDeleteTopic={handleShowDeleteTopic}
          />
        )}
        treeData={treeData.children}
        selectable={false}
        switcherIcon={<DownOutlined />}
      ></Tree>
    </div>
  );
};

const TreeLeaf = (props) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const [showSurveryDetail, setShowSurveyDetail] = useState(false);

  const {
    title,
    topic,
    topicList,
    handleSelectTopic,
    handleShowDeleteTopic,
    allTopicsList,
    handleParentCheckboxChange,
    id,
    handleCheckboxChange,
    handleRunDashboard,
    handleRenameTopicModal,
    handleShowRelationPopup,
    setTopicID,
    editTopicRule,
    runOn,
  } = props;
  return (
    <div className={styles.treeLeaf}>
      <input
        id={topic.id}
        className={styles.topicCheckBox}
        type="checkbox"
        checked={searchTopics(allTopicsList, topic.id)?.checked}
        onChange={(e) => {
          if (topic?.children?.length) {
            handleParentCheckboxChange(e, topic?.child, topic?.query);
          } else {
            handleCheckboxChange(e);
          }
        }}
      />

      <div
        className={styles.title}
        onClick={(e) => {
          e.stopPropagation();
          handleSelectTopic(topic);
        }}
      >
        {title}
      </div>
      <div className={styles.actions}>
        {/* {
                runOn?.length ? <div className={styles.dashCount}>
                    <img className={styles.more} src={theme === 'dark' ? "/assets/dashboards.svg" : "/assets/dashboards_black.svg"} alt="" />
                    {runOn?.length}
                </div> : ""
            } */}

        <div
          className={styles.more}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Dropdown
            overlay={() => (
              <MoreOptions
                handleShowDeleteTopic={handleShowDeleteTopic}
                topic={topic}
                setTopicID={setTopicID}
                editTopicRule={editTopicRule}
                handleRunDashboard={handleRunDashboard}
                handleShowRelationPopup={handleShowRelationPopup}
                handleRenameTopicModal={handleRenameTopicModal}
              />
            )}
            placement="left"
          >
            <img
              className={styles.more}
              src={
                theme === "dark"
                  ? "/assets/more-vertical.svg"
                  : "/assets/more-vertical-black.svg"
              }
              alt=""
            />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const WrappedItem = (props) => {
  let {
    tree,
    topicList,
    handleParentCheckboxChange,
    handleSelectTopic,
    handleCheckboxChange,
    editTopicRule,
    handleRunDashboard,
    handleRenameTopicModal,
    handleShowRelationPopup,
    allTopicsList,
    setTopicID,
    handleShowDeleteTopic,
  } = props;
  return (
    <div className={styles.collaps}>
      {" "}
      <Collapse>
        <Collapse.Panel
          showArrow={false}
          header={SurveryListItem({ ...props })}
        >
          <ItemDetail
            handleSelectTopic={handleSelectTopic}
            treeData={tree}
            topicList={topicList}
            editTopicRule={editTopicRule}
            handleCheckboxChange={handleCheckboxChange}
            allTopicsList={allTopicsList}
            topic={tree}
            handleRunDashboard={handleRunDashboard}
            handleRenameTopicModal={handleRenameTopicModal}
            handleShowRelationPopup={handleShowRelationPopup}
            {...props}
            setTopicID={setTopicID}
            handleShowDeleteTopic={handleShowDeleteTopic}
          />{" "}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default WrappedItem;
