import React, { useState, useRef, useEffect } from "react";
import { notification, Button } from "antd";
import styles from "./resetPassword.module.scss"
import { useHistory } from "react-router-dom";
import { apiRequest } from "../../util/services";

import Icon from "helperComponents/Icon";
import firebase from "../../FirebaseConfig";
import { getParams } from "../../util/common";

const ResetPassword = ({ code }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const passwordRef = useRef()
    const [params, setParams] = useState({ mode: "loading" });
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setParams(getParams(document.location.href));
    }, []);
    const confirmRef = useRef()

    const [loginErrors, setLoginErrors] = useState({
        password: ""
    })
    const checkForWeakPassword = () => {
        let regEx = /\d/; // magic
        if (regEx.test(password)) {
            return false
        } else {
            return true
        }
    }

    const goBack = () => {
        history.push("/login")
    }

    const verifyForm = () => {
        // verification of password
        let flag = true
        if (password.length == 0) {
            notification.error({
                message: "Failed",
                description:
                    "Please enter new password",
            })
            setLoginErrors({
                ...loginErrors,
                password: "error",
            })
            flag = false
        }
        else if (password.length < 6) {
            notification.error({
                message: "Failed",
                description:
                    "Password must be 6 characters log",
            })
            setLoginErrors({
                ...loginErrors,
                password: "error",
            })
            flag = false
        } else if (checkForWeakPassword()) {
            notification.error({
                message: "Failed",
                description:
                    "Please enter a strong password",
            })
            setLoginErrors({
                ...loginErrors,
                password: "error"
            })
            flag = false
        }
        else if (confirmPassword.length == 0) {
            notification.error({
                message: "Failed",
                description:
                    "Please confirm your Password",
            })
            setLoginErrors({
                ...loginErrors,
                confirmPassword: "error"
            })
            flag = false
        }
        else if (password !== confirmPassword) {
            notification.error({
                message: "Failed",
                description:
                    "Password does not match",
            })
            setLoginErrors({
                ...loginErrors,
                confirmPassword: "error"
            })
            flag = false
        }
        return flag
    }

    const resetPassword = () => {
        if (verifyForm()) {
            setLoading(true)
            firebase.auth().confirmPasswordReset(params.oobCode, password)
                .then(function () {
                    setLoading(false)

                    notification.success({
                        message: 'Successful',
                        description: 'Reset password succeeded'
                    })
                    history.push("/login")
                })
                .catch(function (error) {
                    setLoading(false)
                    try {
                        apiRequest("users/client_log", "POST", { error: error, message: "Failed to reset" }, true)
                    }
                    catch (er) {
                        console.log(er)
                    }
                    notification.error({
                        message: 'Failed',
                        description: 'Failed to reset password, Link expired or invalid'
                    })
                })
        }
    }

    const forgotPassword = () => {
        if (!params.email) {
            notification.error({
                message: "Email empty",
                description:
                    "Enter your email in the email field and click forgot password",
            });
        } else {
            firebase
                .auth()
                .sendPasswordResetEmail(params.email)
                .then(function () {
                    notification.success({
                        message: "Email sent",
                        description: `A reset email has been sent to ${params.email}`,
                    });
                })
                .catch((error) => {
                    notification.error({
                        message: "Error occured",
                        description: error.message,
                    });
                });
        }
    };


    const toggleShowHidePassword = (show) => {
        passwordRef && passwordRef?.current && passwordRef?.current?.type === "password" && show ? passwordRef.current.type = "text" : passwordRef.current.type = "password";
        confirmRef && confirmRef?.current?.type && confirmRef?.current?.type === "password" && show ? confirmRef.current.type = "text" : confirmRef.current.type = "password";
    }
    return (
        <div className={styles.backdrop}>
            <div className={styles.illustration}>
                <img src="/assets/reset_password.svg" className={styles.illus} /> :
                <img src="/assets/pivony_logo_black.svg" className={styles.illus} /> :

            </div>
            {
                params.oobCode ? <div className={styles.inputs}>
                    <div className={styles.passwordBlock}>
                        <div className={styles.title}>New Password</div>
                        <input
                            ref={passwordRef}
                            className={`${styles.inputForm} ${loginErrors.password ? styles.error : ''} `}

                            onChange={(e) => {
                                setLoginErrors({
                                    ...loginErrors,
                                    password: "",
                                }); setPassword(e.target.value)
                            }}
                            type="password"
                            placeholder="Enter your password*"
                        />
                        {
                            showPassword ?
                                <img src="/assets/open_eye.svg" className={styles.showHidePassword} alt="" onClick={() => { setShowPassword(false); toggleShowHidePassword(false) }} /> :
                                <img src="/assets/close_eye.svg" className={styles.showHidePassword} alt="" onClick={() => { setShowPassword(true); toggleShowHidePassword(true) }} />
                        }
                        {
                            password.length < 6 ? <div className={styles.description}>Password should be minimum 6 characters long</div> : checkForWeakPassword() ? <div className={`${styles.description} ${styles.weak} `}>Weak Password, should contain minimum 1 numerical</div> : <div className={`${styles.description} ${styles.strong} `}>Strong Password</div>
                        }
                    </div>
                    <div className={styles.passwordBlock}>
                        <div className={styles.title}>Confirm Password</div>

                        <input
                            ref={confirmRef}
                            className={`${styles.inputForm} ${loginErrors.confirmPassword ? styles.error : ''} `}
                            onChange={(e) => {
                                setLoginErrors({
                                    ...loginErrors,
                                    confirmPassword: "",
                                }); setConfirmPassword(e.target.value)
                            }}
                            type="password"
                            placeholder="Please confirm your password"
                        />
                        {
                            showPassword ?
                                <img src="/assets/open_eye.svg" className={styles.showHide} alt="" onClick={() => { setShowPassword(false); toggleShowHidePassword(false) }} /> :
                                <img src="/assets/close_eye.svg" className={styles.showHide} alt="" onClick={() => { setShowPassword(true); toggleShowHidePassword(true) }} />
                        }
                    </div>
                    <Button
                        id="mfa-sign-in"
                        style={{ height: "auto" }}
                        onClick={resetPassword}
                        loading={loading}
                        type="primary"
                        className={`${styles.btn} loginBtn`}
                    >
                        Submit
                    </Button>
                </div> : <div className={styles.content}>
                    <div className={styles.title}>
                        Forgot Your Password?
                    </div>
                    <div className={styles.infoText}>
                        <div className={styles.description}>
                            We sent a reset password email to <span style={{ fontWeight: 600 }}> {params.email}</span>
                        </div>
                        <div className={styles.description}>
                            Please click link to set your new password.</div>
                    </div>
                    <div className={styles.infoText}>
                        <div className={styles.info}>
                            Haven’t you receive the email yet?
                        </div>
                        <div className={styles.info}>
                            Please check your spam folder, or <span className={styles.link} onClick={forgotPassword} > send again</span>
                        </div>
                        <div className={styles.info}>
                            <span className={styles.link} onClick={goBack} > Go back to login page</span>
                        </div>
                    </div>


                </div>
            }


        </div>)
}

export default ResetPassword