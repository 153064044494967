import React from "react";
import CustomInput from "components/General/CustomInput";
import { Modal, Dropdown, MultiSelect, TextArea, DatePicker } from "components/General/ModalFormComponents";
import styles from "./style.module.scss";
import i18n from 'i18next';

const Representation = ({
  setSelectedTeam,
  selectedTeam,
  teams,
  teamMembers,
  setSelectedSpace,
  selectedSpace,
  spaces,
  selectedList,
  setSelectedList,
  lists,
  selectedStatus,
  setSelectedStatus,
  statuses,
  showModal,
  setSelectedMembers,
  onClose,
  date,
  setDate,
  setTitle,
  setDescription,
  showSaveButton,
  save
}) => (
  <Modal
    showModal={showModal}
    onClose={onClose}
    onSave={save}
    showSaveButton={showSaveButton}
    titleAsset="/assets/settings/clickup.png"
    title="Clickup"
    widthofmodal="700px"
  >
    <Dropdown
      title={`${i18n.t("clickup_task.1")} *`}
      options={teams}
      selectedOption={selectedTeam}
      onChange={(value) => setSelectedTeam(value)}
      idKey="id"
      displayKey="name"
    />
    {selectedTeam &&
      <Dropdown
        title={`${i18n.t("clickup_task.2")} *`}
        options={spaces}
        selectedOption={selectedSpace}
        onChange={(value) => setSelectedSpace(value)}
        idKey="id"
        displayKey="name"
      />}
    {selectedTeam && selectedSpace &&
      <Dropdown
        title={`${i18n.t("clickup_task.3")} *`}
        options={lists}
        selectedOption={selectedList}
        onChange={(value) => setSelectedList(value)}
        idKey="id"
        displayKey="name"
      />}
    {selectedTeam && selectedSpace && selectedList &&
      <Dropdown
        title={`${i18n.t("clickup_task.4")} *`}
        options={statuses}
        selectedOption={selectedStatus}
        onChange={(value) => setSelectedStatus(value)}
        idKey="status"
        displayKey="status"
      />}
    {selectedTeam && selectedSpace && selectedList && selectedStatus &&
      <>
        <MultiSelect
          title={`${i18n.t("clickup_task.5")} *`}
          list={teamMembers.map(elem => elem.user)}
          onChange={(value) => setSelectedMembers(value)}
          idKey="id"
          displayKey="username"
        />
        <DatePicker
          title={`${i18n.t("trello_task.5")} *`}
          value={date}
          onChange={(date) => setDate(date)}
        />
        <div className={styles.row}>
          <p className={styles.label}>{`${i18n.t("trello_task.6")} *`}</p>
          <CustomInput
            style={{ margin: "-10px 0px 0px 0px", width: "300px", borderRadius: "10px" }}
            onChange={setTitle}
          />
        </div>
        <TextArea
          title={`${i18n.t("trello_task.7")} *`}
          onChange={(e) => setDescription(e.target.value)}
        />
      </>
    }
  </Modal>
)

export default Representation;