import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Form,
  Carousel,
  notification,
  Checkbox,
  Col,
  Radio,
  Button,
  Select,
} from "antd";
import Card from "./Card";
import config from "../../Configurations.js";
import firebase from "../../FirebaseConfig.js";
import "../../stylesheets/SignForms.scss";
import { authWithGmail, apiRequest, signout } from "../../util/services";
import styles from "./signup.module.scss";
import {
  filterByTypeInterval,
  getPlanById,
  getPlanByUTM,
  getPlans,
} from "./plans";
import { HIDE_BOARD_TOUR, HIDE_CREATION_TOUR } from "../../util/tourGuide";
import { USER } from "../../util/constants";
import { getParams } from "../../util/common";
import { useHistory } from "react-router-dom";
import { hotjar } from "react-hotjar";
import mixpanel from "mixpanel-browser";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
// const { Option } = Select;

const SpecialSignup = ({ user }) => {
  const [selectedPlan, setSelectedPlan] = useState();
  // const [plansCategory, setPlansCategory] = useState("default");
  const setPlansCategory = useState("default");
  const [timeType, setTimeType] = useState("trimonthly");
  // const [currency, setCurrency] = useState("tl");
  const currency = useState("tl");
  // const [reviews, setReviews] = useState("200000");
  const reviews = useState("200000");
  // const [expert, setExpert] = useState("expert_yes");
  const expert = useState("expert_yes");
  // const [plans, setPlans] = useState(
  //   filterByTypeInterval(timeType, plansCategory)
  // );
  const [plans, setPlans] = useState(getPlans());

  const [checkRecaptcha, setCheckRecaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const Paddle = window.Paddle;
  const [form] = Form.useForm();
  let carousel = React.createRef();
  let startingStep = 0;

  /* Determine the starting component */
  const UTMparams = getParams(document.location.href);
  const utmPlan = getPlanByUTM(UTMparams.recur, UTMparams.type, UTMparams.plan);
  if (!user.uid) startingStep = 0;
  else if (!user.emailVerified) startingStep = 1;
  else if (user.role || user.company || user.dataSource || user.usage) {
    startingStep = utmPlan ? 4 : 3;
  } else if (user.emailVerified) startingStep = 2;

  const email = getParams(document.location.href)?.email || "";

  useEffect(() => {
    if (config.HOTJARID) {
      hotjar.initialize(config.HOTJARID, config.HOTJARVERSION);
    }
  }, []);

  useEffect(() => {
    setSelectedPlan(utmPlan?.id);
  }, []);

  const handlePayment = (e, paddleId, freePlan) => {
    e.preventDefault();
    if (!Paddle) {
      notification.info({
        message: "An error happened, Please refresh the page",
        icon: <InfoCircleOutlined />,
      });
      return;
    }

    if (freePlan) {
      addPlan(null);
    } else {
      Paddle.Checkout.open({
        product: paddleId,
        email: user.email,
        passthrough: JSON.stringify({
          user_id: user.uid,
          package_id: getPlanById(selectedPlan).pricing[reviews][expert][
            timeType
          ].id,
        }),
        successCallback: async (data, err) => {
          Paddle.Order.details(data.checkout.id, function(orderDetails) {
            if (orderDetails.order) {
              const subscriptionId = orderDetails.order.subscription_id;
              addPlan(subscriptionId);
            } else {
              notification.success({
                message:
                  "Thanks for your subscription, we are reviewing your payment and will activate your account in few hours. If 24 hours passed and it is not activated please contact us at support@pivony.com",
              });
            }
          });
        },
      });
    }
  };

  const verifyEmail = async () => {
    let user = firebase.auth().currentUser;
    let customURL = document.location.href;
    await user
      .sendEmailVerification({ url: customURL })
      .then(async (res) => {
        notification.info({
          message: "Email sent",
          description: "A verification mail has been sent to your account.",
          icon: <InfoCircleOutlined />,
        });
      })
      .catch((e) => {
        notification.error({
          message:
            "Verification email not sent, Please wait a few minutes then try again",
        });
      });
  };

  const signUpUser = async (values) => {
    window.localStorage.removeItem(HIDE_BOARD_TOUR);
    window.localStorage.removeItem(HIDE_CREATION_TOUR);
    if (
      !checkRecaptcha &&
      document.location.origin !== "http://localhost:3000"
    ) {
      notification.error({
        message: "Google Recaptcha",
        description: "You must verify with google recaptcha",
      });
      return;
    }

    var payload = { email: values.email, operation_type: "sp_signup" };
    const authCheckResult = await apiRequest(
      "users/pre_auth_check",
      "POST",
      payload,
      true
    );
    if ((authCheckResult || {}).error) {
      setLoading(false);
      notification.error({
        message: "Error",
        description: authCheckResult.error,
      });
      signout(false);
      return;
    }

    setLoading(true);
    let user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      type: USER,
      emailVerified: false,
    };

    firebase
      .auth()
      .createUserWithEmailAndPassword(values.email, values.password)
      .then(async (response) => {
        const res = response.user.toJSON();
        (res || {}).uid && mixpanel.identify(res.uid);
        mixpanel.track("REGISTER_SPECIAL");
        registerUID(res.uid, response.user, user, true);
      })
      .catch(function(error) {
        // var errorCode = error.code;
        var errorMessage = error.message;
        notification.error({
          message: "Error",
          description: errorMessage,
        });
        setLoading(false);
      });
  };

  const registerUID = async (uid, authUser, objUser, sendEmail) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({ user_id: uid, email: objUser.email }),
    };

    await fetch(config.BASE_URL + "users/create", requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        if (result.error) {
          authUser.delete();
          notification.error({
            message: "Error",
            description: result.error,
          });
        } else {
          if (sendEmail) {
            await verifyEmail();
          }
          await firebase
            .database()
            .ref(`/users/${uid}`)
            .set(objUser);
          document.location.href = document.location.href;
        }
        setLoading(false);
      })
      .catch((error) => {
        authUser.delete();
        notification.error({
          message: "Error",
          description: "couldn't create user, please refresh and try again",
        });
        setLoading(false);
      });
  };

  const verifyRecaptcha = (token) => {
    // TODO serverside check recaptcha
    setCheckRecaptcha(true);

    // const data = {
    //   response: token,
    //   secret: config.RECAPTCHA_LOCALHOST_SECRET_KEY
    // }
    // fetch("https://www.google.com/recaptcha/api/siteverify", {
    //   method: "POST", // or 'PUT'
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(data),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log("Success:", data);
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };

  const signUpWithGmail = async () => {
    const result = await authWithGmail();
    if (result) {
      var payload = { email: result.email, operation_type: "sp_signup" };
      const authCheckResult = await apiRequest(
        "users/pre_auth_check",
        "POST",
        payload,
        true
      );
      if ((authCheckResult || {}).error) {
        setLoading(false);
        notification.error({
          message: "Error",
          description: authCheckResult.error,
        });
        signout(false);
        return;
      }
      firebase
        .database()
        .ref(`/users/${result.uid}`)
        .once("value", async (snapshot) => {
          if (!snapshot.val()) {
            const objUser = {
              firstName: result.displayName,
              phone: result.phoneNumber,
              email: result.email,
              type: USER,
              emailVerified: true,
            };
            (result || {}).uid && mixpanel.identify(result.uid);
            mixpanel.track("REGISTER_SPECIAL");
            registerUID(result.uid, result, objUser);
          }
        });
    } else {
      notification.error({
        message: "Gmail authentication",
        description: "An error occured please try again later",
      });
    }
  };

  const onFinish = async (values) => {
    const objUser = { ...user, ...values };
    for (let key in objUser) {
      if (!objUser[key] && key !== "emailVerified" && key !== "plan")
        delete objUser[key];
    }
    if (selectedPlan) carousel.goTo(4);
    else carousel.next();
    window.scrollTo(0, 0);
    await firebase
      .database()
      .ref(`/users/${user.uid}`)
      .set(objUser);
  };

  const addPlan = async (subscriptionId) => {
    // carousel.next();
    const result = await apiRequest("subscriptions/create", "POST", {
      user_id: user.uid,
      package_id: getPlanById(selectedPlan).pricing[reviews][expert][timeType]
        .id,
      subscription_id: subscriptionId,
    });
    if (result) {
      await firebase
        .database()
        .ref(`/users/${user.uid}/plan`)
        .set(getPlanById(selectedPlan).pricing[reviews][expert][timeType].id);
      history.push("/");
    } else {
      notification.error({
        message: "An error occured while creating your account",
      });
    }
  };

  const passPlanSection = (planId) => {
    carousel.next();
    window.scrollTo(0, 0);
    setSelectedPlan(planId);
  };

  const changePlansCategory = (e) => {
    setPlansCategory(e.target.value);
    setPlans(filterByTypeInterval(timeType, e.target.value));
  };

  const changeTime = (checked) => {
    if (checked) {
      setTimeType("yearly");
      // setPlans(filterByTypeInterval("yearly", plansCategory));
    } else {
      setTimeType("monthly");
      // setPlans(filterByTypeInterval("monthly", plansCategory));
    }
  };

  return (
    <div className={styles.container}>
      <Carousel
        swipe={false}
        accessibility={false}
        ref={(node) => (carousel = node)}
        dotPosition="top"
        initialSlide={startingStep}
      >
        <div className={styles.carouselContent}>
          <div className={styles.title}>
            <h1>Create your Pivony Account</h1>
          </div>

          <div className={styles.form}>
            <Form form={form} name="mainDetails" onFinish={signUpUser}>
              <Form.Item
                name="firstName"
                label="First name"
                rules={[{ required: true, message: "First name is required" }]}
              >
                <input placeholder="John" className={styles.inputForm} />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last name"
                rules={[{ required: true, message: "Last name is required" }]}
              >
                <input placeholder="Doe" className={styles.inputForm} />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Email is required" }]}
              >
                <input
                  defaultValue={email}
                  placeholder="john.doe@gmail.com"
                  className={styles.inputForm}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: "Password is required" }]}
              >
                <input type="password" className={styles.inputForm} />
              </Form.Item>

              <Form.Item style={{ textAlign: "center" }}>
                <div className={styles.recaptcha}>
                  <ReCAPTCHA
                    style={{ display: "inline-block" }}
                    onChange={verifyRecaptcha}
                    sitekey={config.RECAPTCHA_SITE_PUBLIC_KEY}
                  />
                </div>
                <Button
                  loading={loading}
                  className={styles.btn}
                  type="primary"
                  htmlType="submit"
                  style={{ height: "auto" }}
                >
                  Sign up
                </Button>
              </Form.Item>
            </Form>
          </div>
          <h2 className={styles.or}>
            <span>or</span>
          </h2>
          <div>
            <button onClick={signUpWithGmail} className={styles.gmailSignup}>
              <img src="/assets/google.png" />
              <span>SIGN UP WITH GOOGLE</span>
            </button>
          </div>

          <p className={styles.terms}>
            By clicking the button above, you agree to our <br />
            <a href="https://pivony.com/terms/" target="_blank">
              Terms of Service
            </a>{" "}
            and{" "}
            <a href="https://pivony.com/privacy/" target="_blank">
              Privacy Policy
            </a>
          </p>
        </div>
        <div className={styles.carouselContent}>
          <div className={styles.title}>
            <h1>Verify your email</h1>
            <div className={styles.verified}>
              <img src="/assets/email.png" alt="verified" />
            </div>
            <div className={styles.welcome}>
              <p>We've sent an email to {user.email}</p>
              <p>Click the link in the email to confirm your address</p>
              <p>Can't see the email?</p>
              <p>
                Check your spam folder or{" "}
                <span onClick={verifyEmail}>Resend email</span>.
              </p>
              <p>Your information is safe and secure in Pivony.</p>
              {user.emailVerified && (
                <button
                  className={styles.btn}
                  style={{
                    float: "inherit",
                    width: "90%",
                  }}
                  type="primary"
                  onClick={() => {
                    carousel.next();
                    window.scrollTo(0, 0);
                  }}
                >
                  Next
                </button>
              )}
            </div>
          </div>
          <div className={styles.form}></div>
        </div>
        <div className={styles.carouselContent}>
          <div className={styles.title}>
            <h1>Additional information</h1>
          </div>
          <div className={styles.form}>
            <Form name="additionalDetails" onFinish={onFinish}>
              <Form.Item style={{ marginBottom: "10px" }}>
                <span>
                  <p style={{ marginBottom: "5px" }}>
                    Which data source are you interested in?{" "}
                  </p>
                  <span>(select one or multiple options)</span>
                </span>
              </Form.Item>
              <Form.Item name="dataSource">
                <Checkbox.Group>
                  <Col>
                    <Checkbox
                      value="customerData"
                      style={{ lineHeight: "32px" }}
                    >
                      Your customer data
                    </Checkbox>
                  </Col>
                  <Col>
                    <Checkbox
                      value="socialMedia"
                      style={{ lineHeight: "32px" }}
                    >
                      Social media
                    </Checkbox>
                  </Col>
                  <Col>
                    <Checkbox value="onlineNews" style={{ lineHeight: "32px" }}>
                      Online news
                    </Checkbox>
                  </Col>
                </Checkbox.Group>
              </Form.Item>
              <Form.Item name="company" label="Your company name website">
                <input className={styles.inputForm} />
              </Form.Item>
              <Form.Item
                name="usage"
                label="Please briefly describe your expectation using Pivony"
              >
                <textarea
                  style={{ height: "134px" }}
                  className={styles.inputForm}
                />
              </Form.Item>
              <Form.Item
                name="role"
                label="Which of the following best describes your role?"
              >
                <Radio.Group className="signupRadios">
                  <Radio.Button value="Marketing Director / Manager">
                    Marketing Director / Manager
                  </Radio.Button>
                  <Radio.Button value="Marketing / Content Creator">
                    Marketing / Content Creator
                  </Radio.Button>
                  <Radio.Button value="Designer / Developer">
                    Designer / Developer
                  </Radio.Button>
                  <Radio.Button value="Other">Other</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <div className={styles.btnContainer}>
                  <button
                    className={styles.btn}
                    type="primary"
                    htmlType="submit"
                  >
                    Next
                  </button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className={styles.carouselContent_fullWidth}>
          <div className={styles.title}>
            <h1>The right plan for your company's growth</h1>
          </div>
          {/* <div className={styles.planType}>
            <Radio.Group onChange={changePlansCategory} value={plansCategory}>
              <Radio.Button checked value="customer">
                Customer Data Discovery
              </Radio.Button>
              <Radio.Button value="social">Social Web Discovery</Radio.Button>
              <Radio.Button value="bundles">Bundles</Radio.Button>
            </Radio.Group>
          </div> */}
          {/* <div className={styles.planFilters}>
            <div className={styles.switch}>
              <p>Monthly</p>
              <Switch onChange={changeTime} />
              <p>Annually</p>
            </div>
          </div> */}

          <div className={styles.DashboardsWrapper}>
            {/* <div className={styles.Dashboards}>
              <p>Currency</p>
              <Select
                labelInValue
                defaultValue={{ value: 'usd' }}
                style={{ width: 100 }}
                onChange={({value}) => setCurrency(value)}
              >
                <Option value="usd">USD $</Option>
                <Option value="eur">EUR €</Option>
                <Option value="gbp">GBP £</Option>
                <Option value="tl">TL ₺</Option>
              </Select>
            </div>
            
            <div className={styles.Dashboards}>
              <p>Expert Insights</p>
              <Select
                labelInValue
                defaultValue={{ value: 'expert_no' }}
                style={{ width: 79 }}
                onChange={({value}) => setExpert(value)}
              >
                <Option value="expert_no">No</Option>
                <Option value="expert_yes">Yes</Option>
              </Select>
            </div>

            <div className={styles.Dashboards}>
              <p>Data Needs</p>
              <Select
                labelInValue
                defaultValue={{ value: '20000' }}
                style={{ width: 300 }}
                onChange={({value}) => setReviews(value)}
              >
                <Option value="20000">Tiny - 20K reviews - 2 Dashboards</Option>
                <Option value="50000">Small - 50K reviews - 5 Dashboards</Option>
                <Option value="200000">Medium - 200K reviews - 10 Dashboards</Option>
                <Option value="1000000">Large - 1M reviews - 20 Dashboards</Option>
                <Option value="5000000">X-Large - 5M reviews - 40 Dashboards</Option>
              </Select>
            </div> */}

            {/* <div className={styles.Dashboards}>
              <p>Dashboards</p>
              <Select
                labelInValue
                defaultValue={{ value: '5' }}
                style={{ width: 60 }}
                // onChange={handleChange}
              >
                <Option value="5">5</Option>
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="50">50</Option>
                <Option value="100">100</Option>
              </Select>
            </div> */}
          </div>

          {user.currency && (
            <div className={styles.plans}>
              {plans.map((plan) => (
                <Card
                  plan={plan}
                  passPlanSection={passPlanSection}
                  reviews={reviews}
                  expert={expert}
                  timeType={timeType}
                  currency={currency}
                  user={user}
                />
              ))}
            </div>
          )}
          <p>*Prices do not include VAT</p>
        </div>
        <div className={styles.carouselContent_fullWidth}>
          <div className={styles.title}>
            <h1>The right plan for your company's growth</h1>
          </div>
          <div className={styles.plans_selected}>
            {user.currency && (
              <Card
                plan={{ ...getPlanById(selectedPlan), hidden: true }}
                passPlanSection={passPlanSection}
                reviews={reviews}
                expert={expert}
                timeType={timeType}
                currency={currency}
                user={user}
              />
            )}
            <div className={styles.selectionDetails}>
              <h3>
                You have selected the {getPlanById(selectedPlan)?.title} plan{" "}
                {!getPlanById(selectedPlan)?.free && `${timeType} subscription`}
              </h3>
              <h5>
                Not sure of this plan? pick another{" "}
                <a
                  onClick={() => {
                    carousel.prev();
                    window.scrollTo(0, 0);
                  }}
                >
                  here
                </a>
              </h5>
              <div className={styles.payment}>
                {!getPlanById(selectedPlan)?.free ? (
                  <>
                    <img
                      className={styles.paddleLogo}
                      src="/assets/paddle.svg"
                    />
                    <h3>Payment With Paddle</h3>
                    <p>
                      Click on the next button to sign in and pay with your
                      Paddle account.
                    </p>
                    <p>
                      By clicking this, you will allow Pivony to charge on your
                      credit card during your subscription.
                    </p>
                  </>
                ) : (
                  <>
                    <h3>Free Plan</h3>
                    <p>
                      To start a Free plan, you don't need to enter your credit
                      card details.
                    </p>
                    <p>Click to start your free membership.</p>
                  </>
                )}

                {/* <p>
                  Click on the next button to sign in
                  {!getPlanById(selectedPlan).freeTrial &&
                    " and pay with your Paddle account."}
                </p>
                {!getPlanById(selectedPlan).freeTrial && (
                  <p>
                    By clicking this, you will allow Pivony to charge on your
                    credit card during your subscription.
                  </p>
                )} */}
                <button
                  className={styles.btn}
                  style={{
                    float: "inherit",
                    margin: "20px 0 0 0",
                    width: "100%",
                  }}
                  type="primary"
                  onClick={(e) =>
                    handlePayment(
                      e,
                      getPlanById(selectedPlan).pricing[reviews][expert][
                        timeType
                      ].paddleId,
                      getPlanById(selectedPlan).title === "Free"
                    )
                  }
                >
                  {getPlanById(selectedPlan)?.freeTrial
                    ? "Activate Free Trial"
                    : "Begin My Subscription"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.carouselContent}>
          <div className={styles.title}>
            <h1>You are all set</h1>
            <div className={styles.verified}>
              <img src="/assets/verified.png" alt="verified" />
            </div>
            <div className={styles.welcome}>
              <p>Thank you for joining us!</p>
              <p>
                You can now start your subscription, we will guide you through
              </p>
              <button
                className={styles.btn}
                style={{ float: "inherit", margin: "20px 0 0 0" }}
                type="primary"
                onClick={addPlan}
              >
                Start
              </button>
            </div>
          </div>
          <div className={styles.form}></div>
        </div> */}
      </Carousel>
    </div>
  );
};

export default SpecialSignup;
