import React, { useState, useEffect } from "react";
import { notification } from "antd";
import { apiRequest } from "util/services";
import Representation from './Representation';

const Report = ({ user, getAllReports }) => {
  const [showModal, setShowModal] = useState(false);
  const [chooseInformationChecked, setChooseInformationChecked] = useState(true);
  const [aiChecked, setAIChecked] = useState(true);
  const [fixedChecked, setFixedChecked] = useState(false);
  const [customChecked, setCustomChecked] = useState(false);
  const [insightsChecked, setInsightsChecked] = useState(true);
  const [reviewsSwitch, setReviewsSwitch] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [name, setName] = useState("");
  const [selectedDashboard, setSelectedDashboard] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();

  useEffect(() => {
    const payload = {
      start_index: 0,
      num_dashboards: 8,
      report: true
    }
    if (showModal) {
      apiRequest(
        "dashboards/all",
        "POST",
        payload,
        true
      ).then(result => {
        if (!result.error) {
          setDashboards(result?.dashboards);
        } else {
          notification.error({
            message: result.error,
          });
        }
      });
      apiRequest(
        "subscriptions/all",
        "POST",
        payload,
        true
      ).then(result => {
        if (!result.error) {
          setSubscriptionStatus(result?.[0]);
        } else {
          notification.error({
            message: result.error,
          });
        }
      })
    }
  }, [user.uid, showModal])

  const handleClick = async () => {
    const payload = {
      dashboard_id: parseInt(selectedDashboard),
      user_id: user.uid,
      include_reviews: reviewsSwitch,
      ai_topics_bool: chooseInformationChecked && aiChecked,
      fixed_topics_bool: chooseInformationChecked && fixedChecked,
      custom_topics_bool: chooseInformationChecked && customChecked,
      insights_bool: chooseInformationChecked && insightsChecked,
      report_name: name
    }
    if (!selectedDashboard){
      notification.error({
        message: "Select a Dashboard",
      })

    }else if(!name){
      notification.error({
        message: "Missing Report Name",
      })
    }else{
      apiRequest(
        "report/create",
        "POST",
        payload,
        true
      ).then(() => {
        getAllReports();
      });
      
      notification.success({
        message: "Your report will be generated soon, Once your report is ready you will see it in this page",
      });
      setShowModal(false);
      getAllReports();
    }

  };

  return <Representation
    showModal={showModal}
    setShowModal={setShowModal}
    handleClick={handleClick}
    chooseInformationChecked={chooseInformationChecked}
    setChooseInformationChecked={setChooseInformationChecked}
    aiChecked={aiChecked}
    setAIChecked={setAIChecked}
    fixedChecked={fixedChecked}
    setFixedChecked={setFixedChecked}
    customChecked={customChecked}
    setCustomChecked={setCustomChecked}
    insightsChecked={insightsChecked}
    setInsightsChecked={setInsightsChecked}
    reviewsSwitch={reviewsSwitch}
    setReviewsSwitch={setReviewsSwitch}
    setName={setName}
    dashboards={dashboards}
    selectedDashboard={selectedDashboard}
    setSelectedDashboard={setSelectedDashboard}
    subscriptionStatus={subscriptionStatus}
  />
}

export default Report;