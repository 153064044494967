import React from 'react';
import { Spin } from 'antd';
import ProgressBar from './progressBar';
import styles from "./style.module.scss";

const Representation = ({
  getRootProps,
  getInputProps,
  progress,
  spinning,
  isDragActive,
  theme
}) => (
  <div className={styles.wrapper} {...getRootProps()}>
    <p className={styles.text}>
      {isDragActive ? "Drag here..." : "Drag and drop a csv, xls or xlsx file"}
    </p>
    <div className={styles.uploadWrapper}>
      <input {...getInputProps()} />
      <div className={styles.dottedWrapper}>
        <div className={styles.circle}>
          <Spin spinning={spinning} />
          {
            !spinning &&
              theme === 'dark' ? <img width={70} height={70} src={"/assets/cloud_download.svg"} alt="" />
              : <img width={70} height={70} src={"/assets/cloud_download_black.svg"} alt="" />
          }
        </div>
      </div>
    </div>
    {false && progress > 0 && <ProgressBar progress={progress} />}
  </div>
);

export default Representation;