import React from 'react';
import styles from './style.module.scss';

const Highlighter = ({ children, highlight }) => {
   const text = children;
   const search = highlight;
   const regexp = new RegExp(search, 'g');
   const matches = text.match(regexp);
   var parts = text.split(new RegExp(`${search.replace()}`, 'g'));

   for (var i = 0; i < parts.length; i++) {
      if (i !== parts.length - 1) {
         let match = matches[i];
         while (parts[i + 1] === undefined) {
            match += matches[++i];
         }

         parts[i] = (
            <React.Fragment key={i}>
               {parts[i]}<span className={styles.highlighted}>{match}</span>
            </React.Fragment>
         );
      }
   }
   return <div className={styles.highlighter}>{parts}</div>;
};

export default Highlighter;