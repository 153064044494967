import React, { useEffect } from "react";
import {
  CalendarOutlined,
  ClusterOutlined,
  ControlOutlined,
  LockOutlined,
  UserOutlined,
  TeamOutlined,
  DatabaseOutlined,
  HomeOutlined
} from '@ant-design/icons';
import SideMenu from 'components/General/sideMenu';
import styles from "./style.module.scss";
import i18n from 'i18next.js';

const sideMenuOptions = (isAdmin, plan) => {
  return [
    {
      name: i18n.t("settings_sidebar.8"),
      icon: <HomeOutlined />,
      key: "home"
    },
    {
      name: i18n.t("settings_sidebar.1"),
      icon: <UserOutlined />,
      key: "details"
    },
    {
      name: i18n.t("settings_sidebar.2"),
      icon: <LockOutlined />,
      key: "security"
    },
    ...(plan === 68 ? [
      {
        name: i18n.t("settings_sidebar.5"),
        icon: <CalendarOutlined />,
        key: "plan"
      }] : [
      {
        name: i18n.t("settings_sidebar.3"),
        icon: <ControlOutlined />,
        key: "preferences"
      },
      {
        name: i18n.t("settings_sidebar.4"),
        icon: <ClusterOutlined />,
        key: "integrations",
      },
    ]),
    ...((isAdmin && plan !== 68) ? [
      {
        name: i18n.t("settings_sidebar.5"),
        icon: <CalendarOutlined />,
        key: "plan"
      },
      {
        name: i18n.t("settings_sidebar.6"),
        icon: <DatabaseOutlined />,
        key: "teams"
      },
      {
        name: i18n.t("settings_sidebar.7"),
        icon: <TeamOutlined />,
        key: "users"
      },] : []),
  ]
};

const Representation = ({ children, onChange, selected, isAdmin, plan, ...props }) => {

  return (
    <div className={styles.container}>
      <SideMenu selected={selected} onChange={onChange} isAdmin={isAdmin} plan={plan} theme={props.theme} />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}

export default Representation;