import React, { useState, useEffect, useCallback } from "react";
import { notification } from "antd";
import { apiRequest } from "util/services";
import { UNLOGGED } from "util/constants";
import { useHistory } from "react-router-dom";
import firebase from "FirebaseConfig.js";
import Representation from "./Representation";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const Plan = ({ authenticated, user }) => {
  const history = useHistory();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const [isUnsubscribeModalVisible, setIsUnsubscribeModalVisisble] = useState(
    false
  );
  const [appSumoCode, setAppSumoCode] = useState("");
  const [isTicketModalVisible, setIsTicketModalVisible] = useState(false);
  const [isTicketModalLoading, setIsTicketModalLoading] = useState(false);
  const [ticketText, setTicketText] = useState("");
  const [isReedemingAppsumoButton, setIsReedemingAppsumoButton] = useState(
    false
  );
  const { t } = useTranslation();
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const getSubscribtionStatus = useCallback(() => {
    const payload = {
      user_id: user.uid,
    };
    apiRequest("subscriptions/all", "POST", payload, true).then((result) => {
      if (!result.error) {
        setSubscriptionStatus(result?.[0]);
      } else {
        notification.error({
          message: result.error,
        });
      }
    });
  }, [user.uid]);

  useEffect(() => {
    getSubscribtionStatus();
  }, [getSubscribtionStatus]);

  useEffect(() => {
    setSubscriptionStatus(subscriptionStatus);
  }, [subscriptionStatus]);

  useEffect(() => {
    if (authenticated === UNLOGGED || user.plan === undefined) {
      history.push("/");
    }
  }, [subscriptionStatus, authenticated, user.plan, history]);

  const handleUnSubscribe = async (e) => {
    e.preventDefault();
    const result = await apiRequest("subscriptions/cancel", "POST", {
      user_id: user.uid,
    });
    if (result) {
      notification.success({
        message: t("notifications.117"),
      });
      firebase
        .database()
        .ref(`/users/${user.uid}`)
        .child("plan")
        .remove();
      history.push("/");
    } else {
      notification.error({
        message: t("notifications.118"),
      });
    }
    setIsUnsubscribeModalVisisble(false);
  };

  const handleExpertInsight = async () => {
    const result = await apiRequest("users/authorizePivonyExpert", "POST", {
      firebase_id: user.uid,
      authorize: !subscriptionStatus.authorizedExpert,
    });
    if (result) {
      notification.success({
        message: t("notifications.120"),
      });
      getSubscribtionStatus();
    } else {
      notification.error({
        message: t("notifications.121"),
      });
    }
    setIsUnsubscribeModalVisisble(false);
  };

  const handleAppsumoMultipleCoupons = async () => {
    if (isReedemingAppsumoButton) {
      return;
    }
    setIsReedemingAppsumoButton(true);
    const result = await apiRequest(
      "subscriptions/appsumo/multiple_coupons",
      "POST",
      {
        user_id: user.uid,
        code: appSumoCode,
      },
      true
    );

    if (result.success) {
      notification.success({
        message: t("notifications.122"),
      });
    } else {
      notification.error({
        message: result.error,
      });
    }
    setIsReedemingAppsumoButton(false);
  };

  const handeAppsumoCoupon = async () => {
    if (isReedemingAppsumoButton) {
      return;
    }

    setIsReedemingAppsumoButton(true);
    const result = await apiRequest(
      "subscriptions/appsumo/second_coupon",
      "POST",
      {
        user_id: user.uid,
        code: appSumoCode,
      },
      true
    );

    if (result.success) {
      window.location.reload(false);
    } else {
      notification.error({
        message: result.error,
      });
    }
    setIsReedemingAppsumoButton(false);
  };

  const submitTicket = async () => {
    if (ticketText.length === 0) {
      return;
    }

    setIsTicketModalLoading(true);

    const result = await apiRequest(
      "subscriptions/post_support_ticket",
      "POST",
      {
        content: ticketText,
        firebase_id: user.uid,
      },
      true
    );

    if (result.success) {
      notification.success({
        message: t("notifications.123"),
      });
    } else {
      notification.error({
        message: result.error,
      });
    }

    setTicketText("");
    setIsTicketModalVisible(false);
    setIsTicketModalLoading(false);
  };

  const handleTranslation = () => {
    notification.info({
      message: t("notifications.124"),
      icon: (
        <InfoCircleOutlined
          style={{ color: theme === "dark" ? "white" : "black" }}
        />
      ),
    });
  };

  const handleBuyAdditionalDashboard = (user, adddashboard, price) => {
    let number_dashboards = adddashboard.trim();

    const payload = {
      user_id: user.uid,
      number_dashboards: Number(number_dashboards),
      amount: price,
    };
    apiRequest("subscriptions/additional_boards", "POST", payload, true).then(
      (result) => {
        if (!result.error) {
          notification.success({
            message: t("notifications.125"),
          });
        } else {
          notification.error({
            message: result.error,
          });
        }
      }
    );
  };
  if (subscriptionStatus !== null) {
    return (
      <Representation
        user={user}
        handleBuyAdditionalDashboard={handleBuyAdditionalDashboard}
        handleTranslation={handleTranslation}
        handleExpertInsight={handleExpertInsight}
        plan={subscriptionStatus}
        appSumoCode={appSumoCode}
        setAppSumoCode={setAppSumoCode}
        handeAppsumoCoupon={handeAppsumoCoupon}
        handleAppsumoMultipleCoupons={handleAppsumoMultipleCoupons}
        isReedemingAppsumoButton={isReedemingAppsumoButton}
        isUnsubscribeModalVisible={isUnsubscribeModalVisible}
        setIsUnsubscribeModalVisisble={setIsUnsubscribeModalVisisble}
        handleUnSubscribe={handleUnSubscribe}
        isTicketModalLoading={isTicketModalLoading}
        isTicketModalVisible={isTicketModalVisible}
        setIsTicketModalVisible={setIsTicketModalVisible}
        ticketText={ticketText}
        setTicketText={setTicketText}
        submitTicket={submitTicket}
      />
    );
  } else {
    return <div></div>;
  }
};

export default Plan;
