import React from "react";
import { Button, Input, Checkbox, Select } from "antd";
import { FilePdfFilled } from "@ant-design/icons";
import styles from "./style.module.scss";
import { Modal } from "components/General/ModalFormComponents";

const CustomDropDown = ({ onChange, selectedOption, options, placeholder }) => (
  <Select
    className={styles.dropdown}
    placeholder={placeholder}
    onChange={onChange}
    checked={selectedOption}
    showSearch
    optionFilterProp="title"
  >
    <Select.Option style={{ display: "none" }}>{placeholder}</Select.Option>
    {options?.map((option) => (
      <option key={option.ID} value={option.ID} title={option.Name}>
        {option.Name}
      </option>
    ))}
  </Select>
);

const Representation = ({
  showModal,
  setShowModal,
  handleClick,
  chooseInformationChecked,
  setChooseInformationChecked,
  aiChecked,
  setAIChecked,
  fixedChecked,
  setFixedChecked,
  customChecked,
  setCustomChecked,
  insightsChecked,
  setInsightsChecked,
  reviewsSwitch,
  setReviewsSwitch,
  setName,
  dashboards,
  selectedDashboard,
  setSelectedDashboard,
  subscriptionStatus,
}) => (
  <div className={styles.container}>
    <div className={styles.top}>
      <div className={styles.title}>
        <div className={styles.text}>
          <h5>Reports & Downloads</h5>
          <h6>Find all your created reports and exported files here</h6>
        </div>
      </div>
      <div>
        <div className={styles.createBtn}>
          <Button
            icon={<i className="fa fa-plus" />}
            onClick={() => setShowModal(true)}
          >
            Create your Report now
          </Button>
        </div>
      </div>
    </div>
    <Modal
      showModal={showModal}
      onClose={() => setShowModal(false)}
      onSave={handleClick}
      showSaveButton={true}
      textonButton="Ok"
      titleAsset=""
      title={
        <div className={styles.modalTitle}>
          <FilePdfFilled style={{ fontSize: "32px" }} />
          Create New Report
        </div>
      }
      widthofmodal="600px"
    >
      <div className={styles.modalContent}>
        <div className={styles.modalRow}>
          <p style={{ width: "146px", marginLeft: "-14px" }}>Report Name</p>
          <Input
            placeholder="Enter your report name"
            onChange={(e) => setName(e.target.value)}
            className={styles.textAreaInput}
          />
        </div>
        <div className={styles.modalRow}>
          <p
            style={{ width: "150px", paddingleft: "0px", marginLeft: "-23px" }}
          >
            Dashboard
          </p>
          <CustomDropDown
            placeholder="Choose your dashboard"
            options={dashboards}
            selected={selectedDashboard}
            onChange={(value) => setSelectedDashboard(value)}
          />
        </div>
        <div className={styles.checkboxContainer}>
          <div className={`${styles.checkboxRow} ${styles.marginBottom}`}>
            <Checkbox
              checked={chooseInformationChecked}
              onChange={(e) => setChooseInformationChecked(e.target.checked)}
            />
            <p>Choose the informations included in your report</p>
          </div>
          <div className={`${styles.checkboxRow} ${styles.marginLeft}`}>
            <Checkbox
              checked={insightsChecked}
              disabled={!chooseInformationChecked}
              onChange={(e) => setInsightsChecked(e.target.checked)}
            />
            <p className={!chooseInformationChecked && styles.disabledText}>
              Insights
            </p>
          </div>
          <div className={styles.verticalLine} />
          <div className={`${styles.checkboxRow} ${styles.marginLeft}`}>
            <Checkbox
              checked={aiChecked}
              disabled={!chooseInformationChecked}
              onChange={(e) => setAIChecked(e.target.checked)}
            />
            <p className={!chooseInformationChecked && styles.disabledText}>
              AI topics
            </p>
          </div>
          <div className={styles.verticalLine} />
          <div className={`${styles.checkboxRow} ${styles.marginLeft}`}>
            <Checkbox
              checked={fixedChecked}
              disabled={!chooseInformationChecked}
              onChange={(e) => setFixedChecked(e.target.checked)}
            />
            <p className={!chooseInformationChecked && styles.disabledText}>
              Fixed topics
            </p>
          </div>
          <div className={styles.verticalLine} />
          <div className={`${styles.checkboxRow} ${styles.marginLeft}`}>
            <Checkbox
              checked={customChecked}
              disabled={!chooseInformationChecked}
              onChange={(e) => setCustomChecked(e.target.checked)}
            />
            <p className={!chooseInformationChecked && styles.disabledText}>
              Custom topics
            </p>
          </div>
        </div>
        <div className={styles.switchContainer}>
          <p className={styles.switchText}>Include all reviews</p>
          <div className={styles.dynamicBtn}>
            <input
              type="checkbox"
              id="reviews-switch"
              checked={reviewsSwitch}
              disabled={
                subscriptionStatus?.PackageId < 76 ||
                subscriptionStatus?.PackageId == 80
              }
              onClick={(e) => setReviewsSwitch(e.target.checked)}
            />
            <label for="reviews-switch">Toggle</label>
          </div>
          <div className={styles.proTag}>For PRO</div>
        </div>
      </div>
    </Modal>
  </div>
);

export default Representation;
