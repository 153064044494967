import React from "react";
import styles from "./signup.module.scss";
import { Button } from "antd";
import { numberWithCommas } from "util/common";

const activeCard = {
  borderTop: "30px",
  borderStyle: "solid",
  borderColor: "#891199",
  borderRadius: "5px",
};

const currencySymbol = {
  "usd": "$",
  "eur": "€",
  "gbp": "£",
  "tl": "₺"
}

const reviewToDashboards = {
  "20000": 2,
  "50000": 5,
  "200000": 10,
  "1000000": 20,
  "5000000": 40,
}

const Card = ({ plan, passPlanSection, user, reviews, currency, expert, timeType }) => {

  const choosePlan = () => {
    passPlanSection(plan.planId);
  };

  return (
    <div className={styles.planCard} style={plan.active && activeCard}>
      <h1>{plan.title}</h1>
      {plan.subtitle && <h2>{plan.subtitle}</h2>}
      <div className={styles.prices}>
        {plan.price1 && (
          <h4>
            {currencySymbol[currency]}
            {plan.price1 && parseInt(plan.price1[user.currency], 10)}
          </h4>
        )}
        <h3>
          {currencySymbol[currency]}
          {plan.pricing && parseInt(plan.pricing[reviews][expert][timeType].price[currency], 10)}
        </h3>
        <span>3 Month / User</span>
      </div>
      <div className={styles.pros}>
        {plan.pros?.map((item) => {
          return (
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>{item}</p>
            </span>
          );
        })}
        {plan.hidden && plan.title !== "Free" && <> 
          <span className={styles.prosItem}>
            <img src="/assets/check.png" />
            <p>{numberWithCommas(reviews)} Reviews/month</p>
          </span>
          <span className={styles.prosItem}>
            <img src="/assets/check.png" />
            <p>{reviewToDashboards[reviews]} Dashboards/month</p>
          </span>
          {/* {expert === "expert_yes" && 
            <span className={styles.prosItem}>
              <img src="/assets/check.png" />
              <p>Expert Insights</p>
            </span>
          }  */}
          </>
        }

      </div>
      {!plan.hidden && (
        <div className={styles.chooseBtn}>
          <Button disabled={plan.disabled} onClick={choosePlan}>
            {user.plan === undefined && user.plan === "rejected"
              ? "BUY NOW"
              : plan.btnTxt}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Card;
