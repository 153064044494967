import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(Backend)
  .init(
    {
      fallbackLng: window.localStorage.getItem("userLang") || "en",
      debug: true,
      backend: {
        loadPath: "/assets/locales/{{lng}}/translation.json",
      },
      lng: window.localStorage.getItem("userLang") || "en",
    },
    (err) => {
      if (err) {
        console.error("Error initializing i18next:", err);
      }
    }
  );

export default i18n;
