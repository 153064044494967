import React, { useState } from "react";
import styles from "./style.module.scss";
import { notification, Menu, Dropdown, Button } from "antd";
import { useHistory } from "react-router-dom";
import { getDateString } from "util/common";
import Modal from "antd/lib/modal/Modal";
import { apiRequest } from "util/services";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

const AudienceItem = ({
  item,
  header,
  filterAudience,
  setAudience,
  user,
  audience,
}) => {
  const history = useHistory();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [CSVData, setCSVData] = useState([
    ["AddDate", "UserId", "Email", "Phone"],
  ]);
  const [titleCSV, setTitleCSV] = useState("test.csv");
  const { t } = useTranslation();

  const actionAudience = async (record, action) => {
    const result = await apiRequest("audiences/" + action, "POST", {
      user_id: user.uid,
      name: record.Name,
    });
    if (result) {
      let data = [];
      if (action === "delete") {
        data = audience.filter((item) => {
          if (item.Name === record.Name) return false;
          return true;
        });
      } else if (action === "archive") {
        data = audience.map((item) => {
          if (item.Name === record.Name) {
            return { ...item, Status: "inactive" };
          }
          return item;
        });
      } else if (action === "unarchive") {
        data = audience.map((item) => {
          if (item.Name === record.Name) {
            return { ...item, Status: "active" };
          }
          return item;
        });
      }
      setAudience(data);
      filterAudience(data);
      notification.success({
        message: `${t("engage.16")} ` + action,
      });
    } else {
      notification.error({
        message: t("notifications.142"),
      });
    }
  };

  const exportAudience = async (record) => {
    const result = await apiRequest(
      "audiences/users/get",
      "POST",
      {
        firebase_id: user.uid,
        audience_id: item.ID,
      },
      true
    );
    let data = [["AddDate", "Email", "Phone", "UserId"]];
    if (!result.error) {
      result.forEach((item) => {
        data.push(Object.values(item));
      });
      setCSVData(data);
    }
    document.getElementById("csvlink" + item.Name).click();
  };

  const menu = (
    <Menu style={{ width: "120px" }} theme="dark">
      <Menu.Item key={1} onClick={() => actionAudience(item, "delete")}>
        {t("button.2")}
      </Menu.Item>
      {item.Status === "active" ? (
        <Menu.Item key={2} onClick={() => actionAudience(item, "archive")}>
          {t("engage.10")}
        </Menu.Item>
      ) : (
        <Menu.Item key={2} onClick={() => actionAudience(item, "unarchive")}>
          {t("engage.17")}
        </Menu.Item>
      )}
      <Menu.Item key={3} onClick={() => exportAudience(item)}>
        {t("engage.9")}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={`${styles.container} ${header && styles.header}`}>
      <div className={styles.firstColumn}>
        <span className={styles.title}>
          {header ? t("engage.5") : item.Name}
        </span>
        {item.Language && <span className={styles.label}>{item.Language}</span>}
        {/* {item.DashboardScore && (
          <span className={styles.label}>
            {item.DashboardScore}
            <i className="fa fa-star" />
          </span>
        )} */}
      </div>
      <div className={styles.column}>
        <span className={styles.normalContent}>
          {header ? t("engage.6") : item.Size}
        </span>
      </div>
      <div className={styles.column}>
        <span className={styles.normalContent}>
          {header ? t("engage.7") : getDateString(item.AddDate)}
        </span>
      </div>
      <div className={`${styles.column} ${styles.actions}`}>
        {header ? (
          <span>{t("engage.8")}</span>
        ) : (
          <Dropdown overlay={menu}>
            <Button type="primary">
              <i className="fa fa-cog" />
            </Button>
          </Dropdown>
        )}
      </div>
      <CSVLink
        filename={item.Name + ".csv"}
        style={{ display: "none" }}
        data={CSVData}
        id={"csvlink" + item.Name}
      />
    </div>
  );
};

export default AudienceItem;
