import { combineReducers } from "redux";
import { DashboardReducer } from "./Dashboard/reducer";
import { newDashboardReducer } from "./newDashboard/reducer";
import { popupReducer } from "./popup/reducer";
import { userReducer } from "./user/reducer";

const rootReducer = combineReducers({
  DashboardReducer,
  newDashboardReducer,
  userReducer,
  popupReducer,
});

export { rootReducer };
