import React, { useState, useEffect, useCallback } from "react";
import { notification } from "antd";
import { apiRequest } from "util/services";
import Representation from './Representation';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const TaskModalForTrello = ({
  subscriptionStatus,
  showModal,
  onClose,
  user,
  dashboardId,
  getSearchState,
  timeInterval,
  total_num_docs,
  reporter
}) => {
  const [selectedBoard, setSelectedBoard] = useState("");
  const [boards, setBoards] = useState();
  const [selectedBoardList, setSelectedBoardList] = useState("");
  const [boardList, setBoardList] = useState();
  const [selectedBoardMembers, setSelectedBoardMembers] = useState([]);
  const [boardMembersList, setBoardMembersList] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState();
  const { t } = useTranslation();
  const theme = useSelector(state => state.DashboardReducer.theme);

  const clearState = () => {
    setSelectedBoard(null);
    setSelectedBoardList(null);
    setSelectedBoardMembers(null);
    setTitle(null);
    setDescription(null);
  }

  const sendRequest = useCallback((payload, onSuccess, reqURL, method = 'POST') => {
    apiRequest(
      reqURL,
      method,
      payload,
      true
    ).then(result => {
      if (!result.error) {
        onSuccess(result);
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = () => {
    const searchState = getSearchState();
    const payload = {
      user_id: user.uid,
      list_id: selectedBoardList,
      name: title,
      description,
      members: selectedBoardMembers.join(","),
      date,
      dashboard_id: dashboardId,
      timeInterval,
      total_num_docs,
      reporter,
      searchState
    }
    const onSuccess = () => {
      notification.success({
        message: t("trello_task.1")
      });
      clearState();
      onClose();
    }
    sendRequest(payload, onSuccess, "dashboards/trello/create_card")
  }

  useEffect(() => {
    const payload = {
      user_id: user.uid
    }
    if (showModal && subscriptionStatus?.trello_status === "active") {
      const onSuccessBoardMembers = (result) => setBoards(result);
      sendRequest(payload, onSuccessBoardMembers, "dashboards/trello/boards")
    }

  }, [showModal, sendRequest, subscriptionStatus, user.trello_status, user.uid]);

  useEffect(() => {
    if (boards?.length > 0 && selectedBoard) {
      const payload = {
        board_id: selectedBoard,
        user_id: user.uid
      }
      const onSuccessBoardMembers = (result) => setBoardMembersList(result);
      sendRequest(payload, onSuccessBoardMembers, "dashboards/trello/board_members")

      const onSuccessLists = (result) => setBoardList(result);
      sendRequest(payload, onSuccessLists, "dashboards/trello/lists")
    }
  }, [boards, selectedBoard, sendRequest, user.uid]);

  return <Representation
    showModal={showModal}
    onClose={() => { clearState(); onClose(); }}
    save={save}
    boards={boards}
    selectedBoard={selectedBoard}
    setSelectedBoard={setSelectedBoard}
    boardList={boardList}
    selectedBoardList={selectedBoardList}
    setSelectedBoardList={setSelectedBoardList}
    boardMembersList={boardMembersList}
    setSelectedBoardMembers={setSelectedBoardMembers}
    selectedBoardMembers={selectedBoardMembers}
    setDescription={setDescription}
    setTitle={setTitle}
    showSaveButton={date && title && description && selectedBoardList && selectedBoard}
    date={date}
    setDate={setDate}
    theme={theme}
  />
}

export default TaskModalForTrello;