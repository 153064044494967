import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { copyToClipboard, keyPressed } from "util/common";
import { notification } from "antd";
import Representation from './Representation';
import { apiRequest } from 'util/services';
import i18n from 'i18next';

const validateEmail = (email) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}

const mapPermissions = (permission) => {
  switch (permission) {
    case "Admin":
      return "wrd";
    case "Read & Write":
      return "rw";
    default:
      return "r";
  }
}

const permissions = [
  "Read Only",
  "Read & Write",
]

const InviteMembers = ({ user }) => {
  const history = useHistory();
  const [invitationId, setInvitationId] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [emailPlaceholders, setEmailPlaceholders] = useState([{
    email: "", permission: "Read only"
  }]);

  useEffect(() => {
    const path = window.location.href;
    if (path.split('/invite/')[1]) {
      setSelectedOrganization(path.split('/invite/')[1])
    }
  }, [])

  const fetchOrganizations = useCallback(() => {
    const payload = {
      user_id: user.uid,
    }
    apiRequest(
      "users/organization/info",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        setOrganizations(result.success);
        setInvitationId(result.invitation_id);
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
  }, [user.uid])
  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  const inviteUsers = () => {
    if (!selectedOrganization) {
      notification.error({
        message: i18n.t("notifications.133")
      })
      return;
    }

    const validation = emailPlaceholders.map(elem => validateEmail(elem.email));
    if (validation.includes(false)) {
      notification.error({
        message: i18n.t("notifications.134")
      })
      return;
    }

    const payload = {
      invitor_id: user.uid,
      invited_emails: emailPlaceholders.map(elem => elem.email),
      org_id: selectedOrganization,
      user_permissions: emailPlaceholders.map(elem => mapPermissions(elem.permission))
    }

    apiRequest(
      "users/organization/add_user",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        notification.success({
          message: i18n.t("notifications.135")
        });
        history.goBack();
        setSelectedOrganization();
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
  }

  const addEmailPlaceholder = () => {
    const data = [...emailPlaceholders, {
      email: "", permission: "Read only"
    }]
    setEmailPlaceholders(data);
  }

  const copyLink = () => {
    copyToClipboard(
      `${document.location.origin}/signup?invitationId=${invitationId}`
    );
    notification.success({
      message: i18n.t("notifications.136")
    })
  }

  return <Representation
    history={history}
    organizations={organizations}
    selectedOrganization={selectedOrganization}
    setSelectedOrganization={setSelectedOrganization}
    sendInvitations={inviteUsers}
    emailPlaceholders={emailPlaceholders}
    setEmailPlaceholders={setEmailPlaceholders}
    addEmailPlaceholder={addEmailPlaceholder}
    permissions={permissions}
    copyLink={copyLink}
    link={invitationId}
  />
}

export default InviteMembers;