import React from "react";
import { Button, Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import CustomInput from "components/General/CustomInput";
import styles from "./style.module.scss";
import i18n from "i18next";

const CustomDropDown = ({
  onChange,
  options,
  placeholder,
  id,
  value,
  selected,
}) => (
  <Select
    defaultValue={options?.[0]?.[id]}
    className={styles.dropdown}
    placeholder={placeholder}
    onChange={onChange}
    value={selected}
    showSearch
    optionFilterProp="title"
  >
    <Select.Option style={{ display: "none" }}>{placeholder}</Select.Option>
    {options?.map((option) => (
      <Select.Option key={option[id]} value={option[id]} title={option[value]}>
        {option[value]}
      </Select.Option>
    ))}
  </Select>
);

const Representation = ({
  organizations,
  selectedOrganization,
  setSelectedOrganization,
  emailPlaceholders,
  setEmailPlaceholders,
  sendInvitations,
  addEmailPlaceholder,
  permissions,
  history,
  copyLink,
  link,
}) => (
  <div className={styles.wrapper}>
    <h4 className={styles.containerTitle}>
      <Button
        className={styles.headerBtn}
        onClick={() => history.push("/settings/team")}
      >{`${i18n.t("dashboard_info.3")} >`}</Button>
      {i18n.t("settings.201")}
    </h4>
    <h7 className={styles.subtitle}>{i18n.t("settings.202")}</h7>
    {link !== null ? (
      <div className={styles.container}>
        <h7 className={styles.title}> {i18n.t("settings.203")}</h7>
        <p className={styles.text}>{i18n.t("settings.204")}</p>
        <Button className={styles.btn} onClick={copyLink}>
          <span className={styles.copyIcon}>
            <svg
              width="16"
              height="10"
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99984 1.66699H11.9998C12.4376 1.66699 12.871 1.75321 13.2754 1.92073C13.6799 2.08824 14.0473 2.33377 14.3569 2.6433C14.6664 2.95283 14.9119 3.3203 15.0794 3.72471C15.247 4.12913 15.3332 4.56259 15.3332 5.00033C15.3332 5.43807 15.247 5.87152 15.0794 6.27594C14.9119 6.68036 14.6664 7.04782 14.3569 7.35735C14.0473 7.66688 13.6799 7.91241 13.2754 8.07992C12.871 8.24744 12.4376 8.33366 11.9998 8.33366H9.99984M5.99984 8.33366H3.99984C3.5621 8.33366 3.12864 8.24744 2.72423 8.07992C2.31981 7.91241 1.95234 7.66688 1.64281 7.35735C1.01769 6.73223 0.666504 5.88438 0.666504 5.00033C0.666504 4.11627 1.01769 3.26842 1.64281 2.6433C2.26794 2.01818 3.11578 1.66699 3.99984 1.66699H5.99984"
                stroke="white"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
          </span>
          {i18n.t("settings.205")}
        </Button>
      </div>
    ) : (
      <></>
    )}
    <div className={styles.container}>
      <h7 className={styles.title}> {i18n.t("dashboard_info.3")} </h7>
      <CustomDropDown
        onChange={(value) => setSelectedOrganization(value)}
        selected={selectedOrganization}
        options={organizations}
        id="OrganizationID"
        value="OrganizationName"
        placeholder={i18n.t("settings.206")}
      />
      <p className={styles.text}>{i18n.t("settings.207")}</p>
      <div className={styles.row}>
        <h7 className={styles.title}>{i18n.t("settings.209")} </h7>
        <h7 className={styles.title}> {i18n.t("settings.210")} </h7>
      </div>
      {emailPlaceholders?.map((elem, index) => (
        <div key={index} className={styles.row}>
          <CustomInput
            prefix={<UserOutlined style={{ color: "#6f6c99" }} />}
            placeholder={i18n.t("settings.208")}
            onChange={(value) => {
              emailPlaceholders[index].email = value;
              setEmailPlaceholders(emailPlaceholders);
            }}
            style={{
              maxWidth: "700px",
              margin: "0px",
              backgroundColor: "transparent",
              minWidth: "275px",
            }}
          />
          <CustomDropDown
            onChange={(value) => {
              emailPlaceholders[index].permission = value;
              setEmailPlaceholders(emailPlaceholders);
            }}
            options={permissions.map((elem) => ({ key: elem, value: elem }))}
            id="key"
            value="value"
            placeholder={i18n.t("settings.211")}
          />
        </div>
      ))}
      <div className={`${styles.row} ${styles.marginTop}`}>
        <Button className={styles.btn} onClick={addEmailPlaceholder}>
          {i18n.t("settings.212")}
        </Button>
        <Button className={styles.btn} onClick={sendInvitations}>
          {i18n.t("button.38")}
        </Button>
      </div>
    </div>
  </div>
);

export default Representation;
