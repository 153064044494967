import React from 'react'
import { Menu,Dropdown,Button } from 'antd'
import { MoreOutlined } from '@ant-design/icons';
import styles from './style.module.scss'
const ThreeDotsMenu = ({options, setSelectedFilter})=> {
    const handleSelection = (e) => {
        setSelectedFilter(e.key)
    }
    const menu = (
        <Menu className={styles.menu} onClick={handleSelection}>
            {options.map((option)=>(
                <Menu.Item className={styles.menuBox} key={option.id} value>{option.label}</Menu.Item>
            ))}
        </Menu>
    )
    return (
        <div className={styles.threedotsMenu}>
            <Dropdown className={styles.Dropdown} overlay={menu}>
                <MoreOutlined className={styles.threeDotsIcon} />
            </Dropdown>
        </div>
    )
}
export default ThreeDotsMenu