import React, { useState, useEffect, useCallback } from "react";
import { apiRequest } from "util/services";
import config from "Configurations.js";
import { notification } from "antd";
import { SET_USER } from "redux/user/actions.js";
import { useDispatch } from "react-redux";
import Representation from "./Representation";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "i18next";

const integrations = {
  "All Integrations": [
    "meta Posts",
    "meta Ads",
    "Twitter",
    "Zendesk Tickets",
    "Zendesk Live Chat",
    "Slack",
    "Trello",
    "ClickUp",
    "Jira",
    "TikTok",
    "LinkedIn",
  ],
  "Data Source Integrations": [
    "meta Posts",
    "meta Ads",
    "Twitter",
    "Zendesk Tickets",
    "Zendesk Live Chat",
    "TikTok",
    "LinkedIn",
  ],
  "Task Integrations": ["Trello", "ClickUp", "Jira"],
  "Notification Integrations": ["Slack"],
};

const Integrations = ({ user, theme }) => {
  const [selectedFilter, setSelectedFilter] = useState("All Integrations");
  const [deleteModalText, setDeleteModal] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [showSlackView, setShowSlackView] = useState(false);
  const [showTrelloView, setShowTrelloView] = useState(false);
  const [showFacebookModal, setShowFacebookModal] = useState(false);
  const [showInstagramModal, setShowInstagramModal] = useState(false);
  const [showTwitterModal, setShowTwitterModal] = useState(false);
  const [showZendeskTicketsModal, setShowZendeskTicketsModal] = useState(false);
  const [showZendeskChatModal, setShowZendeskChatModal] = useState(false);
  const [showJiraModel, setShowJiraModel] = useState(false);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const getSubscribtionStatus = useCallback(() => {
    const payload = {
      user_id: user.uid,
    };
    apiRequest("subscriptions/all", "POST", payload, true).then((result) => {
      if (!result.error || !result.cancel) {
        setSubscriptionStatus(result?.[0]);
        dispatch({
          type: SET_USER,
          payload: result?.[0],
        });
      } else {
        notification.error({
          message: result.error,
        });
      }
    });
  }, [user.uid]);

  useEffect(() => {
    getSubscribtionStatus();
  }, [getSubscribtionStatus]);

  const sendRequest = useCallback(
    (payload, message, reqURL, method = "POST") => {
      apiRequest(reqURL, method, payload, true).then((result) => {
        if (!result.error) {
          getSubscribtionStatus();
          notification.success({
            message,
          });
        } else {
          notification.error({
            message: result.error,
          });
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  useEffect(() => {
    const path = window.location.href;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (path.includes("jira")) {
      const code = urlParams.get("code");
      const urli = path.split("?")[0];
      const payload = {
        user_id: user.uid,
      };
      sendRequest(
        payload,
        "Jira Activated",
        `users/jira/add-token?code=${code}&redirect_uri=${urli}`
      );
    } else if (path.includes("state=foobar")) {
      const code = urlParams.get("code");
      const payload = {
        user_id: user.uid,
        code: code,
        redirect_uri: path.split("?")[0],
      };
      urlParams.delete("code");
      urlParams.delete("state");
      history.push({ search: urlParams.toString() });
      sendRequest(payload, "LinkedIn Activated", `users/linkedin/add-token`);
    } else if (path.includes("state=facebook_posts")) {
      const token = path.split("#access_token=")[1];
      const payload = {
        user_id: user.uid,
        access_token: token,
      };
      sendRequest(
        payload,
        "Facebook and Instagram posts integrated",
        `users/meta/posts/add_access_token`
      );
    } else if (path.includes("state=facebook_ads")) {
      const token = path.split("#access_token=")[1];
      const payload = {
        user_id: user.uid,
        access_token: token,
      };
      sendRequest(
        payload,
        "Facebook and Instagram Ads integrated",
        `users/meta/ads/add_access_token`
      );
    } else if (path.includes("#")) {
      const trello_token = path.split("#token=")[1];
      const payload = {
        user_id: user.uid,
        trello_token,
      };
      sendRequest(payload, "Trello Activated", "users/add_trello_token");
    } else if (path.includes("?")) {
      const code = path.split("code=")[1];
      const urli = path.split("&code=")[0];
      if (path.includes("?slack=true")) {
        sendRequest(
          null,
          "Slack Activated",
          `users/slack_authorization_token?code=${code}&redirect_uri=${urli}`,
          "GET"
        );
      } else {
        sendRequest(
          {},
          "Clickup Activated",
          `users/clickup_authorization_token/${user.uid}?code=${code}`
        );
      }
    }
  }, [sendRequest, user.uid]);

  const activateClickup = () => {
    const key = config.CLICKUP_KEY;
    const redirectUrl = window.location.href;
    const link = `https://app.clickup.com/api?client_id=${key}&redirect_uri=${redirectUrl}`;
    window.location.replace(link);
  };
  const activateMetaPosts = () => {
    const key = config.FACEBOOK_CLIENT_ID;
    const redirectUrl = config.FACEBOOK_REDIRCT_URL;
    const link = `https://www.facebook.com/v15.0/dialog/oauth?response_type=token&state=facebook_posts&display=popup&client_id=${key}&redirect_uri=${redirectUrl}&auth_type=rerequest&scope=instagram_basic%2Cpages_show_list%2Cpages_read_engagement%2Cpages_read_user_content%2Cbusiness_management`;
    window.location.replace(link);
  };
  const activateMetaAds = () => {
    const key = config.FACEBOOK_CLIENT_ID;
    const redirectUrl = config.FACEBOOK_REDIRCT_URL;
    const link = `https://www.facebook.com/v15.0/dialog/oauth?response_type=token&state=facebook_ads&display=popup&client_id=${key}&redirect_uri=${redirectUrl}&auth_type=rerequest&scope=pages_show_list%2Cads_read%2Cinstagram_basic%2Cpages_read_engagement%2Cpages_read_user_content`;
    window.location.replace(link);
  };

  const activateJira = () => {
    const key = config.JIRA_KEY;
    const redirectUrl = window.location.href;
    const link = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${key}&scope=read%3Ajira-work%20offline_access%20write%3Ajira-work%20read%3Ajira-user&redirect_uri=${redirectUrl}&state=jira&response_type=code&prompt=consent`;
    window.location.replace(link);
  };

  const activateTiktok = () => {
    const key = config.JIRA_KEY;
    const redirectUrl = window.location.href;
    const link = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${key}&scope=read%3Ajira-work%20offline_access%20write%3Ajira-work%20read%3Ajira-user&redirect_uri=${redirectUrl}&state=jira&response_type=code&prompt=consent`;
    window.location.replace(link);
  };
  const activateLinkedIn = () => {
    const scope = "r_organization_social+rw_organization_admin";
    const authorizationBaseUrl =
      "https://www.linkedin.com/oauth/v2/authorization";
    const redirectUrl = config.LINKEDIN_REDIRECT_URL;
    const clientId = config.LINKEDIN_CLIENT_KEY;
    const authorizationUrl = `${authorizationBaseUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&state=foobar&scope=${scope}`;
    window.location.replace(authorizationUrl);
  };

  const deleteKeys = async (type) => {
    let url, payload;
    if (type === "Twitter") {
      url = "users/delete_twitter_keys";
      payload = { firebase_id: user.uid };
    } else if (type === "Facebook and Instagram Posts") {
      url = "users/meta/posts/delete_access";
      payload = { user_id: user.uid };
    } else if (type === "Zendesk Tickets") {
      url = "users/delete_zendesk_tickets_keys";
      payload = { firebase_id: user.uid };
    } else if (type === "Zendesk Live Chat") {
      url = "users/delete_zendesk_livechat_keys";
      payload = { firebase_id: user.uid };
    } else if (type === "Facebook and Instagram ads") {
      url = "users/meta/ads/delete_access";
      payload = { user_id: user.uid };
    } else if (type === "Slack") {
      url = "users/delete_slack_token";
      payload = { user_id: user.uid };
    } else if (type === "Trello") {
      url = "users/delete_trello_token";
      payload = { user_id: user.uid };
    } else if (type === "ClickUp") {
      url = "users/delete_clickup_token";
      payload = { user_id: user.uid };
    } else if (type === "jira") {
      url = "users/jira/delete-token";
      payload = { user_id: user.uid };
    } else if (type === "LinkedIn") {
      url = "users/linkedin/delete-token";
      payload = { user_id: user.uid };
    }

    const result = await apiRequest(url, "POST", payload, true);
    if (result && !result.error) {
      getSubscribtionStatus();
      notification.success({
        message: i18n.t("notifications.119", { type }),
      });
    } else {
      notification.error({ message: result.error });
    }
    setDeleteModal(null);
  };

  return (
    <Representation
      user={user}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      integrations={integrations}
      subscriptionStatus={subscriptionStatus}
      deleteKeys={deleteKeys}
      deleteModalText={deleteModalText}
      setDeleteModal={setDeleteModal}
      showTwitterModal={showTwitterModal}
      setShowTwitterModal={setShowTwitterModal}
      showZendeskTicketsModal={showZendeskTicketsModal}
      setShowZendeskTicketsModal={setShowZendeskTicketsModal}
      showZendeskChatModal={showZendeskChatModal}
      setShowZendeskChatModal={setShowZendeskChatModal}
      activateClickup={activateClickup}
      activateJira={activateJira}
      activateMetaPosts={activateMetaPosts}
      activateMetaAds={activateMetaAds}
      activateTiktok={activateTiktok}
      activateLinkedIn={activateLinkedIn}
      showSlackView={showSlackView}
      setShowSlackView={setShowSlackView}
      showTrelloView={showTrelloView}
      setShowTrelloView={setShowTrelloView}
      getSubscribtionStatus={getSubscribtionStatus}
      theme={theme}
    />
  );
};

export default Integrations;
