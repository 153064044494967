import React, { useState, useEffect } from "react";
import Representation from './Representation';

const SideMenu = (props) => {
  const [collapse, setCollapse] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const toggleCollapsed = () => {
    setCollapse(!collapse);
  }

  useEffect(() => {
    if (window.innerWidth < 1100) {
      setMobileView(true);
      setCollapse(true);
    }
  }, [])

  return <Representation
    toggleCollapsed={toggleCollapsed}
    collapse={collapse}
    mobileView={mobileView}
    {...props}
  />
}

export default SideMenu;