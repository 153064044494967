import React from "react";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
const ReviewCard = ({ review, height }) => {
  let reviewPlatformId = String(review?.platform);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  return (
    <div className={styles.reviewCard}>
      <div className={styles.header}>
        <div className={styles.profile}>
          <img
            className={styles.profile}
            src={
              review?.photo !== "None" && review?.photo !== "nan"
                ? review?.photo
                : "/assets/facebook_user.jpg"
            }
          />
        </div>
        <div className={styles.title}>
          <div className={styles.name}>
            {" "}
            {!review?.name || review?.name === "None" || review?.name === "nan"
              ? ""
              : review?.name}{" "}
          </div>
          {/* <div className={styles.description}> {(review?.username !== "None" && review?.username !== "nan" && typeof (review?.username) !== "undefined") ? "@" + review?.username : ""} </div> */}
          <div className={styles.description}>
            {review?.date.substring(0, 22)}
          </div>
        </div>
        {reviewPlatformId === "1" ? (
          <img className={styles.twitter} src="/assets/twitter_new_.png" />
        ) : reviewPlatformId === "5" ? (
          <img
            style={{ fontSize: 32, marginRight: 15 }}
            src="/assets/sikayet_icon.svg"
            alt="sikayet"
          />
        ) : reviewPlatformId === "6" ? (
          <i
            style={{ fontSize: 32, marginRight: 15, color: "#674fbc" }}
            className="fa-brands fa-instagram"
          />
        ) : reviewPlatformId === "9" ? (
          <i
            style={{ fontSize: 32, marginRight: 15, color: "#4267B2" }}
            className="fa-brands fa-facebook-square"
          />
        ) : reviewPlatformId === "10" ? (
          <i
            style={{ fontSize: 32, marginRight: 15, color: "#4267B2" }}
            className="fa-brands fa-facebook-square"
          />
        ) : reviewPlatformId === "18" ? (
          <i
            style={{ fontSize: 32, marginRight: 15, color: "#ff4500" }}
            className="fa-brands fa-reddit"
          />
        ) : reviewPlatformId === "19" ? (
          <img
            style={{ objectFit: "contain", marginTop: 5, marginRight: 15 }}
            src="/assets/eksi_icon.svg"
            alt="eksi"
          />
        ) : reviewPlatformId === "27" ? (
          <i
            style={{ fontSize: 32, marginRight: 15, color: "#674fbc" }}
            className="fa-brands fa-instagram"
          />
        ) : null}
      </div>
      <div className={styles.reviewContent}>
        {review?.review?.length > (reviewPlatformId === "1" ? 130 : 160)
          ? review?.review?.substring(0, reviewPlatformId === "1" ? 130 : 160) +
            "..."
          : review?.review}
      </div>
      <div className={styles.engagements}>
        {reviewPlatformId === "1" && (
          <>
            <span className={styles.count}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/comments.svg"
                    : "/assets/comments_dark.svg"
                }
                width={16.25}
                height={18}
              />{" "}
              {review.comments || 0}
            </span>
            <span className={styles.count}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/retweet.svg"
                    : "/assets/retweet_dark.svg"
                }
                width={20.25}
                height={22}
              />{" "}
              {review.retweets || 0}
            </span>
            <span className={styles.count}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/likes.svg"
                    : "/assets/likes_dark.svg"
                }
                width={16.25}
                height={18}
              />{" "}
              {review.likes || 0}
            </span>
            <span className={styles.count}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/views.svg"
                    : "/assets/views_dark.svg"
                }
                width={16.25}
                height={18}
              />{" "}
              {review.views || 0}
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
