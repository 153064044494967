import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { apiRequest } from "util/services";
import { notification, Select } from "antd";
import { useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { useTranslation } from "react-i18next";
import { timezoneList } from "util/constants";

const Wrapper = ({ title, subtitle, children }) => (
  <div className={styles.wrapperHeader}>
    <h4 className={styles.wrapperTitle}>{title}</h4>
    <h7 className={styles.wrapperSubtitle}>{subtitle}</h7>
    {children}
  </div>
);

const Representation = ({
  notificationStatus,
  getSubscribtionStatus,
  subscriptionStatus,
}) => {
  const { i18n, t } = useTranslation();
  const [themeMode, setThemeMode] = useState("dark");
  const [language, setLanguage] = useState("");
  const [status, setStatus] = useState({
    notification_status: true,
    in_platform_status: false,
    in_platform_errors: false,
    in_platform_alerts: false,
    in_platform_updates: false,
    slack_status: false,
    slack_errors: false,
    slack_alerts: false,
    slack_updates: false,
    email_status: false,
    email_updates: false,
    email_errors: false,
    email_alerts: false,
  });
  const [loading, setLoading] = useState(false);
  const [isStatusUpdate, setIsStatusUpdated] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setStatus({
      notification_status: true,
      in_platform_status:
        notificationStatus?.platform?.status === "active" ? true : false,
      in_platform_errors:
        notificationStatus?.platform?.errors === "active" ? true : false,
      in_platform_alerts:
        notificationStatus?.platform?.alerts === "active" ? true : false,
      in_platform_updates:
        notificationStatus?.platform?.updates === "active" ? true : false,
      slack_status:
        notificationStatus?.slack?.status === "active" ? true : false,
      slack_errors:
        notificationStatus?.slack?.errors === "active" ? true : false,
      slack_alerts:
        notificationStatus?.slack?.alerts === "active" ? true : false,
      slack_updates:
        notificationStatus?.slack?.updates === "active" ? true : false,
      email_status:
        notificationStatus?.email?.status === "active" ? true : false,
      email_updates:
        notificationStatus?.email?.updates === "active" ? true : false,
      email_errors:
        notificationStatus?.email?.errors === "active" ? true : false,
      email_alerts:
        notificationStatus?.email?.alerts === "active" ? true : false,
    });
    setLoading(false);
    setIsStatusUpdated(false);
  }, [notificationStatus]);

  useEffect(() => {
    if (subscriptionStatus?.timezone) {
      setSelectedTimezone(subscriptionStatus?.timezone);
    } else setSelectedTimezone(Object.keys(timezoneList)[0]);
  }, [subscriptionStatus]);

  const handleUserTheme = async (theme) => {
    const payload = {
      theme: theme,
    };
    const response = await apiRequest(
      "users/theme/setup",
      "POST",
      payload,
      true
    );
    if (response.success) {
      window.localStorage.setItem("theme", theme);
      dispatch({
        type: actions.THEME_MODE,
        payload: {
          theme: theme,
        },
      });
    }
    if (response.error) {
      notification.error({
        message: response.error,
      });
    }
  };

  const handleUserSelect = (e) => {
    if (e.target.checked) {
      setThemeMode(e.target.value);
      handleUserTheme(e.target.value);
    }
  };

  const getUserTheme = async () => {
    const response = await apiRequest("users/theme", "POST", true);
    if (response.success) {
      setThemeMode(response.success);
      dispatch({
        type: actions.THEME_MODE,
        payload: {
          theme: response.success,
        },
      });
    } else {
      notification.error({
        message: response.error,
      });
    }
  };

  useEffect(() => {
    getUserTheme();
    setLanguage(i18n.language === "en" ? "English" : "Turkish");
  }, [i18n.language]);

  const handleLanguageChange = async (value) => {
    setLanguage(value === "en" ? "English" : "Turkish");
    const res = await apiRequest(
      "users/language/setup",
      "POST",
      { lang: value },
      true
    );
    if (res.success) {
      i18n.changeLanguage(value);
      window.localStorage.setItem("userLang", value);
      notification.success({
        message: t("others.35"),
      });
    } else {
      notification.error({
        message: res.error,
      });
    }
  };

  const handleThemeChange = (value) => {
    setThemeMode(value);
    handleUserTheme(value);
  };

  const updatePlatformStatus = async () => {
    setLoading(true);
    const payload = status;
    const response = await apiRequest(
      "notice/notification/preference",
      "POST",
      payload,
      true
    );
    if (response.success) {
      notification.success({
        message: t("notification_page.11"),
      });
      getSubscribtionStatus();
    } else {
      notification.error({
        error: t("notification_page.12"),
      });
      setLoading(false);
    }
  };

  const handleStatusChange = (e) => {
    const { checked, name } = e.target;
    setStatus({ ...status, [name]: checked });
    setIsStatusUpdated(true);
  };

  const handleTimezoneChange = async (val) => {
    setSelectedTimezone(val);
    const res = await apiRequest(
      "users/timezone/setup",
      "POST",
      { timezone: val },
      true
    );
    if (res.success) {
      notification.success({
        message: t("Timezone selected successfully!"),
      });
    } else {
      notification.error({
        message: res.error,
      });
    }
  };

  return (
    <Wrapper title={t("settings_sidebar.3")} subtitle={t("settings.47")}>
      <div className={styles.languageContainer}>
        <p className={styles.label}>{t("others.36")}</p>
        <Select
          style={{ width: 200 }}
          placeholder={t("others.20")}
          onChange={handleLanguageChange}
          value={language}
        >
          <Select.Option value="en" key="english">
            English
          </Select.Option>
          <Select.Option value="tr" key="turkish">
            Turkish
          </Select.Option>
        </Select>
      </div>
      <div className={styles.languageContainer}>
        <p className={styles.label}>{t("others.57")}</p>
        <Select
          style={{ width: 300 }}
          placeholder={"Select timezone"}
          onChange={handleTimezoneChange}
          value={selectedTimezone}
          showSearch
          optionFilterProp="title"
        >
          {Object.keys(timezoneList)?.map((zone) => (
            <Select.Option value={zone} key={zone} title={zone}>
              {zone}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={styles.themeDropdown}>
        <p className={styles.label}>{t("settings.50")}</p>
        <Select
          style={{ width: 180 }}
          value={themeMode}
          onChange={handleThemeChange}
        >
          <Select.Option value="dark" key="english">
            Dark theme
          </Select.Option>
          <Select.Option value="light" key="turkish">
            Light theme
          </Select.Option>
          <Select.Option value="brand" key="turkish">
            My Brand theme
          </Select.Option>
        </Select>
      </div>
      <div className={styles.themesBlock}>
        <span className={styles.themesBlockHeading}>{t("settings.50")}</span>

        <div className={styles.themesWrapper}>
          <div className={styles.themeBox}>
            <div
              className={styles.themePreview}
              style={{
                border:
                  themeMode === "dark"
                    ? "2px solid #4400AA"
                    : "2px solid rgba(53, 50, 74, 0.5)",
              }}
            >
              <img src="/assets/settings/theme1.png" alt="" />
            </div>
            <div className={styles.checkBox}>
              <input
                onChange={handleUserSelect}
                type="radio"
                id="dark_mode"
                value="dark"
                name="theme"
                checked={themeMode === "dark" ? true : false}
              />
              <label htmlFor="dark_mode">
                <span className={styles.radioBorder}></span>
                {t("settings.51")}
              </label>
            </div>
          </div>

          <div className={styles.themeBox}>
            <div
              className={styles.themePreview}
              style={{
                border:
                  themeMode === "light"
                    ? "2px solid #4400AA"
                    : "2px solid rgba(53, 50, 74, 0.5)",
              }}
            >
              <img src="/assets/settings/theme2.png" alt="" />
            </div>
            <div className={styles.checkBox}>
              <input
                onChange={handleUserSelect}
                type="radio"
                id="light_mode"
                value="light"
                name="theme"
                checked={themeMode === "light" ? true : false}
              />
              <label htmlFor="light_mode">
                <span className={styles.radioBorder}></span>
                {t("settings.52")}
              </label>
            </div>
          </div>

          <div className={styles.themeBox}>
            <div
              className={styles.themePreview}
              style={{
                border:
                  themeMode === "brand"
                    ? "2px solid #4400AA"
                    : "2px solid rgba(53, 50, 74, 0.5)",
              }}
            >
              <img src="/assets/settings/theme3.png" alt="" />
            </div>
            <div className={styles.checkBox}>
              <input
                onChange={handleUserSelect}
                type="radio"
                id="brand_mode"
                name="theme"
                value="brand"
                checked={themeMode === "brand" ? true : false}
              />
              <label htmlFor="brand_mode">
                <span className={styles.radioBorder}></span>
                {t("settings.53")}
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.notificationContainer}>
          <p className={styles.containerTitle}>{t("label.12")}</p>
          <div className={styles.container}>
            <div className={styles.card}>
              <div className={styles.dynamicBtn}>
                <input
                  type="checkbox"
                  id="email-switch"
                  checked={emailToggled}
                  onChange={(e) => handleEmailChange(e.target.checked)}
                />
                <label for="email-switch">Toggle</label>
              </div>
              <div className={styles.cardContent}>
                <div className={styles.cardText}>
                  <p className={styles.cardTitle}>
                    {
                      themeMode === 'dark' ?
                        <img src="/assets/settings/email.svg" alt="" />
                        : <img src="/assets/settings/email_black.svg" alt="" />
                    }{t("settings.48")}</p>
                  <p className={styles.cardSubtitle}>{t("settings.214")}</p>
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.dynamicBtn}>
                <input
                  type="checkbox"
                  id="slack-switch"
                  checked={slackToggled}
                  onChange={(e) => handleSlackChange(e.target.checked)}
                />
                <label for="slack-switch">Toggle</label>
              </div>
              <div className={styles.cardContent}>
                <div className={styles.cardText}>
                  <p className={styles.cardTitle}>
                    {
                      themeMode === 'dark' ?
                        <img src="/assets/settings/slack.png" alt="" />
                        : <img src="/assets/settings/slack_black.png" alt="" />
                    }{t("settings.213")}</p>
                  <p className={styles.cardSubtitle}>{t("settings.49")}</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      <div className={styles.notificationContainer}>
        <p className={styles.containerTitle}>{t("label.12")}</p>
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.cardContent}>
              <div className={`${styles.iconBox} ${styles.bellIconBox}`}>
                <img
                  className={styles.bellIcon}
                  src={
                    themeMode === "dark"
                      ? "/assets/bell.svg"
                      : "/assets/bell_black.svg"
                  }
                  alt=""
                />
              </div>
              <div className={styles.cardText}>
                <p className={styles.cardTitle}>{t("notification_page.13")}</p>
                <p className={styles.cardSubtitle}>
                  {t("notification_page.14")}
                </p>
                <div className={styles.checkItems}>
                  <div className={styles.item}>
                    <input
                      id="platform-alerts"
                      name="in_platform_alerts"
                      type="checkbox"
                      disabled={!status.in_platform_status}
                      onChange={handleStatusChange}
                      checked={status.in_platform_alerts}
                    />
                    <label htmlFor="platform-alerts">
                      {t("notification_page.15")}
                    </label>
                  </div>
                  <div className={styles.item}>
                    <input
                      id="platform-error"
                      name="in_platform_errors"
                      type="checkbox"
                      disabled={!status.in_platform_status}
                      onChange={handleStatusChange}
                      checked={status.in_platform_errors}
                    />
                    <label htmlFor="platform-error">
                      {t("notification_page.16")}
                    </label>
                  </div>
                  <div className={styles.item}>
                    <input
                      id="platform-updates"
                      name="in_platform_updates"
                      type="checkbox"
                      disabled={!status.in_platform_status}
                      onChange={handleStatusChange}
                      checked={status.in_platform_updates}
                    />
                    <label htmlFor="platform-updates">
                      {t("notification_page.17")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.dynamicBtn}>
              <input
                type="checkbox"
                id="in-platform-switch"
                onChange={handleStatusChange}
                checked={status.in_platform_status}
                name="in_platform_status"
              />
              <label for="in-platform-switch">Toggle</label>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.cardContent}>
              <div className={styles.iconBox}>
                <img
                  src={
                    themeMode === "dark"
                      ? "/assets/settings/email.svg"
                      : "/assets/settings/email_black.svg"
                  }
                  alt=""
                />
              </div>
              <div className={styles.cardText}>
                <p className={styles.cardTitle}>{t("notification_page.18")}</p>
                <p className={styles.cardSubtitle}>
                  {t("notification_page.14")}
                </p>
                <div className={styles.checkItems}>
                  <div className={styles.item}>
                    <input
                      id="email-alerts"
                      name="email_alerts"
                      type="checkbox"
                      disabled={!status.email_status}
                      onChange={handleStatusChange}
                      checked={status.email_alerts}
                    />
                    <label htmlFor="email-alerts">
                      {t("notification_page.15")}
                    </label>
                  </div>
                  <div className={styles.item}>
                    <input
                      id="email-error"
                      name="email_errors"
                      type="checkbox"
                      disabled={!status.email_status}
                      onChange={handleStatusChange}
                      checked={status.email_errors}
                    />
                    <label htmlFor="email-error">
                      {t("notification_page.16")}
                    </label>
                  </div>
                  <div className={styles.item}>
                    <input
                      id="email-updates"
                      name="email_updates"
                      type="checkbox"
                      disabled={!status.email_status}
                      onChange={handleStatusChange}
                      checked={status.email_updates}
                    />
                    <label htmlFor="email-updates">
                      {t("notification_page.17")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.dynamicBtn}>
              <input
                type="checkbox"
                id="email-switch"
                onChange={handleStatusChange}
                checked={status.email_status}
                name="email_status"
              />
              <label for="email-switch">Toggle</label>
            </div>
          </div>

          <div className={styles.card}>
            <div className={styles.cardContent}>
              <div className={styles.iconBox}>
                <img
                  src={
                    themeMode === "dark"
                      ? "/assets/settings/slack.png"
                      : "/assets/settings/slack_black.png"
                  }
                  alt=""
                />
              </div>
              <div className={styles.cardText}>
                <p className={styles.cardTitle}>Slack</p>
                <p className={styles.cardSubtitle}>
                  {t("notification_page.14")}
                </p>
                <div className={styles.checkItems}>
                  <div className={styles.item}>
                    <input
                      id="slack-alerts"
                      name="slack_alerts"
                      type="checkbox"
                      disabled={!status.slack_status}
                      onChange={handleStatusChange}
                      checked={status.slack_alerts}
                    />
                    <label htmlFor="slack-alerts">
                      {t("notification_page.15")}
                    </label>
                  </div>
                  <div className={styles.item}>
                    <input
                      id="slack-error"
                      name="slack_errors"
                      type="checkbox"
                      disabled={!status.slack_status}
                      onChange={handleStatusChange}
                      checked={status.slack_errors}
                    />
                    <label htmlFor="slack-error">
                      {t("notification_page.16")}
                    </label>
                  </div>
                  <div className={styles.item}>
                    <input
                      id="slack-updates"
                      name="slack_updates"
                      type="checkbox"
                      disabled={!status.slack_status}
                      onChange={handleStatusChange}
                      checked={status.slack_updates}
                    />
                    <label htmlFor="slack-updates">
                      {t("notification_page.17")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.dynamicBtn}>
              <input
                type="checkbox"
                id="slack-switch"
                onChange={handleStatusChange}
                checked={status.slack_status}
                name="slack_status"
              />
              <label for="slack-switch">Toggle</label>
            </div>
          </div>
        </div>
        <div className={styles.saveBtn}>
          <button
            disabled={loading || !isStatusUpdate}
            onClick={updatePlatformStatus}
          >
            {t("button.16")}
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Representation;
