import React from "react";
import "../stylesheets/Loading.scss";

const Loading = () => {
  return (
    <div className="loader">
      <div className="loaders">
        <svg style={{margin: "auto", display: "block"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(20 50)">
            <circle cx="0" cy="0" r="6" fill="#4400aa">
              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g><g transform="translate(40 50)">
            <circle cx="0" cy="0" r="6" fill="#6f6c99">
              <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g><g transform="translate(60 50)">
            <circle cx="0" cy="0" r="6" fill="#1c193b">
              <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g><g transform="translate(80 50)">
            <circle cx="0" cy="0" r="6" fill="#3b4368">
              <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
          </g>
        </svg>
        <img src='/assets/pivony-logo.png' width={150} />
      </div>

    </div>
  );
};

export default Loading;
