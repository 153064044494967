import React, { useEffect, useState } from "react";
import { Radio, Collapse, Tag, Badge, Button, Spin } from "antd";
import { apiRequest } from "util/services";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as actions from "redux/Dashboard/actions.js";
import { useTranslation } from "react-i18next";
import DropdownSelect from "helperComponents/CustomDropdown";

const TopTopics = ({
  data,
  disableTopTopicsBtn,
  selectedTopTopics,
  setSelectedTopTopics,
  topTopicsLoading,
  checkedTopicIds,
  setCheckedTopicIds,
  selectedTopicIds,
  setSelectedTopicIds,
  boardType,
  handleClearAll,
  handleFilterClear,
  setTopicsOperator,
  showOnlyTree,
  setSavedSearchShowTopTopics,
  setSavedSearchTopTopicsFilter,
  setSavedSearchChildOthers,
  setSavedSearchParentOthers,
}) => {
  const [type, setType] = useState("custom");
  const [viewType, setViewType] = useState("category");
  const [words, setWords] = useState();
  const [startIndex, setStartIndex] = useState(0);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const DashboardReducer = useSelector((state) => state?.DashboardReducer);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const parentBoxRef = React.useRef(null);
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [queryFilters, setQueryFilters] = useState({});
  const [childOthers, setChildOthers] = useState([]);
  const [parentOthers, setParentOthers] = useState([]);
  const [discardedAITopics, setDiscardedAITopics] = useState([]);
  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1Selection" ||
          key === "level2Selection" ||
          key === "level1OfSelected" ||
          key === "level2OfSelected" ||
          key === "decisionFilter" ||
          key === "child_others" ||
          key === "parent_others" ||
          key === "hotterm"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);

  const searchTopics = (topicsArr, id, id_col) => {
    if (!topicsArr || topicsArr?.length === 0) return null;
    for (let tpc of topicsArr) {
      if (tpc[id_col] === parseInt(id, 10)) {
        return tpc;
      }
      if (tpc.child && tpc.child.length > 0) {
        const res = searchTopics(tpc.child, id, id_col);
        if (res) {
          return res;
        }
      }
    }
  };
  useEffect(() => {
    if (
      !topTopicsLoading &&
      queryFilters?.top_topics &&
      queryFilters.top_topics.length > 0
    ) {
      const topics = queryFilters.top_topics;
      topics.forEach((topic) => {
        if (topic.type === "custom") {
          const topicData = searchTopics(data?.tree, topic.id, "id");
          topic.id = topicData?.lib_id;
        }
      });
      setSelectedTopicIds(topics);
      setCheckedTopicIds(topics);
    }
  }, [queryFilters.top_topics, topTopicsLoading]);
  const addTopics = (id, type, label) => {
    let filterID = queryFilters?.top_topics?.filter((item) => item.id === id);
    if (!filterID || filterID?.length === 0) {
      params.delete("top_topics");
      params.append(
        "top_topics",
        JSON.stringify(
          queryFilters?.top_topics?.length > 0
            ? [
                ...queryFilters?.top_topics,
                { id: id, type: type, label: label },
              ]
            : [{ id: id, type: type, label: label }]
        )
      );
      params.append("topics_operator", "AND");
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenTopTopic: [
            ...DashboardReducer.searchState.chosenTopTopic,
            { id: id, type: type, label: label },
          ],
        },
      });
    } else {
      params.delete("top_topics");
      params.append(
        "top_topics",
        JSON.stringify(
          queryFilters?.top_topics?.filter((item) => item.id !== id)
        )
      );
      params.append("topics_operator", "AND");
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenTopTopic: DashboardReducer.searchState.chosenTopTopic?.filter(
            (item) => item.id !== id
          ),
        },
      });
    }
  };

  useEffect(() => {
    setStartIndex(0);
    if (type === "all") {
      setWords(data?.list);
      setViewType("plain");
    } else if (type === "ai") {
      setWords(data?.list?.filter((item) => item.type === "ai"));
      setViewType("plain");
    } else if (type === "fixed") {
      setWords(data?.list?.filter((item) => item.type === "fixed"));
      setViewType("plain");
    } else if (type === "custom") {
      setWords(data?.list?.filter((item) => item.type === "custom"));
      setCategoryList(data?.tree);
      setDiscardedAITopics(data?.discarded_topics);
      setViewType("category");
    }
  }, [type, data]);

  // useEffect(() => {
  //   //loop through the selectedTopTopics and checking if a parent has all its children selected
  //   //if yes, then set the parent to checked
  //   //if no, then set the parent to indeterminate
  //   if (checkedTopicIds?.length > 0) {
  //     for (let i = 0; i < checkedTopicIds?.length; i++) {
  //       const current = checkedTopicIds[i];
  //       if (current.type === "parent") {
  //         if (selectionStatus(current.id) === "some") {
  //           if (document.getElementById(current.id)) {
  //             document.getElementById(current.id).indeterminate = true;
  //           }
  //         } else if (selectionStatus(current.id) === "all") {
  //           if (document.getElementById(current.id)) {
  //             document.getElementById(current.id).checked = true;
  //             document.getElementById(current.id).indeterminate = false;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }, [selectedTopTopics, trigger]);

  const numFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * Math.trunc(Math.abs(num) / 1000) + "K"
      : num;
  };

  const applyOptions = [
    {
      id: "1",
      label: t("filter.13"),
      value: "AND",
    },
    {
      id: "2",
      label: t("filter.14"),
      value: "OR",
    },
  ];
  useEffect(() => {
    if (selectedFilterType && selectedTopicIds.length > 0) {
      handleApplyFilter(selectedFilterType.value);
    }
  }, [selectedFilterType]);
  const visualWords = words?.map((word, index) => {
    if (index >= 10 * startIndex && index < 10 * (startIndex + 1)) {
      return (
        <Tag
          icon={
            <Badge
              count={numFormatter(word.count)}
              overflowCount={9999999999}
              style={{
                marginRight: "10px",
                background: "#FFFFFF",
                border: "none",
                color: "#4400AA",
              }}
            />
          }
          className={
            DashboardReducer?.searchState?.chosenTopTopic?.some(
              (item) => item.id === word.id
            )
              ? styles.activeTerm
              : ""
          }
          color={theme === "brand" ? "#4a4d4e" : "#4400AA"}
          onClick={() =>
            !disableTopTopicsBtn && addTopics(word.id, word.type, word.label)
          }
          title={word.label}
        >
          <span className={styles.wordLabel}>{word.label}</span>
        </Tag>
      );
    }
  });

  const updatePage = (increment) => {
    setStartIndex(startIndex + increment);
  };

  const getText = (type) => {
    if (type === "all") {
      return t("others.47");
    } else if (type === "ai") {
      return t("others.48");
    } else if (type === "fixed") {
      return t("others.49");
    } else if (type === "custom") {
      return t("others.50");
    }
  };

  const handleTypeChange = (e) => {
    setViewType(e.target.value);
  };

  const getSearchItem = (list, str) => {
    const searchResultsTree = list.reduce((acc, item) => {
      if (item.name?.toLowerCase().includes(str)) {
        return [...acc, item];
      }
      if (item?.child?.length > 0) {
        const childSearchResults = getSearchItem(item.child, str);
        if (childSearchResults?.length > 0) {
          return [...acc, { ...item, child: childSearchResults }];
        }
      }
      return acc;
    }, []);
    setCategoryList(searchResultsTree);
    return searchResultsTree;
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (searchValue?.trim()) {
        getSearchItem(data?.tree, searchValue?.toLowerCase());
      } else {
        setCategoryList(data?.tree);
      }
    }
  };
  const handleButtonClickSearch = () => {
    if (searchValue?.trim()) {
      getSearchItem(data?.tree, searchValue?.toLowerCase());
    } else {
      setCategoryList(data?.tree);
    }
  };
  const handleShowSearch = () => {
    setShowSearchBox(true);
  };

  const selectionStatus = (id) => {
    //check if all the child of this parent are present in selectedTopicIds and if the parent is present in selectedTopicIds
    //return fullSelected if parent and all child of this parent are present in selectedTopicIds
    //return partialSelected if parent is present in selectedTopicIds and some of the child of this parent are present in selectedTopicIds
    //return noneSelected if parent is not present in selectedTopicIds and none of the child of this parent are present in selectedTopicIds
    let parent = categoryList?.find((item) => item?.lib_id === id);
    let fullSelected = true;
    let partialSelected = false;
    let noneSelected = true;
    for (let i = 0; i < parent?.child?.length; i++) {
      let c = parent?.child[i];
      if (c && c.count) {
        if (checkedTopicIds?.some((item) => item.id === c.lib_id)) {
          noneSelected = false;
          partialSelected = true;
        } else {
          fullSelected = false;
        }
      }
      //check if child has children
      if (c?.child?.length !== 0) {
        let status = selectionStatus(c.lib_id);
        if (status === "all") {
          noneSelected = false;
          partialSelected = true;
        } else if (status === "some") {
          noneSelected = false;
          partialSelected = true;
          fullSelected = false;
        } else {
          fullSelected = false;
        }
      }
    }

    if (fullSelected) return "all";
    if (partialSelected) return "some";
    if (noneSelected) return "none";
  };

  const selectNestedTopics = (parentId) => {
    let arr = [];
    let parent = findCategoryParent(categoryList, parentId);
    if (parent?.child?.length === 0) return [];
    for (let i = 0; i < parent?.child?.length; i++) {
      let c = parent?.child[i];
      const item = {
        label: c.name,
        id: c.type === 1 ? parseInt(c.id, 10) : parseInt(c.lib_id, 10),
        type: c.type === 1 ? "ai" : "custom",
      };
      if (c.type === "custom") {
        item.parent_id = parentId;
      }
      arr.push(item);
      //check if child has children
      if (c?.child?.length !== 0) {
        arr = arr.concat(selectNestedTopics(c.id));
      }
    }

    return arr;
  };
  const findCategoryParent = (arr, value) => {
    for (const item of arr) {
      if (item.lib_id === parseInt(value, 10)) {
        return item;
      }
      if (item.child) {
        const found = findCategoryParent(item.child, value);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };
  const getAllNestedTopicColsOfParent = (
    topicsArr,
    parentId,
    colName = "id",
    values = []
  ) => {
    const topicValues = values;
    topicsArr.forEach((tpc) => {
      if (tpc.child?.length > 0) {
        if (tpc.lib_id === parentId) {
          topicValues.push(...tpc.child.map((item) => item));
        }
        topicValues.push(
          ...getAllNestedTopicColsOfParent(
            tpc.child,
            parentId,
            colName,
            topicValues
          )
        );
      }
    });
    return [...new Set(topicValues.filter((item) => item?.id > 0))];
  };

  const handleCheckboxChange = (e) => {
    if (disableTopTopicsBtn) {
      return;
    }
    const { id, name, value, accessKey, itemType } = e.target;
    //[parent_id:25].filter.
    if (value) {
      if (
        selectedTopicIds?.filter((item) => {
          if (parseInt(itemType, 10) === 1) {
            return item.id === parseInt(accessKey, 10);
          } else {
            return item.id === parseInt(id, 10);
          }
        })?.length !== 0
      ) {
        const parent = findCategoryParent(categoryList, parseInt(value, 10));
        const newArr = selectedTopicIds.filter(
          (item) =>
            ![parseInt(id, 10), parseInt(accessKey, 10)].includes(item.id)
        );
        const checkedTopicIdsOfParent = [
          ...selectNestedTopics(parseInt(id, 10)),
        ]?.map((item) => item.id);
        const newChecked = checkedTopicIds.filter(
          (item) =>
            ![
              ...checkedTopicIdsOfParent,
              parseInt(id, 10),
              parseInt(accessKey, 10),
            ].includes(item.id)
        );
        //removing the parent if all the children are removed
        if (
          parent &&
          !newArr.some((item) => item.parent_id === parent.lib_id)
        ) {
          setSelectedTopicIds(
            newArr.filter(
              (item) =>
                ![parent.lib_id, ...checkedTopicIdsOfParent].includes(item.id)
            )
          );
          setCheckedTopicIds(
            newChecked.filter((item) => item.id !== parent.lib_id)
          );
        } else {
          setSelectedTopicIds(newArr);
          setCheckedTopicIds(newChecked);
        }
      } else {
        const newArr = [...selectedTopicIds];
        const newCheckedArr = [...checkedTopicIds];
        //checking if the parent is already selected
        const parent = selectedTopicIds.find(
          (item) =>
            item.id ===
            selectedTopicIds.find((item) => {
              if (parseInt(itemType, 10) === 1) {
                return item.id === parseInt(accessKey, 10);
              } else {
                return item.id === parseInt(id, 10);
              }
            })?.parent_id
        );
        if (parent) {
          const it = {
            label: name,
            id:
              parseInt(itemType) === 1
                ? parseInt(accessKey, 10)
                : parseInt(id, 10),
            type: parseInt(itemType) === 1 ? "ai" : "custom",
          };
          if (parseInt(itemType) !== 1) {
            it.parent_id = parseInt(value, 10);
          }
          newArr.push(it);
          newCheckedArr.push(it);
        } else {
          const itm = {
            label: name,
            id:
              parseInt(itemType) === 1
                ? parseInt(accessKey, 10)
                : parseInt(id, 10),
            type: parseInt(itemType) === 1 ? "ai" : "custom",
          };
          if (parseInt(itemType) !== 1) {
            itm.parent_id = parseInt(value, 10);
          }
          newArr.push(itm);
          newCheckedArr.push(itm);
          //finding the parent in the category list
          const catParent = findCategoryParent(categoryList, value);
          newArr.push({
            label: catParent?.name,
            id: parseInt(value, 10),
            type: "parent",
          });
          newCheckedArr.push({
            label: catParent?.name,
            id: parseInt(value, 10),
            type: "parent",
          });
        }
        newCheckedArr.push(...selectNestedTopics(parseInt(id, 10)));
        const unique = [
          ...new Map(newArr.map((item) => [item.id, item])).values(),
        ];
        const uniqueChecked = [
          ...new Map(newCheckedArr.map((item) => [item.id, item])).values(),
        ];
        setSelectedTopicIds(unique);
        setCheckedTopicIds(uniqueChecked);
      }
    } else {
      if (
        selectedTopicIds?.filter((item) => {
          if (parseInt(itemType, 10) === 1) {
            return item.id === parseInt(accessKey, 10);
          } else {
            return item.id === parseInt(id, 10);
          }
        })?.length !== 0
      ) {
        //removing all parent and child topics
        const toBeRemoved = selectNestedTopics(parseInt(id, 10));
        const topicArr = selectedTopicIds.filter(
          (item) => !toBeRemoved.find((i) => i.id === item.id)
        );
        const checkArr = checkedTopicIds.filter(
          (item) => !toBeRemoved.find((i) => i.id === item.id)
        );
        const tpcArr = topicArr.filter((item) => {
          if (parseInt(itemType, 10) === 1) {
            return item.id !== parseInt(accessKey, 10);
          } else {
            return item.id !== parseInt(id, 10);
          }
        });
        setSelectedTopicIds(tpcArr);
        setCheckedTopicIds(
          checkArr.filter((item) => {
            if (parseInt(itemType, 10) === 1) {
              return item.id !== parseInt(accessKey, 10);
            } else {
              return item.id !== parseInt(id, 10);
            }
          })
        );
      } else {
        const arr = selectedTopicIds;
        const checkedArr = checkedTopicIds;
        arr.push({
          label: name,
          id:
            parseInt(itemType, 10) === 1
              ? parseInt(accessKey, 10)
              : parseInt(id, 10),
          type: parseInt(itemType, 10) === 1 ? "ai" : "parent",
        });
        checkedArr.push({
          label: name,
          id:
            parseInt(itemType, 10) === 1
              ? parseInt(accessKey, 10)
              : parseInt(id, 10),
          type: parseInt(itemType, 10) === 1 ? "ai" : "parent",
        });
        //removing all duplicate topics
        const unique = [
          ...new Map(arr.map((item) => [item.id, item])).values(),
        ];
        checkedArr.push(...selectNestedTopics(parseInt(id, 10)));
        //removing all duplicate topics
        const uniqueChecked = [
          ...new Map(checkedArr.map((item) => [item.id, item])).values(),
        ];
        setCheckedTopicIds([...uniqueChecked]);
        setSelectedTopicIds([...unique]);
      }
    }
    setTrigger(!trigger);
  };
  useEffect(() => {
    if (selectedTopicIds?.some((item) => item.id === -2)) {
      const selectedAIIds = selectedTopicIds
        .filter((item) => item.type === "ai")
        ?.map((item) => item?.id);
      const discardedAIIds = discardedAITopics?.map(
        (item) => item?.ai_topic_id
      );
      const allAIIds = data?.list
        ?.filter((item) => item?.type === "ai")
        ?.map((item) => item?.id);

      setParentOthers((prev) => {
        const ai = [...allAIIds, ...prev].filter(
          (item) => ![...selectedAIIds, ...discardedAIIds].includes(item)
        );
        return [...new Set(ai)];
      });
    } else {
      setParentOthers([]);
    }
  }, [selectedTopicIds, discardedAITopics]);
  useEffect(() => {
    if (selectedTopicIds?.some((item) => item.id < -2)) {
      const othersTopics = selectedTopicIds.filter((item) => item.id < -2);
      let childOth = [];
      othersTopics.forEach((item) => {
        const nestedTopics = getAllNestedTopicColsOfParent(
          data?.tree,
          parseInt(item.parent_id, 10)
        );
        const nestedIds = nestedTopics?.map((item) => item?.id);
        const mappingArr = selectedTopicIds?.map((item) => {
          if (item.type === "custom") {
            const child = searchTopics(data?.tree, item.id, "lib_id");
            return { ...item, id: child?.id };
          } else {
            return item;
          }
        });
        const selectedChildIds = mappingArr
          .filter((item) => item.type !== "parent" || item.id < 0)
          ?.map((item) => item?.id);
        childOth = childOth.concat(
          nestedIds.filter((item) => !selectedChildIds.includes(item))
        );
      });
      setChildOthers([...new Set(childOth)]);
    } else {
      setChildOthers([]);
    }
  }, [selectedTopicIds]);

  const handleApplyFilter = (operator) => {
    const topicsArr = queryFilters?.top_topics || [];
    let newArr = topicsArr
      ?.filter((val) => !val.label)
      ?.concat(selectedTopicIds);
    //mapping ids to child_ids
    const mappedArr = newArr?.map((item) => {
      if (item.type === "custom") {
        const child = searchTopics(data?.tree, item.id, "lib_id");
        return { ...item, id: child?.id };
      } else {
        return item;
      }
    });
    if (showOnlyTree) {
      setTopicsOperator(operator);
      setSavedSearchShowTopTopics(false);
      setSavedSearchTopTopicsFilter(mappedArr);
      setSavedSearchChildOthers(childOthers);
      setSavedSearchParentOthers(parentOthers);
      return;
    }
    if (mappedArr) {
      params.delete("top_topics");
      params.delete("topics_operator");
      params.delete("child_others");
      params.delete("parent_others");
      params.append("top_topics", JSON.stringify(mappedArr));
      params.append("topics_operator", operator);
      if (childOthers?.length > 0 || parentOthers?.length > 0) {
        params.append("child_others", JSON.stringify(childOthers));
        params.append("parent_others", JSON.stringify(parentOthers));
      }
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenTopTopic: mappedArr,
          topicsOperator: operator,
        },
      });
    } else {
      params.delete("top_topics");
      params.delete("topics_operator");
      history.push({ search: params.toString() });
    }
  };

  const hasAITopicInTree = (tree) => {
    if (!tree || tree?.length === 0) return false;
    const hasTopic = tree.some((node) => {
      if (node.type === 1) return true;
      if (node.child && node.child.length > 0)
        return hasAITopicInTree(node.child);
      return false;
    });
    return hasTopic;
  };
  const getNestedChild = (item) => {
    return item?.map((it) => (
      <Collapse
        className={showOnlyTree ? styles.boxColorCollapse : styles.collapse}
        bordered={false}
        accordion
      >
        <Collapse.Panel
          key={it.lib_id}
          showArrow={false}
          header={
            <div className={styles.topicHeader}>
              {
                <input
                  checked={checkedTopicIds.some((i) => {
                    if (it.type === 1) {
                      return i.id === it.id;
                    } else {
                      return i.id === it.lib_id;
                    }
                  })}
                  id={it.lib_id}
                  className={`${styles.topicCheckBox}`}
                  type="checkbox"
                  name={it.name}
                  onChange={(e) => {
                    const target = e.target;
                    const { id, name, value } = target;
                    const { itemType, accessKey } = target.dataset;
                    handleCheckboxChange({
                      ...e,
                      target: {
                        ...target,
                        id,
                        name,
                        value,
                        itemType,
                        accessKey,
                      },
                    });
                  }}
                  value={it.parent_id}
                  onClick={(e) => e.stopPropagation()}
                  data-item-type={it.type}
                  data-access-key={it.id}
                />
              }
              {it?.child?.length > 0 && (
                <i className="fa-solid fa-chevron-right"></i>
              )}
              <span className={styles.countTagTopicName}>
                {it?.count >= 0 && !showOnlyTree && (
                  <span className={styles.numbering}>{it?.count}</span>
                )}
                {it?.id > 0 && (
                  <img
                    src={
                      it.type === 1
                        ? theme === "dark"
                          ? "/assets/ai_topic_tag.svg"
                          : "/assets/ai_topic_tag_black.svg"
                        : theme === "dark"
                        ? "/assets/custom_topic_tag.svg"
                        : "/assets/custom_topic_tag_black.svg"
                    }
                    alt=""
                    width={it.type === 1 ? 20 : 18}
                    height={it.type === 1 ? 20 : 18}
                    title={it.type === 1 ? "AI Topic" : "Custom Topic"}
                  />
                )}
                {it.name}
              </span>
            </div>
          }
          collapsible={it?.child?.length > 0 ? "header" : "disabled"}
        >
          {it.child?.length > 0 && getNestedChild(it.child)}
        </Collapse.Panel>
      </Collapse>
    ));
  };

  return (
    <div className={styles.HotWordsSection}>
      {!showOnlyTree && (
        <div className={styles.radioContainer}>
          <Radio.Group
            defaultValue={"custom"}
            buttonStyle="solid"
            onChange={(e) => setType(e.target.value)}
          >
            <Radio.Button className={styles.item} value={"all"}>
              <img
                src={
                  type === "all" && theme !== "dark"
                    ? "/assets/all_words_black.svg"
                    : "/assets/all_words.svg"
                }
                alt=""
              />
              {t("others.43")}
            </Radio.Button>
            <Radio.Button className={styles.item} value={"ai"}>
              <img
                src={
                  type === "ai" && theme !== "dark"
                    ? "/assets/bubble_chart_black.svg"
                    : "/assets/bubble_chart.svg"
                }
                alt=""
              />
              {t("others.44")}
            </Radio.Button>
            {/* <Radio.Button className={styles.item} value={"fixed"}><img src={type === 'fixed' && theme !== 'dark' ? "/assets/industry_words_black.svg" : "/assets/industry_words.svg"} alt="" />{t("others.45")}</Radio.Button> */}
            <Radio.Button className={styles.item} value={"custom"}>
              <img
                src={
                  type === "custom" && theme !== "dark"
                    ? "/assets/custom_words_black.svg"
                    : "/assets/custom_words.svg"
                }
                alt=""
              />
              {t("others.46")}
            </Radio.Button>
          </Radio.Group>
        </div>
      )}

      {!showOnlyTree && (
        <div className={styles.description}>
          <span>{getText(type)}</span>
          {type === "custom" ? (
            <div className={styles.radioInput}>
              <div className={styles.item}>
                <input
                  type="radio"
                  id="plain"
                  value="plain"
                  name="view-type"
                  checked={viewType === "plain"}
                  onChange={handleTypeChange}
                />
                <label htmlFor="plain">
                  {t("selected_dashboard_page.105")}
                </label>
              </div>
              <div className={styles.item}>
                <input
                  type="radio"
                  id="category"
                  value="category"
                  checked={viewType === "category"}
                  name="view-type"
                  onChange={handleTypeChange}
                />
                <label htmlFor="category">
                  {t("selected_dashboard_page.106")}
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      <div className={styles.visualTerms}>
        {words?.length === 0 && !topTopicsLoading && viewType === "plain" ? (
          <div className={styles.emptyState}>
            {" "}
            <div className={styles.title}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/emptyStates/topic_library_icon.svg"
                    : "/assets/emptyStates/topic_library_black.svg"
                }
                alt=""
              />
              {t("notifications.80")}
            </div>
            <div className={styles.description}>
              To create custom topics, go{" "}
              <Link to="/console/topicLibrary">Topic Library</Link>
            </div>
          </div>
        ) : viewType === "plain" ? (
          <div className={styles.topTopicsPlain}>{visualWords}</div>
        ) : type === "custom" ? (
          <div className={styles.categoryBlock}>
            <div
              className={
                checkedTopicIds?.length > 0 ? styles.btnRow : styles.singleBtn
              }
            >
              {!showSearchBox && checkedTopicIds?.length > 0 && (
                <span
                  className={styles.clearAllTopics}
                  onClick={handleClearAll}
                >
                  {t("selected_dashboard_page.107")}
                </span>
              )}
              {!showOnlyTree && (
                <span className={styles.searchNapply}>
                  {!showSearchBox && data?.tree?.length > 0 && (
                    <div className={styles.actionBtnBlock}>
                      <button
                        className={styles.search}
                        onClick={handleShowSearch}
                      >
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/search.svg"
                              : "/assets/search_black.svg"
                          }
                          alt=""
                        />
                      </button>
                    </div>
                  )}
                  {showSearchBox && (
                    <div
                      className={`${styles.searchBoxSmall} ${showSearchBox &&
                        styles.showSearch} `}
                    >
                      <i
                        onClick={() => {
                          setShowSearchBox(false);
                          setSearchValue("");
                          setCategoryList(data?.tree);
                        }}
                        className="fa-solid fa-arrow-left"
                      ></i>
                      <input
                        type="text"
                        placeholder={t("placeholder.2")}
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyPress={handleSearch}
                      />
                      <button onClick={handleButtonClickSearch}>
                        {t("button.5")}
                      </button>
                    </div>
                  )}
                </span>
              )}
            </div>
            {selectedTopicIds && selectedTopicIds.length > 0 && (
              <div className={styles.applyActions}>
                {selectedTopicIds.length === 1 ||
                (selectedTopicIds.length === 2 &&
                  selectedTopicIds.filter((item) =>
                    item.hasOwnProperty("parent_id")
                  ).length < 2 &&
                  selectedTopicIds.filter((item) => item.type === "parent")
                    .length < 2) ? (
                  <span
                    onClick={() =>
                      selectedTopicIds?.some((item) => item.type === "ai")
                        ? handleApplyFilter("AND")
                        : handleApplyFilter("OR")
                    }
                    className={styles.applyFilterDropdown}
                  >
                    {t("button.40")}
                  </span>
                ) : (
                  <DropdownSelect
                    options={applyOptions}
                    selectedOptions={selectedFilterType}
                    setSelectedOptions={setSelectedFilterType}
                    hideImg={true}
                    hiddenTriggerLabel={
                      <span className={styles.applyFilterDropdown}>
                        {t("button.40")}
                      </span>
                    }
                    triggerEvent={"click"}
                    disableMenu={selectedTopicIds.length === 0}
                    placement={"bottomRight"}
                    dropdownClassName={
                      showOnlyTree ? styles.topicsOperatorDropdown : ""
                    }
                  />
                )}
              </div>
            )}
            <div className={styles.topicCategoryContainer}>
              <Spin spinning={false}>
                {categoryList?.length !== 0 ? (
                  <Collapse
                    className={
                      showOnlyTree ? styles.boxColorCollapse : styles.collapse
                    }
                    bordered={false}
                    accordion
                  >
                    {categoryList?.map((item) => (
                      <Collapse.Panel
                        key={item.lib_id}
                        showArrow={false}
                        header={
                          <div className={styles.topicHeader}>
                            {
                              <input
                                ref={parentBoxRef}
                                className={`${styles.topicCheckBox}`}
                                type="checkbox"
                                id={item.lib_id}
                                name={item.name}
                                onChange={(e) => {
                                  const target = e.target;
                                  const { id, name, value } = target;
                                  const {
                                    itemType,
                                    accessKey,
                                  } = target.dataset;
                                  handleCheckboxChange({
                                    ...e,
                                    target: {
                                      ...target,
                                      id,
                                      name,
                                      value,
                                      itemType,
                                      accessKey,
                                    },
                                  });
                                }}
                                checked={checkedTopicIds.some((i) => {
                                  if (item.type === 1) {
                                    return i.id === item.id;
                                  } else {
                                    return i.id === item.lib_id;
                                  }
                                })}
                                value={item.parent_id}
                                onClick={(e) => e.stopPropagation()}
                                data-item-type={item.type}
                                data-access-key={item.id}
                              />
                            }
                            {item?.child?.length > 0 && (
                              <i className="fa-solid fa-chevron-right"></i>
                            )}
                            <span className={styles.countTagTopicName}>
                              {item?.count >= 0 && !showOnlyTree && (
                                <span className={styles.numbering}>
                                  {item?.count}
                                </span>
                              )}
                              {item?.id > 0 && (
                                <img
                                  src={
                                    item.type === 1
                                      ? theme === "dark"
                                        ? "/assets/ai_topic_tag.svg"
                                        : "/assets/ai_topic_tag_black.svg"
                                      : theme === "dark"
                                      ? "/assets/custom_topic_tag.svg"
                                      : "/assets/custom_topic_tag_black.svg"
                                  }
                                  alt=""
                                  width={item.type === 1 ? 20 : 18}
                                  height={item.type === 1 ? 20 : 18}
                                  title={
                                    item.type === 1
                                      ? "AI Topic"
                                      : "Custom Topic"
                                  }
                                />
                              )}
                              {item.name}
                            </span>
                          </div>
                        }
                        collapsible={
                          item?.child?.length > 0 ? "header" : "disabled"
                        }
                      >
                        {item.child?.length > 0 && getNestedChild(item.child)}
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                ) : (
                  <p>{t("notifications.80")}</p>
                )}
              </Spin>
            </div>
          </div>
        ) : (
          type === "custom" && <p>{t("notifications.80")}</p>
        )}
      </div>
      {words?.length > 10 && viewType === "plain" ? (
        <div className={styles.footer}>
          <div className={styles.btn}>
            <Button
              className={styles.button}
              disabled={startIndex === 0}
              onClick={() => updatePage(-1)}
            >
              <i className="fas fa-chevron-left"></i>
            </Button>
          </div>
          <div className={styles.btn}>
            <Button
              className={styles.button}
              disabled={
                (words?.length % 10 === 0 &&
                  words?.length / 10 === startIndex + 1) ||
                (words?.length % 10 !== 0 &&
                  Math.trunc(words?.length / 10) + 1 === startIndex + 1)
              }
              onClick={() => updatePage(1)}
            >
              <i className="fas fa-chevron-right"></i>
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TopTopics;
