import React from "react";
import styles from "./style.module.scss";
import { useHistory } from "react-router-dom";

export const PlatformStatus = ({ status, dashId }) => {
  const history = useHistory();
  switch (status) {
    case "Ready":
      return (
        <div className={styles.statusBox} title="Ready">
          <img src="/assets/ready_icon.svg" alt="" width={22} height={22} />
          {/* <span style={{ color: "#0FBD00" }}>Ready</span> */}
        </div>
      );
    case "In Progress":
    case "Progress":
    case "Custom Topics Running":
    case "Deleting Custom Topic":
      return (
        <div
          className={styles.statusBox}
          style={{ cursor: "pointer" }}
          title={
            status === "Deleting Custom Topic"
              ? "Deleting Custom Topics"
              : `${status}${
                  status === "Progress" || status === "Deleting Custom Topic"
                    ? ``
                    : `\nClick to see dashboard journey`
                }`
          }
          onClick={() => {
            if (status === "Progress" || status === "Deleting Custom Topic") {
              return;
            }
            history.push({
              pathname: `/console/journey/${dashId}`,
            });
          }}
        >
          <img src="/assets/progress_icon.svg" alt="" width={22} height={22} />
          {/* <span style={{ color: "#F48A00" }}>In progress</span> */}
        </div>
      );
    default:
      return (
        <div
          className={styles.statusBox}
          title={
            status === "Expired" || status === "expired" ? "Expired" : "Error"
          }
        >
          {status === "Expired" || status === "expired" ? (
            <img src="/assets/expired_icon.svg" alt="" width={22} height={22} />
          ) : (
            <img src="/assets/error_icon.svg" alt="" width={22} height={22} />
          )}
          {/* <span style={{ color: "#E60000" }}>{status === "Expired" || status === "expired" ? "Expired" : "Error"}</span> */}
        </div>
      );
  }
};
