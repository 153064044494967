import XLSX from 'xlsx';

export const getFileHeaderFromXLSX = (file) => {
  const reader = new FileReader()

  const result = new Promise(function (resolve, reject) {
    reader.onload = () => {
      const binaryStr = reader.result
      const workbook = XLSX.read(binaryStr, { type: 'binary' })
      var sheet_name_list = workbook.SheetNames;
      let columnHeaders = [];
      for (var sheetIndex = 0; sheetIndex < sheet_name_list.length; sheetIndex++) {
        var worksheet = workbook.Sheets[sheet_name_list[sheetIndex]];
        for (let key in worksheet) {
          let regEx = new RegExp("^([A-Za-z]{1,2})(1){1}$");
          if (regEx.test(key) === true) {
            columnHeaders.push(worksheet[key].v);
          }
        }
      }
      resolve(columnHeaders)
    }
  });
  reader.readAsArrayBuffer(file)

  return result
}