import React, { useState, useEffect } from "react";
import styles from "./style.module.scss";
import { Button, Input, Radio, Select } from "antd";
import * as actions from "redux/newDashboard/actions.js";
import { useDispatch } from "react-redux";
import { apiRequest } from "util/services";
import { useTranslation } from "react-i18next";

const DataSource = ({ user, platforms, advancedAllowed, trial }) => {
  const { t } = useTranslation();

  const [localState, setState] = useState({
    platform: 3,
    type: "simple",
    searchTerm: "",
    industry_id: -1,
    industry: "Others",
  });
  const [industries, setIndustries] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const getIndustries = async () => {
      const res = await apiRequest("dashboards/categories", "POST");
      setIndustries(res.industries);
      setState({
        ...localState,
        industry_id: res?.orgIndustry?.id,
        industry: res?.orgIndustry?.name,
      });
    };
    getIndustries();
  }, []);

  useEffect(() => {
    if (platforms && platforms.length > 0) {
      for (let platform of platforms) {
        if (platform?.allowed) {
          setState({ ...localState, platform: platform.id });
          break;
        }
      }
    }
  }, [platforms]);

  const nextStep = () => {
    const { platform, type, industry_id } = localState;
    dispatch({
      type: actions.SET_NEW_Dashboard,
      payload: {
        platform,
        creationType: type,
        industry_id,
        currentStep: 1,
      },
    });
  };

  const handleChange = (value, item) => {
    setState({ ...localState, industry_id: item.key, industry: item.value });
  };

  return (
    <div className={styles.container} id="datasourceselection">
      <div className={styles.header}>
        <h5>{t("new_dashboard.1")}</h5>
      </div>
      <div className={styles.platformSection}>
        <div className={styles.label}>
          <h5>{t("new_dashboard.2")}</h5>
          <h6>{t("new_dashboard.3")}</h6>
        </div>
        <div className={styles.platformContent} id="selectplatforms">
          {/* <div className={styles.search} >
            <Input
              placeholder="Search Platforms"
              prefix={
                <i style={{ marginRight: "5px" }} className="fa fa-search" />
              }
              onChange={(e) =>
                setState({ ...localState, searchTerm: e.target.value })
              }
            />
          </div> */}
          <div className={styles.radio} id="allplatformstochoose">
            <Radio.Group
              onChange={(e) =>
                setState({ ...localState, platform: e.target.value })
              }
              value={localState.platform}
            >
              {platforms?.map((item) => {
                if (
                  !item.name
                    .toLowerCase()
                    .includes(localState.searchTerm.toLowerCase()) ||
                  !item.allowed
                )
                  return;
                return <Radio value={item.id}>{item.name}</Radio>;
              })}
            </Radio.Group>
            {/* <Radio.Group>
              {platforms?.map((item) => {
                if (item.allowed) return;
                return <Radio value={item.id} disabled={!item.allowed} className={styles.disabled}>{item.name}</Radio>;
              })}
            </Radio.Group> */}
          </div>
        </div>
      </div>
      <div className={styles.platformSection}>
        <div className={styles.label}>
          <h5>{t("new_dashboard.4")}</h5>
          <h6>{t("new_dashboard.5")}</h6>
        </div>
        <div className={styles.platformContent}>
          <div className={`${styles.content}`} id="custom_dropdown">
            <Select
              onChange={handleChange}
              value={localState.industry}
              style={{
                width: "100%",
                height: 40,
              }}
              showSearch
              optionFilterProp="title"
            >
              {industries?.map((industry) => {
                return (
                  <Select.Option
                    key={industry.id}
                    value={industry.name}
                    title={industry.name}
                  >
                    {industry.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
      {advancedAllowed && (
        <div className={styles.typeSection}>
          <div className={styles.label}>
            <h5>{t("new_dashboard.6")}</h5>
            <h6>{t("new_dashboard.7")}</h6>
          </div>
          <div className={styles.typeContent}>
            <div className={styles.radio}>
              <Radio.Group
                onChange={(e) =>
                  setState({ ...localState, type: e.target.value })
                }
                value={localState.type}
              >
                <Radio value="simple">{t("new_dashboard.8")}</Radio>
                <Radio value="advanced">{t("new_dashboard.9")}</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      )}
      <div className={styles.btn}>
        <Button onClick={nextStep}>{t("new_dashboard.10")}</Button>
      </div>
    </div>
  );
};

export default DataSource;
