export const plansArr = [
    {
        title: "Paribu",
        subtitle: "",
        pricing: {
            200000: {
                expert_yes: {
                    trimonthly: {
                        price: {
                            tl: 13500,
                        },
                        id: 64,
                        paddleId: 661565,
                        expert: true,
                    }
                },
            }
        },
        pros: [
            "File Upload - Customer Reviews",
            "App Reviews (Google Play and App Store)",
            "Support Service Tickets and Live Chat (Zendesk)",
            "Social Media Comments (Twitter and Instagram)",
            "Account representative",
            "Expert Insights"
        ],
        btnTxt: "Buy Now",
        freeTrial: false,
        active: true,
        major: "Paribu Package",
        category: "default",
        planId: 3,
    },
];

export const getPlans = () => {
    return plansArr.filter((plan) => plan.active);
};

export const filterByTypeInterval = (interval, type) => {
    return plansArr.filter(
        (plan) => plan.timeInterval === interval && plan.category === type
    );
};
export const getPlanByIntervalTypePlan = (interval, type, plan) => {
    for (let i = 0; i < plansArr.length; i++) {
        if (
            plansArr[i].timeInterval === interval &&
            plansArr[i].title.toLowerCase() === type &&
            plansArr[i].category === plan
        ) {
            return plansArr[i];
        }
    }
};

export const getPlanById = (planId) => {
    if (!planId) return {};
    for (let i = 0; i < plansArr.length; i++) {
        if (plansArr[i].planId === planId) {
            return plansArr[i];
        }
    }
};

export const findPackage = (packageId) => {
    for (let i = 0; i < plansArr.length; i++) {
        const len1 = Object.keys(plansArr[i].pricing).length;
        for (let j = 0; j < len1; j++) {
            const planWrapper =
                plansArr[i].pricing[Object.keys(plansArr[i].pricing)[j]];
            const len2 = Object.keys(planWrapper).length;
            for (let z = 0; z < len2; z++) {
                const planWrapper2 = planWrapper[Object.keys(planWrapper)[z]];
                const len3 = Object.keys(planWrapper2).length;
                for (let t = 0; t < len3; t++) {
                    const planWrapper3 =
                        planWrapper2[Object.keys(planWrapper2)[t]];
                    if (planWrapper3.id === packageId) {
                        return planWrapper3;
                    }
                }
            }
        }
    }
};

const recur = ["monthly", "yearly"];
const type = ["starter", "professional"];
const plan = ["customer", "social", "bundles"];

export const getPlanByUTM = (utmRecur, utmType, utmPlan) => {
    if (
        recur.includes(utmRecur) &&
        type.includes(utmType) &&
        plan.includes(utmPlan)
    ) {
        return getPlanByIntervalTypePlan(utmRecur, utmType, utmPlan);
    } else {
        return null;
    }
};
