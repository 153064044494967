import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import firebaseApp from 'firebase/app'
import firebase from "FirebaseConfig.js";
import Representation from './Representation';
import i18n from 'i18next';

const TIMER = 120;

const Security = () => {
  const history = useHistory()
  const [showMFAModal, setShowMFAModal] = useState(false)
  const [recaptchaVerifier, setRecaptchaVerifier] = useState()
  const [verificationId, setVerificationId] = useState()
  const [verificationCode, setVerificationCode] = useState()
  const [phonenumber, setPhonenumber] = useState()
  const [smsSent, setSmsSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const updateTime = () => {
    setTimeout(() => {
      setTimeLeft(timeLeft - 1)
    }, 1000)
  }

  useEffect(() => {
    if (timeLeft > 0)
      updateTime()
  }, [timeLeft])

  useEffect(() => {
    var recaptchaVerifier = new firebaseApp.auth.RecaptchaVerifier('mfa-button', {
      'size': 'invisible',
    });
    setRecaptchaVerifier(recaptchaVerifier)
  }, [])

  const sendSMS = async () => {
    setIsLoading(true);
    await firebase.auth().currentUser.multiFactor.getSession().then(function (multiFactorSession) {
      // Specify the phone number and pass the MFA session.
      var phoneInfoOptions = {
        phoneNumber: phonenumber,
        session: multiFactorSession
      };
      // Send SMS verification code.
      const phoneAuthProvider = new firebaseApp.auth.PhoneAuthProvider();
      phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions, recaptchaVerifier)
        .then((verificationId) => {
          setVerificationId(verificationId)
          setSmsSent(true)
          setTimeLeft(TIMER)
          setIsLoading(false);
        }).catch(e => {
          if (e.message === "CREDENTIAL_TOO_OLD_LOGIN_AGAIN") {
            // TODO: getUserCredentials
          }
          setIsLoading(false);
          notification.error({ message: e.message });
        })
    })
  }

  const enableMFA = async () => {
    setIsLoading(true);
    try {
      // Ask user for the verification code.
      var cred = firebaseApp.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
      var multiFactorAssertion = firebaseApp.auth.PhoneMultiFactorGenerator.assertion(cred);
      // Complete enrollment.
      await firebase.auth().currentUser.multiFactor.enroll(multiFactorAssertion, "My personal phonenumber")
        .then(() => {
          setIsLoading(false);
          history.push("/settings/security")
          notification.success({ message: i18n.t("notifications.109") });
        });
    } catch (e) {
      setIsLoading(false);
      notification.error({ message: e.message });
    }
  }

  const resend = async () => {
    try {
      setTimeLeft(TIMER);
      setIsLoading(true);
      await firebase.auth().currentUser.multiFactor.getSession().then(function (multiFactorSession) {
        // Specify the phone number and pass the MFA session.
        var phoneInfoOptions = {
          phoneNumber: phonenumber,
          session: multiFactorSession
        };
        // Send SMS verification code.
        const phoneAuthProvider = new firebaseApp.auth.PhoneAuthProvider();
        phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions, recaptchaVerifier)
          .then((verificationId) => {
            setVerificationId(verificationId)
            setSmsSent(true)
            setTimeLeft(TIMER)
            setIsLoading(false);
          })
      })
    } catch {
      setIsLoading(false);
    }
  }

  return <Representation
    setPhonenumber={setPhonenumber}
    phonenumber={phonenumber}
    sendSMS={sendSMS}
    verifyCode={enableMFA}
    smsSent={smsSent}
    verificationCode={verificationCode}
    setVerificationCode={setVerificationCode}
    resend={resend}
    showMFAModal={showMFAModal}
    setShowMFAModal={setShowMFAModal}
    timeLeft={timeLeft}
    isLoading={isLoading}
  />
}

export default Security;