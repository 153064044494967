import React, { useState } from "react";
import ConsoleSideMenu from "../helperComponents/ConsoleSideMenu.js";
import "../stylesheets/consoleLayout.css";
import DashboardGuide from "./DashboardGuide/DashboardGuide.js";
import { themeLayoutColor } from 'util/common.js';

const ConsoleLayout = ({ children, ...props }) => {
  const [openRightClickPopup, setOpenRightClickPopup] = useState(false);

  return (
    <div className="consoleLayoutRoot" onClick={() => setOpenRightClickPopup(false)}>
      <div className="consoleLayoutRoot-sideMenu" style={{ backgroundColor: themeLayoutColor(props.theme) }}>
        <ConsoleSideMenu openRightClickPopup={openRightClickPopup} setOpenRightClickPopup={setOpenRightClickPopup} theme={props.theme} />
      </div>

      <div className="consoleLayoutRoot-content">{children}</div>
      {window.innerWidth > 768 && <DashboardGuide theme={props.theme} />}
    </div>
  );
};

export default ConsoleLayout;
