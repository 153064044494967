import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import firebase from "FirebaseConfig.js";
import { apiRequest } from "util/services";
import { logError } from "util/services";
import Representation from "./Representation";
import { useTranslation } from "react-i18next";

const Security = ({ user }) => {
  const history = useHistory();
  const [email, setEmail] = useState(user?.email);
  const [changeEmail, setChangeEmail] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmNewPassword, setConfirmNewPassword] = useState();
  const [mfaEnabled, setMFAEnabled] = useState();
  const [loading, setLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [changePwdLoading, setChangePwdLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setMFAEnabled(
      firebase.auth().currentUser.multiFactor.enrolledFactors.length !== 0
    );
    const payload = {
      user_id: user.uid,
    };
    apiRequest("subscriptions/all", "POST", payload, true).then((result) => {
      if (!result.error) {
        setSubscriptionStatus(result?.[0]);
      } else {
        notification.error({
          message: result.error,
        });
      }
    });
  }, [user.uid]);

  const changePassword = () => {
    if (confirmNewPassword !== newPassword) {
      notification.error({ message: t("notifications.102") });
    } else {
      setChangePwdLoading(true);
      firebase
        .auth()
        .signInWithEmailAndPassword(user.email, oldPassword)
        .then((userCredential) => {
          userCredential.user
            .updatePassword(newPassword)
            .then(() => {
              setShowPasswordModal(false);
              setChangePwdLoading(false);
              notification.success({ message: t("notifications.103") });
            })
            .catch((e) => {
              setShowPasswordModal(false);
              setChangePwdLoading(false);
              notification.error({
                message: `${t("notifications.104")}: ` + e.message,
              });
            });
        });
    }
  };

  const deleteAccount = async () => {
    setLoading(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        user
          .delete()
          .then(async () => {
            var ref = firebase.database().ref("users/".concat(user.uid, "/"));
            if (ref) {
              try {
                ref.remove();
                const payload = {
                  firebase_id: user.uid,
                };
                const result = await apiRequest(
                  "users/delete",
                  "POST",
                  payload,
                  true
                );
                if (!result || result.error) {
                  logError("Deleting user from our db", result);
                  notification.error({
                    message: result.error || JSON.stringify(result),
                  });
                } else {
                  firebase
                    .auth()
                    .signOut()
                    .then(
                      () => {
                        window.localStorage.removeItem("selectedDashboard");
                        notification.success({
                          message: t("notifications.105"),
                        });
                        setTimeout(() => {
                          document.location.href =
                            document.location.origin + "/login";
                        }, 2000);
                      },
                      function(error) {
                        console.error("Sign Out Error", error);
                      }
                    );
                }
                document.location.href = document.location.origin;
              } catch (err) {
                logError("Deleting user from firebase db", err);
                notification.error({
                  message: err.message || JSON.stringify(err),
                });
              }
            }
          })
          .catch((err) => {
            notification.error({
              message: err.message || JSON.stringify(err),
            });
            logError("Deleting user", err);
          });
      }
    });
    setLoading(false);
    setShowDeleteModal(false);
  };

  const saveEmail = () => {
    firebase
      .auth()
      .currentUser.updateEmail(email)
      .then(async () => {
        firebase
          .database()
          .ref(`/users/${user.uid}/email`)
          .set(email);

        const payload = {
          user_id: user.uid,
          email: email,
        };
        await apiRequest("users/update_email", "POST", payload);
        await firebase
          .database()
          .ref(`/users/${user.uid}/`)
          .set({
            ...user,
            ...email,
          });
        notification.success({ message: t("notifications.106") });
      })
      .catch((e) => {
        notification.error({
          message: `${t("notifications.107")} ` + e.message,
        });
      });
  };

  const updateChangeEmail = () => {
    if (changeEmail) {
      setEmail(user?.email);
      setChangeEmail(false);
    } else {
      setChangeEmail(true);
    }
  };
  const disable2fa = () => {
    const user = firebase.auth().currentUser;
    const uid = user.multiFactor.enrolledFactors[0].uid;
    user.multiFactor
      .unenroll(uid)
      .then(() => {
        setMFAEnabled(
          firebase.auth().currentUser.multiFactor.enrolledFactors.length !== 0
        );
        notification.success({ message: t("notifications.109") });
      })
      .catch((e) => {
        if (e.message === "CREDENTIAL_TOO_OLD_LOGIN_AGAIN") {
          // TODO: getUserCredentials
        }
        notification.error({ message: e.message });
      });
  };
  return (
    <Representation
      emailVerified={user?.emailVerified}
      email={email}
      setEmail={setEmail}
      saveEmail={saveEmail}
      setChangeEmail={setChangeEmail}
      changeEmail={changeEmail}
      updateChangeEmail={updateChangeEmail}
      setShowPasswordModal={setShowPasswordModal}
      showPasswordModal={showPasswordModal}
      showDeleteModal={showDeleteModal}
      setShowDeleteModal={setShowDeleteModal}
      changePassword={changePassword}
      deleteAccount={deleteAccount}
      loading={loading}
      setOldPassword={setOldPassword}
      setNewPassword={setNewPassword}
      setConfirmNewPassword={setConfirmNewPassword}
      history={history}
      disable2fa={disable2fa}
      mfaEnabled={mfaEnabled}
      plan={subscriptionStatus}
      changePwdLoading={changePwdLoading}
    />
  );
};

export default Security;
