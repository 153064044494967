import React, { useEffect } from "react";
import ConsoleLayout from "./ConsoleLayout";
import MainLayout from "./MainLayout";
import SettingsLayout from "./SettingsLayout";
import { useHistory } from "react-router-dom";

const Layouts = {
  console: ConsoleLayout,
  main: MainLayout,
  settings: SettingsLayout,
};

const IndexLayout = ({ children, ...props }) => {
  const pathname = window.location.pathname;
  const history = useHistory();

  const getLayout = () => {
    if (/^\/console(?=\/|$)/i.test(pathname)) {
      return "console";
    } else if (/^\/settings(?=\/|$)/i.test(pathname)) {
      return "settings";
    }
    return "main";
  };

  let Component = Layouts[getLayout()];

  useEffect(() => {
    history.listen(() => {
      Component = Layouts[getLayout()];
    });
  }, []);

  return <Component {...props}>{children}</Component>;
};

export default IndexLayout;
