import React from "react";
import { Button } from "antd";

export const HIDE_CREATION_TOUR = "HIDE_CREATION_TOUR";
export const HIDE_BOARD_TOUR = "HIDE_BOARD_TOUR";

const hideTourGuide = (key, close) => {
  window.localStorage.setItem(key, true);
  close()
};

export const createBoardSteps = [
  {
    selector: ".myDashboardsHeader>h1",
    content: ({ goTo, inDOM, close }) => (
      <div>
        The Dashboard you will create from this section will contain the key
        conversation topics so that you can find useful insights easily
        <br />
        <br />
        <Button
          type="primary"
          className="primary-background"
          onClick={() => hideTourGuide(HIDE_CREATION_TOUR, close)}
        >
          Don't show again
        </Button>
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
  {
    selector: ".platformSelector",
    content: ({ goTo, inDOM, close }) => (
      <div>
        Choose your data source (Customer file - Twitter - Google playstore)
        <br />
        <br />
        <Button
          type="primary"
          className="primary-background"
          onClick={() => hideTourGuide(HIDE_CREATION_TOUR, close)}
        >
          Don't show again
        </Button>
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
  {
    selector: ".ant-tabs-tabpane-active",
    content: ({ goTo, inDOM, close }) => (
      <div>
        Fill in your Dashboard creation details
        <br />
        <br />
        <Button
          type="primary"
          className="primary-background"
          onClick={() => hideTourGuide(HIDE_CREATION_TOUR, close)}
        >
          Don't show again
        </Button>
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
  {
    selector: ".create-footer",
    content: ({ goTo, inDOM, close }) => (
      <div>
        Click create and TADAAA!!! your Dashboard is created
        <br />
        <br />
        <Button
          type="primary"
          className="primary-background"
          onClick={() => hideTourGuide(HIDE_CREATION_TOUR, close)}
        >
          Don't show again
        </Button>
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
];

export const DashboardSteps1 = [
  {
    selector: ".columnHeaderTitle",
    content: ({ goTo, inDOM, close }) => (
      <div>
        Welcome to your Dashboard, you can edit here its name or share it with others
        <br />
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
  {
    selector: ".boardContent",
    content: ({ goTo, inDOM, close }) => (
      <div>
        Through this bubble chart you can discover your board Topics. Select any topic to see its information
        <br />
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
];

export const DashboardSteps2 = [
  {
    selector: ".selectedTopicInfo",
    content: ({ goTo, inDOM, close }) => (
      <div>
        Here are the details of the selected topic (Top words, Sentiment, Topic score ..etc)
        <br />
        <br />
        <Button
          type="primary"
          className="primary-background"
          onClick={() => hideTourGuide(HIDE_BOARD_TOUR, close)}
        >
          Don't show again
        </Button>
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
  {
    selector: ".editTopicLabel",
    content: ({ goTo, inDOM, close }) => (
      <div>
        You can edit the label of the selected topic
        <br />
        <br />
        <Button
          type="primary"
          className="primary-background"
          onClick={() => hideTourGuide(HIDE_BOARD_TOUR, close)}
        >
          Don't show again
        </Button>
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
  {
    selector: ".tweetContainer",
    content: ({ goTo, inDOM, close }) => (
      <div>
        You can see the analyzed documents here with their sentiment
        <br />
        <br />
        <Button
          type="primary"
          className="primary-background"
          onClick={() => hideTourGuide(HIDE_BOARD_TOUR, close)}
        >
          Don't show again
        </Button>
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
  {
    selector: ".selectedTopicGraphs",
    content: ({ goTo, inDOM, close }) => (
      <div>
        You can find the time-based graphs of the selected topic (sentiment and volumetric)
        <br />
        <br />
        <Button
          type="primary"
          className="primary-background"
          onClick={() => hideTourGuide(HIDE_BOARD_TOUR, close)}
        >
          Don't show again
        </Button>
      </div>
    ),
    action: (node) => {
      node.focus();
      node.style.filter = "brightness(1)";
    },
  },
];
