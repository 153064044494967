import React from "react";
import { Button, Modal, Popover } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import CustomInput from "components/General/CustomInput";
import styles from "./style.module.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Representation = ({
  showModal,
  setShowModal,
  setAccessToken,
  setAdToken,
  setAppSecret,
  setAppId,
  onSubmit,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();

  const ModalStyle = {
    padding: "45px",
    borderRadius: "15px",
    background: theme === "dark" ? "#1C193B" : "#ffffff",
    overflow: "hidden",
  };
  return (
    <Modal
      open={showModal}
      bodyStyle={ModalStyle}
      footer={null}
      onCancel={() => setShowModal(false)}
      style={{ overflow: "hidden", borderRadius: "15px", padding: "0px" }}
    >
      <CustomInput
        label={
          <div className={styles.title}>
            {t("settings.94")}
            <Popover content={t("settings.92")} trigger="hover">
              <InfoCircleFilled />
            </Popover>
          </div>
        }
        placeholder={t("settings.93")}
        onChange={setAppId}
        style={{ margin: "25px 0px" }}
      />
      <CustomInput
        label={t("settings.95")}
        placeholder={t("settings.96")}
        onChange={setAppSecret}
        style={{ margin: "25px 0px" }}
      />
      <CustomInput
        label={t("settings.97")}
        placeholder={t("settings.98")}
        onChange={setAccessToken}
        style={{ margin: "25px 0px" }}
      />
      <CustomInput
        label={t("settings.99")}
        placeholder={t("settings.100")}
        onChange={setAdToken}
        style={{ margin: "25px 0px" }}
      />
      <div className={styles.row}>
        <Button
          className={`${styles.btn} ${styles.outlined}`}
          onClick={() =>
            window.open(
              "https://www.notion.so/pivony/Facebook-and-Instagram-Ad-832153ce777d427fa9886b5e6ce1b14a",
              "_blank"
            )
          }
        >
          {" "}
          {t("settings.101")}{" "}
        </Button>
        <Button className={styles.btn} onClick={onSubmit}>
          {" "}
          {t("button.4")}
        </Button>
      </div>
    </Modal>
  );
};

export default Representation;
