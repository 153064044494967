export const plansArr = [
    {
        title: "Free",
        subtitle: "",
        pricing: {
            monthly: {
                price: {
                    usd: 0,
                    eur: 0,
                    gbp: 0,
                    tl: 0,
                },
                id: 1,
                paddleId: 878,
                expert: false,
            },
            yearly: {
                price: {
                    usd: 0,
                    eur: 0,
                    gbp: 0,
                    tl: 0,
                },
                id: 2,
                paddleId: 4578,
                expert: false,
            },
        },
        pros: [
            "File Upload - Customer Reviews",
            "App Reviews (Google Play)",
            "2 Dashboards",
            "5 Topics",
            "Access to the Basic features.",
            "1 Tier1 Dashboard included each month. (You can buy Tier1 or Tier2 Dashboards later on)",
            "Last 30 days of reviews",
        ],
        btnTxt: "Start Now",
        freeTrial: false,
        active: false,
        major: "Free Package",
        category: "default",
        free: true,
        planId: 1,
    },
    {
        title: "Basic",
        subtitle: "",
        pricing: {
            monthly: {
                price: {
                    usd: 390,
                    eur: 390,
                    gbp: 390,
                    tl: 6800,
                },
                id: 74,
                paddleId: 748245,
                expert: false,
            },
            yearly: {
                price: {
                    usd: 360,
                    eur: 360,
                    gbp: 360,
                    tl: 5800,
                },
                id: 75,
                paddleId: 748246,
                expert: false,
            },
        },
        pros: [
            "1 user",
            "5 Dashboards each month.",
            "2K post analysis per dashboard",
            "Social Media Basic, FB Page, FB & Instagram Ads, Forums, Apps",
            "TR and EN language AI analysis support",
            "10 Custom Topics",
            "No OKR integration",
            "Notifications via Slack integration"
        ],
        btnTxt: "Buy Now",
        freeTrial: false,
        active: true,
        major: "Basic Package",
        category: "default",
        planId: 2,
    },
    {
        title: "PRO",
        subtitle: "",
        pricing: {
            monthly: {
                price: {
                    usd: 520,
                    eur: 520,
                    gbp: 520,
                    tl: 9800,
                },
                id: 76,
                paddleId: 748247,
                expert: false,
            },
            yearly: {
                price: {
                    usd: 480,
                    eur: 480,
                    gbp: 480,
                    tl: 8800,
                },
                id: 77,
                paddleId: 748248,
                expert: false,
            },
        },
        pros: [
            "3 users",
            "10 dashboards each month",
            "5K posts analysis per dashboard",
            "Additionally Instagram Posts",
            "TR and EN language AI analysis support",
            "25 Custom Topics",
            "OKR integration",
            "Notifications via Slack integration"
        ],
        btnTxt: "Buy Now",
        freeTrial: false,
        active: true,
        major: "Pro Package",
        category: "default",
        planId: 3,
    },
    {
        title: "PRO PLUS",
        subtitle: "",
        pricing: {
            monthly: {
                price: {
                    usd: 750,
                    eur: 750,
                    gbp: 750,
                    tl: 14000,
                },
                id: 78,
                paddleId: 748249,
                expert: false,
            },
            yearly: {
                price: {
                    usd: 700,
                    eur: 700,
                    gbp: 700,
                    tl: 10000,
                },
                id: 79,
                paddleId: 748250,
                expert: false,
            },
        },
        pros: [
            "5 users",
            "20 dashboards each month",
            "10K posts analysis per dashboard",
            "Same Data Sources of Pro Plan",
            "Additionally, Google Translate",
            "50 Custom Topics",
            "OKR integration",
            "Notifications via Slack integration"
        ],
        btnTxt: "Buy Now",
        freeTrial: false,
        active: true,
        major: "PRO Plus Package",
        category: "default",
        planId: 4,
        tweets: {
            100000: 50000,
            500000: 125000,
            2000000: 400000
        }
    },
    {
        title: "Paribu",
        subtitle: "",
        pricing: {
            monthly: {
                price: {
                    usd: 150,
                    eur: 120,
                    gbp: 105,
                    tl: 1245,
                },
                id: 28,
                paddleId: 652787,
                expert: false,
            },
            yearly: {
                price: {
                    usd: 110,
                    eur: 90,
                    gbp: 75,
                    tl: 935,
                },
                id: 29,
                paddleId: 652788,
                expert: false,
            },
        },
        pros: [
            "File Upload - Customer Reviews",
            "App Reviews (Google Play and App Store)",
            "Support Service Tickets and Live Chat (Zendesk)",
            "Social Media Comments (Twitter and Instagram)",
        ],
        btnTxt: "Buy Now",
        freeTrial: false,
        active: false,
        major: "Paribu Package",
        category: "default",
        planId: 3,
    },
];

export const getPlans = () => {
    return plansArr.filter((plan) => plan.active);
};

export const filterByTypeInterval = (interval, type) => {
    return plansArr.filter(
        (plan) => plan.timeInterval === interval && plan.category === type
    );
};
export const getPlanByIntervalTypePlan = (interval, type, plan) => {
    for (let i = 0; i < plansArr.length; i++) {
        if (
            plansArr[i].timeInterval === interval &&
            plansArr[i].title.toLowerCase() === type &&
            plansArr[i].category === plan
        ) {
            return plansArr[i];
        }
    }
};

export const getPlanById = (planId) => {
    if (!planId) return {};
    for (let i = 0; i < plansArr.length; i++) {
        if (plansArr[i].planId === planId) {
            return plansArr[i];
        }
    }
};

export const findPackage = (packageId) => {
    for (let i = 0; i < plansArr.length; i++) {
        const len1 = Object.keys(plansArr[i].pricing).length;
        for (let j = 0; j < len1; j++) {
            const planWrapper =
                plansArr[i].pricing[Object.keys(plansArr[i].pricing)[j]];
            const len2 = Object.keys(planWrapper).length;
            for (let z = 0; z < len2; z++) {
                const planWrapper2 = planWrapper[Object.keys(planWrapper)[z]];
                const len3 = Object.keys(planWrapper2).length;
                for (let t = 0; t < len3; t++) {
                    const planWrapper3 =
                        planWrapper2[Object.keys(planWrapper2)[t]];
                    if (planWrapper3.id === packageId) {
                        return planWrapper3;
                    }
                }
            }
        }
    }
};

const recur = ["monthly", "yearly"];
const type = ["starter", "professional"];
const plan = ["customer", "social", "bundles"];

export const getPlanByUTM = (utmRecur, utmType, utmPlan) => {
    if (
        recur.includes(utmRecur) &&
        type.includes(utmType) &&
        plan.includes(utmPlan)
    ) {
        return getPlanByIntervalTypePlan(utmRecur, utmType, utmPlan);
    } else {
        return null;
    }
};
