import React from "react";
import styles from "./style.module.scss";
import { Select } from "antd";
const { Option } = Select;

const Representation = ({
  title,
  options,
  selectedOption,
  onChange,
  idKey,
  displayKey,
}) => (
  <div className={styles.row}>
    <p className={styles.label}>{title}</p>
    <div>
      <Select
        className={styles.dropdown}
        onChange={onChange}
        value={selectedOption}
        showSearch
        optionFilterProp="title"
      >
        <option style={{ display: "none" }} />
        {options?.map((option) => (
          <Option
            key={option[idKey]}
            value={option[idKey]}
            title={option[displayKey]}
          >
            {option[displayKey]}
          </Option>
        ))}
      </Select>
    </div>
  </div>
);

export default Representation;
