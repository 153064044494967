import React from "react";
import styles from "./style.module.scss";
const StackedBar = ({ data, colorMap, width }) => {
  // Calculate total sum of all steps
  const totalSum = Object.values(data).reduce(
    (acc, step) => acc + step.total,
    0
  );

  return (
    <div
      className={styles.stackedBar}
      style={{
        width: width ? `${width}%` : "100%",
        marginLeft: width ? `${(100 - width) / 2}%` : "0%",
      }}
    >
      {Object.entries(data).map(([stepKey, stepData]) => {
        const percentage = totalSum > 0 ? (stepData.total / totalSum) * 100 : 0;
        return (
          <div
            key={stepKey}
            className={styles.segment}
            style={{
              width: `${percentage}%`,
              backgroundColor: `${colorMap[stepKey]}`,
            }}
          >
            {percentage > 2 ? `${Math.round(percentage).toFixed(0)}%` : ""}
          </div>
        );
      })}
    </div>
  );
};

export default StackedBar;
