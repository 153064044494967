import React, { useEffect, useState } from "react";
import { notification } from "antd";
import config from "../Configurations.js";
import { getWithExpiry } from "util/common.js";
import { useSelector } from 'react-redux';
import history from "../history.js";
// import { Link } from "react-router-dom";

const AuthenticatedLanding = ({ user }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [packageId, setPackageId] = useState(-1);

  const isAppSumoBlackFridayAvaliable = true;
  const theme = useSelector(state => state.DashboardReducer.theme);

  useEffect(() => {
    getSubscriptions();
    history.push("/console/myDashboards")
  }, []);

  const getSubscriptions = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const token = getWithExpiry("jwt_token");

    if (token) {
      myHeaders.append("Authorization", "Bearer " + token);
    }

    var raw = JSON.stringify({ user_id: user.uid });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(config.BASE_URL + "subscriptions/all", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.error) {
          setSubscriptions(result);
          if (result && result.length > 0) {
            setPackageId(result[0].PackageId)
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  const plans = subscriptions.map((item) => {
    let title = "";
    if (item.AppId === 1) title = "Topic Scope";
    else if (item.AppId === 2) title = "Bee Competitor";
    else title = "Bee Audience";
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: window.innerWidth < 768 ? "column" : "row" }}>
        {isAppSumoBlackFridayAvaliable && packageId === 68 ?
          <div key={title} className="authLandingCard">
            <div className="authLandingCard-title">
              <img src="/assets/pivony-logo-blackfriday-removebg.png" alt="" />
            </div>
            <div className="authLandingCard-footer">
              <a href="/sentiment_intent_analyzer">Continue to app</a>
            </div>
          </div>
          :
          <div key={title} className={theme === 'brand' ? "authLandingCardBrand" : "authLandingCard"}>
            <div className="authLandingCard-title">
              <img src="/assets/pivony-logo.png" alt="" />
            </div>
            <div className="authLandingCard-footer">
              <a href="/console/myDashboards">Continue to app</a>
            </div>
          </div>
        }
      </div>
    );
  });
  return (
    <div className={`${theme === 'light' ? "light" : theme === "brand" ? "brand" : ""} Landing`}>
      <div className="landingHeader">
        <h1>High Performance Text Analytics Technology</h1>
      </div>
      <div className="boardsContainer">
        <div className="boardsContainerContent">{plans}</div>
      </div>
    </div>
  );
};

export default AuthenticatedLanding;
