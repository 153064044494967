import config from "../Configurations";
import axios from "axios";
import firebase, { provider } from "../FirebaseConfig.js";
import { getWithExpiry } from "./common";
import history from "../history";

export const apiRequest = (
  url,
  method,
  payload,
  getFullResult,
  signal,
  pre_auth_check = false
) => {
  return new Promise((resolve) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Access-Control-Allow-Origin", config.BASE_URL);

    // modified version of getWithExpiry
    const tokenStr = window.localStorage.getItem("jwt_token");
    let tokenExpired = false;
    let token;

    if (tokenStr) {
      const item = JSON.parse(tokenStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        window.localStorage.removeItem("jwt_token");
        tokenExpired = true;
      } else {
        token = item.value;
      }
    }

    if (tokenExpired && !pre_auth_check) {
      firebase
        .auth()
        .signOut()
        .then(
          () => {
            window.localStorage.removeItem("selectedDashboard");
            document.location.href = document.location.origin + "/login";
            return;
          },
          function(error) {
            console.error("Sign Out Error", error);
          }
        );
    }

    if (token && firebase.auth().currentUser != null) {
      myHeaders.append("Authorization", "Bearer " + token);
    }

    var requestOptions = {
      method: method,
      headers: myHeaders,
      redirect: "follow",
    };

    if (payload) {
      requestOptions.body = JSON.stringify(payload);
    }

    if (signal) {
      requestOptions.signal = signal;
    }

    fetch(config.BASE_URL + url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (!result.error || getFullResult) {
          resolve(result);
        } else {
          resolve(false);
        }
      })
      .catch((error) => {
        console.log(`Error in API ${url}`, error);
        resolve({ cancel: true });
      });
  });
};

export const uploadFileToS3 = (presignedPostData, file, setProgress) => {
  return new Promise((resolve) => {
    // create a form obj
    const formData = new FormData();

    // append the fields in presignedPostData in formData
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });

    // append the file
    formData.append("file", file);

    // post the data on the s3 url
    axios
      .post(presignedPostData.url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      })
      .then(function(response) {
        resolve(true);
      })
      .catch(function(error) {
        resolve(false);
        logError("Uploading Files to S3", error);
      });
  });
};

export const authWithGmail = () => {
  return new Promise((resolve) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        // var token = result.credential.accessToken;
        var user = result.user;
        resolve(user);
      })
      .catch(function(error) {
        logError("Gmail Auth", error);
        resolve(false);
      });
  });
};

export const signout = (redirect = true) => {
  firebase
    .auth()
    .signOut()
    .then(
      () => {
        window.localStorage.removeItem("selectedDashboard");
        history.push("/login");
        // if (redirect) document.location.href = document.location.origin + "/";
      },
      function(error) {
        console.error("Sign Out Error", error);
      }
    );
};

export const logoutAllUsers = () => {
  firebase
    .database()
    .ref(`/users`)
    .once("value", async (snapshot) => {
      let users = snapshot.val();
      for (let key in users) {
        await firebase
          .database()
          .ref(`/users/${key}/mandatoryLogout`)
          .set(true);
      }
    });
};

export const logError = (location, error, extra = "") => {};
