import React from "react";
import CustomInput from "components/General/CustomInput";
import { Modal, Dropdown, MultiSelect, DatePicker, TextArea } from "components/General/ModalFormComponents";
import styles from "./style.module.scss";
import i18n from 'i18next';

const Representation = ({
  showModal,
  selectedBoard,
  setSelectedBoard,
  boards,
  selectedBoardList,
  setSelectedBoardList,
  boardList,
  boardMembersList,
  setSelectedBoardMembers,
  onClose,
  setTitle,
  date,
  setDate,
  setDescription,
  showSaveButton,
  save,
  theme
}) => (
  <Modal
    showModal={showModal}
    onClose={onClose}
    onSave={save}
    showSaveButton={showSaveButton}
    titleAsset={"/assets/settings/trello-color.png"}
    title="Trello"
  >
    <Dropdown
      title={`${i18n.t("trello_task.2")} *`}
      options={boards}
      selectedOption={selectedBoard}
      onChange={(value) => setSelectedBoard(value)}
      idKey="id"
      displayKey="name"
    />
    {selectedBoard &&
      <Dropdown
        title={`${i18n.t("trello_task.3")} *`}
        options={boardList}
        selectedOption={selectedBoardList}
        onChange={(value) => setSelectedBoardList(value)}
        idKey="id"
        displayKey="name"
      />}
    {selectedBoard && selectedBoardList &&
      <>
        <MultiSelect
          title={i18n.t("trello_task.4")}
          list={boardMembersList.map(elem => elem.member)}
          onChange={(value) => setSelectedBoardMembers(value)}
          idKey="id"
          displayKey="fullName"
        />
        <DatePicker
          title={`${i18n.t("trello_task.5")} *`}
          value={date}
          onChange={(date) => setDate(date)}
        />
        <div className={styles.row}>
          <p className={styles.label}>{i18n.t("trello_task.6")} *</p>
          <CustomInput
            style={{ margin: "-10px 0px 0px 0px", width: "300px", borderRadius: "10px" }}
            onChange={setTitle}
          />
        </div>
        <TextArea
          title={`${i18n.t("trello_task.7")} *`}
          onChange={(e) => setDescription(e.target.value)}
        />
      </>
    }
  </Modal>
)

export default Representation;