import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import { Select } from "antd";
import SortPopup from "helperComponents/SortPopup";
import { useSelector } from "react-redux";
import { apiRequest } from "util/services";
import Listing from "./Listing/Listing";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Button } from "antd";
const { Option } = Select;

const Survery = () => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  useEffect(() => {
    fetchListing();
  }, []);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showRowItems, setShowRowItems] = useState(true);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [sortValue, setSortValue] = useState();
  const [surveyList, setsurveyList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [surveyType, setSurveyType] = useState("All");
  const [searchWord, setSearchWord] = useState("");
  const [totalSurveys, settotalSurveys] = useState();
  const [surveryPageNumber, setsurveryPageNumber] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedPerPage, setSelectedPerPage] = useState(10);
  const [hasNextPage, setHasNextPage] = useState(true);
  const isMounted = useRef(false);
  const { t } = useTranslation();

  const handlePageChange = () => {
    let end_index = surveyList.length;
    if (isMounted.current) {
      if (selectedPerPage * surveryPageNumber < end_index) {
        end_index = selectedPerPage * surveryPageNumber;
        setHasNextPage(true);
      } else {
        setHasNextPage(false);
      }
    } else {
      isMounted.current = true;
    }

    let temp = surveyList.slice(startIndex * selectedPerPage, end_index);
    setFilteredList(temp);
  };

  useEffect(() => {
    handlePageChange();
  }, [surveryPageNumber]);
  const handleUserPage = () => {};

  useEffect(() => {
    setStartIndex(0);
    let list = surveyList;
    let temp = list?.slice(
      0,
      selectedPerPage > surveyList.length ? surveyList.length : selectedPerPage
    );
    setFilteredList(temp);
    settotalSurveys(surveyList?.length);
  }, [surveyList]);

  const handleShowSearch = () => {
    if (window.innerWidth < 880) {
      setShowRowItems(false);
    }
    setShowSearchBox(!showSearchBox);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (searchWord.trim()) {
        let list = surveyList.filter((item) => {
          if (item.survey_name.search(searchWord) !== -1) {
            return true;
          } else return false;
        });
        setFilteredList(list);
        // startIndex ? setStartIndex(0) : getAllDashboards()
        // setDashboardPageNumber(1)
      } else {
        setFilteredList(surveyList);
      }
    }
  };
  const fetchListing = async () => {
    setLoading(true);
    const result = await apiRequest(
      "topic_tracker/get_topic_tracker",
      "POST",
      undefined
    );
    if (!result.error) {
      setsurveyList(result.data);
      if (selectedPerPage > result?.data?.length) {
        setHasNextPage(false);
        setsurveryPageNumber(1);
      }
      setLoading(false);
    } else {
    }
  };

  const sortList = () => {
    if (sortValue == "newest") {
      let list = surveyList.sort((b, a) => {
        return dayjs(a.last_refreshed_date).diff(dayjs(b.last_refreshed_date));
      });
      setFilteredList(list);
    }
    if (sortValue == "oldest") {
      let list = surveyList.sort((a, b) => {
        return dayjs(a.last_refreshed_date).diff(dayjs(b.last_refreshed_date));
      });
      setFilteredList(list);
    } else if (sortValue === "az") {
      let list = surveyList.sort((a, b) => {
        if (a.survey_name < b.survey_name) {
          return -1;
        }
        if (a.survey_name > b.survey_name) {
          return 1;
        }
        return 0;
      });
      setFilteredList(list);
    } else if (sortValue === "za") {
      let list = surveyList.sort((b, a) => {
        if (a.survey_name < b.survey_name) {
          return -1;
        }
        if (a.survey_name > b.survey_name) {
          return 1;
        }
        return 0;
      });
      setFilteredList(list);
    }
    setShowSortPopup(false);
  };

  useEffect(() => {
    setAnalyzedSurveys();
  }, [surveyType]);

  const setAnalyzedSurveys = () => {
    if (surveyType === "Analyzed") {
      let list = surveyList.filter((item) => {
        if (item.status == 2) {
          return true;
        } else {
          return false;
        }
      });
      setFilteredList(list);
    } else {
      setFilteredList(surveyList);
    }
  };

  useEffect(() => {
    sortList();
  }, [sortValue]);

  const handleBackward = async () => {
    setShowRowItems(true);
    setSearchWord("");
  };
  return (
    <div className={styles.mainWindow}>
      <div className={styles.header}>
        <div className={styles.content}>
          <p>{t("dashboard_sidebar.10")}</p>
          <p className={styles.description}>{t("followed_topics.2")}</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.firstRow}>
          {!showRowItems && (
            <>
              <div
                className={`${styles.searchBoxSmall} ${showSearchBox &&
                  styles.showSearch} `}
              >
                <i
                  onClick={() => {
                    handleBackward();
                  }}
                  className="fa-solid fa-arrow-left"
                ></i>
                <input
                  type="text"
                  placeholder={t("placeholder.1")}
                  value={searchWord}
                  onChange={(e) => setSearchWord(e.target.value)}
                  onKeyPress={handleSearch}
                />
                <button>{t("button.5")}</button>
              </div>
            </>
          )}
          {showRowItems && (
            <>
              {" "}
              <div className={styles.selectors}></div>
              <div className={styles.actionBtnBlock}>
                <button className={styles.search} onClick={handleShowSearch}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/search.svg"
                        : "/assets/search_black.svg"
                    }
                    alt=""
                  />
                </button>
                <img
                  src={
                    theme === "dark"
                      ? "/assets/sort_icon.svg"
                      : "/assets/sort_icon_black.svg"
                  }
                  onClick={() => {
                    setShowSortPopup(true);
                  }}
                  alt=""
                  className={styles.sort}
                />
                {showSortPopup && (
                  <SortPopup
                    setSortValue={setSortValue}
                    sortValue={sortValue}
                    setShowSortPopup={setShowSortPopup}
                  />
                )}
                {window.innerWidth > 880 && (
                  <div
                    className={`${styles.searchBox} ${showSearchBox &&
                      styles.showSearch} `}
                  >
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/search.svg"
                          : "/assets/search_black.svg"
                      }
                      alt=""
                    />
                    <input
                      type="text"
                      onKeyPress={handleSearch}
                      value={searchWord}
                      onChange={(e) => setSearchWord(e.target.value)}
                      placeholder={t("placeholder.13")}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {loading ? (
          <Spin spinning={loading}></Spin>
        ) : filteredList?.length == 0 ? (
          <div className={styles.emptyList}>
            <div className={styles.header}>
              <div className={styles.description}>
                {t("followed_topics.1")}
                <img
                  width={24}
                  src={
                    theme === "dark"
                      ? "/assets/follow_filled.svg"
                      : "/assets/follow_filled_black.svg"
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        ) : (
          <Listing fetchList={fetchListing} list={filteredList} />
        )}
        {!loading && filteredList.length > 0 ? (
          <div className={styles.paginationWrapper}>
            <div className={styles.innerWrapper}>
              {
                <div className={styles.paginationBox}>
                  <Button
                    disabled={startIndex === 0}
                    onClick={() => {
                      parseInt(surveryPageNumber, 10) === startIndex + 1 &&
                        setStartIndex((prev) => prev - 1);
                      parseInt(surveryPageNumber, 10) === startIndex + 1 &&
                        setsurveryPageNumber((prev) => parseInt(prev, 10) - 1);
                    }}
                  >
                    <i className="fa fa-angle-left" />
                  </Button>
                  <span className={styles.pagination}>
                    <input
                      className={styles.pageIndex}
                      onKeyPress={handleUserPage}
                      type="text"
                      value={surveryPageNumber}
                      onChange={(e) => setsurveryPageNumber(e.target.value)}
                    />
                    {totalSurveys
                      ? ` / ${Math.ceil(totalSurveys / selectedPerPage)}`
                      : ""}
                  </span>
                  <Button
                    disabled={!hasNextPage}
                    onClick={() => {
                      parseInt(surveryPageNumber, 10) === startIndex + 1 &&
                        setStartIndex((prev) => prev + 1);
                      parseInt(surveryPageNumber, 10) === startIndex + 1 &&
                        setsurveryPageNumber((prev) => parseInt(prev, 10) + 1);
                    }}
                  >
                    <i className="fa fa-angle-right" />
                  </Button>
                </div>
              }

              {surveyList?.length > 0 && (
                <Select
                  className="review-page-number-selector"
                  value={selectedPerPage + ` / ${t("label.10")}`}
                  onChange={handlePageChange}
                  style={{ backgroundColor: "transparent" }}
                >
                  <Option value={10}>10</Option>
                  <Option
                    disabled={totalSurveys <= 10 ? true : false}
                    value={25}
                  >
                    25
                  </Option>
                  <Option
                    disabled={totalSurveys <= 25 ? true : false}
                    value={50}
                  >
                    50
                  </Option>
                  <Option
                    disabled={totalSurveys <= 50 ? true : false}
                    value={100}
                  >
                    100
                  </Option>
                </Select>
              )}
            </div>
            {totalSurveys > 0 && (
              <div className={styles.dashboardsTotal}>
                {selectedPerPage * startIndex +
                  filteredList?.length +
                  " of " +
                  totalSurveys}
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default Survery;
