import React, {useState, useEffect} from "react";
import styles from './style.module.scss'
const RangeBar = ({ min = 0, max = 100, value }) => {
    // Calculate the percentage of the value relative to the min and max
    const percentage = ((value - min) / (max - min)) * 100;
    // Determine the position and alignment of the value label
    const labelPosition = percentage > 50 ? "left" : "right";

    return (
        <div className={styles.mainBar}>
            {labelPosition === 'left' ?
                <>
                    {value&&<div className={styles.verticalRightLine} style={{ left: value>=98?`${percentage-3}%`:`${percentage}%` }}></div>}
                    <span className={styles.label} style={{ left: value>=98?`${percentage-28}%`:`${percentage-20}%`}} >{value}</span>
                </>
                :
                <>
                    {value!==""&&<div className={styles.verticalLeftLine} style={{ left: `${percentage}%`}}></div>}
                    <span className={styles.label} style={{ left: `${percentage+6}%`}} >{value}</span>
                </>
            }
        </div>
    );
  };
  

export default RangeBar;
