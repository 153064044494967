import React, { useState, useEffect } from "react";
import { Checkbox, Table, ConfigProvider } from "antd";
import { RightOutlined, DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import styles from "./style.module.scss";
const NestedDataSelection = ({
  options,
  selectedOptions,
  setSelectedOptions,
  resetPopup,
  columnHeaders,
  dataLoading,
  emptyDataContent,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);

  const getAllNestedKeys = (option) => {
    const keys = [];
    const gatherKeys = (option) => {
      if (option.child && option.child.length > 0) {
        option.child.forEach((child) => {
          keys.push({ key: child.key, ...child });
          gatherKeys(child);
        });
      }
    };
    gatherKeys(option);
    return keys;
  };
  const handleSelection = (selected, allChildKeys = []) => {
    let updatedSelection = [...selectedOptions];
    const isSelected = updatedSelection.some(
      (option) => option.key === selected.key
    );

    if (isSelected) {
      updatedSelection = updatedSelection.filter(
        (option) => option.key !== selected.key
      );
      updatedSelection = updatedSelection.filter(
        (option) => !allChildKeys.some((child) => child.key === option.key)
      );
    } else {
      updatedSelection.push({ key: selected.key, ...selected });
      allChildKeys.forEach((child) => {
        if (!updatedSelection.some((option) => option.key === child.key)) {
          updatedSelection.push({ key: child.key, ...child });
        }
      });
    }
    setSelectedOptions(updatedSelection);
  };

  const isParentIndeterminate = (parentKey) => {
    const childrenKeys = getAllNestedKeys({
      key: parentKey,
      child: options.find((opt) => opt.key === parentKey)?.child,
    }).map((child) => child.key);

    const selectedChildrenCount = selectedOptions.filter((opt) =>
      childrenKeys.includes(opt.key)
    ).length;

    if (
      selectedChildrenCount > 0 &&
      selectedChildrenCount < childrenKeys.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  const countOptions = (options) => {
    let count = 0;
    const recursiveCounter = (options) => {
      options.forEach((option) => {
        count++;
        if (option.child && option.child.length > 0) {
          recursiveCounter(option.child);
        }
      });
    };
    recursiveCounter(options);
    return count;
  };
  const handleSelectAll = (type, options) => {
    let updatedSelection = [];
    if (type === "all") {
      const recursiveSelectAll = (options) => {
        options.forEach((option) => {
          updatedSelection.push({ key: option.key, ...option });
          if (option.child && option.child.length > 0) {
            recursiveSelectAll(option.child);
          }
        });
      };
      recursiveSelectAll(options);
    } else if (type === "none") {
      updatedSelection = [];
    }
    setSelectedOptions(updatedSelection);
  };
  const columns = columnHeaders.map((col, i) => ({
    title: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          color: theme === "dark" ? "#fff" : "#000",
        }}
      >
        {i === 0 && (
          <Checkbox
            checked={selectedOptions.length === countOptions(options)}
            indeterminate={
              selectedOptions.length > 0 &&
              selectedOptions.length < countOptions(options)
            }
            style={{ marginRight: "2rem" }}
            onChange={() => {
              selectedOptions.length === 0
                ? handleSelectAll("all", options)
                : handleSelectAll("none", options);
            }}
          />
        )}
        {col.title}
      </div>
    ),
    dataIndex: `col${i + 1}`,
    key: `col${i + 1}`,
    align: col.align,
    render: (text, record) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          paddingLeft: record.level > 0 ? record.level * 40 : 0,
          color: theme === "dark" ? "#fff" : "#000",
        }}
      >
        <Checkbox
          checked={selectedOptions.some((option) => option.key === record.key)}
          indeterminate={isParentIndeterminate(record.key)}
          onChange={() => {
            handleSelection(record, getAllNestedKeys(record));
          }}
          style={{ marginRight: 8 }}
        />
        {text}
      </div>
    ),
  }));

  const expandCollapseIcons = (props) => {
    if (!props.record.child || props.record.child.length === 0) {
      return null;
    }
    if (props.expanded) {
      return (
        <DownOutlined
          style={{ color: theme === "dark" ? "#fff" : "#000" }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    } else {
      return (
        <RightOutlined
          style={{ color: theme === "dark" ? "#fff" : "#000" }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    }
  };
  return (
    <div className={styles.nestedDataSelection}>
      {!dataLoading && options.length === 0 ? (
        emptyDataContent
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerSplitColor: "transparent",

                borderColor: "transparent",
              },
            },
          }}
        >
          <Table
            columns={columns}
            rowSelection={null}
            dataSource={options}
            childrenColumnName="child"
            indentSize={50}
            pagination={false}
            expandIcon={expandCollapseIcons}
          />
        </ConfigProvider>
      )}
    </div>
  );
};

export default NestedDataSelection;
