import React, { useState, useEffect } from "react";
import { Table, Checkbox, Radio, Spin, ConfigProvider } from "antd";

const DataSelection = ({
  options,
  selectedOptions,
  setSelectedOptions,
  resetPopup,
  columnHeaders,
  selectionType,
  dataLoading,
  emptyDataContent,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (selectionType === "multiple") {
      setSelectAll(selectedOptions?.length === options?.length);
    }
  }, [selectedOptions, options, selectionType]);

  useEffect(() => {
    if (selectionType === "multiple") {
      setSelectAll(
        selectedOptions?.length === options?.length ||
          (selectedOptions?.length > 0 &&
            selectedOptions?.length < options?.length)
      );
    }
  }, [selectedOptions, options, selectionType]);

  const handleSelection = (selected) => {
    if (selectionType === "single") {
      setSelectedOptions(selected.key);
    } else {
      const updatedSelection = [...selectedOptions];
      const index = updatedSelection.findIndex(
        (option) => option?.key === selected?.key
      );
      if (index === -1) {
        updatedSelection.push(selected);
      } else {
        updatedSelection.splice(index, 1);
      }
      setSelectedOptions(updatedSelection);
    }
  };
  const handleSelectAll = () => {
    if (selectionType === "multiple") {
      const newSelectAll = !(
        selectedOptions?.length === options?.length ||
        (selectedOptions?.length > 0 &&
          selectedOptions?.length < options?.length)
      );
      setSelectAll(newSelectAll);
      const selected = newSelectAll
        ? options?.map((option) => ({
            key: option.key,
            ...columnHeaders.reduce((acc, { title }, index) => {
              acc[`col${index + 1}`] = option[`col${index + 1}`];
              return acc;
            }, {}),
          }))
        : [];
      setSelectedOptions(selected);
    }
  };

  const columns = [
    selectionType === "multiple" && {
      title: (
        <Checkbox
          checked={selectAll}
          indeterminate={
            selectedOptions?.length > 0 &&
            selectedOptions?.length < options?.length
          }
          onChange={handleSelectAll}
        />
      ),
      key: "checkbox",
      align: "center",
      render: (record) => (
        <Checkbox
          checked={selectedOptions?.some(
            (option) => option?.key === record?.key
          )}
          onChange={() => handleSelection(record)}
        />
      ),
    },
    selectionType === "single" && {
      title: "",
      key: "radio",
      align: "center",
      render: (record) => (
        <Radio
          checked={selectedOptions === record?.key}
          onChange={() => handleSelection(record)}
        />
      ),
    },
    ...columnHeaders?.map((col, i) => ({
      title: col?.title,
      dataIndex: `col${i + 1}`,
      key: `col${i + 1}`,
      align: col?.align,
    })),
  ].filter(Boolean); // Filter out the `false` value from columns when selectionType is 'single'

  const data = options?.map((option) => ({
    key: option.key,
    ...columnHeaders.reduce((acc, { title }, index) => {
      acc[`col${index + 1}`] = option[`col${index + 1}`];
      return acc;
    }, {}),
  }));
  return (
    <div>
      {!dataLoading && data.length === 0 ? (
        emptyDataContent
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                headerSplitColor: "transparent",
                borderColor: "transparent",
              },
            },
          }}
        >
          <Table
            loading={dataLoading}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </ConfigProvider>
      )}
    </div>
  );
};

export default DataSelection;
