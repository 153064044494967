import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/newDashboard/actions.js";
import { Button, notification } from "antd";
import steps from "pages/NewDashboard/steps";
import stepsTur from "pages/NewDashboard/stepsTur";
import CreationItem from "components/NewDashboard/CreationItem";
import { getParams, verifyDates } from "util/common";
import { apiRequest } from "util/services";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { Modal } from "components/General/ModalFormComponents";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const StepsWrapper = ({
  user,
  advancedAllowed,
  trial,
  facebookPageObjects = [],
  facebookAdObjects = [],
  instagramAccounts = [],
  forums = [],
  linkedinPages = [],
  facebookPageObjectsLoading,
  facebookAdObjectsLoading,
  instagramAccountsLoading,
  forumsLoading,
  linkedPagesLoading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [localState, setState] = useState({
    num_iters: 100,
  });
  const [columnNames, setColumnNames] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [missingDateModalOpen, setMissingDateModalOpen] = useState(false);
  const [showDateWarning, setShowDateWarning] = useState(false);

  const newDashboardReducer = useSelector((state) => state.newDashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const step = newDashboardReducer.currentStep;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setState({ ...localState, product_links: [], category_links: [] });
  }, [localState.ecommerceCreationLinkType]);

  if (!step) return <div />;

  let info =
    i18n.language === "en"
      ? steps[newDashboardReducer.platform][newDashboardReducer.creationType]
      : stepsTur[newDashboardReducer.platform][
          newDashboardReducer.creationType
        ];
  const totalSteps = Object.values(info).length;
  info = info[`step${step}`];
  if (newDashboardReducer.platform === 9 && step === 2) {
    info[0].acceptedValues = facebookPageObjects.map(({ id, name }) => ({
      value: id,
      text: name,
    }));
  } else if (newDashboardReducer.platform === 10 && step === 2) {
    info[0].acceptedValues = facebookAdObjects.map(({ account_id }) => ({
      value: account_id,
      text: account_id,
    }));
  }

  const creationChecker = () => {
    const {
      num_topics,
      num_iters,
      app_url,
      beta,
      customerDataFileS3Link,
      customerFeedbackTextIndex,
      start_date,
      end_date,
      geolocation,
      city,
    } = localState;
    const { platform, creationType } = newDashboardReducer;
    if (num_topics < 10 || num_topics > 100) {
      notification.error({
        message: t("notifications.9"),
      });
      return false;
    }
    if (num_iters < 20 || num_iters > 1000) {
      notification.error({
        message: t("notifications.10"),
      });
      return false;
    }
    if ((platform === 2 || platform === 4) && !app_url) {
      notification.error({
        message: t("notifications.11"),
      });
      return false;
    }
    if (beta && beta < 0) {
      notification.error({
        message: t("notifications.12"),
      });
      return false;
    }
    if (creationType === 3 && !customerDataFileS3Link) {
      notification.error({ message: t("notifications.13") });
      return false;
    }
    if (creationType === 3 && !customerFeedbackTextIndex) {
      notification.error({
        message: t("notifications.14"),
      });
      return false;
    }
    let dateVerification = verifyDates(start_date, end_date);

    if (!dateVerification.check) {
      notification.error({
        message: dateVerification.msg,
      });
      return false;
    }
    return true;
  };

  const createDashboard = async () => {
    const {
      app_url,
      google_play_app_url,
      app_store_app_url,
      ecommerceCreationLinkType,
    } = localState;
    const { platform, creationType, industry_id } = newDashboardReducer;
    if (!creationChecker()) return;

    setLoading(true);

    let payload = {
      ...localState,
      platform_id: platform,
      user_id: user.uid,
      isCategory: ecommerceCreationLinkType === "product_links" ? false : true,
      industry_id,
    };
    let api = "dashboards/create/advanced";
    if (creationType === "simple") {
      api = "dashboards/create/simple";
      delete payload.num_iters;
    }
    if (platform === 2) {
      const playstore_id = getParams(app_url).id;
      if (!playstore_id) {
        notification.error({
          message: t("notifications.15"),
        });
        setLoading(false);
        return;
      }
      delete payload.app_url;
      payload.playstore_id = playstore_id;
    } else if (platform === 4) {
      const splittedURL = app_url ? app_url.split("/") : "";
      const app_name =
        splittedURL && splittedURL.length >= 6 ? splittedURL[5] : "";
      if (!app_name) {
        notification.error({
          message: t("notifications.16"),
        });
        setLoading(false);
        return;
      }
      delete payload.app_url;
      payload.app_name = app_name;
    } else if (platform === 11) {
      const play_store_id = getParams(google_play_app_url).id;
      if (
        (localState.platforms || []).indexOf("Google Play") !== -1 &&
        !play_store_id
      ) {
        notification.error({
          message: t("notifications.15"),
        });
        setLoading(false);
        return;
      }
      delete payload.google_play_app_url;
      payload.play_store_id = play_store_id;

      const splittedURL = app_store_app_url ? app_store_app_url.split("/") : "";
      const app_store_app_name =
        splittedURL && splittedURL.length >= 6 ? splittedURL[5] : "";
      const chunk =
        splittedURL && splittedURL.length >= 6 ? splittedURL[6] : "";
      const app_store_app_id =
        chunk && chunk.length >= 3 ? chunk.split("?")[0] : "";

      if (
        (localState.platforms || []).indexOf("App Store") !== -1 &&
        !app_store_app_name
      ) {
        notification.error({
          message: t("notifications.16"),
        });
        setLoading(false);
        return;
      }
      if (
        (localState.platforms || []).indexOf("App Store") !== -1 &&
        !app_store_app_id
      ) {
        notification.error({
          message: t("notifications.16"),
        });
        setLoading(false);
        return;
      }
      delete payload.app_store_app_url;
      payload.app_store_app_name = app_store_app_name;
      payload.app_store_app_id = app_store_app_id;
    }

    for (let key in payload) {
      if (!payload[key]) delete payload[key];
    }

    if (payload["geolocation"] === "true") {
      payload["geolocation"] = true;
    } else {
      payload["geolocation"] = false;
    }

    if (payload["language"] !== "Turkish") {
      delete payload["city"];
      delete payload["district"];
      payload["geolocation"] = false;
    }
    if (payload["npsScale"]) {
      const scaleWithText = payload["npsScale"];
      const scale = scaleWithText
        .split(" ")
        .slice(0, 3)
        .join(" ");
      payload["npsScale"] = scale;
    }
    const result = await apiRequest(api, "POST", payload, true);
    if (!result.error) {
      mixpanel.track("CREATE_Dashboard", { DASHBOARD_ID: result.success });
      notification.success({ message: t("notifications.17") });
      setState({});
      dispatch({
        type: actions.UPDATE_NEW_Dashboard,
        payload: {
          currentStep: -1,
        },
      });
      history.push("/console/myDashboards");
    } else {
      notification.error({
        message: result.error,
      });
    }
    setLoading(false);
  };

  const updateStep = (increment, warning = true) => {
    const { platform, creationType, industry_id } = newDashboardReducer;

    try {
      if (step == 1 && increment !== -1) {
        let StepInputs =
          steps[newDashboardReducer.platform][creationType][`step${step}`];
        let flag = false;
        if (StepInputs.length) {
          StepInputs.forEach((stepInfo) => {
            if (
              stepInfo["key"] == "start_date" ||
              stepInfo["key"] == "end_date"
            ) {
              flag = true;
            }
          });
        }
        if (flag) {
          if (
            localState["start_date"] === undefined ||
            localState["end_date"] === undefined
          ) {
            notification.info({
              message:
                "Without specifying a date, a dashboard will be created for last month",
              icon: (
                <InfoCircleOutlined
                  style={{ color: theme === "dark" ? "white" : "black" }}
                />
              ),
            });
          } else if (
            verifyDates(localState["start_date"], localState["end_date"])
          ) {
            let diff = dayjs(localState["end_date"]).diff(
              localState["start_date"],
              "days"
            );
            if (diff < 30) {
              if (warning) {
                setShowDateWarning(true);
                return;
              }
            }
          }
        }
      }
    } catch (err) {
      console.error("Something Went wrong");
    }

    if (increment === 1) {
      for (let i = 0; i < info.length; i++) {
        if (info[i].required && !localState[info[i].key]) {
          notification.info({
            message: t("notifications.18"),
            icon: (
              <InfoCircleOutlined
                style={{ color: theme === "dark" ? "white" : "black" }}
              />
            ),
          });
          return;
        }
        if (info[i].title === "Number Of Topics") {
          const { num_topics } = localState;
          if (num_topics < 10 || num_topics > 100) {
            notification.error({
              message: t("notifications.9"),
            });
            return;
          }
        }
        if (info[i].title === "Platforms") {
          const { platforms } = localState;
          if (platforms.length === 0) {
            notification.error({
              message: t("notifications.19"),
            });
            return;
          }
        }
      }
    }
    if (step === totalSteps && increment === 1) {
      setShowConfirmationModal(true);
    } else {
      dispatch({
        type: actions.UPDATE_NEW_Dashboard,
        payload: {
          currentStep: newDashboardReducer.currentStep + increment,
        },
      });
    }
  };

  return (
    <>
      <div className={styles.container} id="datasource_section">
        <div className={styles.header}>
          <h5>
            {t("new_dashboard.11")} {step}/{totalSteps}
          </h5>
        </div>
        <div className={styles.dynamicContent}>
          {info?.map((item) => {
            if (
              newDashboardReducer.platform === 11 &&
              step === 2 &&
              (((localState.platforms || []).indexOf("App Store") === -1 &&
                item.key === "app_store_app_url") ||
                ((localState.platforms || []).indexOf("Google Play") === -1 &&
                  item.key === "google_play_app_url"))
            ) {
              return null;
            }
            if (
              newDashboardReducer.platform === 12 &&
              step === 3 &&
              (localState.platforms || []).indexOf(
                "Instagram Hashtag Search"
              ) === -1
            ) {
              return null;
            }

            return (
              <CreationItem
                item={item}
                setState={setState}
                localState={localState}
                columnNames={columnNames}
                setColumnNames={setColumnNames}
                defaultValue={localState[item.key]}
                user={user}
              />
            );
          })}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf("Twitter") !== -1 && (
              <>
                <CreationItem
                  item={{
                    title: t("new_dashboard.16"),
                    subtitle: t("new_dashboard.53"),
                    valueType: "multiInput",
                    key: "any_keywords",
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["any_keywords"]}
                  user={user}
                />
                <CreationItem
                  item={{
                    title: t("new_dashboard.17"),
                    subtitle: t("new_dashboard.54"),
                    valueType: "multiInput",
                    key: "all_keywords",
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["all_keywords"]}
                  user={user}
                />
                <CreationItem
                  item={{
                    title: t("new_dashboard.18"),
                    subtitle: t("new_dashboard.19"),
                    valueType: "multiInput",
                    key: "excluded_keywords",
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["excluded_keywords"]}
                  user={user}
                />
                <CreationItem
                  item={{
                    title: t("new_dashboard.20"),
                    subtitle: t("new_dashboard.55"),
                    valueType: "multiInput",
                    key: "banned_accounts",
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["banned_accounts"]}
                  user={user}
                />
              </>
            )}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf("Reddit") !== -1 && (
              <>
                <CreationItem
                  item={{
                    title: t("new_dashboard.21"),
                    subtitle: t("new_dashboard.22"),
                    valueType: "input",
                    placeholder: t("new_dashboard.23"),
                    key: "reddit_search_term",
                    required: true,
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["reddit_search_term"]}
                  user={user}
                />
              </>
            )}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf("Ekşi Sözlük") !== -1 && (
              <>
                <CreationItem
                  item={{
                    title: t("new_dashboard.24"),
                    subtitle: t("new_dashboard.25"),
                    valueType: "input",
                    placeholder: t("new_dashboard.23"),
                    key: "eksisozluk_search_term",
                    required: true,
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["eksisozluk_search_term"]}
                  user={user}
                />
              </>
            )}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf(
              "Facebook and Instagram ads"
            ) !== -1 && (
              <>
                <CreationItem
                  item={{
                    title: t("new_dashboard.26"),
                    subtitle: t("new_dashboard.56"),
                    valueType: "dropdown_2",
                    acceptedValues: facebookAdObjects.map(({ account_id }) => ({
                      value: account_id,
                      text: account_id,
                    })),
                    placeholder: t("new_dashboard.57"),
                    key: "ad_account_id",
                    required: true,
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["ad_account_id"]}
                  user={user}
                  disabled={facebookAdObjectsLoading}
                />
              </>
            )}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf("TikTok") !== -1 && (
              <>
                <CreationItem
                  item={{
                    title: "TikTok search Term",
                    subtitle: "Add TikTok search term",
                    valueType: "input",
                    placeholder: "",
                    key: "tiktok_search_term",
                    required: true,
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["tiktok_search_term"]}
                  user={user}
                />
              </>
            )}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf("Facebook Page") !== -1 && (
              <>
                <CreationItem
                  item={{
                    title: t("new_dashboard.27"),
                    subtitle: t("new_dashboard.58"),
                    valueType: "dropdown_2",
                    acceptedValues: facebookPageObjects.map(({ id, name }) => ({
                      value: id,
                      text: name,
                    })),
                    placeholder: t("new_dashboard.59"),
                    key: "page_id",
                    required: true,
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["page_id"]}
                  user={user}
                  disabled={facebookPageObjectsLoading}
                />
              </>
            )}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf("Instagram Account Posts") !==
              -1 && (
              <>
                <CreationItem
                  item={{
                    title: "Instagram accounts",
                    subtitle: t("new_dashboard.58"),
                    valueType: "dropdown_2",
                    acceptedValues: instagramAccounts.map(({ id, name }) => ({
                      value: id,
                      text: name,
                    })),
                    placeholder: "Select An Instagram Account",
                    key: "instagram_account_id",
                    required: true,
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["page_id"]}
                  user={user}
                  disabled={instagramAccountsLoading}
                />
              </>
            )}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf("Forums/Community") !== -1 && (
              <>
                <CreationItem
                  item={{
                    title: "Select Forum",
                    subtitle: "Choose Forum link to collect forum conversation",
                    valueType: "dropdown_2",
                    acceptedValues: forums.map(({ id, name }) => ({
                      value: id,
                      text: name,
                    })),
                    key: "forum_id",
                    required: true,
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["forum_id"]}
                  user={user}
                  disabled={forumsLoading}
                />
              </>
            )}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf("LinkedIn Posts") !== -1 && (
              <>
                <CreationItem
                  item={{
                    title: "Select Linkedin page",
                    subtitle: "Choose a page to get posts",
                    valueType: "dropdown_2",
                    acceptedValues: linkedinPages.map(
                      ({ org_id, org_name }) => ({
                        value: org_id,
                        text: org_name,
                      })
                    ),
                    key: "linkedin_org_id",
                    required: true,
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["linkedin_org_id"]}
                  user={user}
                  disabled={linkedPagesLoading}
                />
              </>
            )}
          {newDashboardReducer.platform === 12 &&
            step === 3 &&
            (localState.platforms || []).indexOf("Şikayet Var") !== -1 && (
              <>
                <CreationItem
                  item={{
                    title: t("new_dashboard.28"),
                    subtitle: t("new_dashboard.29"),
                    valueType: "input",
                    placeholder: `${t(
                      "new_dashboard.30"
                    )}: https://www.sikayetvar.com/thy`,
                    key: "brand",
                    required: true,
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["brand"]}
                  user={user}
                />
              </>
            )}
          {!trial && newDashboardReducer.platform === 1 && step === 1 && (
            <>
              <CreationItem
                item={{
                  title: t("new_dashboard.60"),
                  subtitle: t("new_dashboard.31"),
                  valueType: "date",
                  key: "start_date",
                }}
                setState={setState}
                localState={localState}
                defaultValue={localState["start_date"]}
                user={user}
              />
              <CreationItem
                item={{
                  title: t("new_dashboard.61"),
                  subtitle: t("new_dashboard.32"),
                  valueType: "date",
                  key: "end_date",
                }}
                setState={setState}
                localState={localState}
                defaultValue={localState["end_date"]}
                user={user}
              />
            </>
          )}

          {newDashboardReducer.platform === 3 &&
            localState["customerRatingIndex"] &&
            (newDashboardReducer.creationType === "advanced"
              ? step === 8
              : step === 7) && (
              <CreationItem
                item={{
                  title: t("new_dashboard.33"),
                  valueType: "radio",
                  acceptedValues: [
                    { text: "No", key: "false" },
                    { text: "Yes", key: "true" },
                  ],
                  key: "nps",
                }}
                setState={setState}
                localState={localState}
                defaultValue={localState["nps"]}
                user={user}
              />
            )}
          {newDashboardReducer.platform === 3 &&
            localState["customerRatingIndex"] &&
            (newDashboardReducer.creationType === "advanced"
              ? step === 8
              : step === 7) &&
            localState["nps"] === "true" && (
              <>
                <CreationItem
                  item={{
                    title: t("new_dashboard.65"),
                    subtitle: t("new_dashboard.66"),
                    valueType: "dropdown",
                    acceptedValues: ["1 to 5 (VoC Score)", "0 to 10 (NPS)"],
                    key: "npsScale",
                    defaultValue: "0 to 10 (NPS)",
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={
                    localState["npsScale"]
                      ? localState["npsScale"]
                      : "0 to 10 (NPS)"
                  }
                  user={user}
                />
              </>
            )}
          {newDashboardReducer.platform === 3 &&
            localState["customerDateIndex"] &&
            (newDashboardReducer.creationType === "advanced"
              ? step === 7
              : step === 6) && (
              <CreationItem
                item={{
                  title: t("new_dashboard.35"),
                  subtitle: t("new_dashboard.36"),
                  valueType: "date",
                  key: "lastDate",
                }}
                setState={setState}
                localState={localState}
                defaultValue={localState["lastDate"]}
                user={user}
              />
            )}
          {/* {newDashboardReducer.platform !== 13 && newDashboardReducer.platform !== 11 && localState["language"] === "Turkish" &&
            step === 1 && (
              <>
                <CreationItem
                  item={{
                    title: t("new_dashboard.37"),
                    subtitle: t("new_dashboard.38"),
                    valueType: "radio",
                    acceptedValues: [
                      { text: "No", key: "false" },
                      { text: "Yes", key: "true" },
                    ],
                    key: "geolocation",
                  }}
                  setState={setState}
                  localState={localState}
                  defaultValue={localState["geolocation"]}
                  user={user}
                />
                {localState["geolocation"] === "true" && (
                  <>
                    <CreationItem
                      item={{
                        title: t("new_dashboard.39"),
                        subtitle: t("new_dashboard.40"),
                        valueType: "dropdown",
                        acceptedValues: citiesTurkey,
                        placeholder: t("new_dashboard.41"),
                        key: "city",
                        required: false,
                      }}
                      setState={setState}
                      localState={localState}
                      defaultValue={localState["city"]}
                      user={user}
                    />
                    {localState["city"] && (
                      <CreationItem
                        item={{
                          title: t("new_dashboard.42"),
                          subtitle: t("new_dashboard.43"),
                          valueType: "dropdown",
                          acceptedValues: districtsTurkey[localState["city"]],
                          placeholder: t("new_dashboard.44"),
                          key: "district",
                        }}
                        setState={setState}
                        localState={localState}
                        defaultValue={localState["district"]}
                        user={user}
                      />
                    )}
                  </>
                )}
              </>
            )} */}
        </div>
        <div className={styles.btnsContainer}>
          <div className={styles.backBtn}>
            <Button loading={loading} onClick={() => updateStep(-1)}>
              {t("new_dashboard.50")}
            </Button>
          </div>
          <div className={styles.nextBtns}>
            <div className={styles.btn}>
              <Button
                disabled={
                  info?.[0].disableIfNo && localState[info?.[0].key] === "false"
                }
                loading={loading}
                onClick={() => updateStep(1)}
              >
                {step === totalSteps
                  ? t("new_dashboard.48")
                  : t("new_dashboard.52")}
              </Button>
            </div>
            {info?.[0].skipAll && (
              <div className={styles.skipBtn}>
                <Button
                  loading={loading}
                  onClick={() => setShowConfirmationModal(true)}
                >
                  {t("new_dashboard.51")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        title={"Are you sure?"}
        showModal={showDateWarning}
        onClose={() => setShowDateWarning(false)}
        onSave={() => {
          updateStep(1, false);
          setShowDateWarning(false);
        }}
        showSaveButton={true}
        showCancelButton={true}
        textonButton={t("new_dashboard.10")}
        titleAsset=""
        widthofmodal="430px"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              margin: "0px",
              paddingBottom: "10px",
              color: theme === "dark" ? "white" : "black",
            }}
          >
            Are you sure about the date range you specified?
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              columnGap: "5px",
              padding: "10px",
              textAlign: "left",
              color:
                theme === "dark"
                  ? "color: rgba(255, 255, 255, 0.6)"
                  : "color: rgba(255, 255, 255, 0.6)",
              background: "rgba(111, 108, 153, 0.05)",
            }}
          >
            {theme === "dark" ? (
              <img className={styles.icon} src="/assets/info1.svg" alt="" />
            ) : (
              <img
                className={styles.icon}
                src="/assets/info1_black.svg"
                alt=""
              />
            )}
            Broadened date range allows to access more data you can then narrow
            the results by filtering
          </div>
        </div>
      </Modal>
      <Modal
        title={t("new_dashboard.45")}
        showModal={missingDateModalOpen}
        onClose={() => setMissingDateModalOpen(false)}
        onSave={createDashboard}
        showSaveButton={true}
        textonButton={t("new_dashboard.10")}
        titleAsset=""
        widthofmodal="530px"
      >
        <p style={{ color: theme === "dark" ? "white" : "black" }}>
          {t("new_dashboard.46")}
        </p>
      </Modal>
      <Modal
        title={t("new_dashboard.47")}
        showModal={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onSave={() => createDashboard()}
        showSaveButton={true}
        hideCloseIcon={true}
        showCancelButton={true}
        textonButton={t("new_dashboard.48")}
        titleAsset=""
        description={t("new_dashboard.62")}
        loadingSaveClick={loading}
        widthofmodal={window.innerWidth < 800 ? "350px" : "650px"}
      >
        <p
          style={{
            color: theme === "dark" ? "white" : "black",
            fontSize: "14px",
          }}
        >
          {t("new_dashboard.63")}
        </p>
      </Modal>
    </>
  );
};

export default StepsWrapper;
