import React from "react";
import styles from "./style.module.scss";
import { Input } from "antd";
import { keyPressed } from "util/common";
import { CloseOutlined } from '@material-ui/icons';

const CustomInput = ({
  label,
  placeholder,
  onChange,
  password,
  onOk,
  style,
  extra,
  id,
  value,
  defaultValue,
  disabled,
  searchWord,
  ...rest
}) => {
  return (
    <div className={styles.customInputContainer} style={style}>
      <h4 className={styles.label}>{label}</h4>

      <Input
        defaultValue={defaultValue}
        value={value}
        type={password && "password"}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        autoComplete={password && "new-password"}
        onKeyPress={(e) => keyPressed(e, onOk)}
        suffix={extra}
        id={id}
        disabled={disabled}
        {...rest}
      />

    </div>
  );
};

export default CustomInput;
