import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";

export default ({ name, fill, size = 24, useDefaultSize = false }) => {

  const ImportedIconRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const theme = useSelector((state) => state.DashboardReducer.theme);


  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (await import(`../../public/assets/${name}.svg`)).default;
      } catch (err) {
        throw err;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    if (useDefaultSize) {
      return <ImportedIcon fill={fill ? fill : theme === 'dark' ? 'white' : 'black'} />;
    } else {
      return <ImportedIcon fill={fill ? fill : theme === 'dark' ? 'white' : 'black'} width={size} height={size} />;

    }
  }
  return null;
};