import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.scss";
import { notification, Select, Tooltip } from "antd";
import SortPopup from "helperComponents/SortPopup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SurveyListing from "./Listing/Listing";
import { apiRequest } from "util/services";
import { Spin } from "antd";
import dayjs from "dayjs";
import { Button } from "antd";
import DateFilterPopUp from "components/Dashboard/DateFilter";
import { useHistory } from "react-router-dom";
import { beautifyNumber, convertDateIntoYYYYMMDD } from "util/common";
import SingleSelect from "helperComponents/SingleSelect";
import StackedBar from "components/StackedBar";
import BoardCard from "components/Dashboard/BoardCard";
import CustomChart from "components/Charts";
const { Option } = Select;

const Survey = () => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const [switchTab, setSwitchTab] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  useEffect(() => {
    if (switchTab === "create") {
      fetchListing();
    } else if (switchTab === "stats") {
      getSurveyOptions();
    }
  }, [switchTab]);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showRowItems, setShowRowItems] = useState(true);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [sortValue, setSortValue] = useState();
  const [surveyList, setsurveyList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [surveyType, setSurveyType] = useState("All");
  const [searchWord, setSearchWord] = useState("");
  const [totalSurveys, settotalSurveys] = useState();
  const [surveryPageNumber, setsurveryPageNumber] = useState(1);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedPerPage, setSelectedPerPage] = useState(10);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [surveyTypes, setSurveyTypes] = useState([]);
  const [surveyStats, setSurveyStats] = useState(null);
  const [statsLoading, setStatsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [showDateFilterPopup, setShowDateFilterPopup] = useState(false);
  const [disableDateBtn, setDisableDateBtn] = useState(false);
  const [allDateLabels, setAllDateLabels] = useState([]);
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const isMounted = useRef(false);
  const { t } = useTranslation();
  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };
  const getAllSurveyTypes = async () => {
    const result = await apiRequest("survey/survey_names", "POST", true);
    if (!result.error) {
      setSurveyTypes(result?.data);
    }
  };
  useEffect(() => {
    let uniqueAllDates = [];
    let dateArray = [];
    let period = "monthly";
    const since = params.get("since")
      ? params.get("since")
      : convertDateIntoYYYYMMDD(
          new Date().getTime() - 29 * 24 * 60 * 60 * 1000
        );
    const until = params.get("until")
      ? params.get("until")
      : convertDateIntoYYYYMMDD(new Date());
    const sinceDate = dayjs(since);
    const untilDate = dayjs(until);
    const noOfDays =
      since === until ||
      (untilDate.diff(sinceDate, "day") === 1 && since !== until)
        ? untilDate.diff(sinceDate, "day")
        : untilDate.diff(sinceDate, "day") + 1;
    period = noOfDays < 30 ? "daily" : "weekly";

    const endDate = new Date(until);
    const startDate = new Date(since);

    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const allDates = dateArray?.map((date) => {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;
      if (
        period &&
        (period.toLowerCase() === "daily" || period.toLowerCase() === "weekly")
      ) {
        return `${year}-${month}-${day}`;
      } else if (period && period.toLowerCase() === "monthly") {
        return `${year}-${month}`;
      } else if (period && period.toLowerCase() === "yearly") {
        return `${year}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    });
    uniqueAllDates = [...new Set(allDates)];
    if (period === "weekly") {
      const endDateWeekday = endDate.getDay();
      uniqueAllDates = uniqueAllDates.filter((date) => {
        const dateObj = new Date(date);
        return dateObj.getDay() === endDateWeekday;
      });
    }
    uniqueAllDates.sort((a, b) => {
      return new Date(a) - new Date(b);
    });
    setAllDateLabels(uniqueAllDates);
  }, [window.location.search]);

  const getSurveyStats = async () => {
    setStatsLoading(true);
    const payload = {};
    if (params.get("since") && params.get("until")) {
      payload["since"] = params.get("since");
      payload["until"] = params.get("until");
      const sinceDate = dayjs(params.get("since"));
      const untilDate = dayjs(params.get("until"));
      const noOfDays =
        params.get("since") === params.get("until") ||
        (untilDate.diff(sinceDate, "day") === 1 &&
          params.get("since") !== params.get("until"))
          ? untilDate.diff(sinceDate, "day")
          : untilDate.diff(sinceDate, "day") + 1;
      payload["period"] = noOfDays < 30 ? "daily" : "weekly";
    } else if (params.get("daysRange") !== "allTime") {
      const untilDate = new Date();
      const sinceDate = new Date(untilDate.getTime() - 6 * 24 * 60 * 60 * 1000);
      params.append("since", convertDateIntoYYYYMMDD(sinceDate));
      params.append("until", convertDateIntoYYYYMMDD(untilDate));
      params.append("daysRange", "last7d");
      history.push({ search: params.toString() });
      payload["since"] = convertDateIntoYYYYMMDD(sinceDate);
      payload["until"] = convertDateIntoYYYYMMDD(untilDate);
      payload["period"] = "daily";
    } else {
      payload["period"] = "weekly";
      payload["until"] = convertDateIntoYYYYMMDD(new Date());
    }
    if (selectedType && selectedType?.id !== -1) {
      payload["type"] = selectedType?.id;
    }
    const result = await apiRequest("survey/stats", "POST", payload, true);
    if (result?.success) {
      setSurveyStats(result?.success);
    } else {
      notification.error({
        message: result?.error ? result.error : "Loading statistics failed.",
      });
    }
    setStatsLoading(false);
  };
  const colorMap = {
    s0: "#9E1684",
    s1: "#FF5280",
    s2: "rgba(75,192,192,0.8)",
  };
  const skipOnceRef = useRef(false);
  const getUserEnterprise = async () => {
    const result = await apiRequest("survey/user_enterprise", "POST", {}, true);
    if (result?.success) {
      const enterprise = result.success;
      setSwitchTab(enterprise === "etstur" ? "stats" : "create");
    } else {
      setSwitchTab("create");
      notification.error({
        message: result?.error
          ? result.error
          : "Unable to get user enterprise.",
      });
    }
  };
  const getSurveyOptions = async () => {
    const result = await apiRequest("survey/type_options", "POST", {}, true);
    if (result?.success) {
      const surveys = result.success;
      const opt = surveys.map(
        (item) =>
          Object.entries(item).map(([survey_id, survey_name]) => ({
            id: parseInt(survey_id, 10),
            label: survey_name,
            value: parseInt(survey_id, 10),
          }))[0]
      );
      setTypeOptions([{ id: -1, label: "All", value: -1 }, ...opt]);
    } else {
      setTypeOptions([]);
      notification.error({
        message: result?.error ? result.error : "Unable to get survey options.",
      });
    }
  };
  useEffect(() => {
    getUserEnterprise();
  }, []);
  useEffect(() => {
    if (switchTab) {
      if (switchTab === "create") {
        getAllSurveyTypes();
      } else if (switchTab === "stats") {
        getSurveyStats();
      }
    }
  }, [switchTab, selectedType, history, window.location.search]);

  const tooltipLine = {
    id: "tooltipLine",
    beforeDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.setLineDash([4, 6]);
        ctx.moveTo(activePoint?._view?.x, chart.chartArea.top);
        ctx.lineTo(activePoint?._view?.x, chart.chartArea.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "rgba(155, 155, 155)";
        ctx.stroke();
        ctx.restore();
      }
    },
  };
  const rangePresets = {
    last24: {
      label: t("filter.15"),
    },
    last7d: {
      label: t("filter.16"),
    },
    last14d: {
      label: t("filter.17"),
    },
    last30d: {
      label: t("filter.18"),
    },
    last90d: {
      label: t("filter.19"),
    },
    custom: {
      label: t("filter.20"),
    },
    allTime: {
      label: t("filter.21"),
    },
  };
  const dateFilterRef = useRef();
  // const typeOptions = [
  //   {
  //     id: "1",
  //     label: "All",
  //     value: "1",
  //   },
  //   {
  //     id: "2",
  //     label: "SURVEY",
  //     value: "2",
  //   },
  //   {
  //     id: "3",
  //     label: "OTELPUAN",
  //     value: "3",
  //   },
  //   {
  //     id: "4",
  //     label: "ACS",
  //     value: "4",
  //   },
  // ];
  const handlePageChange = () => {
    let end_index = surveyList.length;
    if (isMounted.current) {
      if (selectedPerPage * surveryPageNumber < end_index) {
        end_index = selectedPerPage * surveryPageNumber;
        setHasNextPage(true);
      } else {
        setHasNextPage(false);
      }
    } else {
      isMounted.current = true;
    }
    if (surveyList.length == 0) {
      let temp = [];
      setFilteredList(temp);
    } else {
      let temp = surveyList?.slice(startIndex * selectedPerPage, end_index);
      setFilteredList(temp);
    }
  };

  useEffect(() => {
    handlePageChange();
  }, [surveryPageNumber]);
  const handleUserPage = () => {};

  useEffect(() => {
    setStartIndex(0);
    let list = surveyList;
    let temp = list?.length
      ? list?.slice(
          0,
          selectedPerPage > surveyList.length
            ? surveyList.length
            : selectedPerPage
        )
      : [];
    setFilteredList(temp);
    settotalSurveys(surveyList?.length ? surveyList.length : 0);
  }, [surveyList]);

  const handleShowSearch = () => {
    if (window.innerWidth < 880) {
      setShowRowItems(false);
    }
    setShowSearchBox(!showSearchBox);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (searchWord.trim()) {
        let list = surveyList.filter((item) => {
          if (item.survey_name.search(searchWord) !== -1) {
            return true;
          } else return false;
        });
        setFilteredList(list);
        // startIndex ? setStartIndex(0) : getAllDashboards()
        // setDashboardPageNumber(1)
      } else {
        setFilteredList(surveyList);
      }
    }
  };
  const fetchListing = async () => {
    setLoading(true);
    const result = await apiRequest("survey/all", "POST", {
      start_index: 0,
      number_surveys: 5,
    });

    if (!result.error) {
      if (result?.surveys) setsurveyList(result.surveys);
      if (selectedPerPage > result.length) {
        setHasNextPage(false);
        setsurveryPageNumber(1);
      }
      setLoading(false);
    } else {
    }
  };

  const sortList = () => {
    if (sortValue === "newest") {
      let list = surveyList.sort((b, a) => {
        return dayjs(a.last_refreshed_date).diff(dayjs(b.last_refreshed_date));
      });
      setFilteredList(list);
    }
    if (sortValue === "oldest") {
      let list = surveyList.sort((a, b) => {
        return dayjs(a.last_refreshed_date).diff(dayjs(b.last_refreshed_date));
      });
      setFilteredList(list);
    } else if (sortValue === "az") {
      let list = surveyList.sort((a, b) => {
        if (a.survey_name < b.survey_name) {
          return -1;
        }
        if (a.survey_name > b.survey_name) {
          return 1;
        }
        return 0;
      });
      setFilteredList(list);
    } else if (sortValue === "za") {
      let list = surveyList.sort((b, a) => {
        if (a.survey_name < b.survey_name) {
          return -1;
        }
        if (a.survey_name > b.survey_name) {
          return 1;
        }
        return 0;
      });
      setFilteredList(list);
    }
    setShowSortPopup(false);
  };

  useEffect(() => {
    setAnalyzedSurveys();
  }, [surveyType]);

  const setAnalyzedSurveys = () => {
    if (surveyType !== "All") {
      let list = surveyList.filter((item) => {
        if (item.survey_name === surveyType) {
          return true;
        } else {
          return false;
        }
      });
      setFilteredList(list);
    } else {
      setFilteredList(surveyList);
    }
  };

  useEffect(() => {
    sortList();
  }, [sortValue]);

  const handleBackward = async () => {
    setShowRowItems(true);
    setSearchWord("");
  };
  const basicLineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    datasets: {
      point: {
        tension: 0.5,
        fill: true,
        pointRadius: 0,
        backgroundColor: "red",
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          usePointStyle: true,
          color: theme === "dark" ? "white" : "black",
          font: {
            size: 12,
            color: theme === "dark" ? "white" : "black",
          },
          pointStyle: "circle",
          pointStyleWidth: 16,
          padding: 20,
          boxWidth: 12,
        },
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          // Tooltip Element
          var tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<div></div>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            if (tooltipEl) {
              tooltipEl.remove();
            }
            return;
          }
          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            let innerHtml = "<div class=" + styles.driversTooltip + ">";
            tooltipModel.dataPoints.forEach((dataPoint) => {
              const dataset = dataPoint.dataset;
              const datasetLabel = dataPoint.label;
              const datasetValue = dataPoint.raw;
              innerHtml += `
                <div class=${styles.topicLabelInTooltip}>
                  <span class=${styles.fillColor} style="background-color: ${dataset?.borderColor}"></span>
                  <span class=${styles.topicLabel}>${datasetLabel}: ${datasetValue}</span>
                </div>`;
            });
            innerHtml += "</div>";
            const tooltipRoot = tooltipEl.querySelector("div");
            tooltipRoot.innerHTML = innerHtml;
          }
          // this will be the overall tooltip
          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          let left = position.left + window.pageXOffset + tooltipModel.caretX;
          if (left + tooltipEl.offsetWidth > window.innerWidth) {
            left =
              position.left +
              window.pageXOffset / 2 +
              tooltipModel.caretX -
              tooltipEl.offsetWidth;
          }
          tooltipEl.style.left = left + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
      datalabels: {
        display: false,
      },
      filler: {
        propagate: true,
      },
    },
    scales: {
      x: {
        grid: {
          borderColor:
            theme === "dark"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
          color: "#808080",
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          padding: 20,
          color: theme === "dark" ? "white" : "black",
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          padding: 20,
          color: theme === "dark" ? "white" : "black",
        },
        grid: {
          borderColor:
            theme === "dark"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
          color: theme === "dark" ? "white" : "black",
          drawOnChartArea: false,
          drawTicks: false,
        },
        afterDataLimits: (axis) => {
          const max = axis.max;
          let addVal = 0;
          if (max < 5) {
            addVal = 1;
          } else if (max < 10) {
            addVal = 3;
          } else if (max < 100) {
            addVal = 10;
          } else if (max < 1000) {
            addVal = 50;
          } else if (max < 10000) {
            addVal = 500;
          } else if (max < 100000) {
            addVal = 1000;
          } else if (max < 1000000) {
            addVal = 5000;
          } else {
            addVal = 10000;
          }
          axis.max += addVal;
        },
      },
    },
  };
  return (
    <Spin spinning={loading}>
      <div className={styles.mainWindow}>
        <div className={styles.header}>
          <div className={styles.content}>
            <p>{t("dashboard_sidebar.8")}</p>
            <p className={styles.description}>{t("survey_repository.1")}</p>
          </div>
        </div>
        <div className={styles.topicsWrapper}>
          {switchTab === "stats" && (
            <span
              onClick={() => {
                setSwitchTab("stats");
              }}
              className={switchTab === "stats" && styles.activeTopic}
            >
              {t("survey_repository.2")}
            </span>
          )}
          {switchTab === "create" && (
            <span
              onClick={() => {
                setSwitchTab("create");
              }}
              className={switchTab === "create" && styles.activeTopic}
            >
              {t("survey_repository.3")}
            </span>
          )}
        </div>
        {switchTab === "create" && (
          <div className={styles.bottom}>
            <div className={styles.firstRow}>
              {!showRowItems && (
                <>
                  <div
                    className={`${styles.searchBoxSmall} ${showSearchBox &&
                      styles.showSearch} `}
                  >
                    <i
                      onClick={() => {
                        handleBackward();
                      }}
                      className="fa-solid fa-arrow-left"
                    ></i>
                    <input
                      type="text"
                      placeholder={t("placeholder.1")}
                      value={searchWord}
                      onChange={(e) => setSearchWord(e.target.value)}
                      onKeyPress={handleSearch}
                    />
                    <button>{t("button.5")}</button>
                  </div>
                </>
              )}
              {showRowItems && (
                <>
                  {" "}
                  <div className={styles.selectors}>
                    <Select
                      style={{ width: 200 }}
                      value={surveyType}
                      onChange={(val) => {
                        setSurveyType(val);
                      }}
                      showSearch
                      optionFilterProp="title"
                    >
                      <Select.Option key="All">
                        {" "}
                        {t("reports_downloads.3")}{" "}
                      </Select.Option>
                      {surveyTypes?.map((item) => {
                        return (
                          <Select.Option key={item} title={item}>
                            {item}{" "}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className={styles.actionBtnBlock}>
                    <button
                      className={styles.search}
                      onClick={handleShowSearch}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/search.svg"
                            : "/assets/search_black.svg"
                        }
                        alt=""
                      />
                    </button>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/sort_icon.svg"
                          : "/assets/sort_icon_black.svg"
                      }
                      onClick={() => {
                        setShowSortPopup(true);
                      }}
                      alt=""
                      className={styles.sort}
                    />
                    {showSortPopup && (
                      <SortPopup
                        setSortValue={setSortValue}
                        sortValue={sortValue}
                        setShowSortPopup={setShowSortPopup}
                      />
                    )}
                    {window.innerWidth > 880 && (
                      <div
                        className={`${styles.searchBox} ${showSearchBox &&
                          styles.showSearch} `}
                      >
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/search.svg"
                              : "/assets/search_black.svg"
                          }
                          alt=""
                        />
                        <input
                          type="text"
                          onKeyPress={handleSearch}
                          value={searchWord}
                          onChange={(e) => setSearchWord(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {switchTab === "create" ? (
          <>
            {loading ? (
              <Spin spinning={loading}></Spin>
            ) : switchTab === "create" && filteredList?.length === 0 ? (
              <div className={styles.emptyList}>
                <div className={styles.header}>
                  <div className={styles.title}>
                    No Surveys found{" "}
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/survey_list.svg"
                          : "/assets/survey_list_black.svg"
                      }
                      alt=""
                    />
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.description}>
                    Contact us to analyze your survey responses using pivony
                  </div>
                </div>
                <div className={styles.footer}>
                  <div className={styles.title}>Contact Us</div>
                  <div className={styles.description}>
                    mail to : support@pivony.com
                  </div>
                </div>
              </div>
            ) : (
              <SurveyListing surveys={filteredList} />
            )}
          </>
        ) : (
          <>
            {switchTab === "stats" && (
              <Spin spinning={statsLoading}>
                <div className={styles.statsContainer}>
                  <div className={styles.statsHeader}>
                    <SingleSelect
                      options={typeOptions}
                      selectedOptions={
                        selectedType?.id ? selectedType : typeOptions[0]
                      }
                      setSelectedOptions={setSelectedType}
                    />
                    {switchTab === "stats" && (
                      <div className={styles.extraFilters}>
                        <span
                          className={styles.search}
                          onClick={() => {
                            getSurveyStats();
                          }}
                          title="Refresh Stats"
                        >
                          <img
                            src={
                              theme === "dark"
                                ? "/assets/refresh.svg"
                                : "/assets/refresh_black.svg"
                            }
                            width={18}
                            height={18}
                          />
                        </span>
                        <button
                          className={styles.dateFilterPopupBtn}
                          onClick={() =>
                            setShowDateFilterPopup(!showDateFilterPopup)
                          }
                          disabled={disableDateBtn}
                        >
                          <img
                            src={
                              theme === "dark"
                                ? "/assets/calendar.svg"
                                : "/assets/calendar_black.svg"
                            }
                            alt=""
                          />
                          {
                            rangePresets[params.get("daysRange") || "last7d"]
                              .label
                          }
                          <img
                            src={
                              theme === "dark"
                                ? "/assets/arrow_down.svg"
                                : "/assets/arrow_down_black.svg"
                            }
                            alt=""
                          />
                        </button>
                      </div>
                    )}
                    {showDateFilterPopup && (
                      <div ref={dateFilterRef} className={styles.dateFilter}>
                        <DateFilterPopUp
                          onChange={(dateString, daysRange) => {
                            if (dateString?.length > 0) {
                              updateQParams("since", dateString[0]);
                              updateQParams("until", dateString[1]);
                            } else {
                              params.delete("since");
                              params.delete("until");
                              history.push({ search: params.toString() });
                            }
                            updateQParams("daysRange", daysRange);
                            setShowDateFilterPopup(false);
                          }}
                          close={() => {
                            setShowDateFilterPopup(false);
                          }}
                          selectedDaysRange={
                            params.get("daysRange") || "last7d"
                          }
                          defaultStartDate={
                            params.get("since") && params.get("until")
                              ? params.get("since")
                              : new Date()
                          }
                          defaultEndDate={
                            params.get("since") && params.get("until")
                              ? params.get("until")
                              : new Date()
                          }
                          searchPeriod={
                            params.get("since") && params.get("until")
                              ? [params.get("since"), params.get("until")]
                              : [new Date(), new Date()]
                          }
                        />
                      </div>
                    )}
                  </div>
                  {surveyStats && Object.keys(surveyStats)?.length > 0 && (
                    <div className={styles.statsDataChart}>
                      <div className={styles.container}>
                        <BoardCard
                          title={
                            <span className={styles.insightTitle}>
                              {"Total Stats"}
                              <img
                                className={styles.message_img}
                                src={
                                  theme === "dark"
                                    ? "/assets/info1.svg"
                                    : "/assets/info1_black.svg"
                                }
                                style={{ cursor: "pointer" }}
                                width={20}
                                height={20}
                                // onClick={() => {
                                //   const win = window.open(
                                //     "https://pivony.notion.site/Insight-Creation-160faa1a90cc4191baf11fb62c88d808",
                                //     "_blank"
                                //   );
                                //   win.focus();
                                // }}
                              />
                            </span>
                          }
                        >
                          <div className={styles.totalCountsBar}>
                            <div className={styles.statCount}>
                              <Tooltip
                                title={beautifyNumber(
                                  Object.values(surveyStats).reduce(
                                    (acc, step) => acc + step.total,
                                    0
                                  ),
                                  ","
                                )}
                              >
                                <div className={styles.total}>
                                  {beautifyNumber(
                                    Object.values(surveyStats).reduce(
                                      (acc, step) => acc + step.total,
                                      0
                                    ),
                                    "#"
                                  )}
                                </div>
                              </Tooltip>
                              <div className={styles.distribution}>
                                {Object.entries(surveyStats).map(
                                  ([stepKey, stepData]) => (
                                    <div className={styles.stepInfo}>
                                      <span className={styles.stepWiseCount}>
                                        {stepData.total}
                                      </span>
                                      <span className={styles.stepLabel}>
                                        <div
                                          className={styles.stepItemColor}
                                          style={{
                                            backgroundColor: colorMap[stepKey],
                                          }}
                                        />
                                        <div className={styles.stepItemText}>
                                          {stepKey === "s0"
                                            ? "Pending"
                                            : stepKey === "s1"
                                            ? "In-Progress"
                                            : "Completed"}
                                        </div>
                                      </span>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <StackedBar
                              data={surveyStats}
                              colorMap={colorMap}
                              width={100}
                            />
                          </div>
                        </BoardCard>
                      </div>
                      <div className={styles.container}>
                        <BoardCard
                          title={
                            <span className={styles.insightTitle}>
                              {"Time-based Stats"}
                              <img
                                className={styles.message_img}
                                src={
                                  theme === "dark"
                                    ? "/assets/info1.svg"
                                    : "/assets/info1_black.svg"
                                }
                                style={{ cursor: "pointer" }}
                                width={20}
                                height={20}
                                // onClick={() => {
                                //   const win = window.open(
                                //     "https://pivony.notion.site/Insight-Creation-160faa1a90cc4191baf11fb62c88d808",
                                //     "_blank"
                                //   );
                                //   win.focus();
                                // }}
                              />
                            </span>
                          }
                        >
                          <div className={styles.dailyCountsLine}>
                            <CustomChart
                              type={"line"}
                              data={{
                                labels: allDateLabels,
                                datasets: [
                                  {
                                    label: "Pending",
                                    lineTension: 0.5,
                                    data: allDateLabels.map((date) =>
                                      surveyStats.s0.data[date]
                                        ? surveyStats.s0.data[date]
                                        : 0
                                    ),
                                    borderColor: "#9E1684",
                                    borderWidth: 4,
                                    pointHoverRadius: 6,
                                    backgroundColor: "transparent",
                                    pointHoverBackgroundColor: "white",
                                  },
                                  {
                                    label: "In-Progress",
                                    lineTension: 0.5,
                                    data: allDateLabels.map((date) =>
                                      surveyStats.s1.data[date]
                                        ? surveyStats.s1.data[date]
                                        : 0
                                    ),
                                    borderColor: "#FF5280",
                                    borderWidth: 4,
                                    pointHoverRadius: 6,
                                    backgroundColor: "transparent",
                                    pointHoverBackgroundColor: "white",
                                  },
                                  {
                                    label: "Completed",
                                    lineTension: 0.5,
                                    data: allDateLabels.map((date) =>
                                      surveyStats.s2.data[date]
                                        ? surveyStats.s2.data[date]
                                        : 0
                                    ),
                                    borderColor: "rgba(75,192,192,0.8)",
                                    borderWidth: 4,
                                    pointHoverRadius: 6,
                                    backgroundColor: "transparent",
                                    pointHoverBackgroundColor: "white",
                                  },
                                ],
                              }}
                              options={basicLineOptions}
                              theme={theme}
                              plugins={[
                                {
                                  beforeDraw: (chart) => {
                                    const { ctx, tooltip, chartArea } = chart;
                                    if (tooltip?.dataPoints?.length > 0) {
                                      ctx.save();
                                      const activePoint = tooltip.dataPoints[0];
                                      const x = activePoint.element.x;
                                      ctx.beginPath();
                                      ctx.setLineDash([4, 6]);
                                      ctx.moveTo(x, chartArea.top);
                                      ctx.lineTo(x, chartArea.bottom);
                                      ctx.lineWidth = 2;
                                      ctx.strokeStyle = "rgba(155, 155, 155)";
                                      ctx.stroke();
                                      ctx.restore();
                                    }
                                  },
                                },
                              ]}
                            />
                            {/* <Line
                              height={300}
                              data={{
                                labels: allDateLabels,
                                datasets: [
                                  {
                                    label: "Pending",
                                    lineTension: 0.5,
                                    data: allDateLabels.map((date) =>
                                      surveyStats.s0.data[date]
                                        ? surveyStats.s0.data[date]
                                        : 0
                                    ),
                                    borderColor: "#9E1684",
                                    borderWidth: 4,
                                    pointHoverRadius: 6,
                                    backgroundColor: "transparent",
                                    pointHoverBackgroundColor: "white",
                                  },
                                  {
                                    label: "In-Progress",
                                    lineTension: 0.5,
                                    data: allDateLabels.map((date) =>
                                      surveyStats.s1.data[date]
                                        ? surveyStats.s1.data[date]
                                        : 0
                                    ),
                                    borderColor: "#FF5280",
                                    borderWidth: 4,
                                    pointHoverRadius: 6,
                                    backgroundColor: "transparent",
                                    pointHoverBackgroundColor: "white",
                                  },
                                  {
                                    label: "Completed",
                                    lineTension: 0.5,
                                    data: allDateLabels.map((date) =>
                                      surveyStats.s2.data[date]
                                        ? surveyStats.s2.data[date]
                                        : 0
                                    ),
                                    borderColor: "rgba(75,192,192,0.8)",
                                    borderWidth: 4,
                                    pointHoverRadius: 6,
                                    backgroundColor: "transparent",
                                    pointHoverBackgroundColor: "white",
                                  },
                                ],
                              }}
                              plugins={tooltipLine}
                              options={{
                                color: "rgba(75,192,192,1)",
                                maintainAspectRatio: false,
                                responsive: true,
                                scales: {
                                  xAxes: [
                                    {
                                      afterDataLimits: (axis) => {
                                        axis.max += 5;
                                      },
                                      gridLines: {
                                        drawOnChartArea: false,
                                        drawTicks: false,
                                        color: "#808080",
                                      },
                                      ticks: {
                                        padding: 20,
                                        fontColor:
                                          theme === "dark" ? "white" : "black",
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      afterDataLimits: (axis) => {
                                        const max = axis.max;
                                        let addVal = 0;
                                        if (max < 10) {
                                          addVal = 5;
                                        } else if (max < 100) {
                                          addVal = 10;
                                        } else if (max < 1000) {
                                          addVal = 50;
                                        } else if (max < 10000) {
                                          addVal = 500;
                                        } else if (max < 100000) {
                                          addVal = 1000;
                                        } else if (max < 1000000) {
                                          addVal = 5000;
                                        } else {
                                          addVal = 10000;
                                        }
                                        axis.max += addVal;
                                      },
                                      ticks: {
                                        beginAtZero: true,
                                        maxTicksLimit: 4,
                                        padding: 20,
                                        fontColor:
                                          theme === "dark" ? "white" : "black",
                                      },
                                      gridLines: {
                                        drawOnChartArea: false,
                                        drawTicks: false,
                                        color: "#808080",
                                      },
                                      scaleLabel: {
                                        labelString: "Reviews Count",
                                      },
                                    },
                                  ],
                                },
                                elements: {
                                  point: {
                                    radius: 0,
                                    hitRadius: 3,
                                  },
                                },
                                legend: {
                                  display: false,
                                  labels: {
                                    usePointStyle: true,
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                    pointStyle: "circle",
                                    padding: 20,
                                    boxWidth: 8,
                                  },
                                  borderRadius: 12,
                                  position: "bottom",
                                },
                                hover: {
                                  mode: "point",
                                  intersect: true,
                                },
                                interaction: {
                                  mode: "index",
                                  intersect: false,
                                },
                                layout: {
                                  padding: {
                                    top: 20,
                                  },
                                },
                                tooltips: {
                                  mode: "label",
                                  intersect: false,
                                },
                                plugins: {
                                  datalabels: {
                                    labels: {
                                      title: null,
                                    },
                                  },
                                },
                              }}
                            /> */}
                          </div>
                          <div className={styles.legend}>
                            <div className={styles.legendItem}>
                              <div
                                className={styles.legendItemColor}
                                style={{ backgroundColor: "#9E1684" }}
                              />
                              <span className={styles.legendItemText}>
                                Pending
                              </span>
                            </div>
                            <div className={styles.legendItem}>
                              <div
                                className={styles.legendItemColor}
                                style={{ backgroundColor: "#FF5280" }}
                              />
                              <span className={styles.legendItemText}>
                                In-Progress
                              </span>
                            </div>
                            <div className={styles.legendItem}>
                              <div
                                className={styles.legendItemColor}
                                style={{
                                  backgroundColor: "rgba(75,192,192,0.8)",
                                }}
                              />
                              <span className={styles.legendItemText}>
                                Completed
                              </span>
                            </div>
                          </div>
                        </BoardCard>
                      </div>
                    </div>
                  )}
                </div>
              </Spin>
            )}
          </>
        )}
        {!loading && filteredList?.length && switchTab === "create" ? (
          <div className={styles.paginationWrapper}>
            <div className={styles.innerWrapper}>
              {
                <div className={styles.paginationBox}>
                  <Button
                    disabled={startIndex === 0}
                    onClick={() => {
                      parseInt(surveryPageNumber, 10) === startIndex + 1 &&
                        setStartIndex((prev) => prev - 1);
                      parseInt(surveryPageNumber, 10) === startIndex + 1 &&
                        setsurveryPageNumber((prev) => parseInt(prev, 10) - 1);
                    }}
                  >
                    <i className="fa fa-angle-left" />
                  </Button>
                  <span className={styles.pagination}>
                    <input
                      className={styles.pageIndex}
                      onKeyPress={handleUserPage}
                      type="text"
                      value={surveryPageNumber}
                      onChange={(e) => setsurveryPageNumber(e.target.value)}
                    />
                    {totalSurveys
                      ? ` / ${Math.ceil(totalSurveys / selectedPerPage)}`
                      : ""}
                  </span>
                  <Button
                    disabled={!hasNextPage}
                    onClick={() => {
                      parseInt(surveryPageNumber, 10) === startIndex + 1 &&
                        setStartIndex((prev) => prev + 1);
                      parseInt(surveryPageNumber, 10) === startIndex + 1 &&
                        setsurveryPageNumber((prev) => parseInt(prev, 10) + 1);
                    }}
                  >
                    <i className="fa fa-angle-right" />
                  </Button>
                </div>
              }

              {surveyList?.length > 0 && (
                <Select
                  className="review-page-number-selector"
                  value={selectedPerPage + ` / ${t("label.10")}`}
                  onChange={handlePageChange}
                  style={{ backgroundColor: "transparent" }}
                >
                  <Option value={10}>10</Option>
                  <Option
                    disabled={totalSurveys <= 10 ? true : false}
                    value={25}
                  >
                    25
                  </Option>
                  <Option
                    disabled={totalSurveys <= 25 ? true : false}
                    value={50}
                  >
                    50
                  </Option>
                  <Option
                    disabled={totalSurveys <= 50 ? true : false}
                    value={100}
                  >
                    100
                  </Option>
                </Select>
              )}
            </div>
            {totalSurveys > 0 && (
              <div className={styles.dashboardsTotal}>
                {selectedPerPage * startIndex +
                  filteredList?.length +
                  " of " +
                  totalSurveys}
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </Spin>
  );
};
export default Survey;
