import React, { useState, useEffect } from "react";
import { Avatar, Button, Select, notification } from "antd";
import Modal from "components/General/ModalFormComponents/Modal";
import CustomInput from "components/General/CustomInput";
import styles from "./style.module.scss";
import Uploader from "./uploder";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddPhnPopup from "../security/mfa/Representation";
import { apiRequest } from "util/services";
const CustomDropDown = ({
  onChange,
  selectedOption,
  options,
  placeholder,
  theme,
}) => (
  <Select
    placeholder={placeholder}
    onChange={onChange}
    value={selectedOption}
    showSearch
    optionFilterProp="title"
  >
    <Select.Option style={{ display: "none" }}>{placeholder}</Select.Option>
    {options?.map((option) => (
      <Select.Option key={option} value={option} title={option}>
        {option}
      </Select.Option>
    ))}
  </Select>
);
const Wrapper = ({ title, subtitle, children }) => (
  <div className={styles.wrapper}>
    <h4 className={styles.wrapperTitle}>{title}</h4>
    <h7 className={styles.wrapperSubtitle}>{subtitle}</h7>
    {children}
  </div>
);

const Representation = ({
  user,
  fullName,
  setFullName,
  phoneNumber,
  setPhoneNumber,
  setRole,
  roles,
  role,
  save,
  deleteLogo,
  numberRetrievedFromDB,
  setNumberRetrievedFromDB,
}) => {
  const userReducer = useSelector((state) => state.userReducer);
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [deleteBtnClicked, setDeleteBtnClicked] = useState(false);
  const [verificationCode, setVerificationCode] = useState();
  const [smsSent, setSmsSent] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const TIMER = 120;
  const updateTime = () => {
    setTimeout(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
  };

  useEffect(() => {
    if (timeLeft > 0) updateTime();
  }, [timeLeft]);

  const handleAddNumber = () => {
    if (!isPopupOpen) {
      setIsPopupOpen(true);
    }
  };
  const handleDeleteNumber = () => {
    if (!deleteBtnClicked) {
      setDeleteBtnClicked(true);
    }
  };
  const handleConfirmDelete = async () => {
    setDeleteBtnClicked(false);
    const apiResponse = await apiRequest("users/deleteNumber", "POST", {
      phone: phoneNumber,
    });
    if (apiResponse?.success) {
      setPhoneNumber("");
      setNumberRetrievedFromDB(false);
      setSmsSent(false);
      notification.success({
        message: "Successfully deleted number",
      });
    } else {
      notification.error({
        message: "Failed to delete number",
      });
    }
  };
  const onClose = () => {
    if (isPopupOpen) {
      setIsPopupOpen(false);
    } else if (deleteBtnClicked) {
      setDeleteBtnClicked(false);
    }
  };

  const sendSMS = async () => {
    try {
      setIsLoading(true);
      const apiResponse = await apiRequest("users/sendOTP", "POST", {
        phone: phoneNumber,
      });
      if (apiResponse?.success) {
        notification.success({
          message: `OTP sent to ${phoneNumber} successfully.`,
        });
        setSmsSent(true);
        setTimeLeft(TIMER);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        notification.error({
          message: "Please verify your number",
        });
      }
    } catch (error) {
      notification.error({
        message: "Failed to send OTP. Try again",
        error: error,
      });
      setIsLoading(false);
    }
  };
  const resend = async () => {
    try {
      setIsLoading(true);
      const apiResponse = await apiRequest("users/sendOTP", "POST", {
        phone: phoneNumber,
      });
      if (apiResponse?.success) {
        notification.success({
          message: `OTP sent to ${phoneNumber} successfully.`,
        });
        setSmsSent(true);
        setTimeLeft(TIMER);
        setIsLoading(false);
      }
    } catch (error) {
      notification.error({
        message: "Failed to send OTP. Try again",
        error: error,
      });
      setIsLoading(false);
    }
  };
  const verifyOTP = async () => {
    try {
      setIsLoading(true);
      const apiResponse = await apiRequest("users/verifyOTP", "POST", {
        phone: phoneNumber,
        otp: verificationCode,
      });
      if (apiResponse?.success) {
        setIsPopupOpen(false);
        setIsLoading(false);
        setPhoneNumber(phoneNumber);
        setNumberRetrievedFromDB(true);
        notification.success({
          message: "Mobile number registered successfully.",
        });
      } else {
        setIsPopupOpen(false);
        setIsLoading(false);
        setPhoneNumber("");
        notification.error({
          message: "Registration failed. Try again",
        });
      }
    } catch {
      notification.error({
        message: "Invalid OTP",
      });
      setIsPopupOpen(false);
      setIsLoading(false);
    }
  };
  return (
    <Wrapper title={t("settings.1")} subtitle={t("settings.2")}>
      <div className={styles.container}>
        <h7 className={styles.title}> {t("settings.3")} </h7>
        <CustomInput
          defaultValue={fullName}
          placeholder={t("settings.5")}
          onChange={setFullName}
          style={{ maxWidth: "700px", margin: "0px 0px 20px 0px" }}
        />
        <h7 className={styles.title}> {t("settings.4")} </h7>
        <p className={styles.subtitle}>
          By setting phone number, you can receive alerts and notifications
        </p>
        <div className={styles.phoneNumber}>
          <CustomInput
            defaultValue={phoneNumber}
            placeholder={phoneNumber}
            onChange={setPhoneNumber}
            disabled={true}
            style={{ maxWidth: "700px", margin: "0px 0px 20px 0px" }}
          />
          {!numberRetrievedFromDB ? (
            <Button className={styles.saveBtn} onClick={handleAddNumber}>
              <PlusOutlined /> Add
            </Button>
          ) : (
            <Button className={styles.saveBtn} onClick={handleDeleteNumber}>
              <DeleteOutlined /> Delete
            </Button>
          )}
          {isPopupOpen && (
            <div className={styles.AddPhnPopup}>
              <Modal
                showModal={isPopupOpen}
                onClose={onClose}
                showSaveButton={false}
                titleAsset=""
                footer={null}
              >
                <AddPhnPopup
                  setPhonenumber={setPhoneNumber}
                  phonenumber={phoneNumber}
                  smsSent={smsSent}
                  timeLeft={timeLeft}
                  verificationCode={verificationCode}
                  setVerificationCode={setVerificationCode}
                  isLoading={isLoading}
                  sendSMS={sendSMS}
                  resend={resend}
                  verifyCode={verifyOTP}
                />
              </Modal>
            </div>
          )}
          {deleteBtnClicked && (
            <Modal
              showModal={deleteBtnClicked}
              onClose={onClose}
              widthofmodal="450px"
              titleAsset=""
              onSave={handleConfirmDelete}
              showSaveButton={true}
              textonButton="delete"
            >
              <p>
                By deleting your phone number you will no longer receive any
                alerts or notifications. Are you sure to continue?
              </p>
            </Modal>
          )}
        </div>
        <h7 className={styles.title}> {t("settings.7")} </h7>
        <p className={styles.subtitle}>
          Roles help deliver tailored results for each profile.
        </p>
        <CustomDropDown
          onChange={(value) => setRole(value)}
          selectedOption={role}
          options={roles}
          placeholder={t("settings.8")}
        />
        <div className={styles.subContainer}>
          <h7 className={styles.title}> {t("settings.9")}</h7>
          <p className={styles.subtitle}>{t("settings.11")}</p>

          {!userReducer.logoUrl && <Uploader user={user} />}

          {userReducer.logoUrl && (
            <div
              style={{
                display: "flex",
                padding: "10px",
                maxWidth: "700px",
                maxHeight: "250px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #6f6c99",
                borderRadius: "2px",
                paddingTop: "10px",
                marginTop: "5px",
              }}
            >
              <img
                src={userReducer.logoUrl}
                style={{ maxHeight: 140, maxWidth: 140 }}
              />
              <Button className={styles.cancelBtn} onClick={deleteLogo}>
                <DeleteOutlined />
              </Button>
            </div>
          )}
        </div>
        <div className={styles.subContainer}>
          <Button className={styles.saveBtn} onClick={save}>
            {t("button.16")}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default Representation;
