import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import { apiRequest } from "util/services";
import Representation from './Representation';

const SettingsLayout = ({ authenticated, user, ...props }) => {
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(false);
  const onChange = (val) => {
    if (val === 'home') {
      history.push(`/console/myDashboards`)
    } else {
      history.push(`/settings/${val}`)
    }
  };
  const path = window.location.href;

  useEffect(() => {
    const payload = {
      user_id: user?.uid
    };
    user?.uid && apiRequest(
      "subscriptions/all",
      "POST",
      payload,
      true
    ).then(result => {
      if (!result.error) {
        setIsAdmin(result[0].IsOrganizationAdmin)
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
  }, [user])

  return <Representation
    onChange={onChange}
    selected={path.split('/settings/')[1]}
    history={history}
    isAdmin={isAdmin}
    plan={user.plan}
    {...props}
  />
}

export default SettingsLayout;