import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import { Select, Popover, Button, notification } from "antd";
import { apiRequest } from "util/services";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const SelectMetric = ({
  setCardChart,
  itemKey,
  setSelectedItemKey,
  blockLocation,
  selectedTeam,
  getGlobalChartsData,
}) => {
  const [sources, setSources] = useState([]);
  const [customMetrics, setCustomMetrics] = useState([]);
  const [generalMetrics, setGeneralMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [aiTopics, setAiTopics] = useState([]);
  const [selectedCustomMetric, setSelectedCustomMetric] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sumMean, setSumMean] = useState("sum");
  const [customTitle, setCustomTitle] = useState("");
  const [generalCustomMetrics, setGeneralCustomMetrics] = useState([]);
  const [showChartTypePopup, setShowChartTypePopup] = useState(false);
  const [showAiChartPopup, setShowAiChartPopup] = useState(false);
  const [selectedAiTopics, setSelectedAiTopics] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const theme = useSelector((state) => state.DashboardReducer.theme);

  const { t } = useTranslation();
  const chartPopupRef = useRef();

  const handleClickChange = (visible) => {
    setClicked(visible);
  };
  const hide = () => {
    setClicked(false);
  };
  const show = () => setClicked(true);

  useEffect(() => {
    if (!clicked) {
      setSelectedItems([]);
      setSelectedMetric([]);
      setGeneralMetrics([]);
      setCustomMetrics([]);
      setSelectedCustomMetric(null);
      setSelectedAiTopics([]);
    }
  }, [clicked]);

  // useEffect(() => {
  //   setCardChart();
  // }, [setCardChart])

  const loadData = async () => {
    if (blockLocation === "Top") {
      setSelectedItemKey(itemKey);
    }
    if (selectedItems === null || selectedItems.length === 0) {
      notification.error({
        message: t("notifications.26"),
      });
    } else if (selectedMetric === null) {
      notification.error({
        message: t("notifications.25"),
      });
    } else {
      setDataLoading(true);
      const payload = {
        dashboard_ids: selectedItems.map((str) => parseInt(str, 10)),
        metric: selectedMetric,
        bottom_metric: selectedCustomMetric,
        block_location: blockLocation,
        operation: sumMean,
        team: selectedTeam,
        custom_title: customTitle,
        ai_bubbles: selectedAiTopics,
      };
      const result = await apiRequest(
        "executive_summary/v2/chartData",
        "POST",
        payload,
        true
      );
      if (result?.success) {
        // setCardChart(result?.success);
        getGlobalChartsData();
        setDataLoading(false);
        hide();
      } else {
        notification.error({
          message: result?.error,
        });
        setDataLoading(false);
      }
    }
  };

  const getMetricsList = async (value, topics) => {
    let include_topics = "";
    if (topics) include_topics = topics;
    const payload = {
      dashboard_ids: value.map((str) => parseInt(str, 10)),
      block_location: blockLocation,
      custom_metric: include_topics,
    };
    const result = await apiRequest(
      "executive_summary/v2/metricList",
      "POST",
      payload,
      true
    );
    if (result.success) {
      setAiTopics(result?.success?.ai_topics);
      setCustomMetrics(result?.success?.custom_metrics);
      if (topics && topics.length > 0)
        setGeneralCustomMetrics(result?.success?.general_metrics);
      else setGeneralMetrics(result?.success?.general_metrics);
    }
  };

  const getSources = async () => {
    const result = await apiRequest("executive_summary/source", "POST", true);
    if (result.success) {
      setSources(result?.success);
    }
  };

  const onChange = (value) => {
    setSelectedItems(value);
    getMetricsList(value);
  };

  const onMetricsChange = (value) => {
    if (value.length > 4 && selectedCustomMetric === "Choose your own topics") {
      notification.error({
        message: t("notifications.24"),
      });
    } else setSelectedMetric(value);
  };

  const onAiTopicsChange = (value) => {
    setSelectedAiTopics(value);
  };

  const onCustomMetricsChange = (value) => {
    setSelectedCustomMetric(value);
    if (value === "Choose your own topics")
      getMetricsList(selectedItems, value);
    else setSelectedMetric([]);
  };

  const changeScrollbarColor = () => {
    const getAllScrollbar = document.querySelectorAll(
      ".rc-virtual-list-scrollbar-thumb"
    );
    for (let i = 0; i < getAllScrollbar.length; i++) {
      getAllScrollbar[i].style.background = "rgba(217, 217, 217, 0.8)";
    }
  };

  // const filteredOptions = sources?.filter(o => !selectedItems.includes(o.Name));

  const handleSumMean = (e) => {
    setSumMean(e.target.value);
  };

  const handleShowPopup = () => {
    getSources();
    if (blockLocation === "Top") {
      setShowChartTypePopup(true);
    } else show();
  };

  const popupOutClick = (e) => {
    if (chartPopupRef.current && !chartPopupRef.current.contains(e.target)) {
      setShowChartTypePopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", popupOutClick, true);
    return () => document.removeEventListener("click", popupOutClick, true);
  }, []);

  return (
    <Popover
      className="global-dashboard-select"
      content={
        <div
          className="popup-content-box1"
          style={{
            transform:
              window.innerWidth > 450
                ? "translate(0, -50%)"
                : "translate(-25%, -50%)",
          }}
        >
          <div className={styles.globalSelectBox1}>
            <span className={styles.heading}>{t("team_kpi_view.5")}</span>
            <Select
              onPopupScroll={() => changeScrollbarColor()}
              dropdownStyle={{
                backgroundColor: "rgba(38, 34, 80, 1)",
                color: "#fff",
                accentColor: "#fff",
              }}
              style={{
                width: "150px",
              }}
              placeholder={t("team_kpi_view.6")}
              placement="bottomLeft"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              mode="multiple"
              onChange={onChange}
              value={selectedItems}
            >
              {sources?.map((x) => (
                <Option className="metric-option" key={x.id} value={x.id}>
                  {`${x.Name} (${x.id})`}
                </Option>
              ))}
            </Select>
          </div>
          {(blockLocation === "bottom1" || blockLocation === "bottom2") && (
            <div className={styles.customSelectBox}>
              <span className={styles.heading}>{t("team_kpi_view.7")}</span>
              <Select
                dropdownStyle={{
                  backgroundColor: "rgba(38, 34, 80, 1)",
                  color: "#fff",
                  accentColor: "#fff",
                }}
                style={{
                  width: "150px",
                }}
                placeholder={t("team_kpi_view.8")}
                placement="bottomLeft"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                onChange={onCustomMetricsChange}
                value={selectedCustomMetric}
                className="kpi-selector"
              >
                {generalMetrics?.map((x) => (
                  <Option className="metric-option" key={x}>
                    {x}
                  </Option>
                ))}
              </Select>
            </div>
          )}

          {selectedCustomMetric === "Choose your own topics" && (
            <div className={styles.titleBox}>
              <span className={styles.heading}>{t("team_kpi_view.10")}*</span>
              <input
                type="text"
                placeholder={t("placeholder.5")}
                value={customTitle}
                onChange={(e) => setCustomTitle(e.target.value)}
              />
            </div>
          )}
          {selectedCustomMetric === "Choose your own topics" && (
            <div className={styles.globalSelectBox2}>
              <span className={styles.heading}>{t("team_kpi_view.11")}</span>
              <Select
                onPopupScroll={() => changeScrollbarColor()}
                dropdownStyle={{
                  backgroundColor: "rgba(38, 34, 80, 1)",
                  color: "#fff",
                  accentColor: "#fff",
                }}
                style={{
                  width: "150px",
                }}
                placeholder={t("team_kpi_view.12")}
                placement="bottomLeft"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                mode="multiple"
                onChange={onMetricsChange}
                value={selectedMetric}
                className="kpi-selector"
              >
                <>
                  {generalCustomMetrics?.map((x) => (
                    <Option className="metric-option" key={x}>
                      {x}
                    </Option>
                  ))}
                </>
              </Select>
            </div>
          )}

          {((blockLocation === "Top" && !showAiChartPopup) ||
            blockLocation === "bottom3") && (
            <div className={styles.globalSelectBox2}>
              <span className={styles.heading}>{t("team_kpi_view.13")}</span>
              <Select
                onPopupScroll={() => changeScrollbarColor()}
                dropdownStyle={{
                  backgroundColor: "rgba(38, 34, 80, 1)",
                  color: "#fff",
                  accentColor: "#fff",
                }}
                style={{
                  width: "150px",
                }}
                placeholder={t("team_kpi_view.12")}
                placement="bottomLeft"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                mode="multiple"
                onChange={onMetricsChange}
                value={selectedMetric}
                className="kpi-selector"
              >
                {blockLocation === "Top" ? (
                  <>
                    {customMetrics?.map((x) => (
                      <Option className="metric-option" key={x}>
                        {x}
                      </Option>
                    ))}
                    {generalMetrics.map((x) => (
                      <Option className="metric-option" key={x}>
                        {x}
                      </Option>
                    ))}
                  </>
                ) : (
                  <>
                    {generalMetrics?.map((x) => (
                      <Option className="metric-option" key={x}>
                        {x}
                      </Option>
                    ))}
                  </>
                )}
              </Select>
            </div>
          )}

          {selectedItems?.length > 1 &&
            blockLocation === "Top" &&
            !showAiChartPopup && (
              <div className={styles.globalSelectBox3}>
                <span className={styles.heading}>{t("team_kpi_view.14")}</span>
                <div className={styles.optionsWrapper}>
                  <div className={styles.item}>
                    <input
                      type="radio"
                      checked={sumMean === "sum" ? true : false}
                      id="sum"
                      value="sum"
                      name="operator"
                      onChange={handleSumMean}
                    />
                    <label htmlFor="sum">
                      <span className={styles.radioBorder}></span>
                      {t("team_kpi_view.15")}
                    </label>
                  </div>
                  <div className={styles.item}>
                    <input
                      type="radio"
                      checked={sumMean === "mean" ? true : false}
                      id="mean"
                      value="mean"
                      name="operator"
                      onChange={handleSumMean}
                    />
                    <label htmlFor="mean">
                      <span className={styles.radioBorder}></span>
                      {t("team_kpi_view.16")}
                    </label>
                  </div>
                </div>
              </div>
            )}
          {showAiChartPopup && (
            <div className={styles.globalSelectBox2}>
              <span className={styles.heading}>
                {t("selected_dashboard_sidebar.2")}
              </span>
              <Select
                onPopupScroll={() => changeScrollbarColor()}
                dropdownStyle={{
                  backgroundColor: "rgba(38, 34, 80, 1)",
                  color: "#fff",
                  accentColor: "#fff",
                }}
                style={{
                  width: "150px",
                }}
                placeholder={t("label.13")}
                placement="bottomLeft"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                mode="multiple"
                onChange={onAiTopicsChange}
                value={selectedAiTopics}
                className="kpi-selector"
              >
                {aiTopics?.map((x) => (
                  <Option className="metric-option" key={x.topic_id}>
                    {x.topic_name}
                  </Option>
                ))}
              </Select>
            </div>
          )}

          <div className="popup-add-block">
            <Button onClick={() => loadData()} disabled={dataLoading}>
              + {t("team_kpi_view.19")}
            </Button>
          </div>
          <span onClick={hide} className="close-popup-btn">
            <img
              src={theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"}
              alt=""
            />
          </span>
        </div>
      }
      trigger="none"
      placement={window.innerWidth > 450 ? "left" : "top"}
      defaultVisible={false}
      open={clicked}
      onVisibleChange={handleClickChange}
      overlayStyle={{
        width: "200px",
        paddingTop: "0px",
      }}
    >
      <div className={styles.addBlock} ref={chartPopupRef}>
        <Button
          className="add-chart-item-btn"
          onClick={handleShowPopup}
          icon={<i className="fa fa-plus" />}
        ></Button>
        {blockLocation === "Top" && showChartTypePopup && (
          <div className={styles.typePopup}>
            {/* <span onClick={() => {
                setShowChartTypePopup(false);
                setShowAiChartPopup(true);
                show();
              }}
              ><img src={theme === 'dark' ? "/assets/bubble_chart.svg" : "/assets/bubble_chart_black.svg"} alt="" />{t("label.14")}</span> */}
            <span
              onClick={() => {
                setShowChartTypePopup(false);
                setShowAiChartPopup(false);
                show();
              }}
            >
              <img
                src={
                  theme === "dark"
                    ? "/assets/solid_line.svg"
                    : "/assets/solid_line_black.svg"
                }
                alt=""
              />
              {t("label.15")}
            </span>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default SelectMetric;
