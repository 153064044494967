import React from "react";
import styles from "./style.module.scss";
import DashboardWrapper from "components/Dashboard/DashboardWrapper";

const Dashboard = ({ authenticated, user, match }) => {
  return (
    <div className={styles.mainWindow}>
      <DashboardWrapper user={user} authenticated={authenticated} topicsType="ai" match={match} />
    </div>
  );
};

export default Dashboard;
