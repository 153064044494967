let config = {};
if (process.env.REACT_APP_ENV === "fullDevelopment") {
  config = {
    BASE_URL: "http://localhost:5000/api/",
    RECAPTCHA_SITE_PUBLIC_KEY: "6LccE6YZAAAAAInGNlfPxRYIOQADG2hoh-edNXcc",
    MIXPANEL_ID: "df52ddfc02e2b9b201bd56cfd87d97a0",
    TRELLO_KEY: "9b8ed51dce54e4ec3ea26410ffe10116",
    CLICKUP_KEY: "ZMLF12DJ7IKDIOTTH9FH08AEV593HWKV",
    SLACK_KEY: "1050391391570.2876009322519",
    JIRA_KEY: "C37EHB8wBxyZqWvjuaIxJKcRuzx1UNEo",
    FACEBOOK_CLIENT_ID: "615804762377447",
    FACEBOOK_REDIRCT_URL: "http://localhost:3000/settings/integrations",
    LINKEDIN_CLIENT_KEY: "77e7nhpcx1ldqa",
    LINKEDIN_REDIRECT_URL: "http://localhost:3000/settings/integrations",
  };
} else if (process.env.REACT_APP_ENV === "partialDevelopment") {
  config = {
    BASE_URL: "https://demo.pivony.com/api/",
    RECAPTCHA_SITE_PUBLIC_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    MIXPANEL_ID: "df52ddfc02e2b9b201bd56cfd87d97a0",
    TRELLO_KEY: "9b8ed51dce54e4ec3ea26410ffe10116",
    CLICKUP_KEY: "ZMLF12DJ7IKDIOTTH9FH08AEV593HWKV",
    MONDAY_KEY: "bcd81e275d47b926193829d37c4505e1",
    SLACK_KEY: "1050391391570.2876009322519",
    JIRA_KEY: "C37EHB8wBxyZqWvjuaIxJKcRuzx1UNEo",
    FACEBOOK_CLIENT_ID: "615804762377447",
    FACEBOOK_REDIRCT_URL: "http://localhost:3000/settings/integrations",
    LINKEDIN_CLIENT_KEY: "77e7nhpcx1ldqa",
    LINKEDIN_REDIRECT_URL: "http://localhost:3000/settings/integrations",
  };
} else if (process.env.REACT_APP_ENV === "staging") {
  config = {
    BASE_URL: "https://demo.pivony.com/api/",
    RECAPTCHA_SITE_PUBLIC_KEY: "6LdMCagZAAAAADFPtpXqR4UyU-Tf8DLRCugmR-Hh",
    MIXPANEL_ID: "df52ddfc02e2b9b201bd56cfd87d97a0",
    TRELLO_KEY: "9b8ed51dce54e4ec3ea26410ffe10116",
    CLICKUP_KEY: "ZMLF12DJ7IKDIOTTH9FH08AEV593HWKV",
    SLACK_KEY: "1050391391570.2876009322519",
    JIRA_KEY: "C37EHB8wBxyZqWvjuaIxJKcRuzx1UNEo",
    FACEBOOK_CLIENT_ID: "615804762377447",
    FACEBOOK_REDIRCT_URL: "https://demo.pivony.com/settings/integrations",
    LINKEDIN_CLIENT_KEY: "77e7nhpcx1ldqa",
    LINKEDIN_REDIRECT_URL: "https://demo.pivony.com/settings/integrations",
  };
} else if (process.env.REACT_APP_ENV === "production") {
  config = {
    BASE_URL: "https://app.pivony.com/api/v1/",
    RECAPTCHA_SITE_PUBLIC_KEY: "6LdMCagZAAAAADFPtpXqR4UyU-Tf8DLRCugmR-Hh",
    HOTJARID: 3203725,
    HOTJARVERSION: 6,
    GTM_ID: "GTM-M5M9V58",
    MIXPANEL_ID: "e93eb7791880f768d7ee04d5af6895a0",
    TRELLO_KEY: "9b8ed51dce54e4ec3ea26410ffe10116",
    CLICKUP_KEY: "ZMLF12DJ7IKDIOTTH9FH08AEV593HWKV",
    MONDAY_KEY: "bcd81e275d47b926193829d37c4505e1",
    SLACK_KEY: "1050391391570.2876009322519",
    JIRA_KEY: "oy4HFpagOP5255jIoCQNAIAxeLCjzWA1",
    FACEBOOK_CLIENT_ID: "615804762377447",
    FACEBOOK_REDIRCT_URL: "https://app.pivony.com/settings/integrations",
    LINKEDIN_CLIENT_KEY: "77e7nhpcx1ldqa",
    LINKEDIN_REDIRECT_URL: "https://app.pivony.com/settings/integrations",
  };
}

export default config;
