import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import CreationItem from "components/NewDashboard/CreationItem";
import { apiRequest } from "util/services";
import { useHistory, useLocation } from "react-router-dom";
import { notification, Spin } from "antd";
import { hexToRgbA } from "util/common";
import CustomChart from "components/Charts";
import { Chart, Interaction } from "chart.js";
const LineChartWrapper = ({
  Time_Rating,
  Time_Daily_Rating,
  VolumeDaily,
  SentimentDaily,
  clickable,
  height,
  data,
  allData,
  hideLegend,
  TopicId,
  topicsType,
  DashboardId,
  period,
  max_y,
}) => {
  const dispatch = useDispatch();
  const [localState, setState] = useState({ data: {} });
  const [timeRatingData, setTimeRatingData] = useState({});
  const [loading, setLoading] = useState(false);
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = DashboardReducer.theme;

  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  const customizeGraphData = (dataArr, valueKeyName) => {
    if (!dataArr || dataArr.length === 0) return { labels: [], data: [] };
    const sortedArr = dataArr?.sort(
      (a, b) => new Date(a.day) - new Date(b.day)
    );

    if (
      sortedArr[0][valueKeyName] === undefined &&
      valueKeyName === "num_of_docs"
    ) {
      valueKeyName = "num_of_tweet";
    }
    return {
      labels: sortedArr.map((item) => item.day),
      data: sortedArr.map((item) => item[valueKeyName]),
    };
  };
  const allVolData = data?.map((d) => customizeGraphData(d, "num_of_docs"));
  const allPosSentData = data?.map((d) => customizeGraphData(d, "num_of_pos"));
  const datesArr = VolumeDaily
    ? allVolData?.map((volData) => volData.labels)
    : allPosSentData?.map((posSentData) => posSentData.labels);
  const dateRange = DashboardReducer.searchState.dateRange;
  let dateArray = [];
  let uniqueAllDates = [];
  if (params.get("since") && params.get("until")) {
    const startDate = new Date(params.get("since"));
    const endDate = new Date(params.get("until"));
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const allDates = dateArray?.map((date) => {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      if (
        period &&
        (period.toLowerCase() === "daily" || period.toLowerCase() === "default")
      ) {
        return `${year}-${month}-${day}`;
      } else if (period && period.toLowerCase() === "monthly") {
        return `${year}-${month}`;
      } else if (period && period.toLowerCase() === "yearly") {
        return `${year}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    });
    uniqueAllDates = [...new Set(allDates)];
  } else {
    if (data) {
      const datesUnion = [...new Set(datesArr.flat())];
      uniqueAllDates = datesUnion;
    }
    if (allData) {
      const sentD = allData?.SentimentDaily;
      const volD = allData?.VolumeDaily;
      const ratD = allData?.RatingsDaily
        ? Object.values(allData.RatingsDaily)
        : [];
      const rUnion = [
        ...new Set(ratD[0]?.map((obj) => Object.keys(obj)).flat()),
      ];
      const dtArr = [];
      if (volD) {
        dtArr.push(customizeGraphData(volD, "num_of_docs").labels);
      }
      if (sentD) {
        dtArr.push(customizeGraphData(sentD, "num_of_pos").labels);
      }
      if (ratD) {
        dtArr.push(rUnion);
      }
      uniqueAllDates = [...new Set(dtArr.flat())];
    }
  }
  // Sort the Array by date
  uniqueAllDates.sort((a, b) => {
    return new Date(a) - new Date(b);
  });
  // When there's only one date in the array
  if (uniqueAllDates && uniqueAllDates.length === 1 && uniqueAllDates[0]) {
    let dateStr = uniqueAllDates.pop();
    let date = new Date(dateStr);
    let prevDate = "";
    let nextDate = "";

    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateStr}`);
    } else {
      if (period && period.toLowerCase() === "monthly") {
        date.setMonth(date.getMonth() - 1);
        prevDate = date.toISOString().slice(0, 7);
        date.setMonth(date.getMonth() + 2);
        nextDate = date.toISOString().slice(0, 7);
      } else if (period && period.toLowerCase() === "yearly") {
        date.setFullYear(date.getFullYear() - 1);
        prevDate = date.toISOString().slice(0, 4);
        date.setFullYear(date.getFullYear() + 2);
        nextDate = date.toISOString().slice(0, 4);
      } else {
        date.setDate(date.getDate() - 1);
        prevDate = date.toISOString().split("T")[0];
        date.setDate(date.getDate() + 2);
        nextDate = date.toISOString().split("T")[0];
      }
      uniqueAllDates = [prevDate, dateStr, nextDate];
    }
  }

  const updateGraphData = (volumetric, sentimental) => {
    let data = {};
    if (volumetric) {
      const volData = customizeGraphData(volumetric, "num_of_docs");
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        uniqueAllDates.forEach((date, index) => {
          const indexInVolData = volData.labels.indexOf(date);
          if (indexInVolData === -1) {
            volData.labels.splice(index, 0, date);
            volData.data.splice(index, 0, 0);
          }
        });
      }
      data = {
        labels: volData.labels,
        datasets: [
          {
            label: "Volumetric data",
            tension: 0.5,
            borderColor: "rgba(33, 172, 158, 0.8)",
            borderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
            pointRadius: 0,
            fill: "origin",
            pointHoverBackgroundColor: "white",
            data: volData.data,
            backgroundColor: "black",
          },
        ],
      };
    } else if (sentimental) {
      let posSentData = customizeGraphData(sentimental, "num_of_pos");
      let negSentData = customizeGraphData(sentimental, "num_of_neg");
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        for (let i = 0; i < uniqueAllDates?.length; i++) {
          let pIndex = posSentData.labels.indexOf(uniqueAllDates[i]);
          if (pIndex === -1) {
            posSentData.labels.splice(i, 0, uniqueAllDates[i]);
            posSentData.data.splice(i, 0, 0);
          }
          let nIndex = negSentData.labels.indexOf(uniqueAllDates[i]);
          if (nIndex === -1) {
            negSentData.labels.splice(i, 0, uniqueAllDates[i]);
            negSentData.data.splice(i, 0, 0);
          }
        }
      }
      data = {
        labels: posSentData.labels,
        datasets: [
          {
            label: "Positive sentiment",
            tension: 0.5,
            data: posSentData.data,
            borderColor: "rgba(16, 200, 0, 1)",
            borderWidth: 4,
            pointHoverRadius: 6,
            backgroundColor: "red",
            pointHoverBackgroundColor: "white",
            pointRadius: 0,
            fill: 1,
          },
          {
            label: "Negative sentiment",
            tension: 0.5,
            data: negSentData.data,
            borderColor: "rgba(220, 37, 37, 1)",
            borderWidth: 4,
            pointHoverRadius: 6,
            // backgroundColor: "transparent",
            pointHoverBackgroundColor: "white",
            pointRadius: 0,
            fill: true,
          },
        ],
      };
    }
    setState({ ...localState, data });
    setLoading(false);
  };
  useEffect(() => {
    if (VolumeDaily) {
      updateGraphData(VolumeDaily);
    } else if (SentimentDaily) {
      updateGraphData(null, SentimentDaily);
    }
  }, [VolumeDaily, SentimentDaily]);

  const handlePointClick = (item, type) => {
    var tooltipEl = document.getElementById("chartjs-tooltip");
    if (tooltipEl) {
      tooltipEl.remove();
    }
    if (item && clickable) {
      const datasetIndex = item.datasetIndex;
      const index = item.index;
      const date =
        type === "rating"
          ? timeRatingData?.rating_data?.labels[index]
          : localState.data.labels[index];
      params.delete("match_date");
      params.append("match_date", date);
      params.delete("since");
      params.delete("until");
      params.delete("daysRange");
      params.append("daysRange", "allTime");
      if (type === "sentiment") {
        let sentiment = "0.5";
        if (datasetIndex === 0) sentiment = "1.0";
        else if (datasetIndex === 1) sentiment = "0.0";
        params.delete("sentiment");
        params.append("sentiment", sentiment);
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            chosenDate: localState.data.labels[index],
            chosenSentiment: sentiment,
            dateRange: [],
          },
        });
      } else if (type === "volumetric") {
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            chosenDate: localState?.data?.labels[index],
            dateRange: [],
          },
        });
      } else {
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            chosenDate: timeRatingData?.rating_data?.labels[index],
            dateRange: [],
          },
        });
      }
      history.push({ search: params.toString() });
    }
  };

  const changeView = async () => {
    const payload = {
      dashboard_id: DashboardId,
      topic_id: TopicId,
      topic_type: topicsType,
      period: localState.view,
    };
    const result = await apiRequest(
      "dashboards/change/graph_view",
      "POST",
      payload,
      true
    );
    if (result.error) {
      notification.error({
        message: result.error,
      });
    } else if (VolumeDaily) {
      updateGraphData(result.VolumeDaily);
    } else {
      updateGraphData(null, result.SentimentDaily);
    }
  };

  useEffect(() => {
    if (localState.view && localState.view !== "default") {
      setLoading(true);
      changeView();
    }
  }, [localState.view]);
  let a = [];
  let b = [];
  let z = [];
  let y = [];

  const Google = [];
  const Apple = [];
  let result = [];
  let labels = [];
  let RatingsDaily = Time_Daily_Rating;

  const Filtering = (arr) => {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  };

  // if (Time_Daily_Rating) {

  //   let uniquelabel = []
  //   for (let i = 0; i < RatingsDaily["2"]?.length; i++) {
  //     if (!RatingsDaily["4"]) {
  //       uniquelabel.push(Object.keys(RatingsDaily["2"][i]));
  //     }
  //     Google.push((RatingsDaily["2"][i]))
  //   }

  //   for (let i = 0; i < RatingsDaily["4"]?.length; i++) {

  //     uniquelabel.push(Object.keys(RatingsDaily["4"][i]));
  //     Apple.push((RatingsDaily["4"][i]))

  //   }
  //   result = Filtering(uniquelabel);
  //   result.sort((date1, date2) => date1 - date2);

  //   function getAvgRating(value, index, array) {
  //     return Object.values(value)[0]
  //   }
  //   const a = Google.map(getAvgRating);
  //   const b = Apple.map(getAvgRating);

  //   z = RatingsDaily["2"] ? a : [];
  //   y = RatingsDaily["4"] ? b : [];
  // }

  // labels = result

  const getData = () => {
    // if (Time_Daily_Rating) {
    if (
      Time_Daily_Rating["14"] ||
      Time_Daily_Rating["17"] ||
      Time_Daily_Rating["None"]
    ) {
      let tempData = [];
      let tempLabel = [];
      let num = Time_Daily_Rating["14"]
        ? 14
        : Time_Daily_Rating["17"]
        ? 17
        : "None";
      Time_Daily_Rating[num].map((item) => {
        for (const key in item) {
          tempData.push(item[key]);
          tempLabel.push(key);
        }
      });
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        for (let i = 0; i < uniqueAllDates?.length; i++) {
          let index = tempLabel.indexOf(uniqueAllDates[i]);
          if (index === -1) {
            tempLabel.splice(i, 0, uniqueAllDates[i]);
            tempData.splice(i, 0, 0);
          }
        }
      }
      return {
        labels: tempLabel,
        datasets: [
          {
            label:
              num === 14 ? "Trendyol" : num === "None" ? "Custom" : "Amazon",
            data: tempData,
            tension: 0.6,
            fill: true,
            borderColor: "#1C5789",
            borderColor: "#1C5789",
            borderWidth: 4,
            pointRadius: 0,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
          },
        ],
      };
    } else if (Time_Daily_Rating["2"] || Time_Daily_Rating["4"]) {
      let tempGoogleData = [];
      let tempGoogleLabel = [];
      let tempAppleData = [];
      let tempAppleLabel = [];
      Time_Daily_Rating["2"] &&
        Time_Daily_Rating["2"].map((item) => {
          for (const key in item) {
            tempGoogleData.push(item[key]);
            tempGoogleLabel.push(key);
          }
        });
      Time_Daily_Rating["4"] &&
        Time_Daily_Rating["4"].map((item) => {
          for (const key in item) {
            tempAppleData.push(item[key]);
            tempAppleLabel.push(key);
          }
        });
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        for (let i = 0; i < uniqueAllDates?.length; i++) {
          let gIndex = tempGoogleLabel.indexOf(uniqueAllDates[i]);
          if (gIndex === -1) {
            tempGoogleLabel.splice(i, 0, uniqueAllDates[i]);
            tempGoogleData.splice(i, 0, 0);
          }
          let aIndex = tempAppleLabel.indexOf(uniqueAllDates[i]);
          if (aIndex === -1) {
            tempAppleLabel.splice(i, 0, uniqueAllDates[i]);
            tempAppleData.splice(i, 0, 0);
          }
        }
      }
      return {
        labels: [...new Set(tempGoogleLabel.concat(tempAppleLabel))],
        datasets: [
          {
            label: "Google play",
            tension: 0.6,
            data: tempGoogleData,
            borderColor: "#9054A5",
            borderWidth: 4,
            fill: true,
            borderColor: "#9054A5",
            borderWidth: 4,
            pointRadius: 0,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
          },
          {
            label: "Apple store",
            tension: 0.5,
            data: tempAppleData,
            borderColor: "#DF3C5F",
            borderWidth: 4,
            tension: 0.6,
            fill: true,
            borderColor: "#DF3C5F",
            borderWidth: 4,
            pointRadius: 0,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
          },
        ],
      };
    } else if (
      Time_Daily_Rating["33"] ||
      Time_Daily_Rating["34"] ||
      Time_Daily_Rating["35"]
    ) {
      let tempData = [];
      let tempLabel = [];
      let num = Time_Daily_Rating["33"]
        ? 33
        : Time_Daily_Rating["34"]
        ? 34
        : 35;
      Time_Daily_Rating[num].map((item) => {
        for (const key in item) {
          tempData.push(item[key]);
          tempLabel.push(key);
        }
      });
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        for (let i = 0; i < uniqueAllDates?.length; i++) {
          let index = tempLabel.indexOf(uniqueAllDates[i]);
          if (index === -1) {
            tempLabel.splice(i, 0, uniqueAllDates[i]);
            tempData.splice(i, 0, 0);
          }
        }
      }
      return {
        labels: tempLabel,
        datasets: [
          {
            label: num === 33 ? "ACS" : num === 34 ? "Otelpuan" : "Anket",
            data: tempData,
            // backgroundColor: hexToRgbA("#9054A5", 0.8),
            tension: 0.6,
            fill: true,
            pointRadius: 0,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
            borderWidth: 4,
            borderColor: "#9054A5",
          },
        ],
      };
    } else return [];
    // } else {
    //   return [];
    // }
  };

  // const getLabel = () => {
  //   if (Time_Daily_Rating) {
  //     let temp = Time_Daily_Rating[14].map(item => {
  //       for (const key in item) {
  //         return key;
  //       }
  //     })
  //     return temp
  //   } else return [];
  // }

  // const data2 = {
  //   labels: getLabel(),
  //   datasets: [
  //     {
  //       data: getData(),
  //       tension: 0.5,
  //       borderColor: "rgba(75,192,192,1)",
  //       borderWidth: 1
  //     }
  //   ],
  // };
  const basicLineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    datasets: {
      point: {
        tension: 0.5,
        fill: true,
        pointRadius: 0,
        backgroundColor: "red",
      },
    },
    onHover: (event, chartElement) => {
      if (chartElement.length) {
        event.native.target.style.cursor = "pointer";
      } else {
        event.native.target.style.cursor = "default";
      }
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
        labels: {
          usePointStyle: true,
          color: theme === "dark" ? "white" : "black",
          font: {
            size: 12,
            color: theme === "dark" ? "white" : "black",
          },
          pointStyle: "circle",
          pointStyleWidth: 16,
          padding: 20,
          boxWidth: 12,
        },
      },
      tooltip: {
        enabled: false,
        external: (context) => {
          // Tooltip Element
          var tooltipEl = document.getElementById("chartjs-tooltip");

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<div></div>";
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            if (tooltipEl) {
              tooltipEl.remove();
            }
            return;
          }
          // Set caret Position
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            let innerHtml = "<div class=" + styles.driversTooltip + ">";
            tooltipModel.dataPoints.forEach((dataPoint) => {
              const dataset = dataPoint.dataset;
              const datasetLabel = dataPoint.label;
              const datasetValue = dataPoint.raw;
              innerHtml += `
                <div class=${styles.topicLabelInTooltip}>
                  <span class=${styles.fillColor} style="background-color: ${dataset?.borderColor}"></span>
                  <span class=${styles.topicLabel}>${datasetLabel}: ${datasetValue}</span>
                </div>`;
            });
            innerHtml += "</div>";
            const tooltipRoot = tooltipEl.querySelector("div");
            tooltipRoot.innerHTML = innerHtml;
          }
          // this will be the overall tooltip
          const position = context.chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          let left = position.left + window.pageXOffset + tooltipModel.caretX;
          if (left + tooltipEl.offsetWidth > window.innerWidth) {
            left =
              position.left +
              window.pageXOffset / 2 +
              tooltipModel.caretX -
              tooltipEl.offsetWidth;
          }
          tooltipEl.style.left = left + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
      datalabels: {
        display: false,
      },
      filler: {
        propagate: true,
      },
    },
    scales: {
      x: {
        grid: {
          borderColor:
            theme === "dark"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
          color: "#808080",
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          padding: 20,
          color: theme === "dark" ? "white" : "black",
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          padding: 20,
          color: theme === "dark" ? "white" : "black",
        },
        grid: {
          borderColor:
            theme === "dark"
              ? "rgba(255, 255, 255, 0.4)"
              : "rgba(0, 0, 0, 0.4)",
          color: theme === "dark" ? "white" : "black",
          drawOnChartArea: false,
          drawTicks: false,
        },
        afterDataLimits: (axis) => {
          const max = axis.max;
          let addVal = 0;
          if (max < 5) {
            addVal = 1;
          } else if (max < 10) {
            addVal = 3;
          } else if (max < 100) {
            addVal = 10;
          } else if (max < 1000) {
            addVal = 50;
          } else if (max < 10000) {
            addVal = 500;
          } else if (max < 100000) {
            addVal = 1000;
          } else if (max < 1000000) {
            addVal = 5000;
          } else {
            addVal = 10000;
          }
          axis.max += addVal;
        },
      },
    },
  };
  useEffect(() => {
    if (Time_Daily_Rating) {
      setTimeRatingData({
        rating_data: getData(),
      });
    }
  }, [Time_Daily_Rating]);
  return (
    <>
      {!Time_Rating ? (
        <div className={styles.container}>
          {TopicId && (
            <CreationItem
              item={{
                valueType: "dropdown",
                acceptedValues: ["daily", "weekly", "monthly", "yearly"],
                placeholder: "Select the chart view",
                key: "view",
              }}
              localState={localState}
              setState={setState}
              defaultValue={"monthly"}
              value={localState.view}
            />
          )}
          <Spin spinning={loading}>
            <div className={styles.chartAreaWrapper}>
              <CustomChart
                type={"line"}
                data={localState.data}
                height={200}
                options={{
                  ...basicLineOptions,
                  onClick: (e) => {
                    if (!clickable) return;
                    var point = e.chart?.getElementsAtEventForMode(
                      e,
                      "nearest",
                      { intersect: true },
                      true
                    );
                    if (point?.length > 0) {
                      handlePointClick(
                        point[0],
                        VolumeDaily ? "participation" : "sentiment"
                      );
                    }
                  },
                }}
                plugins={[
                  {
                    beforeDraw: (chart) => {
                      const { ctx, tooltip, chartArea } = chart;
                      if (tooltip?.dataPoints?.length > 0) {
                        ctx.save();
                        const activePoint = tooltip.dataPoints[0];
                        const x = activePoint.element.x;
                        ctx.beginPath();
                        ctx.setLineDash([4, 6]);
                        ctx.moveTo(x, chartArea.top);
                        ctx.lineTo(x, chartArea.bottom);
                        ctx.lineWidth = 2;
                        ctx.strokeStyle = "rgba(155, 155, 155)";
                        ctx.stroke();
                        ctx.restore();
                      }
                    },
                  },
                ]}
                theme={theme}
              />
            </div>
          </Spin>
        </div>
      ) : (
        <div className={styles.container}>
          {TopicId && (
            <CreationItem
              item={{
                valueType: "dropdown",
                acceptedValues: ["daily", "weekly", "monthly", "yearly"],
                placeholder: "Select the chart view",
                key: "view",
              }}
              localState={localState}
              setState={setState}
              defaultValue={"monthly"}
              value={localState.view}
            />
          )}
          <Spin spinning={loading}>
            <div className={styles.chartAreaWrapper}>
              <CustomChart
                type={"line"}
                data={timeRatingData?.rating_data}
                height={200}
                options={{
                  ...basicLineOptions,
                  onClick: (e) => {
                    if (!clickable) return;
                    var point = e.chart?.getElementsAtEventForMode(
                      e,
                      "nearest",
                      { intersect: true },
                      true
                    );
                    if (point?.length > 0) {
                      handlePointClick(point[0], "rating");
                    }
                  },
                }}
                plugins={[
                  {
                    beforeDraw: (chart) => {
                      const { ctx, tooltip, chartArea } = chart;
                      if (tooltip?.dataPoints?.length > 0) {
                        ctx.save();
                        const activePoint = tooltip.dataPoints[0];
                        const x = activePoint.element.x;
                        ctx.beginPath();
                        ctx.setLineDash([4, 6]);
                        ctx.moveTo(x, chartArea.top);
                        ctx.lineTo(x, chartArea.bottom);
                        ctx.lineWidth = 2;
                        ctx.strokeStyle = "rgba(155, 155, 155)";
                        ctx.stroke();
                        ctx.restore();
                      }
                    },
                  },
                ]}
                theme={theme}
              />
            </div>
          </Spin>
        </div>
      )}
    </>
  );
};

export default LineChartWrapper;
