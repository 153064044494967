import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import { hexToRgbA } from "util/common";
import { notification, Button, Spin, Checkbox } from "antd";
import ReactReadMoreReadLess from "react-read-more-read-less";
import StarRatings from "react-star-ratings";
import { apiRequest } from "util/services";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  CaretDownFilled,
  CaretUpFilled,
  CloseOutlined,
} from "@ant-design/icons";
import SingleSelect from "helperComponents/SingleSelect";
import RangeBar from "../RangeBar";
import Dropdown from "helperComponents/CustomDropdown";
const ReviewItem = ({
  text,
  title,
  date,
  sentiment,
  intents,
  engagements,
  checkbox,
  relatedTopics,
  rate,
  sequences,
  classifierSequences,
  currentDashboardHash,
  feedbackModeEnabled,
  setReviewsLoading,
  shared,
  name,
  highlights,
  getHighlights,
  pic,
  prevDeletionKey,
  setPrevDeletionKey,
  highlightedText,
  setHighlightedText,
  selectionIsFromSameReview,
  setSelectionIsFromSameReview,
  userVerified,
  displayFeedbackArea,
  username,
  likes,
  deletedTopics,
  setDeletedTopics,
  deletedIntents,
  setDeletedIntents,
  boardDataSrc,
  restaurant,
  prevSelectionKey,
  setPrevSelectionKey,
  internalId,
  // facebookReplies,
  // getFacebookReplies,
  reviewPlatformId,
  reviewCode,
  npsRating,
  npsScale,
  showCustomTopicLabel,
  showAiTopicLabel,
  Gpid,
  date_gpid,
  levelWiseTopics,
  showCustomTopicParentLabel,
  allReviews,
  publish,
  openCase,
  action,
  setPublish,
  setAction,
  setOpenCase,
  prevClassifierKey,
  setPrevClassifierKey,
  showPivotsInReviews,
  allReviewsIntents,
  setAllReviewsIntents,
  pageChanged,
  setPageChanged,
  getOnlyReviews,
  reviewPageNumber,
  startIndex,
  decisionTags,
  setDecisionTags,
  isTopicLearningBased,
}) => {
  const [relTopics, setRelTopics] = useState(relatedTopics);
  const [parentTopics, setParentTopics] = useState([]);
  const [childTopics, setChildTopics] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [selectedParent, setSelectedParent] = useState(null);
  const [selectedSentiment, setSelectedSentiment] = useState([]);
  const [selectedIntent, setSelectedIntent] = useState([]);
  const [modifiedSentiments, setModifiedSentiments] = useState({});
  const [modifiedIntents, setModifiedIntents] = useState({});
  const [newSentiment, setNewSentiment] = useState(null);
  const [overallSentiment, setOverallSentiment] = useState(null);
  const [overallIntent, setOverallIntent] = useState(null);
  const [newIntent, setNewIntent] = useState(null);
  const [deletedTopic, setDeletedTopic] = useState(null);
  const [displayActions, setDisplayActions] = useState(false);
  const [topicClicked, setTopicClicked] = useState(false);
  const [topicsEdited, setTopicsEdited] = useState(false);
  const [topicSentiments, setTopicSentiments] = useState(null);
  const [topicIntents, setTopicIntents] = useState(null);
  const [markedTextClicked, setMarkedTextClicked] = useState(false);
  const [clickedTopic, setClickedTopic] = useState(null);
  const [sentimentBarValue, setSentimentBarValue] = useState(
    sentiment === "None"
      ? ""
      : sentiment == 1.0
      ? 100
      : sentiment === 0.5
      ? 50
      : 0
  );
  const [resetParent, setResetParent] = useState(false);
  const [resetSentiment, setResetSentiment] = useState(false);
  const [resetIntents, setResetIntents] = useState(false);
  const [resetOptions, setResetOptions] = useState(false);
  const [topicIndices, setTopicIndices] = useState(null);
  const [intentIndices, setIntentIndices] = useState(null);
  const [indicesSentiment, setIndicesSentiment] = useState(null);
  const [collapseAssociations, setCollapseAssociations] = useState(true);
  const associationsRef = useRef();
  const [associationsRefHeight, setAssociationsRefHeight] = useState(0);
  // const [facebookReplyMessage, setFacebookReplyMessage] = useState("");
  // const [
  //   isReplyFacebookModalVisible,
  //   setIsReplyFacebookModalVisible,
  // ] = useState(false);
  // const [
  //   isReplyFacebookModalLoading,
  //   setIsReplyFacebookModalLoading,
  // ] = useState(false);
  // const [
  //   isReplyFacebookReviewsVisible,
  //   setIsReplyFacebookReviewsVisible,
  // ] = useState(false);
  const [publishChanged, setPublishChanged] = useState(false);
  const [openCaseChanged, setOpenCaseChanged] = useState(false);
  const [actionChanged, setActionChanged] = useState(false);
  const [classifierIndices, setClassifierIndices] = useState(null);
  const [clickedDecision, setClickedDecision] = useState(null);
  const { t } = useTranslation();
  const dashboard_id = useSelector(
    (state) => state.DashboardReducer.dashboard?.ID
  );
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const language = useSelector(
    (state) => state.DashboardReducer.dashboard?.Language
  );
  const platformId = useSelector(
    (state) => state.DashboardReducer.dashboard?.PlatformId
  );
  const advancedAllowed = useSelector(
    (state) => state.userReducer.advancedAllowed
  );
  const userId = useSelector(
    (state) => state.DashboardReducer.dashboard?.UserId
  );
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 950);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);
  // const replyFacebook = async (comment_id, message) => {
  //   if (isReplyFacebookModalLoading) {
  //     return;
  //   }
  //   if (!message) {
  //     notification.error({
  //       message: t("notifications.89"),
  //     });
  //     return;
  //   }
  //   setIsReplyFacebookModalLoading(true);
  //   const url = "dashboards/post_facebook_comment";
  //   const payload = {
  //     comment_id: comment_id,
  //     message: message,
  //     firebase_id: userId,
  //   };
  //   const result = await apiRequest(url, "POST", payload, true);
  //   if (!result.error) {
  //     getFacebookReplies();
  //     notification.success({
  //       message: result.success,
  //     });
  //   } else {
  //     notification.error({
  //       message: result.error,
  //     });
  //   }
  //   setIsReplyFacebookModalVisible(false);
  //   setIsReplyFacebookModalLoading(false);
  // };
  const addUserFeedback = async (payload) => {
    setReviewsLoading(true);
    const result = await apiRequest(
      "dashboards/correction",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      notification.success({
        message: result.success ? result.success : t("review.5"),
      });
      getHighlights(allReviews);
    } else {
      if (payload.type === "sentiment") {
        setOverallSentiment(null);
        setResetSentiment(true);
      } else {
        setOverallIntent(null);
        setResetIntents(true);
      }
      notification.error({
        message: result.error
          ? result.error
          : "Updating sentiment feedback failed.",
      });
      setReviewsLoading(false);
    }
  };
  useEffect(() => {
    if (overallSentiment) {
      const pay = {
        type: "sentiment",
        language,
        wrong_value: sentiment,
        correct_value: overallSentiment?.value,
        review_text: text,
        Gpid: Gpid,
        dashboard_id: dashboard_id,
        date_gpid: date_gpid,
      };
      if (internalId) {
        pay.internal_id = internalId;
      }
      addUserFeedback(pay);
    }
  }, [overallSentiment]);
  useEffect(() => {
    if (overallIntent) {
      const pay = {
        type: "intent",
        language,
        wrong_value: intents,
        correct_value: overallIntent?.value,
        review_text: text,
        Gpid: Gpid,
        dashboard_id: dashboard_id,
        date_gpid: date_gpid,
      };
      if (internalId) {
        pay.internal_id = internalId;
      }
      addUserFeedback(pay);
    }
  }, [overallIntent]);
  /*const select = (topic) => {
    const payload = {
      selected_topic: topic.id,
    };
    if (boardDataSrc) {
      payload.trends = {};
    }
    dispatch({
      type: actions.SELECT_TOPIC,
      payload,
    });
    if (shared && currentDashboardHash) {
      if (topic.type === "ai") {
        history.push({
          pathname: `/share/Dashboard/${currentDashboardHash}`,
          state: {
            searchInsight: true,
            selected_topic: topic.id,
          }
        });
      }
      else if (topic.type === "custom") {
        history.push({
          pathname: `/console/customTopics/${currentDashboardHash}`,
          state: {
            searchInsight: true,
            selected_topic: topic.id,
          }
        });
      }
    } else {
      if (topic.type === "ai") {
        history.push({
          pathname: `/console/Dashboard/${dashboard_id}`,
          state: {
            searchInsight: true,
            selected_topic: topic.id,
          }
        });
      }
      else if (topic.type === "custom") {
        history.push({
          pathname: `/console/customTopics/${dashboard_id}`,
          state: {
            searchInsight: true,
            selected_topic: topic.id,
          }
        });
      }
    }
  };*/
  const color = (topicType) => {
    if (topicType === "ai") {
      return [
        "rgba(215, 183, 124, 0.945)",
        theme === "dark" ? hexToRgbA("#754d03", 1) : hexToRgbA("#bd7b01", 1),
      ];
    } else if (topicType === "custom") {
      return [
        "rgba(185, 157, 255, 0.3)",
        theme === "dark" ? "rgb(185, 167, 230)" : "rgb(101, 77, 163)",
      ];
    }
  };

  const label = (Label, topicType) => {
    if (topicType === "ai") {
      return `${Label}`;
    } else if (topicType === "custom") {
      if (showCustomTopicParentLabel) {
        return `${Label}`;
      } else {
        let str = Label?.split(">").at(-1);
        str = str?.trim();
        if (str) {
          return `${str}`;
        } else return `${Label}`;
      }
    }
  };
  const mobileLabel = (label) => {
    if (label && label !== "") {
      const hasParent = label.includes(">");
      if (hasParent) {
        let parent = label.split(">")[0];
        const child = removeParentFromChildren(label);
        if (parent.length > 5) {
          return `${parent.slice(0, 4)}... > ${child}`;
        } else {
          return `${parent} > ${child}`;
        }
      } else {
        return label;
      }
    }
  };
  const handleUserText = (e) => {
    let element = document.getElementById("custom_text_input");
    if (element.scrollTop !== 0) {
      element.style.height = element.scrollHeight + "px";
    }
  };
  const writeClipboardText = (text) => {
    try {
      navigator.clipboard.writeText(text);
    } catch (error) {
      console.error(error.message);
    }
  };
  const reviewRef = useRef();
  const handleMarkClick = (e, startIndex, endIndex) => {
    setMarkedTextClicked(true);
    setHighlightedText((prevHighlightedText) => {
      const updatedText = prevHighlightedText[date_gpid].filter((selection) => {
        const [start, end] = selection.indices;
        return !(startIndex === start && endIndex === end);
      });
      return { ...prevHighlightedText, [date_gpid]: updatedText };
    });
  };
  useEffect(() => {
    if (
      highlightedText &&
      highlightedText[date_gpid] &&
      highlightedText[date_gpid].length > 0
    ) {
      const handleCopy = () => {
        const hlt = highlightedText[date_gpid]?.map((item) => item.text);
        writeClipboardText(hlt.join(" "));
      };
      document.addEventListener("copy", handleCopy);
      return () => {
        document.removeEventListener("copy", handleCopy);
      };
    }
  });
  const Highlight = ({ search = [], child = "" }) => {
    if (!search.length) {
      return child;
    }
    const parts = [];
    let lastIndex = 0;

    search.forEach((item) => {
      const { indices, sentiment } = item;
      const startIndex = indices[0];
      const endIndex = indices[1];
      parts.push(child.slice(lastIndex, startIndex));
      const modifiedSent =
        Object.values(modifiedSentiments).length > 0
          ? Object.values(modifiedSentiments)[0]
          : newSentiment
          ? newSentiment
          : null;
      parts.push(
        <mark
          key={startIndex}
          className={styles.highlight}
          style={
            !deletedTopic
              ? {
                  backgroundColor:
                    parseFloat(modifiedSent ? modifiedSent?.value : sentiment) >
                      0.5 &&
                    parseFloat(
                      modifiedSent ? modifiedSent?.value : sentiment
                    ) <= 1.0
                      ? "#369E3A"
                      : parseFloat(
                          modifiedSent ? modifiedSent?.value : sentiment
                        ) == 0.5
                      ? "#FFC223"
                      : parseFloat(
                          modifiedSent ? modifiedSent?.value : sentiment
                        ) >= 0.0 &&
                        parseFloat(
                          modifiedSent ? modifiedSent?.value : sentiment
                        ) < 0.5
                      ? "#FF1D1D"
                      : "#858585",
                }
              : {
                  backgroundColor: "transparent",
                  color: theme === "dark" ? "white" : "black",
                  fontWeight: "500",
                }
          }
          onClick={(e) => handleMarkClick(e, startIndex, endIndex)}
        >
          {child.slice(startIndex, endIndex + 1)}
        </mark>
      );
      lastIndex = endIndex + 1;
    });

    parts.push(child.slice(lastIndex));
    return parts;
  };
  const handleDiscardChanges = () => {
    window.getSelection().removeAllRanges();
    setDisplayActions(false);
    setTopicsEdited(false);
    setHighlightedText({});
    setModifiedIntents({});
    setModifiedSentiments({});
    setNewIntent(null);
    setNewSentiment(null);
    setResetOptions(true);
    setTopicClicked(false);
    setClickedDecision(null);
    setSelectedParent(null);
    if (deletedTopic) {
      setDeletedTopic(null);
    }
    if (clickedTopic) {
      setClickedTopic(null);
    }
    if (markedTextClicked) {
      setMarkedTextClicked(false);
    }
    if (clickedDecision) {
      setClickedDecision(null);
    }
    if (deletedTopics && deletedTopics[date_gpid]?.length > 0) {
      setDeletedTopics({});
      setDeletedIntents({});
      setRelTopics([...relTopics, ...deletedTopics[date_gpid]]);
    }
    setPublish((prev) => {
      const updated = { ...prev };
      updated[date_gpid] = null;
      return updated;
    });
    setOpenCase((prev) => {
      const updated = { ...prev };
      updated[date_gpid] = null;
      return updated;
    });
    setAction((prev) => {
      const updated = { ...prev };
      updated[date_gpid] = null;
      return updated;
    });
    setPublishChanged(false);
    setOpenCaseChanged(false);
    setActionChanged(false);
    setPrevSelectionKey(null);
    setPrevClassifierKey(null);
    setPrevDeletionKey(null);
  };
  const handleMouseDown = (e) => {
    const dropdownClicked = e.target.closest(
      `.ant-select, .ant-select-selector, .ant-select-selection-item, .ant-select-item, .ant-checkbox, .ant-select-dropdown, .ant-dropdown, .ant-dropdown-trigger, .ant-dropdown-menu, .itemContainer, .itemRelatedTopics, .ant-btn, .actions, .anticon`
    );
    const closestKeyElement = e.target?.closest("[keyelement]");
    const keyElement = closestKeyElement?.getAttribute("keyelement");
    if (
      !dropdownClicked &&
      !(
        topicsEdited ||
        selectedParent ||
        displayActions ||
        (deletedTopics && deletedTopics[date_gpid]?.length > 0) ||
        markedTextClicked ||
        publishChanged ||
        openCaseChanged ||
        actionChanged
      )
    ) {
      if (reviewRef?.current && !reviewRef.current.contains(e.target)) {
        window.getSelection().removeAllRanges();
        setHighlightedText({});
        handleDiscardChanges();
      }
    }
  };
  const handleMouseup = (e) => {
    const isCtrlPressed = e.ctrlKey || e.metaKey; // Check if CTRL key is pressed
    const dropdownClicked = e.target.closest(
      `.ant-select, .ant-select-selector, .ant-select-selection-item, .ant-select-item, .ant-checkbox .ant-select-dropdown, .ant-dropdown, .ant-dropdown-menu, .ant-dropdown-trigger, .itemContainer, .itemRelatedTopics, itemRelatedTopics, .ant-btn, .actions, .anticon`
    );
    const selection = !dropdownClicked && window.getSelection();
    if (!selection.rangeCount) return;
    const range = selection.getRangeAt(0);
    const selectedText = range.toString();
    if (selectedText) {
      // Calculate indices based on the Range object
      let node = range.startContainer;
      let totalStartIndex = range.startOffset;
      while (node && node !== reviewRef.current) {
        if (node.previousSibling) {
          node = node.previousSibling;
          totalStartIndex += node.textContent.length;
        } else {
          node = node.parentNode;
        }
      }

      node = range.endContainer;
      let totalEndIndex = range.endOffset;
      while (node && node !== reviewRef.current) {
        if (node.previousSibling) {
          node = node.previousSibling;
          totalEndIndex += node.textContent.length;
        } else {
          node = node.parentNode;
        }
      }
      if (
        !dropdownClicked &&
        prevSelectionKey &&
        e.target
          ?.closest(
            `.${
              feedbackModeEnabled
                ? styles.reviewText
                : styles.reviewTextWithNoSelect
            }`
          )
          ?.getAttribute("keyelement") !== prevSelectionKey
      ) {
        window.getSelection().removeAllRanges();
        setTopicsEdited(false);
        setHighlightedText({});
        setDeletedTopics({});
        setDeletedIntents({});
        setSelectionIsFromSameReview(false);
        setPublishChanged(false);
        setOpenCaseChanged(false);
        setActionChanged(false);
      } else {
        setSelectionIsFromSameReview(true);
      }
      setPrevSelectionKey(
        e.target
          ?.closest(
            `.${
              feedbackModeEnabled
                ? styles.reviewText
                : styles.reviewTextWithNoSelect
            }`
          )
          ?.getAttribute("keyelement")
      );
      // If CTRL is pressed, append the new selection to the existing highlighted text
      // Otherwise, replace the existing highlighted text with the new selection
      setHighlightedText((prevHighlightedText) => {
        const newSelection = {
          text: selectedText,
          indices: [totalStartIndex, totalEndIndex - 1],
        };

        // Filter out any existing selections that overlap with the new selection
        const filteredHighlightedText = prevHighlightedText[date_gpid]
          ? prevHighlightedText[date_gpid].filter((selection) => {
              const [start, end] = selection.indices;
              const [newStart, newEnd] = newSelection.indices;
              return !(newStart <= end && newEnd >= start);
            })
          : [];

        const updatedHighlightedText = isCtrlPressed
          ? [...filteredHighlightedText, newSelection]
          : [newSelection];
        // Sort the highlighted text array based on indices
        if (updatedHighlightedText) {
          updatedHighlightedText.sort((a, b) => a.indices[0] - b.indices[0]);
          return {
            ...prevHighlightedText,
            [date_gpid]: updatedHighlightedText,
          };
        }
      });
    }
  };

  useEffect(() => {
    if (levelWiseTopics) {
      // Split levelWiseTopics into parentTopics and childTopics
      const pTopics = [];
      const cTopics = {};
      const getChildTopics = (topic, prefix = "", exclude_parent = false) => {
        const c = [];
        const labelWithPrefix = prefix
          ? `${prefix} > ${topic.label}`
          : topic.label;
        if (!topic.id && topic.children.length > 0) {
          topic.children.forEach((childTpc) => {
            if (!pTopics.map((tpc) => tpc.id).includes(childTpc.id)) {
              if (childTpc.learning) {
                pTopics.push({
                  id: childTpc.id,
                  label: `${labelWithPrefix} > ${childTpc.label}`,
                  value: childTpc.id,
                  learning: childTpc.learning ? true : false,
                });
              }
              if (childTpc.learning) {
                cTopics[childTpc.id] = [
                  ...getChildTopics(childTpc, labelWithPrefix, true),
                ];
              }
            }
          });
        }
        if (topic.children.length === 0) {
          if (!exclude_parent && topic.learning) {
            c.push({
              id: topic.id,
              label: labelWithPrefix,
              value: topic.id,
              learning: topic.learning ? true : false,
            });
          }
        } else {
          if (!exclude_parent && topic.learning) {
            c.push({
              id: topic.id,
              label: labelWithPrefix,
              value: topic.id,
              learning: topic.learning ? true : false,
            });
          }
          topic.children.forEach((child) => {
            c.push(...getChildTopics(child, labelWithPrefix));
          });
        }
        return c;
      };

      levelWiseTopics.forEach((topic) => {
        if (topic.learning) {
          pTopics.push({ id: topic.id, label: topic.label, value: topic.id });
        }
        topic.children.forEach((child) => {
          cTopics[topic.id] = [...getChildTopics(topic)];
        });
      });
      setParentTopics(pTopics);
      setChildTopics(cTopics);
    }
  }, [levelWiseTopics]);
  const overallIntentOptions = [
    {
      id: "33",
      value: "33",
      label: t("feedback_dropdown_values.13"),
      img: "/assets/mixed_intents.svg",
    },
    {
      id: "1",
      value: "1",
      label: t("feedback_dropdown_values.5"),
      img: "/assets/Appraisal.svg",
    },
    {
      id: "2",
      value: "2",
      label: t("feedback_dropdown_values.6"),
      img: "/assets/Complaint.svg",
    },
    {
      id: "3",
      value: "3",
      label: t("feedback_dropdown_values.7"),
      img: "/assets/Suggestion.svg",
    },
    {
      id: "4",
      value: "4",
      label: t("feedback_dropdown_values.8"),
      img: "/assets/Remark.svg",
    },
    {
      id: "5",
      value: "5",
      label: t("feedback_dropdown_values.9"),
      img: "/assets/Marketing & Spam.svg",
    },
    {
      id: "6",
      value: "6",
      label: t("feedback_dropdown_values.10"),
      img: "/assets/Information.svg",
    },
    {
      id: "7",
      value: "7",
      label: t("feedback_dropdown_values.11"),
      img: "/assets/Request.svg",
    },
    {
      id: "8",
      value: "8",
      label: t("feedback_dropdown_values.12"),
      img: "/assets/Comparison.svg",
    },
  ];
  const intentOptions = [
    {
      id: "-1",
      value: "-1",
      label: t("feedback_dropdown_values.1"),
      img: "/assets/Unspecified.svg",
    },
    {
      id: "1",
      value: "1",
      label: t("feedback_dropdown_values.5"),
      img: "/assets/Appraisal.svg",
    },
    {
      id: "2",
      value: "2",
      label: t("feedback_dropdown_values.6"),
      img: "/assets/Complaint.svg",
    },
    {
      id: "3",
      value: "3",
      label: t("feedback_dropdown_values.7"),
      img: "/assets/Suggestion.svg",
    },
    {
      id: "4",
      value: "4",
      label: t("feedback_dropdown_values.8"),
      img: "/assets/Remark.svg",
    },
    {
      id: "5",
      value: "5",
      label: t("feedback_dropdown_values.9"),
      img: "/assets/Marketing & Spam.svg",
    },
    {
      id: "6",
      value: "6",
      label: t("feedback_dropdown_values.10"),
      img: "/assets/Information.svg",
    },
    {
      id: "7",
      value: "7",
      label: t("feedback_dropdown_values.11"),
      img: "/assets/Request.svg",
    },
    {
      id: "8",
      value: "8",
      label: t("feedback_dropdown_values.12"),
      img: "/assets/Comparison.svg",
    },
  ];
  const overallSentimentOptions = [
    {
      id: "33",
      value: "33",
      label: t("feedback_dropdown_values.13"),
      img: "/assets/mixed_sentiments.svg",
    },
    {
      id: "1.0",
      value: "1.0",
      label: t("feedback_dropdown_values.2"),
      img: "/assets/Positive.svg",
    },
    {
      id: "0.5",
      value: "0.5",
      label: t("feedback_dropdown_values.3"),
      img: "/assets/Neutral.svg",
    },
    {
      id: "0.0",
      value: "0.0",
      label: t("feedback_dropdown_values.4"),
      img: "/assets/Highly_negative.svg",
    },
  ];
  const sentimentOptions = [
    {
      id: "-1",
      value: "-1",
      label: t("feedback_dropdown_values.1"),
      img: "/assets/Unspecified_Sentiment.svg",
    },
    {
      id: "1.0",
      value: "1.0",
      label: t("feedback_dropdown_values.2"),
      img: "/assets/Positive.svg",
    },
    {
      id: "0.5",
      value: "0.5",
      label: t("feedback_dropdown_values.3"),
      img: "/assets/Neutral.svg",
    },
    {
      id: "0.0",
      value: "0.0",
      label: t("feedback_dropdown_values.4"),
      img: "/assets/Negative.svg",
    },
  ];

  const applyFeedback = async (payload) => {
    const url = "dashboards/v2/feedback";
    const result = await apiRequest(url, "POST", payload, true);
    if (result?.success) {
      notification.success({
        message: "Feedback applied successfully.",
      });
      getHighlights(allReviews);
    } else {
      notification.error({
        message: result?.error,
      });
      setReviewsLoading(false);
    }
  };

  const getIntersections = (sequences, newSequence) => {
    if (!sequences || sequences?.length === 0) {
      return [];
    }
    return sequences
      .filter(
        (seq) =>
          parseInt(newSequence.start_index) <= parseInt(seq.end_index) &&
          parseInt(newSequence.end_index) >= parseInt(seq.start_index)
      )
      .map((seq) => ({
        ...seq,
        topicIds: seq.topic_associations.map((topic) => topic.id?.toString()),
        topicIntents: seq.topic_associations.map((topic) => topic.intent),
      }));
  };
  const getIntersectedSeqeunces = (sequences, newSequence, fromClassifier) => {
    if (!sequences || sequences?.length === 0) {
      return [];
    }
    return sequences.filter(
      (seq) =>
        parseInt(newSequence.start_index) <= parseInt(seq.end_index) &&
        parseInt(newSequence.end_index) >= parseInt(seq.start_index)
    );
  };
  const filterDeletedTopics = (sequences, deleted) => {
    if (!sequences || sequences?.length === 0) {
      return [];
    }
    sequences.forEach((seq) => {
      const stringDeletedTopics = deleted?.map((t) => t.id?.toString());
      seq.topic_associations = seq?.topic_associations?.filter((ta) => {
        return !stringDeletedTopics.includes(ta?.id?.toString());
      });
    });
    return sequences;
  };
  const removeUnhighlightedSequence = (sequences, newSeq, clickedTopic) => {
    return sequences
      .filter((sequence) => {
        // Check if the sequence doesn't include the clickedTopic id
        if (
          !sequence.topic_associations.some(
            (topic) => topic.id === clickedTopic.id
          )
        ) {
          return true;
        } else {
          // Check if the sequence is in the newSeq
          return newSeq.some(
            (newSequence) => newSequence.seq_id === sequence.seq_id
          );
        }
      })
      .map((sequence) => {
        return { ...sequence, isAnnotated: true };
      });
  };
  const removeSequenceUpdateClassifier = (
    sequences,
    newSeq,
    clickedDecision
  ) => {
    // Determine the classifier key based on the clicked decision
    const cls = ["Publish", "Do not Publish"].includes(clickedDecision)
      ? "mPublish"
      : ["Open a case", "Do not open a case"].includes(clickedDecision)
      ? "mOpenCase"
      : "mAction";

    // Create a new array to hold the updated sequences
    let updatedSequences = sequences.map((sequence) => {
      // Find a matching sequence in newSeq
      let match = newSeq.find(
        (newSeqItem) => newSeqItem.seq_id === sequence.seq_id
      );
      // If a match is found, update the classifier value
      if (match) {
        return {
          ...sequence,
          isAnnotated: true,
          classifier: {
            ...sequence.classifier,
            [cls]: match.classifier[cls],
          },
        };
      }
      // If no match is found and newSeq is empty, set the classifier[cls] to null
      if (newSeq.length === 0) {
        if (clickedDecision === "Publish") {
          /*Make only classifiers where mPublish is true to null*/
          return sequence.classifier.mPublish === true
            ? {
                ...sequence,
                isAnnotated: true,
                classifier: { ...sequence.classifier, [cls]: null },
              }
            : sequence;
        } else if (clickedDecision === "Do not Publish") {
          /*Make only classifiers where mPublish is false to null*/
          return sequence.classifier.mPublish === false
            ? {
                ...sequence,
                isAnnotated: true,
                classifier: { ...sequence.classifier, [cls]: null },
              }
            : sequence;
        }
        if (clickedDecision === "Open a case") {
          /*Make only classifiers where mOpenCase is true to null*/
          return sequence.classifier.mOpenCase === true
            ? {
                ...sequence,
                isAnnotated: true,
                classifier: { ...sequence.classifier, [cls]: null },
              }
            : sequence;
        } else if (clickedDecision === "Do not open a case") {
          /*Make only classifiers where mOpenCase is false to null*/
          return sequence.classifier.mOpenCase === false
            ? {
                ...sequence,
                isAnnotated: true,
                classifier: { ...sequence.classifier, [cls]: null },
              }
            : sequence;
        }
        if (clickedDecision === "Take action") {
          /*Make only classifiers where mAction is true to null*/
          return sequence.classifier.mAction === true
            ? {
                ...sequence,
                isAnnotated: true,
                classifier: { ...sequence.classifier, [cls]: null },
              }
            : sequence;
        } else if (clickedDecision === "Do not take action") {
          /*Make only classifiers where mAction is false to null*/
          return sequence.classifier.mAction === false
            ? {
                ...sequence,
                isAnnotated: true,
                classifier: { ...sequence.classifier, [cls]: null },
              }
            : sequence;
        }
      }
      // If no match is found and newSeq is not empty, return the sequence as is
      return sequence;
    });

    return updatedSequences;
  };
  function updateAssociations(sequences, newSequences) {
    const newSequencesMap = new Map();
    newSequences.forEach((seq) => {
      newSequencesMap.set(seq.seq_id, seq);
    });

    // Map over the original sequences to update topic_associations
    return sequences.map((seq) => {
      const newSeq = newSequencesMap.get(seq.seq_id);
      if (newSeq) {
        // Update only the topic_associations that have the same id
        const updatedTopicAssociations = seq.topic_associations.map((topic) => {
          const newTopic = newSeq.topic_associations[0];
          return newTopic
            ? {
                ...topic,
                sentiment: newTopic.sentiment,
                intent: newTopic.intent,
              }
            : topic;
        });

        // Return the updated sequence
        return {
          ...seq,
          isAnnotated: true,
          topic_associations: updatedTopicAssociations,
        };
      }
      // If no matching new sequence, return the original
      return seq;
    });
  }
  const handleApplyChanges = async () => {
    setReviewsLoading(true);
    let payload = {
      date_gpid: date_gpid,
      dashboard_id: dashboard_id,
      language: language,
    };
    if (internalId) {
      payload.internal_id = internalId;
    }
    if (
      !markedTextClicked &&
      highlightedText[date_gpid] &&
      highlightedText[date_gpid].length > 0
    ) {
      if ((selectedParent && selectedParent.id) || clickedTopic) {
        let topic_associations = selectedParent
          ? selectedTopics && selectedTopics.length > 0
            ? [...selectedTopics, ...relTopics, selectedParent]
            : [selectedParent, ...relTopics]
          : [...relTopics];
        const topicsToPayload = topic_associations?.map((t) => ({
          id: t.id,
          label: t.label,
          type: t.type ? t.type : "custom",
          sentiment: newSentiment
            ? newSentiment.value
            : selectedParent
            ? sentimentOptions[0].value
            : modifiedSentiments[t.id]?.value || selectedSentiment[t.id].value,
          intent: newIntent
            ? newIntent.value
            : selectedParent
            ? intentOptions[0].value
            : modifiedIntents[t.id]?.value || selectedIntent[t.id].value,
        }));
        let newSequences = highlightedText[date_gpid]?.map(
          (highlightedText) => ({
            start_index: highlightedText.indices[0],
            end_index: highlightedText.indices[1],
            text: highlightedText.text,
            seq_id: `${date_gpid} ⌀ ${highlightedText.indices[0]}_${highlightedText.indices[1]}`,
            date: new Date(Date.now()).toISOString(),
            isAnnotated: true,
            topic_associations: topicsToPayload,
          })
        );
        const allSelectedText = highlightedText[date_gpid]
          ?.map((hlt) => hlt.text)
          ?.flat();
        const combinedText = allSelectedText.join(" ");
        const cleanedText = combinedText.replace(/[^a-zA-Z0-9]/g, "");
        if (cleanedText.length < 3) {
          notification.warning({
            message: "Please select more text.",
          });
          setReviewsLoading(false);
          return;
        }
        let filteredSeq = [];
        let intersectedTopicIds = [];
        let allSeq = [];
        if (selectedParent && selectedParent.id) {
          const intersectedSequences = newSequences?.map((sequence) =>
            getIntersectedSeqeunces(sequences, sequence, false)
          );
          const flatMapOfIntersections = intersectedSequences?.flat();
          intersectedTopicIds = flatMapOfIntersections
            ?.map((seq) =>
              seq.topic_associations.map((topic) => topic.id?.toString())
            )
            .flat();

          filteredSeq = sequences?.filter(
            (seq) => !flatMapOfIntersections.includes(seq)
          );
          const finalFilteredSeq = filteredSeq;
          if (sequences?.length > 0) {
            sequences.forEach((sequence) => {
              const topicIds = sequence.topic_associations.map((ta) => ta.id);
              const isTopicLearningBasedArray = topicIds.map((id) =>
                isTopicLearningBased(id, 10)
              );
              const isAnyTopicNotLearningBased = isTopicLearningBasedArray.some(
                (val) => !val
              );
              if (isAnyTopicNotLearningBased) {
                const existingSeq = finalFilteredSeq.find(
                  (fs) =>
                    fs.start_index === sequence.start_index &&
                    fs.end_index === sequence.end_index
                );
                if (existingSeq) {
                  const nonLearningTopics = sequence.topic_associations.filter(
                    (ta) => !isTopicLearningBased(ta.id, 10)
                  );
                  existingSeq.topic_associations.push(...nonLearningTopics);
                } else {
                  const nonLearningSequence = {
                    ...sequence,
                    topic_associations: sequence.topic_associations.filter(
                      (ta) => !isTopicLearningBased(ta.id, 10)
                    ),
                  };
                  finalFilteredSeq.push(nonLearningSequence);
                }
              }
            });
          }
          allSeq = finalFilteredSeq
            ? [...finalFilteredSeq, ...newSequences]
            : newSequences;
        } else {
          allSeq = updateAssociations(sequences, newSequences);
        }
        allSeq.forEach((sequence) => {
          newSequences.forEach((newSequence) => {
            newSequence.topic_associations.forEach((newTopic) => {
              const matchingTopic = sequence.topic_associations.find(
                (topic) => topic.id?.toString() === newTopic.id?.toString()
              );
              if (matchingTopic) {
                matchingTopic.sentiment = newTopic.sentiment;
                matchingTopic.intent = newTopic.intent;
              }
            });
          });
        });
        const filteredTopics = allSeq
          ?.map((seq) =>
            seq.topic_associations.map((topic) => topic.id?.toString())
          )
          .flat();
        const finalTopics = relatedTopics
          ? [
              ...relatedTopics?.map((t) => t.id?.toString()),
              ...topic_associations?.map((t) => t.id?.toString()),
            ]
          : topic_associations?.map((t) => t.id?.toString());

        const finalTpcs = new Set([
          ...finalTopics?.filter(
            (tpc) =>
              !intersectedTopicIds?.includes(tpc) ||
              !isTopicLearningBased(tpc, 10)
          ),
          ...filteredTopics,
        ]);
        payload["final_topics"] = [...finalTpcs];
        const final_sequences =
          classifierSequences && classifierSequences.length > 0
            ? [...allSeq, ...classifierSequences]
            : allSeq;
        if (final_sequences && final_sequences.length > 0) {
          payload["sequences"] = final_sequences;
        }
      } else {
        let newSequences = highlightedText[date_gpid]?.map(
          (highlightedText) => ({
            start_index: highlightedText.indices[0],
            end_index: highlightedText.indices[1],
            text: highlightedText.text,
            seq_id: `${date_gpid} ⌀ ${highlightedText.indices[0]}_${highlightedText.indices[1]}`,
            date: new Date(Date.now()).toISOString(),
            isForClassifier: true,
            isAnnotated: true,
            classifier: {
              mPublish: publish[date_gpid],
              mOpenCase: openCase[date_gpid],
              mAction: action[date_gpid],
            },
          })
        );
        const allSelectedText = highlightedText[date_gpid]
          ?.map((hlt) => hlt.text)
          ?.flat();
        const combinedText = allSelectedText.join(" ");
        const cleanedText = combinedText.replace(/[^a-zA-Z0-9]/g, "");
        if (cleanedText.length < 3) {
          notification.warning({
            message: "Please select more text.",
          });
          setReviewsLoading(false);
          return;
        }
        // if (!publishChanged && !openCaseChanged && !actionChanged) {
        //   notification.warning({
        //     message: "Please select atleast one option",
        //   });
        //   setReviewsLoading(false);
        //   return;
        // }
        let filteredSeq = [];
        let allSeq = [];
        const intersectedSequences = newSequences?.map((sequence) =>
          getIntersectedSeqeunces(classifierSequences, sequence, true)
        );
        const flatMapOfIntersections = intersectedSequences?.flat();
        filteredSeq = classifierSequences?.filter(
          (seq) => !flatMapOfIntersections.includes(seq)
        );
        if (clickedDecision) {
          newSequences = newSequences.map((seq) => {
            const existingSeq = classifierSequences.find(
              (cs) =>
                cs.start_index === seq.start_index &&
                cs.end_index === seq.end_index
            );
            if (["Publish", "Do not Publish"].includes(clickedDecision)) {
              existingSeq.classifier.mPublish = publish[date_gpid];
            } else if (
              ["Open a case", "Do not open a case"].includes(clickedDecision)
            ) {
              existingSeq.classifier.mOpenCase = openCase[date_gpid];
            } else {
              existingSeq.classifier.mAction = action[date_gpid];
            }
            return {
              ...seq,
              classifier: existingSeq.classifier,
            };
          });
        }
        allSeq = filteredSeq
          ? sequences && sequences.length > 0
            ? [...filteredSeq, ...newSequences, ...sequences]
            : [...filteredSeq, ...newSequences]
          : sequences && sequences.length > 0
          ? [...newSequences, ...sequences]
          : newSequences;
        if (allSeq && allSeq.length > 0) {
          payload["sequences"] = allSeq;
        }
        payload["final_topics"] =
          relatedTopics && relatedTopics.length > 0
            ? [...relatedTopics?.map((tpc) => tpc.id?.toString())]
            : [];
      }
      applyFeedback(payload);
      // console.log(payload);
      // setReviewsLoading(false);
      setDisplayActions(false);
      setHighlightedText({});
      setDeletedIntents({});
      setDeletedTopics({});
      setRelTopics(relatedTopics);
      setTopicsEdited(false);
      setTopicClicked(false);
      setNewIntent(null);
      setNewSentiment(null);
      setResetOptions(true);
      setPublish((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setOpenCase((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setAction((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setPublishChanged(false);
      setOpenCaseChanged(false);
      setActionChanged(false);
      setClickedDecision(null);
      return;
    }

    if (markedTextClicked) {
      if (clickedTopic) {
        const topicsToPayload = [
          {
            id: clickedTopic.id,
            label: clickedTopic.label,
            type: clickedTopic.type ? clickedTopic.type : "custom",
            sentiment: newSentiment
              ? newSentiment.value
              : selectedParent
              ? sentimentOptions[0].value
              : modifiedSentiments[clickedTopic.id]?.value ||
                selectedSentiment[clickedTopic.id].value,
            intent: newIntent
              ? newIntent.value
              : selectedParent
              ? intentOptions[0].value
              : modifiedIntents[clickedTopic.id]?.value ||
                selectedIntent[clickedTopic.id].value,
          },
        ];

        let newSequences = highlightedText[date_gpid]?.map(
          (highlightedText) => ({
            start_index: highlightedText.indices[0],
            end_index: highlightedText.indices[1],
            text: highlightedText.text,
            seq_id: `${date_gpid} ⌀ ${highlightedText.indices[0]}_${highlightedText.indices[1]}`,
            date: new Date(Date.now()).toISOString(),
            isAnnotated: true,
            topic_associations: topicsToPayload,
          })
        );
        const allSelectedText = highlightedText[date_gpid]
          ?.map((hlt) => hlt.text)
          ?.flat();
        const combinedText = allSelectedText.join(" ");
        const cleanedText = combinedText.replace(/[^a-zA-Z0-9]/g, "");
        if (cleanedText.length < 3) {
          notification.warning({
            message: "Please select more text.",
          });
          setReviewsLoading(false);
          return;
        }
        const filteredSeq = removeUnhighlightedSequence(
          sequences,
          newSequences,
          clickedTopic
        );
        const fsq = updateAssociations(filteredSeq, newSequences);
        payload["final_topics"] = [
          ...relatedTopics?.map((tpc) => tpc.id?.toString()),
        ];
        const final_sequences =
          classifierSequences && classifierSequences.length > 0
            ? [...fsq, ...classifierSequences]
            : fsq;
        if (final_sequences && final_sequences.length > 0) {
          payload["sequences"] = final_sequences;
        }
      } else if (clickedDecision) {
        let newSequences = highlightedText[date_gpid]?.map(
          (highlightedText) => ({
            start_index: highlightedText.indices[0],
            end_index: highlightedText.indices[1],
            text: highlightedText.text,
            seq_id: `${date_gpid} ⌀ ${highlightedText.indices[0]}_${highlightedText.indices[1]}`,
            date: new Date(Date.now()).toISOString(),
            isForClassifier: true,
            isAnnotated: true,
            classifier: {
              mPublish:
                publish[date_gpid] && publish[date_gpid] === true
                  ? true
                  : publish[date_gpid] === false
                  ? false
                  : null,
              mOpenCase:
                openCase[date_gpid] && openCase[date_gpid] === true
                  ? true
                  : openCase[date_gpid] === false
                  ? false
                  : null,
              mAction:
                action[date_gpid] && action[date_gpid] === true
                  ? true
                  : action[date_gpid] === false
                  ? false
                  : null,
            },
          })
        );

        const filteredSeq = removeSequenceUpdateClassifier(
          classifierSequences,
          newSequences,
          clickedDecision
        );
        payload["final_topics"] =
          relatedTopics && relatedTopics.length > 0
            ? [...relatedTopics?.map((tpc) => tpc.id?.toString())]
            : [];
        const final_sequences =
          sequences && sequences.length > 0
            ? [...filteredSeq, ...sequences]
            : filteredSeq;
        if (final_sequences && final_sequences.length > 0) {
          payload["sequences"] = final_sequences;
        }
      }
      applyFeedback(payload);
      // console.log(payload);
      // setReviewsLoading(false);
      setDisplayActions(false);
      setHighlightedText({});
      setDeletedIntents({});
      setDeletedTopics({});
      setTopicsEdited(false);
      setNewIntent(null);
      setRelTopics(relatedTopics);
      setNewSentiment(null);
      setResetOptions(true);
      setPublish((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setOpenCase((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setAction((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setMarkedTextClicked(false);
      setPublishChanged(false);
      setOpenCaseChanged(false);
      setActionChanged(false);
      setClickedDecision(null);
      return;
    }
    if (
      deletedTopics &&
      deletedTopics[date_gpid] &&
      deletedTopics[date_gpid].length > 0
    ) {
      const filteredSequences = filterDeletedTopics(
        sequences,
        deletedTopics[date_gpid]
      );
      const deletedTpcs = deletedTopics[date_gpid].map((tpc) =>
        tpc.id.toString()
      );
      const finalTopicsToPay = relatedTopics?.filter(
        (t) => !deletedTpcs.includes(t?.id?.toString())
      );
      payload["final_topics"] = [
        ...new Set(finalTopicsToPay?.map((fT) => fT?.id)),
      ];
      payload["review_text"] = text;
      const final_sequences =
        classifierSequences && classifierSequences.length > 0
          ? [...filteredSequences, ...classifierSequences]
          : filteredSequences;
      if (final_sequences && final_sequences.length > 0) {
        payload["sequences"] = final_sequences;
      }
      payload["review_topics"] = [
        ...new Set(
          finalTopicsToPay
            ?.filter((fTP) => fTP.type !== "ai" && fTP.learning)
            ?.map((fT) => fT?.label)
        ),
      ];
      applyFeedback(payload);
      // console.log(payload);
      // setReviewsLoading(false);
      setDisplayActions(false);
      setHighlightedText({});
      setDeletedIntents({});
      setDeletedTopics({});
      setModifiedIntents([]);
      setTopicsEdited(false);
      setModifiedSentiments([]);
      setNewIntent(null);
      setNewSentiment(null);
      setResetOptions(true);
      setRelTopics(relatedTopics);
      return;
    }
  };

  // const timerRef = useRef(null);
  // const notificationText = (highlightedText&& highlightedText.length===0)?"Please select a topic or highlight text to give feedback":(highlightedText&& highlightedText.length===1)?"Long Press CTRL and select to highlight multiple texts":""

  // useEffect(() => {
  //   const handleMouseEnter = () => {
  //     setShowNotificationMsg(true);
  //     clearTimeout(timerRef.current);
  //     timerRef.current = setTimeout(() => {
  //       setShowNotificationMsg(false);
  //     }, 3000);
  //   };

  //   const handleMouseLeave = () => {
  //     clearTimeout(timerRef.current);
  //     setShowNotificationMsg(false);
  //   };

  //   const itemContainer = document.querySelector(`.${styles.itemContainer}`);

  //   itemContainer.addEventListener('mouseenter', handleMouseEnter);
  //   itemContainer.addEventListener('mouseleave', handleMouseLeave);

  //   return () => {
  //     itemContainer.removeEventListener('mouseenter', handleMouseEnter);
  //     itemContainer.removeEventListener('mouseleave', handleMouseLeave);
  //     clearTimeout(timerRef.current);
  //   };
  // }, []);

  // Update selected options and modified arrays when dropdown value changes
  const handleSentimentChange = (topic, newValue) => {
    const newModifiedSents = { ...modifiedSentiments };
    newModifiedSents[topic.id] = newValue;
    setModifiedSentiments(newModifiedSents);
    setTopicsEdited(true);
  };

  const handleIntentChange = (topic, newValue) => {
    const newModifiedIntents = { ...modifiedIntents };
    newModifiedIntents[topic.id] = newValue;
    setModifiedIntents(newModifiedIntents);
    setTopicsEdited(true);
  };
  useEffect(() => {
    setRelTopics(relatedTopics);
  }, [relatedTopics]);
  useEffect(() => {
    if (sequences && sequences.length > 0) {
      let indicesSent = {};
      let tpcIndices = {};
      let intntIndices = {};
      let tpcSent = {};
      let tpcIntnt = {};
      sequences.forEach((sequence) => {
        let startIndex = parseInt(sequence.start_index);
        let endIndex = parseInt(sequence.end_index);

        sequence.topic_associations.forEach((topic) => {
          let topicId = topic.id;
          let sentiment = topic.sentiment;
          let intent = topic.intent;
          let date = new Date(sequence.date);

          // Update indicesSent
          indicesSent[`${startIndex}, ${endIndex}`] = sentiment;

          // Update tpcIndices
          if (!tpcIndices[topicId]) {
            tpcIndices[topicId] = [];
          }
          tpcIndices[topicId].push([startIndex, endIndex]);

          // Update intntIndices
          if (!intntIndices[intent]) {
            intntIndices[intent] = [];
          }
          intntIndices[intent].push([startIndex, endIndex]);

          // Update tpcSent
          if (!tpcSent[topicId] || date > new Date(tpcSent[topicId].date)) {
            tpcSent[topicId] = {
              sentiment: sentiment,
              date: date,
            };
          }

          // Update tpcIntnt
          if (!tpcIntnt[topicId] || date > new Date(tpcIntnt[topicId].date)) {
            tpcIntnt[topicId] = {
              intent: intent,
              date: date,
            };
          }
        });
      });

      // Convert tpcSent and tpcIntnt to the desired format
      for (let topicId in tpcSent) {
        tpcSent[topicId] = tpcSent[topicId].sentiment;
      }
      for (let topicId in tpcIntnt) {
        tpcIntnt[topicId] = tpcIntnt[topicId].intent;
      }
      setTopicSentiments(tpcSent);
      setTopicIntents(tpcIntnt);
      setIndicesSentiment(indicesSent);
      setTopicIndices(tpcIndices);
      setIntentIndices(intntIndices);
    }
  }, [sequences]);

  useEffect(() => {
    if (pageChanged) {
      setTopicSentiments(null);
      setTopicIntents(null);
      setIndicesSentiment(null);
      setTopicIndices(null);
      setIntentIndices(null);
      handleDiscardChanges();
      setSelectedIntent([]);
      setSelectedSentiment([]);
      setClassifierIndices(null);
      setPageChanged(false);
    }
  }, [pageChanged]);
  useEffect(() => {
    if (sentiment && sentiment !== "None") {
      setSentimentBarValue(
        sentiment === "1.0"
          ? 100
          : sentiment === "0.0"
          ? 0
          : sentiment === "0.5"
          ? 50
          : ""
      );
    }
  }, [sentiment]);
  useEffect(() => {
    if (indicesSentiment && Object.keys(indicesSentiment)?.length > 0) {
      const seqSentiments = Object.values(indicesSentiment);
      const filteredSentiments =
        seqSentiments.length > 0
          ? seqSentiments?.filter((item) => item != -1)
          : [];
      const barVal = filteredSentiments?.reduce(
        (acc, val) => acc + parseFloat(val),
        0
      );
      if (filteredSentiments.length > 0) {
        setSentimentBarValue((barVal * 100) / filteredSentiments?.length);
      }
    } else {
      if (sentiment) {
        setSentimentBarValue(
          sentiment === "1.0" ? 100 : sentiment === "0.0" ? 0 : 50
        );
      } else {
        setSentimentBarValue("");
      }
    }
  }, [indicesSentiment, sequences, allReviews]);
  // Get initial values of dropdowns
  useEffect(() => {
    if (relTopics) {
      relTopics.map((t) => {
        if (topicIntents && topicIntents[t?.id]) {
          const i = intentOptions.find(
            (item) => item.id === topicIntents[t.id]
          );
          setSelectedIntent((prevTopicIntents) => {
            const updatedPrevTopicIntents = { ...prevTopicIntents };
            updatedPrevTopicIntents[t.id] = i;
            return updatedPrevTopicIntents;
          });
        }
      });
    }
    if (relTopics) {
      relTopics.map((t) => {
        if (topicSentiments && topicSentiments[t?.id]) {
          const s = sentimentOptions.find((item) => {
            if (
              parseFloat(topicSentiments[t.id]) === 1.0 ||
              parseFloat(topicSentiments[t.id]) === 0.75
            ) {
              return parseFloat(item.id) === 1.0;
            } else if (
              parseFloat(topicSentiments[t.id]) === 0.0 ||
              parseFloat(topicSentiments[t.id]) === 0.25
            ) {
              return parseFloat(item.id) === 0.0;
            } else if (parseFloat(topicSentiments[t.id]) === 0.5) {
              return parseFloat(item.id) === 0.5;
            } else {
              return parseInt(item.id) === -1;
            }
          });
          setSelectedSentiment((prevTopicSents) => {
            const updatedPrevTopicSents = { ...prevTopicSents };
            updatedPrevTopicSents[t.id] = s;
            return updatedPrevTopicSents;
          });
        }
      });
    }
  }, [topicIntents, topicSentiments]);
  const handleRemoveTopic = (topic) => {
    setDeletedTopic(topic);
    setDeletedTopics((prevDeletedTopics) => {
      const updated = { ...prevDeletedTopics };
      if (deletedTopics && deletedTopics[date_gpid]) {
        updated[date_gpid] = [...deletedTopics[date_gpid], topic];
        return updated;
      } else {
        updated[date_gpid] = [topic];
        return updated;
      }
    });
    if (topicIntents && topic.id in topicIntents) {
      setDeletedIntents((prevDeletedIntents) => {
        const updated = { ...prevDeletedIntents };
        if (deletedIntents[date_gpid]) {
          updated[date_gpid] = [
            ...deletedIntents[date_gpid],
            topicIntents[topic?.id],
          ];
          return updated;
        } else {
          updated[date_gpid] = [topicIntents[topic?.id]];
          return updated;
        }
      });
    } else {
      setDeletedIntents((prevDeletedIntents) => {
        const updated = { ...prevDeletedIntents };
        if (deletedIntents[date_gpid]) {
          updated[date_gpid] = [
            ...deletedIntents[date_gpid],
            intentOptions[0].value,
          ];
          return updated;
        } else {
          updated[date_gpid] = [intentOptions[0].value];
          return updated;
        }
      });
    }
    setRelTopics(relTopics.filter((t) => t !== topic));
    setTopicsEdited(true);
  };

  const handleOuterClick = (e) => {
    const dropdownClicked = e.target.closest(
      `.ant-select, .ant-select-selector, .ant-select-selection-item, .ant-select-item, .ant-checkbox .ant-select-dropdown, .ant-dropdown, .ant-dropdown-menu, .ant-dropdown-trigger, .itemContainer, .itemRelatedTopics, itemRelatedTopics, .ant-btn, .actions, .anticon`
    );
    const closestKeyElement = e.target?.closest("[keyelement]");
    const keyElement = closestKeyElement?.getAttribute("keyelement");
    if (
      (!dropdownClicked && prevDeletionKey && keyElement !== prevDeletionKey) ||
      (keyElement && highlightedText[prevSelectionKey]?.length > 0)
    ) {
      setHighlightedText({});
      setDeletedTopics({});
      setDeletedIntents({});
      handleDiscardChanges();
      setPublish((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setOpenCase((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setAction((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setPublishChanged(false);
      setOpenCaseChanged(false);
      setActionChanged(false);
      setClickedDecision(null);
    }
    setPrevDeletionKey(
      e.target
        .closest(`.${styles.itemRelatedTopics}`)
        ?.getAttribute("keyelement")
    );
  };
  const handleOutOfClassifierClick = (e) => {
    const dropdownClicked = e.target.closest(
      `.ant-select, .ant-select-selector, .ant-select-selection-item, .ant-select-item, .ant-checkbox .ant-select-dropdown, .ant-dropdown, .ant-dropdown-menu, .ant-dropdown-trigger, .itemContainer, .itemRelatedTopics, itemRelatedTopics, .ant-btn, .actions, .anticon, ${styles.decisionTags}`
    );
    if (
      !dropdownClicked &&
      prevClassifierKey &&
      e.target
        .closest(`.${styles.classifierChecks}`)
        ?.getAttribute("keyClassifierElement") !== prevClassifierKey
    ) {
      // setHighlightedText({});
      setDeletedTopics({});
      setDeletedIntents({});
      // handleDiscardChanges();
      setPublish((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setOpenCase((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setAction((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = null;
        return updated;
      });
      setPublishChanged(false);
      setOpenCaseChanged(false);
      setActionChanged(false);
      setClickedDecision(null);
    }
    setPrevClassifierKey(
      e.target
        .closest(`.${styles.classifierChecks}`)
        ?.getAttribute("keyClassifierElement")
    );
  };

  useEffect(() => {
    const intentsFromSeq = new Set(
      Object.values(topicIntents ? topicIntents : {})
    );
    const finalInt = new Set([...intentsFromSeq]);
    const intentsToDisplay = [...finalInt]?.map((intent) => {
      if (intent) {
        const i =
          intent !== "None" || intent !== "-1"
            ? intentOptions.find((item) => item.id === intent)
            : intentOptions[0];
        return i;
      }
    });
    setAllReviewsIntents((prev) => {
      const updated = { ...prev };
      updated[date_gpid] = intentsToDisplay;
      return updated;
    });
  }, [intents, topicIntents]);
  const removeDuplicates = (array) => {
    const stringifiedArr = array?.map((item) => JSON.stringify(item));
    const noDupArray = [...new Set(stringifiedArr)];
    return noDupArray.map((item) => JSON.parse(item));
  };
  const handleIntentClick = (id) => {
    const indices = intentIndices && intentIndices[id] ? intentIndices[id] : [];
    if (!indices || !indices.length) return; // No indices for this intent
    indices.sort((a, b) => a[0] - b[0]);
    const text = reviewRef.current.textContent;
    const highlightText = indices.map(([start, end]) => ({
      text: text.substring(start, end + 1),
      indices: [start, end],
      sentiment: indicesSentiment[`${start}, ${end}`]
        ? indicesSentiment[`${start}, ${end}`]
        : "0.5",
    }));
    setHighlightedText((prevHighlightedText) => {
      const updatedHighlightedText = { ...prevHighlightedText };
      updatedHighlightedText[date_gpid] = removeDuplicates(highlightText);
      return updatedHighlightedText;
    });
  };
  const handleTopicClick = (topic) => {
    setTopicClicked(true);
    setClickedTopic(topic);
    setPrevSelectionKey(date_gpid);
    const indices =
      topicIndices && topicIndices[topic.id] ? topicIndices[topic.id] : [];
    if (!indices || !indices.length) return; // No indices for this topic
    indices.sort((a, b) => a[0] - b[0]);
    const text = reviewRef.current.textContent;
    const highlightText = indices.map(([start, end]) => ({
      text: text.substring(start, end + 1),
      indices: [start, end],
      sentiment: indicesSentiment[`${start}, ${end}`]
        ? indicesSentiment[`${start}, ${end}`]
        : "0.5",
    }));
    setHighlightedText((prevHighlightedText) => {
      const updatedHighlightedText = { ...prevHighlightedText };
      updatedHighlightedText[date_gpid] = highlightText;
      return updatedHighlightedText;
    });
    if (feedbackModeEnabled && highlightText.length > 0) {
      setRelTopics([topic]);
    }
  };
  const handleChildTopicsChange = (selected) => {
    let updatedSelection = [...selected];
    setSelectedTopics(updatedSelection);
  };
  useEffect(() => {
    if (
      topicClicked &&
      !highlightedText[date_gpid]?.length > 0 &&
      markedTextClicked
    ) {
      setTopicClicked(false);
      setRelTopics(relatedTopics);
      setMarkedTextClicked(false);
      setClickedTopic(null);
      handleDiscardChanges();
    }
  }, [topicClicked, highlightedText[date_gpid]]);

  useEffect(() => {
    if (selectedTopics?.length > 0) {
      setSelectedTopics([]);
    }
  }, [selectedParent]);
  useEffect(() => {
    if (selectedParent && !highlightedText[date_gpid]?.length > 0) {
      setSelectedParent(null);
      setResetParent(true);
    }
    if (
      feedbackModeEnabled &&
      !topicClicked &&
      highlightedText[date_gpid]?.length > 0
    ) {
      setRelTopics([]);
    } else if (feedbackModeEnabled && !highlightedText[date_gpid]?.length > 0) {
      setRelTopics(relatedTopics);
    }
  }, [highlightedText[date_gpid]]);
  useEffect(() => {
    if (prevDeletionKey && prevDeletionKey !== date_gpid) {
      setDeletedTopics({});
      setDeletedIntents({});
      setHighlightedText({});
      handleDiscardChanges();
    }
  }, [prevDeletionKey, deletedTopics[date_gpid]]);

  // useEffect(() => {
  //   if (prevClassifierKey && prevClassifierKey !== date_gpid) {
  //     setPublish((prev) => {
  //       const updated = { ...prev };
  //       updated[date_gpid] = null;
  //       return updated;
  //     });
  //     setOpenCase((prev) => {
  //       const updated = { ...prev };
  //       updated[date_gpid] = null;
  //       return updated;
  //     });
  //     setAction((prev) => {
  //       const updated = { ...prev };
  //       updated[date_gpid] = null;
  //       return updated;
  //     });
  //     setPublishChanged(false);
  //     setOpenCaseChanged(false);
  //     setActionChanged(false);
  //     // handleDiscardChanges();
  //   }
  // }, [
  //   prevClassifierKey,
  //   publish[date_gpid],
  //   openCase[date_gpid],
  //   action[date_gpid],
  // ]);

  // useEffect(() => {
  //   if (
  //     (publishChanged || actionChanged || openCaseChanged) &&
  //     deletedTopics &&
  //     deletedTopics[date_gpid]?.length > 0
  //   ) {
  //     setPublish((prev) => {
  //       const updated = { ...prev };
  //       updated[date_gpid] = null;
  //       return updated;
  //     });
  //     setOpenCase((prev) => {
  //       const updated = { ...prev };
  //       updated[date_gpid] = null;
  //       return updated;
  //     });
  //     setAction((prev) => {
  //       const updated = { ...prev };
  //       updated[date_gpid] = null;
  //       return updated;
  //     });
  //     setPublishChanged(false);
  //     setOpenCaseChanged(false);
  //     setActionChanged(false);
  //   }
  // }, [
  //   highlightedText[date_gpid],
  //   deletedTopics[date_gpid],
  //   publishChanged,
  //   openCaseChanged,
  //   actionChanged,
  // ]);

  const removeParentFromChildren = (parent, flag = false) => {
    const selectedParentLabel = selectedParent?.label + " >";
    if (flag && selectedParentLabel && parent.includes(selectedParentLabel)) {
      const endIndex =
        parent.indexOf(selectedParentLabel) + selectedParentLabel.length;
      const remainingString = parent.slice(endIndex);
      return remainingString.trim();
    }
    const substrs = parent.split(">");
    substrs.splice(0, 1);
    return substrs.join(">");
  };
  const handleClassifierChange = (type, values) => {
    if (type === "mPublish") {
      const updatedVal = parseInt(values?.value);
      setPublish((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = updatedVal === 1 ? true : false;
        return updated;
      });
      setPublishChanged(true);
    } else if (type === "mOpenCase") {
      const updatedVal = parseInt(values?.value);
      setOpenCase((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = updatedVal === 1 ? true : false;
        return updated;
      });
      setOpenCaseChanged(true);
    } else if (type === "mAction") {
      const updatedVal = parseInt(values?.value);
      setAction((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = updatedVal === 1 ? true : false;
        return updated;
      });
      setActionChanged(true);
    }
  };
  const publishOptions = [
    {
      id: "1",
      label: "Could be published",
      value: "1",
      img: "/assets/mPublish.svg",
    },
    {
      id: "2",
      label: "Do not publish",
      value: "2",
      img: "/assets/cancel_circle_outlined.svg",
    },
  ];
  const caseOptions = [
    {
      id: "1",
      label: "Open a case",
      value: "1",
      img: "/assets/mOpenCase.svg",
    },
    {
      id: "2",
      label: "Do not open any case",
      value: "2",
      img: "/assets/cancel_circle_outlined.svg",
    },
  ];
  const actionOptions = [
    {
      id: "1",
      label: "Take an action",
      value: "1",
      img: "/assets/mAction.svg",
    },
    {
      id: "2",
      label: "Do not take any action",
      value: "2",
      img: "/assets/cancel_circle_outlined.svg",
    },
  ];

  useEffect(() => {
    if (associationsRef && associationsRef.current) {
      setAssociationsRefHeight(associationsRef.current.scrollHeight);
    }
  });
  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const handleResize = debounce(function() {
    setAssociationsRefHeight(associationsRef.current.scrollHeight);
    console.log("resizing…");
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const handleDecisionTagClick = (i) => {
    setClickedDecision(i);
    setPrevClassifierKey(date_gpid);
    const indices =
      classifierIndices && classifierIndices[i] ? classifierIndices[i] : [];
    if (!indices || !indices.length) return; // No indices for this topic
    indices.sort((a, b) => a[0] - b[0]);
    const text = reviewRef.current.textContent;
    const highlightText = indices.map(([start, end]) => ({
      text: text.substring(start, end + 1),
      indices: [start, end],
    }));
    setHighlightedText((prevHighlightedText) => {
      const updatedHighlightedText = { ...prevHighlightedText };
      updatedHighlightedText[date_gpid] = highlightText;
      return updatedHighlightedText;
    });
  };
  useEffect(() => {
    if (classifierSequences && classifierSequences.length > 0) {
      let clsIndices = {
        Publish: [],
        "Do not Publish": [],
        "Open a case": [],
        "Do not open a case": [],
        "Take action": [],
        "Do not take action": [],
      };
      classifierSequences.forEach((sequence) => {
        const indices = [
          parseInt(sequence.start_index, 10),
          parseInt(sequence.end_index, 10),
        ];
        if (sequence.classifier.mPublish === true) {
          clsIndices["Publish"].push(indices);
        } else if (sequence.classifier.mPublish === false) {
          clsIndices["Do not Publish"].push(indices);
        }
        if (sequence.classifier.mOpenCase === true) {
          clsIndices["Open a case"].push(indices);
        } else if (sequence.classifier.mOpenCase === false) {
          clsIndices["Do not open a case"].push(indices);
        }
        if (sequence.classifier.mAction === true) {
          clsIndices["Take action"].push(indices);
        } else if (sequence.classifier.mAction === false) {
          clsIndices["Do not take action"].push(indices);
        }
      });
      setClassifierIndices(clsIndices);
    }
  }, [classifierSequences]);
  useEffect(() => {
    if (classifierSequences && classifierSequences.length > 0) {
      const dTags = [];
      const publishSet = new Set();
      const caseSet = new Set();
      const actionSet = new Set();

      classifierSequences.forEach((seq) => {
        publishSet.add(seq.classifier.mPublish);
        caseSet.add(seq.classifier.mOpenCase);
        actionSet.add(seq.classifier.mAction);
      });

      if (publishSet.has(true)) {
        dTags.push("Publish");
      }
      if (publishSet.has(false)) {
        dTags.push("Do not Publish");
      }
      if (caseSet.has(true)) {
        dTags.push("Open a case");
      }
      if (caseSet.has(false)) {
        dTags.push("Do not open a case");
      }
      if (actionSet.has(true)) {
        dTags.push("Take action");
      }
      if (actionSet.has(false)) {
        dTags.push("Do not take action");
      }

      setDecisionTags((prev) => {
        const updated = { ...prev };
        updated[date_gpid] = dTags;
        return updated;
      });
    }
  }, [classifierSequences]);

  const ETSplatformIdsNames = {
    "33": "ACS",
    "34": "OTELPUAN",
    "35": "SURVEY",
  };

  const hasMultipleValues = (obj) => {
    if (obj && Object.keys(obj)?.length > 0) {
      const values = [...new Set(Object.values(obj))];
      return values.length > 1;
    }
    return false;
  };
  const partOfAllReviews = (
    <>
      {title && title !== "None" && (
        <span className={styles.reviewTitle}>{title}</span>
      )}
      <div
        className={
          feedbackModeEnabled
            ? styles.reviewText
            : styles.reviewTextWithNoSelect
        }
        ref={reviewRef}
        keyelement={date_gpid}
        onDoubleClick={handleMouseup}
        onTouchEnd={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleMouseup(e);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onMouseUp={handleMouseup}
        title={
          feedbackModeEnabled && !highlightedText[date_gpid]?.length > 0
            ? "Select a topic or text to give feedback"
            : ""
        }
      >
        <div>
          {!feedbackModeEnabled &&
            (platformId === 7 ||
              platformId === 8 ||
              platformId === 3 ||
              platformId === 28 ||
              platformId === 2) && (
              <div className={styles.checkbox}>{checkbox}</div>
            )}
        </div>
        <div>
          <Highlight
            search={
              highlightedText[date_gpid] &&
              highlightedText[date_gpid]?.length > 0
                ? highlightedText[date_gpid]
                : []
            }
            child={text && text !== "nan" ? text : ""}
          >
            {text && text !== "nan" ? text : ""}
          </Highlight>
        </div>
      </div>

      <div className={styles.itemBottom}>
        <div className={styles.firstLine}>
          <div className={styles.engagements}>
            {reviewPlatformId === "1" && engagements && (
              <>
                <span className={styles.count}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/comments.svg"
                        : "/assets/comments_dark.svg"
                    }
                    width={16.25}
                    height={18}
                  />{" "}
                  {engagements["comments"]}
                </span>
                <span className={styles.count}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/retweet.svg"
                        : "/assets/retweet_dark.svg"
                    }
                    width={20.25}
                    height={22}
                  />{" "}
                  {engagements["retweets"]}
                </span>
                <span className={styles.count}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/likes.svg"
                        : "/assets/likes_dark.svg"
                    }
                    width={16.25}
                    height={18}
                  />{" "}
                  {engagements["likes"]}
                </span>
                <span className={styles.count}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/views.svg"
                        : "/assets/views_dark.svg"
                    }
                    width={16.25}
                    height={18}
                  />{" "}
                  {engagements["views"]}
                </span>
              </>
            )}
          </div>
          {date && (
            <span className={styles.itemDate}>
              <span>
                <i style={{ marginRight: 8 }} className="fa fa-calendar-o" />
                {dayjs(date).format("DD MMM YYYY")}
              </span>
              <span>
                <i style={{ marginRight: 8 }} className="fa fa-clock-o" />
                {dayjs(date).format("HH:mm")}
              </span>
            </span>
          )}
        </div>
        {feedbackModeEnabled ? (
          <>
            {!highlightedText[date_gpid] && (
              <div
                keyClassifierElement={date_gpid}
                className={styles.classifierChecks}
                onMouseDown={handleOutOfClassifierClick}
              >
                {decisionTags &&
                  decisionTags[date_gpid] &&
                  decisionTags[date_gpid].length > 0 &&
                  decisionTags[date_gpid].map((item) => (
                    <span
                      className={styles.decisionTags}
                      onClick={() => handleDecisionTagClick(item)}
                    >
                      {item}
                    </span>
                  ))}
              </div>
            )}
          </>
        ) : (
          <div
            keyClassifierElement={date_gpid}
            className={styles.classifierChecks}
            onMouseDown={handleOutOfClassifierClick}
          >
            {" "}
            {decisionTags &&
              decisionTags[date_gpid] &&
              decisionTags[date_gpid].length > 0 &&
              decisionTags[date_gpid].map((item) => (
                <span
                  className={styles.decisionTags}
                  onClick={() => handleDecisionTagClick(item)}
                >
                  {item}
                </span>
              ))}
          </div>
        )}
        {feedbackModeEnabled && highlightedText && highlightedText[date_gpid] && (
          <div
            keyClassifierElement={date_gpid}
            className={styles.classifierChecks}
            onMouseDown={handleOutOfClassifierClick}
          >
            {highlightedText &&
              highlightedText[date_gpid] &&
              highlightedText[date_gpid].length > 0 &&
              !selectedParent &&
              !clickedTopic && (
                <>
                  {(!clickedDecision ||
                    (clickedDecision &&
                      ![
                        "Open a case",
                        "Do not open a case",
                        "Take action",
                        "Do not take action",
                      ].includes(clickedDecision))) && (
                    <div
                      className={
                        theme === "dark"
                          ? styles.classifierWrapper
                          : styles.lightModeClassifier
                      }
                      classifierKey={"mPublish"}
                    >
                      <SingleSelect
                        options={publishOptions}
                        placeholder={
                          <span
                            className={styles.actionsPlaceholder}
                            style={{
                              color: theme === "dark" ? "white" : "black",
                            }}
                          >
                            {t("button.48")}
                          </span>
                        }
                        selectedOptions={
                          publish[date_gpid] && publish[date_gpid] === true
                            ? publishOptions[0]
                            : publish[date_gpid] === false
                            ? publishOptions[1]
                            : null
                        }
                        setSelectedOptions={(values) =>
                          handleClassifierChange("mPublish", values)
                        }
                        disableMenu={
                          (selectedParent && selectedParent.id) ||
                          (deletedTopics &&
                            deletedTopics[date_gpid]?.length > 0)
                        }
                      />
                    </div>
                  )}
                  {(!clickedDecision ||
                    (clickedDecision &&
                      ![
                        "Publish",
                        "Do not Publish",
                        "Take action",
                        "Do not take action",
                      ].includes(clickedDecision))) && (
                    <div
                      className={
                        theme === "dark"
                          ? styles.classifierWrapper
                          : styles.lightModeClassifier
                      }
                      classifierKey={"mOpenCase"}
                      title="Open a new case"
                    >
                      <SingleSelect
                        options={caseOptions}
                        placeholder={
                          <span
                            className={styles.actionsPlaceholder}
                            style={{
                              color: theme === "dark" ? "white" : "black",
                            }}
                          >
                            {t("button.49")}
                          </span>
                        }
                        selectedOptions={
                          openCase[date_gpid] && openCase[date_gpid] === true
                            ? caseOptions[0]
                            : openCase[date_gpid] === false
                            ? caseOptions[1]
                            : null
                        }
                        setSelectedOptions={(values) =>
                          handleClassifierChange("mOpenCase", values)
                        }
                        hideImgOnlyForOptions={true}
                        disableMenu={
                          deletedTopics && deletedTopics[date_gpid]?.length > 0
                        }
                      />
                    </div>
                  )}
                  {(!clickedDecision ||
                    (clickedDecision &&
                      ![
                        "Publish",
                        "Do not Publish",
                        "Open a case",
                        "Do not open a case",
                      ].includes(clickedDecision))) && (
                    <div
                      className={
                        theme === "dark"
                          ? styles.classifierWrapper
                          : styles.lightModeClassifier
                      }
                      title="Take an immediate action"
                      classifierKey={"mAction"}
                    >
                      <SingleSelect
                        options={actionOptions}
                        placeholder={
                          <span
                            className={styles.actionsPlaceholder}
                            style={{
                              color: theme === "dark" ? "white" : "black",
                            }}
                          >
                            {t("button.50")}
                          </span>
                        }
                        selectedOptions={
                          action[date_gpid] && action[date_gpid] === true
                            ? actionOptions[0]
                            : action[date_gpid] === false
                            ? actionOptions[1]
                            : null
                        }
                        setSelectedOptions={(values) =>
                          handleClassifierChange("mAction", values)
                        }
                        hideImgOnlyForOptions={true}
                        disableMenu={
                          deletedTopics && deletedTopics[date_gpid]?.length > 0
                        }
                      />
                    </div>
                  )}
                </>
              )}
          </div>
        )}
        <div
          className={styles.feedbackActions}
          onMouseDown={handleMouseDown}
          style={{ display: "flex" }}
        >
          <div className={styles.hideOverflows}>
            <div
              className={styles.itemProps}
              ref={associationsRef}
              style={
                !feedbackModeEnabled && collapseAssociations
                  ? { maxHeight: "120px", overflow: "hidden" }
                  : {}
              }
            >
              {(sentiment || sentimentBarValue !== "") &&
                sentiment !== "None" &&
                !feedbackModeEnabled && (
                  <RangeBar value={Math.round(sentimentBarValue)} />
                )}
              {sentiment &&
                (!highlightedText[date_gpid] ||
                  highlightedText[date_gpid]?.length === 0) &&
                (!deletedTopics[date_gpid] ||
                  deletedTopics[date_gpid]?.length === 0) && (
                  <SingleSelect
                    options={overallSentimentOptions}
                    reset={resetSentiment}
                    placeholder={
                      (sentiment === "None" ||
                        sentiment === "" ||
                        sentiment.toLowerCase() === "unspecified" ||
                        sentiment === "-1") && (
                        <span
                          className={styles.placeholder}
                          style={{
                            color: theme === "dark" ? "white" : "black",
                          }}
                        >
                          <img
                            src={sentimentOptions[0].img}
                            width={12}
                            height={12}
                          />{" "}
                          {sentimentOptions[0].label}
                        </span>
                      )
                    }
                    selectedOptions={
                      overallSentiment
                        ? overallSentiment
                        : topicSentiments && hasMultipleValues(topicSentiments)
                        ? overallSentimentOptions[0]
                        : sentiment &&
                          sentiment !== "None" &&
                          overallSentimentOptions.find(
                            (item) => item.id === sentiment
                          )
                    }
                    setSelectedOptions={setOverallSentiment}
                  />
                )}
              {intents &&
                intents !== "0" &&
                (!highlightedText[date_gpid] ||
                  highlightedText[date_gpid]?.length === 0) &&
                (!deletedTopics[date_gpid] ||
                  deletedTopics[date_gpid]?.length === 0) && (
                  <SingleSelect
                    options={overallIntentOptions}
                    reset={resetIntents}
                    placeholder={
                      (intents === "None" ||
                        intents === "" ||
                        intents.toLowerCase() === "unspecified" ||
                        intents === "-1") && (
                        <span
                          className={styles.placeholder}
                          style={{
                            color: theme === "dark" ? "white" : "black",
                          }}
                        >
                          <img
                            src={intentOptions[0].img}
                            width={12}
                            height={12}
                          />{" "}
                          {intentOptions[0].label}
                        </span>
                      )
                    }
                    selectedOptions={
                      overallIntent
                        ? overallIntent
                        : topicIntents && hasMultipleValues(topicIntents)
                        ? overallIntentOptions[0]
                        : intents &&
                          intents !== "None" &&
                          overallIntentOptions.find(
                            (item) => item.id === intents
                          )
                    }
                    setSelectedOptions={setOverallIntent}
                  />
                )}
              {allReviewsIntents &&
                Object.keys(allReviewsIntents)?.length > 0 &&
                allReviewsIntents[date_gpid] &&
                allReviewsIntents[date_gpid].length > 0 &&
                !feedbackModeEnabled && (
                  <>
                    {allReviewsIntents[date_gpid]?.map((intent) => (
                      <>
                        {intent.id !== "-1" && (
                          <div
                            className={styles.itemIntent}
                            style={{
                              backgroundColor:
                                theme === "dark" ? "#1F2E5E" : "#3757BC",
                              color: theme === "dark" ? "#2F80ED" : "white",
                            }}
                            onClick={() => handleIntentClick(intent.id)}
                          >
                            <img src={intent?.img} width={20} height={20} />{" "}
                            {intent?.label}{" "}
                            {/* {intentIndices &&
                              intentIndices[intent.id] &&
                              [
                                ...new Set(
                                  intentIndices[intent.id].map(JSON.stringify)
                                ),
                              ].map(JSON.parse).length > 1 &&
                              `(${
                                [
                                  ...new Set(
                                    intentIndices[intent.id].map(JSON.stringify)
                                  ),
                                ].map(JSON.parse).length
                              })`} */}
                          </div>
                        )}
                      </>
                    ))}
                  </>
                )}
              {relTopics &&
                relTopics.length > 0 &&
                [...new Set(relTopics)].map((topic) => {
                  return topic.type === "ai" && showAiTopicLabel ? (
                    <div
                      className={styles.itemRelatedTopics}
                      style={{
                        backgroundColor: color(topic.type)[0],
                        color: color(topic.type)[1],
                        cursor: "pointer",
                      }}
                      onMouseDown={handleOuterClick}
                      onClick={() => handleTopicClick(topic)}
                    >
                      <span className={styles.topicLabel}>
                        <Dropdown
                          bgColor={color(topic.type)[0]}
                          options={sentimentOptions}
                          reset={resetOptions}
                          disableMenu={
                            !(
                              feedbackModeEnabled &&
                              highlightedText[date_gpid] &&
                              highlightedText[date_gpid].length > 0
                            )
                          }
                          selectedOptions={
                            modifiedSentiments?.length === 0
                              ? selectedSentiment[topic?.id]
                              : modifiedSentiments[topic.id] ||
                                selectedSentiment[topic?.id]
                          }
                          setSelectedOptions={(newValue) =>
                            handleSentimentChange(topic, newValue)
                          }
                        />
                        {feedbackModeEnabled && (
                          <Dropdown
                            bgColor={color(topic.type)[0]}
                            options={intentOptions}
                            disableMenu={
                              !(
                                feedbackModeEnabled &&
                                highlightedText[date_gpid] &&
                                highlightedText[date_gpid].length > 0
                              )
                            }
                            selectedOptions={
                              modifiedIntents?.length === 0
                                ? selectedIntent[topic?.id]
                                : modifiedIntents[topic.id] ||
                                  selectedIntent[topic?.id]
                            }
                            setSelectedOptions={(newValue) =>
                              handleIntentChange(topic, newValue)
                            }
                          />
                        )}
                        <span
                          onClick={() => handleTopicClick(topic)}
                          className={styles.label}
                          title={topic.label}
                        >
                          {isMobileView ? (
                            <span>{mobileLabel(topic.label)}</span>
                          ) : (
                            label(topic.label, "ai")
                          )}{" "}
                          {feedbackModeEnabled && (
                            <CloseOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleRemoveTopic(topic);
                              }}
                            />
                          )}
                        </span>
                      </span>
                    </div>
                  ) : (
                    (!topic.type || topic.type === "custom") &&
                      showCustomTopicLabel && (
                        <div
                          className={styles.itemRelatedTopics}
                          style={{
                            backgroundColor: color("custom")[0],
                            color: color("custom")[1],
                            cursor: "pointer",
                          }}
                          onClick={() => handleTopicClick(topic)}
                          onMouseDown={handleOuterClick}
                          keyelement={date_gpid}
                        >
                          <span className={styles.topicLabel}>
                            <Dropdown
                              bgColor={color("custom")[0]}
                              options={sentimentOptions}
                              disableMenu={
                                !(
                                  feedbackModeEnabled &&
                                  highlightedText[date_gpid] &&
                                  highlightedText[date_gpid].length > 0
                                )
                              }
                              selectedOptions={
                                modifiedSentiments?.length === 0
                                  ? selectedSentiment[topic?.id]
                                  : modifiedSentiments[topic.id] ||
                                    selectedSentiment[topic?.id]
                              }
                              setSelectedOptions={(newValue) =>
                                handleSentimentChange(topic, newValue)
                              }
                            />
                            {feedbackModeEnabled && (
                              <Dropdown
                                bgColor={color("custom")[0]}
                                options={intentOptions}
                                disableMenu={
                                  !(
                                    feedbackModeEnabled &&
                                    highlightedText[date_gpid] &&
                                    highlightedText[date_gpid].length > 0
                                  )
                                }
                                selectedOptions={
                                  modifiedIntents?.length === 0
                                    ? selectedIntent[topic?.id]
                                    : modifiedIntents[topic.id] ||
                                      selectedIntent[topic?.id]
                                }
                                setSelectedOptions={(newValue) =>
                                  handleIntentChange(topic, newValue)
                                }
                              />
                            )}
                            <span
                              onClick={() => handleTopicClick(topic)}
                              className={styles.label}
                              title={topic.label}
                            >
                              {isMobileView
                                ? mobileLabel(topic.label)
                                : label(topic.label, "custom")}{" "}
                            </span>
                            {feedbackModeEnabled && (
                              <CloseOutlined
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  handleRemoveTopic(topic);
                                }}
                              />
                            )}
                          </span>
                        </div>
                      )
                  );
                })}
              {restaurant &&
                Object.keys(restaurant)?.length > 0 &&
                !feedbackModeEnabled &&
                showPivotsInReviews && (
                  <>
                    {Object.keys(restaurant).map((key) => (
                      <span className={styles.pivotValues}>
                        {key}: {restaurant[key]}
                      </span>
                    ))}
                  </>
                )}
              <div className={styles.newSelectionItems}>
                <div className={styles.firstLineItems}>
                  {feedbackModeEnabled &&
                    highlightedText[date_gpid] &&
                    highlightedText[date_gpid].length > 0 &&
                    !topicClicked &&
                    !clickedDecision &&
                    !(publishChanged || openCaseChanged || actionChanged) && (
                      <SingleSelect
                        options={parentTopics.filter(
                          (t) =>
                            t.id !== null &&
                            t.id >= 0 &&
                            t.label.toLowerCase() !== "others"
                        )}
                        reset={resetParent}
                        disableMenu={
                          publishChanged || openCaseChanged || actionChanged
                        }
                        placeholder={
                          <span
                            className={styles.placeholder}
                            style={{
                              color: theme === "dark" ? "white" : "black",
                            }}
                          >
                            <i
                              className="fa fa-plus"
                              aria-hidden="true"
                              style={{
                                color: theme === "dark" ? "white" : "black",
                                borderColor:
                                  theme === "dark" ? "white" : "black",
                              }}
                            />{" "}
                            Add Parent Topic
                          </span>
                        }
                        selectedOptions={selectedParent}
                        setSelectedOptions={setSelectedParent}
                      />
                    )}
                  {feedbackModeEnabled &&
                    highlightedText[date_gpid] &&
                    highlightedText[date_gpid].length > 0 &&
                    selectedParent && (
                      <SingleSelect
                        options={sentimentOptions}
                        placeholder={
                          <span className={styles.placeholder}>
                            {t("button.45")}
                          </span>
                        }
                        reset={resetOptions}
                        selectedOptions={newSentiment}
                        setSelectedOptions={setNewSentiment}
                      />
                    )}
                  {feedbackModeEnabled &&
                    highlightedText[date_gpid] &&
                    highlightedText[date_gpid].length > 0 &&
                    selectedParent && (
                      <SingleSelect
                        options={intentOptions}
                        reset={resetOptions}
                        placeholder={
                          <span className={styles.placeholder}>
                            {t("button.46")}
                          </span>
                        }
                        selectedOptions={newIntent}
                        setSelectedOptions={setNewIntent}
                      />
                    )}
                </div>
                <div className={styles.bottomLineItems}>
                  {selectedParent &&
                    selectedParent.id &&
                    childTopics[selectedParent.id] &&
                    childTopics[selectedParent.id]?.length > 0 &&
                    feedbackModeEnabled &&
                    highlightedText[date_gpid] &&
                    highlightedText[date_gpid].length > 0 &&
                    (!topicClicked || relTopics?.length === 0) &&
                    childTopics[selectedParent.id]?.map((topic) => (
                      <>
                        {topic?.label.split(">")[1] && topic.id >= 0 && (
                          <div
                            className={
                              theme === "dark"
                                ? styles.childTopicsWrapper
                                : styles.lightModeChildTopics
                            }
                            style={{
                              backgroundColor: color("custom")[0],
                              color: color("custom")[1],
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const newSelectedItems = selectedTopics.includes(
                                topic
                              )
                                ? selectedTopics.filter(
                                    (item) => item !== topic
                                  )
                                : [...selectedTopics, topic];
                              handleChildTopicsChange(newSelectedItems);
                            }}
                          >
                            <Button className={styles.childTopic}>
                              <span>
                                <Checkbox
                                  checked={selectedTopics.includes(topic)}
                                  onChange={() => {
                                    const newSelectedItems = selectedTopics.includes(
                                      topic
                                    )
                                      ? selectedTopics.filter(
                                          (item) => item !== topic
                                        )
                                      : [...selectedTopics, topic];
                                    handleChildTopicsChange(newSelectedItems);
                                  }}
                                  style={{ marginRight: "8px" }}
                                />
                                {removeParentFromChildren(topic?.label, true)}
                              </span>
                            </Button>
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            </div>
            {!feedbackModeEnabled &&
              associationsRefHeight &&
              associationsRefHeight > 120 && (
                <span
                  className={styles.showMoreAssociations}
                  onClick={() => setCollapseAssociations(!collapseAssociations)}
                >
                  {collapseAssociations ? (
                    <span>
                      Show More <CaretDownFilled />
                    </span>
                  ) : (
                    <span>
                      Show Less <CaretUpFilled />
                    </span>
                  )}
                </span>
              )}
          </div>
          {feedbackModeEnabled &&
            (topicsEdited ||
              selectedParent ||
              displayActions ||
              (deletedTopics && deletedTopics[date_gpid]?.length > 0) ||
              markedTextClicked ||
              publishChanged ||
              openCaseChanged ||
              actionChanged) && (
              <div className={styles.actions}>
                <Button
                  className={styles.discardBtn}
                  onClick={handleDiscardChanges}
                >
                  Discard
                </Button>
                <Button
                  type="primary"
                  disabled={
                    !selectedParent &&
                    !topicsEdited &&
                    !markedTextClicked &&
                    !(publishChanged || openCaseChanged || actionChanged)
                  }
                  className={styles.applyBtn}
                  onClick={() => {
                    handleApplyChanges();
                  }}
                >
                  Apply Changes
                </Button>
              </div>
            )}
        </div>
      </div>
      {feedbackModeEnabled && (
        <div className={styles.reviewFooter}>
          <span className={styles.gpid}>Gpid: {date_gpid?.split("_")[1]}</span>
          {["33", "34", "35"].includes(reviewPlatformId) && internalId && (
            <span className={styles.review_id}>
              <span>
                {`UniqueReviewId: ${ETSplatformIdsNames[reviewPlatformId]}_${
                  internalId.split("_")[0]
                }`}
              </span>
              <span>
                {internalId.split("_")[1] &&
                  `QuestionId: ${internalId.split("_")[1]}`}
              </span>
            </span>
          )}
        </div>
      )}
    </>
  );

  // const FacebookReply = ({ item }) => {
  //   const formatDate = (dateString) => {
  //     if (dateString.length === 1) {
  //       dateString = "0" + dateString;
  //     }

  //     return dateString;
  //   };

  //   const ownerName = item?.from?.name;
  //   const dateObject = new Date(item?.created_time);
  //   const date =
  //     String(dateObject.getFullYear()) +
  //     "-" +
  //     formatDate(String(dateObject.getMonth() + 1)) +
  //     "-" +
  //     formatDate(String(dateObject.getDate())) +
  //     " " +
  //     formatDate(String(dateObject.getHours())) +
  //     ":" +
  //     formatDate(String(dateObject.getMinutes())) +
  //     ":" +
  //     formatDate(String(dateObject.getSeconds()));

  //   return (
  //     <>
  //       <div
  //         className={styles.itemContainerNoBorder}
  //         style={{
  //           padding: 15,
  //           backgroundColor: theme === "dark" ? "#100D31" : "#fff",
  //           borderRadius: 8,
  //           marginTop: 8,
  //         }}
  //       >
  //         <div className={styles.facebookComment}>
  //           <div className={styles.facebookCommentLeft}>
  //             {/* <div className={styles.checkbox}>{checkbox}</div> */}
  //             <div>
  //               <img
  //                 src="/assets/facebook_user.jpg"
  //                 className={styles.facebookCommentPic}
  //               />
  //             </div>
  //             <div className={styles.facebookCommentName}>
  //               <p style={{ color: ownerName ? "#1890ff" : "#fff" }}>
  //                 {ownerName || t("review.11")} ({t("review.10")})
  //               </p>
  //             </div>
  //           </div>
  //           <div className={styles.facebookCommentRight}>
  //             {/* <div>
  //               <i className="fa fa-thumbs-up fa-2x"></i>
  //               <p>{likes}</p>
  //             </div>
  //             <img src="/assets/google-play.svg"/> */}
  //           </div>
  //         </div>
  //         {/* <ShowMoreText
  //           lines={3}
  //           more="Show more"
  //           less="Show less"
  //           expanded={false}
  //           className="review-more"
  //           anchorclassName=''
  //         >
  //           {item.message}
  //         </ShowMoreText> */}
  //         <ReactReadMoreReadLess
  //           charLimit={200}
  //           readMoreText={`${t("review.6")} ▼`}
  //           readLessText={`${t("review.7")} ▲`}
  //         >
  //           {item.message}
  //         </ReactReadMoreReadLess>
  //         <div className={styles.itemBottom}>
  //           <div className={styles.itemProps}></div>
  //           <span className={styles.itemDate}>{date}</span>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  if (platformId === 2 && pic && (name || username)) {
    return (
      <div
        className={styles.itemContainer}
        keyelement={date_gpid}
        onMouseDown={handleMouseDown}
      >
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}>
            <div>
              <img src={pic} className={styles.tweetPic} />
            </div>
            <div className={styles.tweetName}>
              <p>{name || username}</p>
              {rate && (
                <div className={styles.starRating}>
                  <StarRatings
                    rating={parseFloat(rate)}
                    starDimension="20px"
                    starSpacing="5px"
                    starRatedColor="#ffaf00"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.tweetLikes}>
            <div>
              <i className="fa fa-thumbs-up fa-2x"></i>
              <p>{likes}</p>
            </div>
            <img src="/assets/google-play.svg" />
          </div>
        </div>
        {partOfAllReviews}
      </div>
    );
  }

  if (platformId === 11 && text !== t("notifications.86")) {
    return (
      <div
        className={styles.itemContainer}
        keyelement={date_gpid}
        onMouseDown={handleMouseDown}
      >
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}>
            <div>
              <img
                src={pic || "/assets/facebook_user.jpg"}
                className={styles.tweetPic}
              />
            </div>
            <div className={styles.tweetName}>
              <p>{name || username}</p>
              {rate && (
                <div className={styles.starRating}>
                  <StarRatings
                    rating={parseFloat(rate)}
                    starDimension="20px"
                    starSpacing="5px"
                    starRatedColor="#ffaf00"
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.tweetLikes}>
            {/* <div>
              <i className="fa fa-thumbs-up fa-2x"></i>
              <p>{likes}</p>
            </div> */}
            {reviewPlatformId === "2" ? (
              <i
                style={{
                  fontSize: 32,
                  marginRight: 15,
                  color: theme === "dark" ? "#fff" : "#000",
                }}
                className="fab fa-google-play"
              />
            ) : reviewPlatformId === "4" ? (
              <i
                style={{
                  fontSize: 32,
                  marginRight: 15,
                  color: theme === "dark" ? "#fff" : "#000",
                }}
                className="fab fa-app-store"
              />
            ) : null}
          </div>
        </div>
        {partOfAllReviews}
      </div>
    );
  }

  if (platformId === 12 && text !== t("notifications.86")) {
    return (
      <div
        className={styles.itemContainer}
        keyelement={date_gpid}
        onMouseDown={handleMouseDown}
      >
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}>
            <div>
              <img
                src={
                  pic !== "None" && pic !== "nan"
                    ? pic
                    : "/assets/facebook_user.jpg"
                }
                className={styles.tweetPic}
              />
            </div>
            <div className={styles.tweetName}>
              <div className={styles.tweetNameVerified}>
                <h5>{name !== "None" && name !== "nan" ? name : ""}</h5>
                {userVerified && userVerified === "True" && (
                  <svg
                    viewBox="0 0 24 24"
                    aria-label="Verified account"
                    className="r-1fmj7o5 r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
                  >
                    <g>
                      <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"></path>
                    </g>
                  </svg>
                )}
              </div>
              <p>
                {username !== "None" &&
                username !== "nan" &&
                typeof username !== "undefined"
                  ? "@" + username
                  : ""}
              </p>
            </div>
          </div>

          <div
            styles={{
              marginRight: 10,
            }}
            className={styles.tweetLikes}
          >
            {/* <div>
              <i className="fa fa-thumbs-up fa-2x"></i>
              <p>{likes}</p>
            </div> */}
            {reviewPlatformId === "1" ? (
              <img className={styles.twitter} src="/assets/twitter_new_.png" />
            ) : reviewPlatformId === "5" ? (
              <img
                style={{ fontSize: 32, marginRight: 15 }}
                src="/assets/sikayet_icon.svg"
                alt="sikayet"
              />
            ) : reviewPlatformId === "6" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#674fbc" }}
                className="fa-brands fa-instagram"
              />
            ) : reviewPlatformId === "9" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#4267B2" }}
                className="fa-brands fa-facebook-square"
              />
            ) : reviewPlatformId === "10" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#4267B2" }}
                className="fa-brands fa-facebook-square"
              />
            ) : reviewPlatformId === "18" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#ff4500" }}
                className="fa-brands fa-reddit"
              />
            ) : reviewPlatformId === "19" ? (
              <img
                style={{ objectFit: "contain", marginTop: 5, marginRight: 15 }}
                src="/assets/eksi_icon.svg"
                alt="eksi"
              />
            ) : reviewPlatformId === "27" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#674fbc" }}
                className="fa-brands fa-instagram"
              />
            ) : null}
          </div>
        </div>
        {partOfAllReviews}
      </div>
    );
  }
  if (
    (platformId === 3 || platformId === 28) &&
    text !== t("notifications.86")
  ) {
    return (
      <div
        className={styles.itemContainer}
        keyelement={date_gpid}
        onMouseDown={handleMouseDown}
      >
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}></div>

          {!isNaN(npsRating) ? (
            <div className={styles.npsTag}>
              <span
                style={{
                  color: "white",
                  backgroundColor:
                    npsRating < (npsScale[1] === 5 ? 4 : 7)
                      ? "#FF6253"
                      : (npsRating >= npsScale[1] === 5 ? 4 : 7) &&
                        npsRating <= (npsScale[1] === 5 ? 4 : 8)
                      ? "#FFA800"
                      : "#00B07B",
                }}
              >
                {npsRating}
                <img src="/assets/Star.svg" alt="" />
              </span>
              <span
                style={{
                  backgroundColor: "#e8c5af",
                }}
              >
                {npsScale[1] === 5 ? "VoC" : "NPS"}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        {partOfAllReviews}
      </div>
    );
  }
  if (
    (platformId === 7 || platformId === 8) &&
    text !== t("notifications.86")
  ) {
    return (
      <div
        className={styles.itemContainer}
        keyelement={date_gpid}
        onMouseDown={handleMouseDown}
      >
        <div className={styles.facebookComment}>
          <div className={styles.facebookCommentLeft}>
            <div>
              <img
                src="/assets/facebook_user.jpg"
                className={styles.facebookCommentPic}
              />
            </div>
            <div className={styles.facebookCommentName}>
              <p> {name && name !== "None" ? name : t("review.11")} </p>
            </div>
          </div>
        </div>
        {partOfAllReviews}
      </div>
    );
  }
  // if (
  //   (platformId === 9 || platformId === 10) &&
  //   text !== t("notifications.86")
  // ) {
  //   return (
  //     <>
  //       <div
  //         className={styles.itemContainer}
  //         keyelement={date_gpid}
  //         onMouseDown={handleMouseDown}
  //       >
  //         <div className={styles.facebookComment}>
  //           <div className={styles.facebookCommentLeft}>
  //
  //             <div>
  //               <img
  //                 src="/assets/facebook_user.jpg"
  //                 className={styles.facebookCommentPic}
  //               />
  //             </div>
  //             <div className={styles.facebook CommentName}>
  //               <p>{t("review.11")}</p>
  //             </div>
  //           </div>
  //           <div className={styles.facebookCommentRight}>
  //             <Button
  //               type="primary"
  //               onClick={() => {
  //                 setIsReplyFacebookReviewsVisible(
  //                   !isReplyFacebookReviewsVisible
  //                 );
  //               }}
  //               style={{
  //                 marginRight: 8,
  //                 backgroundColor: theme === "dark" ? "#100D31" : "#ffffff",
  //                 width: 150,
  //               }}
  //             >
  //               {((facebookReplies || {})[reviewId] || []).length > 0 &&
  //                 (isReplyFacebookReviewsVisible
  //                   ? t("review.12")
  //                   : `${t("review.13")} (` +
  //                     ((facebookReplies || {})[reviewId] || []).length +
  //                     ")")}
  //             </Button>
  //             <Button
  //               type="primary"
  //               onClick={() => {
  //                 setIsReplyFacebookModalVisible(true);
  //               }}
  //               style={{
  //                 backgroundColor: theme === "dark" ? "#100D31" : "#ffffff",
  //               }}
  //             >
  //               {t("review.10")}
  //             </Button>
  //             {/* <div>
  //                 <i className="fa fa-thumbs-up fa-2x"></i>
  //                 <p>{likes}</p>
  //               </div>
  //               <img src="/assets/google-play.svg"/> */}
  //           </div>
  //         </div>
  //         {partOfAllReviews}
  //         {/* {isReplyFacebookReviewsVisible &&
  //           ((facebookReplies || {})[reviewId] || []).map((item) => (
  //             <FacebookReply item={item} />
  //           ))} */}
  //       </div>
  //       {/* {isReplyFacebookModalVisible && (
  //         <div
  //           // onClose={() => {
  //           //   setFacebookReplyMessage("");
  //           //   setIsReplyFacebookModalVisible(false);
  //           // }}
  //           // onSave={() => {
  //           //   replyFacebook(reviewId, facebookReplyMessage);
  //           //   setFacebookReplyMessage("");
  //           // }}
  //           // showSaveButton={true}
  //           // textonButton="Ok"
  //           // titleAsset=""
  //           // title="Reply to comment"
  //           // widthofmodal="800px"
  //           className={styles.replyBoxModal}
  //         >
  //           <div className={styles.replyBoxModalContent}>
  //             <div className={styles.headingBox}>
  //               <div className={styles.titleBox}>
  //                 <p>{t("review.14")}</p>
  //                 <p>
  //                   <svg
  //                     width="14"
  //                     height="14"
  //                     viewBox="0 0 14 14"
  //                     fill="none"
  //                     xmlns="http://www.w3.org/2000/svg"
  //                   >
  //                     <g clip-path="url(#clip0_1593_1271)">
  //                       <path
  //                         d="M7.00033 12.8337C10.222 12.8337 12.8337 10.222 12.8337 7.00033C12.8337 3.77866 10.222 1.16699 7.00033 1.16699C3.77866 1.16699 1.16699 3.77866 1.16699 7.00033C1.16699 10.222 3.77866 12.8337 7.00033 12.8337Z"
  //                         stroke={theme === "dark" ? "#D9D9D9" : "#222222"}
  //                         stroke-linecap="round"
  //                         stroke-linejoin="round"
  //                       />
  //                       <path
  //                         d="M7 9.33333V7"
  //                         stroke={theme === "dark" ? "#D9D9D9" : "#222222"}
  //                         stroke-linecap="round"
  //                         stroke-linejoin="round"
  //                       />
  //                       <path
  //                         d="M7 4.66699H7.00667"
  //                         stroke={theme === "dark" ? "#D9D9D9" : "#222222"}
  //                         stroke-linecap="round"
  //                         stroke-linejoin="round"
  //                       />
  //                     </g>
  //                     <defs>
  //                       <clipPath id="clip0_1593_1271">
  //                         <rect width="14" height="14" fill="white" />
  //                       </clipPath>
  //                     </defs>
  //                   </svg>
  //                   {t("review.15")}
  //                 </p>
  //               </div>
  //               {theme === "dark" ? (
  //                 <img
  //                   src="/assets/x.svg"
  //                   alt=""
  //                   onClick={() => {
  //                     setFacebookReplyMessage("");
  //                     setIsReplyFacebookModalVisible(false);
  //                   }}
  //                 />
  //               ) : (
  //                 <img
  //                   src="/assets/x_black.svg"
  //                   alt=""
  //                   onClick={() => {
  //                     setFacebookReplyMessage("");
  //                     setIsReplyFacebookModalVisible(false);
  //                   }}
  //                 />
  //               )}
  //             </div>

  //             <Spin spinning={isReplyFacebookModalLoading}>
  //               <div className={styles.userNameDetails}>
  //                 <span>
  //                   <img src="/assets/facebook_user.jpg" alt="" />{" "}
  //                   {t("review.11")}
  //                 </span>
  //                 <p>{text}</p>
  //               </div>
  //               <div className={styles.replyBox}>
  //                 <textarea
  //                   onInput={handleUserText}
  //                   id="custom_text_input"
  //                   value={facebookReplyMessage}
  //                   rows={"3"}
  //                   placeholder={t("placeholder.17")}
  //                   onChange={(e) => setFacebookReplyMessage(e.target.value)}
  //                 />
  //                 <button
  //                   type="button"
  //                   onClick={() => {
  //                     replyFacebook(reviewId, facebookReplyMessage);
  //                     setFacebookReplyMessage("");
  //                   }}
  //                 >
  //                   {t("review.10")}
  //                 </button>
  //               </div>
  //             </Spin>
  //           </div>
  //         </div>
  //       )} */}
  //     </>
  //   );
  // }

  return (
    <div
      className={styles.itemContainer}
      keyelement={date_gpid}
      onMouseDown={handleMouseDown}
    >
      {rate && (
        <div className={styles.starRating}>
          <StarRatings
            rating={parseFloat(rate)}
            starDimension="20px"
            starSpacing="5px"
            starRatedColor="#ffaf00"
          />
        </div>
      )}
      {platformId === 1 && pic && name && username && userVerified && (
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}>
            <div>
              <img src={pic} className={styles.tweetPic} />
            </div>
            <div className={styles.tweetName}>
              <div className={styles.tweetNameVerified}>
                <h5>{name}</h5>
                {userVerified === "True" && (
                  <svg
                    viewBox="0 0 24 24"
                    aria-label="Verified account"
                    className="r-1fmj7o5 r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
                  >
                    <g>
                      <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"></path>
                    </g>
                  </svg>
                )}
              </div>

              <p>@{username}</p>
            </div>
          </div>

          <div>
            <i className="fab fa-twitter fa-2x"></i>
          </div>
        </div>
      )}
      {partOfAllReviews}
    </div>
  );
};

export default ReviewItem;
