import React, { useEffect, useRef, useState } from 'react';
import styles from "./style.module.scss"
import { Select, notification } from 'antd';
import SortPopup from 'helperComponents/SortPopup';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { apiRequest } from "util/services";
import { Spin } from "antd";
import { Button } from 'antd';
import { HorizontalChart, BarChart, BarChartFeedback } from "./journeyChart"
import DateFilterPopUp from "../../components/Dashboard/DateFilter/index";
import dayjs from 'dayjs'
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime)


const { Option } = Select



const CustomerJourney = () => {
    const theme = useSelector((state) => state.DashboardReducer.theme);



    const [showSearchBox, setShowSearchBox] = useState(false);
    const [showRowItems, setShowRowItems] = useState(true);
    const [showSortPopup, setShowSortPopup] = useState(false);
    const [sortValue, setSortValue] = useState()
    const [surveyList, setsurveyList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [loading, setLoading] = useState(false)
    const [surveyType, setSurveyType] = useState("All")
    const [searchWord, setSearchWord] = useState("");
    const [totalSurveys, settotalSurveys] = useState()
    const [dashboards, setDashboards] = useState()
    const [surveryPageNumber, setsurveryPageNumber] = useState(1)
    const [startIndex, setStartIndex] = useState(0);
    const [selectedPerPage, setSelectedPerPage] = useState(10);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [surveyTypes, setSurveyTypes] = useState([]);
    const [selectedDashboards, setSelectedDashboards] = useState([])
    const [preSelectedDashboards, setPreSelected] = useState([])
    const [chartData, setChartData] = useState({})
    const [showSourcePicker, setShowPicker] = useState(false)
    const isMounted = useRef(false);
    const { t } = useTranslation();

    useEffect(() => {
        getSelectedDashboards()
    }, [])



    const getSelectedDashboards = async () => {
        const result = await apiRequest(
            "users/cj/source",
            "POST",
            {

            },
            true
        );
        if (!result.error) {
            let dashboardsSelected = []
            if (result?.success?.length) {
                result.success.map((info) => {
                    if (info.status === 1) {
                        dashboardsSelected.push(info.id)
                    }
                })
            }
            setDashboards(result.success)
            if (dashboardsSelected.length) {
                setShowPicker(false)
                setSelectedDashboards(dashboardsSelected)
                setDateRange([ dayjs().add(-30, 'd'),dayjs()])
            } else {
                setShowPicker(true)
            }

        }
    }

    const saveSelects = async (removedItem) => {
        let dashboard_ids = []
        if (selectedDashboards.length) {
            selectedDashboards.map((info) => {
                if (info) {
                    dashboard_ids.push(info)
                }
            })
        }
        if (removedItem) {
            dashboard_ids = dashboard_ids.filter((item) => item !== removedItem)
        }
        const result = await apiRequest(
            "users/cj/source/add",
            "POST",
            {
                dashboard_ids: dashboard_ids
            },
            true
        );
        if (!result.error) {
            setSurveyTypes(result?.data);
        }
    }

    const fetchChartData = async (removedItem) => {
        setLoading(true)
        let dashboard_ids = []
        if (selectedDashboards.length) {
            selectedDashboards.map((info) => {
                dashboard_ids.push(info)

            })
        }
        if (removedItem) {
            dashboard_ids = dashboard_ids.filter((item) => item !== removedItem)
        }
        if (dateRange && dateRange.length) {
            const result = await apiRequest(
                "users/topicJourneyMapping",
                "POST",
                {
                    start_date: dateRange && dateRange[0] ? dateRange[0] : "",
                    end_date: dateRange && dateRange[1] ? dateRange[1] : "",
                    dashboard_ids: dashboard_ids
                },
                true
            );
            if (!result.error) {
                setSurveyTypes(result?.data);
            }
            setChartData(result)
        } else {
            const result = await apiRequest(
                "users/topicJourneyMapping",
                "POST",
                {

                    dashboard_ids: dashboard_ids
                },
                true
            );
            if (!result.error) {
                setSurveyTypes(result?.data);
            }
            setChartData(result)
        }


     




        setLoading(false)

    }


    const handlePageChange = () => {
        let end_index = surveyList.length
        if (isMounted.current) {
            if (selectedPerPage * surveryPageNumber < end_index) {
                end_index = selectedPerPage * surveryPageNumber;
                setHasNextPage(true)
            } else {
                setHasNextPage(false)
            }
        } else {
            isMounted.current = true
        }
        if (surveyList.length == 0) {
            let temp = []
            setFilteredList(temp)

        } else {
            let temp = surveyList?.slice(startIndex * selectedPerPage, end_index)
            setFilteredList(temp)
        }

    }

    useEffect(() => {
        handlePageChange()
    }, [surveryPageNumber])



    useEffect(() => {
        setStartIndex(0);
        let list = surveyList
        let temp = list?.length ? list?.slice(0, selectedPerPage > surveyList.length ? surveyList.length : selectedPerPage) : []
        setFilteredList(temp)
        settotalSurveys(surveyList?.length ? surveyList.length : 0)
    }, [surveyList])



    const handleShowSearch = () => {
        if (window.innerWidth < 880) {
            setShowRowItems(false);
        }
        setShowSearchBox(!showSearchBox);
    }

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            if (searchWord.trim()) {
                let list = surveyList.filter((item) => {
                    if (item.survey_name.search(searchWord) !== -1) {
                        return true
                    } else return false
                })
                setFilteredList(list)
                // startIndex ? setStartIndex(0) : getAllDashboards()
                // setDashboardPageNumber(1)
            } else {
                setFilteredList(surveyList)
            }
        }
    }

    const [showDateFilterPopup, setShowDateFilterPopup] = useState(false)
    const [dateRange, setDateRange] = useState()
    const [selectedDaysRange, setSelectedDaysRange] = useState("last7d")
    const rangePresets = {
        last24: {
            label: "Last 24 hours"
        },
        last7d: {
            label: "Last 7 Days"
        },
        last14d: {
            label: "Last 14 Days "
        },
        last30d: {
            label: "Last 30 Days "
        },
        last90d: {
            label: "Last 90 Days"
        },
        custom: {
            label: "Custom"
        },
        allTime: {
            label: "All Time"
        }
    }
    const dateFilterRef = useRef()

    useEffect(() => {
        fetchChartData()
    }, [dateRange])


    return <div className={styles.mainWindow}>
        <div className={styles.header}>

            <div className={styles.content}>
                <p>Customer Journey</p>
                <p className={styles.description}>Customer actions lifecycle</p>

                {
                    showDateFilterPopup && (
                        <div
                            ref={dateFilterRef}
                        >
                            <DateFilterPopUp
                                onChange={

                                    (dateString, daysRange) => {
                                        setDateRange(dateString)
                                        setShowDateFilterPopup(false)
                                        setSelectedDaysRange(daysRange)
                                    }
                                }
                                close={() => {
                                    setShowDateFilterPopup(false)
                                }}
                                selectedDaysRange={selectedDaysRange}
                                defaultStartDate={new Date()}
                                defaultEndDate={new Date()}
                                searchPeriod={[new Date(), new Date()]}
                            />
                        </div>
                    )
                }
            </div>
            <div className={styles.buttonWrapper}>
                {
                    showSourcePicker == false ? <div className={styles.drop}>
                        <img width={24} src={theme === 'dark' ? "/assets/dashboards.svg" : "/assets/dashboards_black.svg"} alt="" />
                        <Select
                            mode="multiple"
                            value={selectedDashboards}
                            allowClear
                            onDropdownVisibleChange={(val) => {
                                if (val == false) {
                                    fetchChartData()
                                    saveSelects()
                                }
                            }}
                            onClear={() => {
                                saveSelects()
                                fetchChartData()
                            }}
                            onDeselect={(val) => {
                                saveSelects(val)
                                fetchChartData(val)
                            }}
                            style={{ width: "300px" }}
                            placeholder={<div className={styles.placeholder}> Please select dashboards                <img width={24} src={theme === 'dark' ? "/assets/arrow_down.svg" : "/assets/arrow_down_black.svg"} alt="" />
                            </div>}
                            onChange={(value) => {
                                setSelectedDashboards(value)
                                // setError("")
                                // setSelectedTopics(value)
                            }}
                        >
                            {
                                dashboards && dashboards.length ? dashboards.map((info) => {
                                    return <Select.Option value={info.id} key={info.id}>{info.name}</Select.Option>;
                                }) : ""
                            }
                        </Select></div> : ''
                }

                <button
                    className={styles.dateFilterPopupBtn}
                    onClick={() => setShowDateFilterPopup(!showDateFilterPopup)}
                >
                    <img
                        src={
                            theme === "dark"
                                ? "/assets/calendar.svg"
                                : "/assets/calendar_black.svg"
                        }
                        alt=""
                    />
                    {selectedDaysRange === 'custom' ? `${dayjs(dateRange[0]).format("MMMM D, YYYY")} - ${dayjs(dateRange[1]).format("MMMM D, YYYY")}`:
                    rangePresets[`${selectedDaysRange}`].label}
                    <img
                        src={
                            theme === "dark"
                                ? "/assets/arrow_down.svg"
                                : "/assets/arrow_down_black.svg"
                        }
                        alt=""
                    />
                </button>
            </div>
        </div>
        {
            showSourcePicker ? <div className={styles.noTopics}>

                <p className={styles.heading}>

                    <img width={24} src={theme === 'dark' ? "/assets/dashboards.svg" : "/assets/dashboards.svg"} alt="" />
                    Select a source</p>
                <p className={styles.description}>Some random description </p>

                <Select
                    mode="multiple"
                    value={selectedDashboards}
                    allowClear
                    style={{ width: "400px" }}
                    placeholder={<div className={styles.placeholder}> Please select dashboards                <img width={24} src={theme === 'dark' ? "/assets/arrow_down.svg" : "/assets/arrow_down_black.svg"} alt="" />
                    </div>}
                    onChange={(value) => {
                        setSelectedDashboards(value)
                        // setError("")
                        // setSelectedTopics(value)
                    }}
                    onDropdownVisibleChange={(val) => {
                        if (val === false) {
                            setShowPicker(false)
                            fetchChartData()
                            saveSelects()
                        }
                    }}
                >
                    {
                        dashboards && dashboards.length ? dashboards.map((info) => {
                            return <Select.Option value={info.id} key={info.id}>{info.name}</Select.Option>;

                        }) : ""
                    }
                </Select>
                {/* <div className={styles.secondRow}>
                <img src={theme === 'dark' ? "/assets/error_outline.svg" : "/assets/error_outline_black.svg"} alt="" />
                {t("empty_states.12")}
            </div> */}


                {/* <p className={styles.link}>{t("empty_states.4")}</p> */}
            </div> : ''
        }

        <Spin
            className={styles.customSpin}
            style={{ marginTop: "20px" }}
            spinning={loading}
        >

            <div className={`${showSourcePicker ? styles.overlay : ''}`}>
                <div className={styles.vertical}>
                    <div className={styles.text}>
                        <div className={styles.title}>Net Sentiment score</div>
                        <div className={styles.description}>compared to time periods</div>

                    </div>
                    <div className={styles.chart}>
                        <BarChart ChartData={chartData} />

                    </div>

                </div>
                <div className={styles.vertical} >
                    <div className={styles.text}>
                        <div className={styles.title}>Positive Feedback rate</div>
                        <div className={styles.description}>compared to time periods</div>

                    </div>
                    <div className={styles.chart}>
                        <BarChartFeedback ChartData={chartData} />
                    </div>
                </div>
            </div></Spin>


    </div>
}
export default CustomerJourney