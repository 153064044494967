import React, { useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { apiRequest, uploadFileToS3 } from "util/services";
import { notification } from "antd";
import firebase from "FirebaseConfig.js";
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_USER } from 'redux/user/actions';
import { useTranslation } from 'react-i18next';

const Uploader = ({ user }) => {
  const [progress, setProgress] = useState(0);

  const axios = require("axios").default;
  const theme = useSelector(state => state.DashboardReducer.theme);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(status, meta);
  };
  const handleFiles = async (files) => {
    const f = files[0];
    const urlResponse = await apiRequest("dashboards/bucket_temp", "POST", {
      user_id: user.uid,
      filename: f["file"].name,
    });

    if (urlResponse) {
      const result = await uploadFileToS3(urlResponse, f["file"], setProgress);
      if (result) {
        notification.success({ message: t("notifications.99") });
        // save in firebase
        const logo_url = urlResponse.url + urlResponse.fields.key
        firebase
          .database()
          .ref(`/users/${user.uid}/`)
          .set({
            ...user,
            logo_url,
          }).then(() => {
            console.log("info updated");
          }).catch(() => {
            notification.error({
              message: t("settings.98")
            });
          })
        const logoupdate = await apiRequest("users/logo", "POST", {
          user_id: user.uid,
          logoUrl: logo_url,
        });
        dispatch({
          type: UPDATE_USER,
          payload: {
            logoUrl: logo_url
          }
        })

      } else {
        notification.error({
          message: t("notifications.100")
        });
      }
    } else {
      notification.error({
        message: t("notifications.101"),
      });
    }
  };

  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      onSubmit={handleFiles}
      maxFiles={1}
      multiple={false}
      canCancel={false}
      inputContent={theme === 'dark' ? <img width={70} height={70} src={"/assets/cloud_download.svg"} alt="" /> : <img width={70} height={70} src={"/assets/cloud_download_black.svg"} alt="" />}
      //   styles={{
      //     dropzone: { width: 400, height: 200 },
      //     dropzoneActive: { borderColor: "green" },
      //   }}

      styles={{
        dropzone: { display:"flex",padding: "10px", maxWidth: "700px", maxHeight: "250px", width: "100%", overflow: "Hidden", border: "1px solid #6f6c99", borderRadius: "2px", margin: "0", marginTop: "10px" }, dropzoneActive: {
          color: "#d9eeee",
          fontFamily: 'Poppins',
          fontSize: "12px",
          fontWeight: "100",
        }, submitButton: { backgroundColor: theme === "brand" ? "#4A4D4E" : "#4400aa", color: "#fff", fontSize: "12px", fontWeight: "50", fontFamily: "Poppins" }
      }}
    />
  );
};

export default Uploader;