import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { Modal } from "components/General/ModalFormComponents";
import { useTranslation } from "react-i18next";
import GroupsSelection from "./GroupsSelection";
import DashboardsSelection from "./DashboardsSelection";
import CategoriesSelection from "./CategoriesSelection";
import styles from "./style.module.scss";
import { t } from "i18next";
const Popup = ({
  open,
  onClose,
  data,
  onSave,
  dataType,
  setContinueButtonClicked,
  setSelectCategoriesButtonClicked,
  selectedGroups,
  setSelectedGroups,
  selectedDashboards,
  setSelectedDashboards,
  selectedCategories,
  setSelectedCategories,
  resetPopup,
  loading,
}) => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 950);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);
  const handleContinue = () => {
    onClose();
    setContinueButtonClicked(true);
  };

  const handleSelectCategories = () => {
    onClose();
    setSelectCategoriesButtonClicked(true);
  };

  const handleGenerateChart = () => {
    onClose();
    onSave();
  };
  return (
    <Modal
      showModal={open}
      onClose={onClose}
      onSave={onSave}
      showSaveButton={false}
      showCancelButton={false}
      hideCloseIcon={true}
      titleAsset={""}
      widthofmodal={isMobileView ? "25rem" : ""}
      className={styles.PopupSequence}
      title={
        <div className={styles.popupHeading}>
          {dataType === "Groups" && (
            <>
              {isMobileView
                ? t("competitor_analysis.12.mobile")
                : t("competitor_analysis.12.desktop")}
            </>
          )}
          {dataType === "Dashboards" && (
            <>
              {isMobileView
                ? t("competitor_analysis.13.mobile")
                : t("competitor_analysis.13.desktop")}
            </>
          )}
          {dataType === "Categories" && (
            <>
              {isMobileView
                ? t("competitor_analysis.14.mobile")
                : t("competitor_analysis.14.desktop")}
            </>
          )}
        </div>
      }
    >
      <div className={styles.Popup}>
        {dataType === "Dashboards" && (
          <DashboardsSelection
            dashboards={data}
            selectedDashboards={selectedDashboards}
            setSelectedDashboards={setSelectedDashboards}
            resetPopup={resetPopup}
            loading={loading}
          />
        )}
        {dataType === "Groups" && (
          <GroupsSelection
            groups={data}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            resetPopup={resetPopup}
            loading={loading}
          />
        )}
        {dataType === "Categories" && (
          <CategoriesSelection
            categories={data}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            resetPopup={resetPopup}
            loading={loading}
          />
        )}
      </div>
      <div className={styles.footer}>
        <Button className={styles.footerBtn} onClick={onClose}>
          {t("button.3")}
        </Button>
        {dataType === "Groups" && (
          <Button
            className={styles.footerBtn}
            type="primary"
            onClick={handleContinue}
            disabled={selectedGroups?.length > 0 ? false : true}
          >
            {t("competitor_analysis.15")}
          </Button>
        )}
        {dataType === "Dashboards" && (
          <Button
            className={styles.footerBtn}
            type="primary"
            onClick={handleSelectCategories}
            disabled={selectedDashboards?.length > 0 ? false : true}
          >
            {t("competitor_analysis.16")}
          </Button>
        )}
        {dataType === "Categories" && (
          <Button
            className={styles.footerBtn}
            type="primary"
            onClick={handleGenerateChart}
            disabled={selectedCategories?.length > 0 ? false : true}
          >
            {t("competitor_analysis.17")}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default Popup;
