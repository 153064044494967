import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import { Checkbox, Select, notification } from "antd";
import { apiRequest } from "util/services";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const AlertPopup = ({
  theme,
  close,
  type,
  handleAction,
  disable,
  editFrequency,
  editUsers,
  editCondition,
  editThreshold,
  editSendSMS,
}) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState(type === "edit" ? editUsers : []);
  const [usersList, setUsersList] = useState([]);
  const [condition, setCondition] = useState(
    type === "edit" ? editCondition : ""
  );
  const [frequency, setFrequency] = useState(
    type === "edit" ? editFrequency : [t("alerts_page.36"), t("alerts_page.37")]
  );
  const [newEmailValue, setNewEmailValue] = useState("");
  const [showAddEmailField, setShowAddEmailField] = useState(true);
  const [thresholdValue, setThresholdValue] = useState(
    type === "edit" ? editThreshold : 0
  );
  const [smsBoxChecked, setSmsBoxChecked] = useState(
    type === "edit" ? editSendSMS : false
  );
  const emailInputRef = useRef();
  const inputRef = useRef();
  const handleAlert = () => {
    if (!condition || !thresholdValue || !frequency) {
      notification.error({
        message: t("notifications.148"),
      });
    } else if (isNaN(thresholdValue)) {
      notification.error({
        message: t("notifications.149"),
      });
    } else {
      handleAction(condition, thresholdValue, users, frequency, smsBoxChecked);
    }
  };

  const handleAddNewEmail = () => {
    let value = inputRef?.current?.value;
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (!value || value?.length === 0) {
      // notification.error({
      //    message: "Enter a valid email address"
      // })
    } else if (!value.match(validRegex)) {
      notification.error({
        message: "Enter a valid email address",
      });
    } else {
      setUsers([...users, value]);
      setShowAddEmailField(false);
      setNewEmailValue("");
    }
  };

  const EmailClickOut = (e) => {
    if (emailInputRef.current && !emailInputRef.current.contains(e.target)) {
      handleAddNewEmail(e);
    }
  };

  useEffect(() => {
    document.addEventListener("click", EmailClickOut, true);
    return () => document.removeEventListener("click", EmailClickOut, true);
  }, []);

  const handleAddUser = (e) => {
    if (e.which === 13) {
      const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!e.target.value || e.target.value?.length === 0) {
        notification.error({
          message: "Enter a valid email address",
        });
      } else if (!e.target.value.match(validRegex)) {
        notification.error({
          message: "Enter a valid email address",
        });
      } else {
        setUsers([...users, e.target.value]);
        setShowAddEmailField(false);
        setNewEmailValue("");
      }
    }
  };

  const handleRemoveUser = (val) => {
    setUsers(users?.filter((user) => user !== val));
  };

  return (
    <div className={styles.overlayContainer}>
      <div className={styles.centerWrapper}>
        <div className={styles.alertContent}>
          <div className={styles.title}>
            <span>
              {type === "add" ? t("alerts_page.39") : t("alerts_page.40")}
            </span>
            <img
              src={theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"}
              onClick={() => close(false)}
              alt=""
            />
          </div>
          <div className={styles.dropdownBox}>
            <p className={styles.label}>{t("alerts_page.7")}</p>
            <Select
              style={{ width: "100%" }}
              placeholder={t("alerts_page.19")}
              value={condition}
              onChange={(value) => setCondition(value)}
            >
              <Option value="above" key={"above"}>
                {t("alerts_page.34")}
              </Option>
              <Option value="below" key={"below"}>
                {t("alerts_page.35")}
              </Option>
            </Select>
          </div>
          <div className={styles.fieldsWrapper}>
            <div className={styles.inputBox}>
              <p className={styles.label}>{t("alerts_page.8")}(%)</p>
              <input
                type="text"
                value={thresholdValue}
                onChange={(e) => setThresholdValue(e.target.value)}
                placeholder={t("label.16")}
              />
            </div>
            <div className={`${styles.dropdownBox} ${styles.frequencyBox}`}>
              <p className={styles.label}>{t("alerts_page.27")}</p>
              <Select
                placeholder={t("alerts_page.28")}
                style={{ minWidth: 100 }}
                mode="multiple"
                value={frequency}
                onChange={(value) => setFrequency(value)}
                showArrow
                maxTagCount="responsive"
              >
                <Option value="hourly" key={"hourly"}>
                  {t("alerts_page.38")}
                </Option>
                <Option value="daily" key={"daily"}>
                  {t("alerts_page.37")}
                </Option>
                <Option value="weekly" key={"weekly"}>
                  {t("alerts_page.36")}
                </Option>
              </Select>
            </div>
          </div>

          <div className={styles.dropdownBox}>
            <p className={styles.label}>{t("alerts_page.17")}</p>
            <div className={styles.userListBox}>
              {users?.map((user) => (
                <span className={styles.userTag} key={user}>
                  <span title={user} className={styles.userEmail}>
                    {user}
                  </span>{" "}
                  <img
                    src={"/assets/x.svg"}
                    onClick={() => handleRemoveUser(user)}
                    alt=""
                  />
                </span>
              ))}
            </div>
            {showAddEmailField && (
              <div className={styles.inputWrapper} ref={emailInputRef}>
                {" "}
                <input
                  ref={inputRef}
                  className={styles.inputField}
                  onKeyPress={handleAddUser}
                  placeholder={t("alerts_page.33")}
                  value={newEmailValue}
                  onChange={(e) => {
                    setNewEmailValue(e.target.value);
                  }}
                />
              </div>
            )}
            <div className={styles.addEmailBlock}>
              +{" "}
              <span onClick={() => setShowAddEmailField(true)}>
                {t("alerts_page.32")}
              </span>
            </div>
          </div>
          <div className={styles.checkBox}>
            <Checkbox
              checked={smsBoxChecked}
              onClick={() => setSmsBoxChecked(!smsBoxChecked)}
            >
              <p className={styles.checkboxLabel}>{t("alerts_page.41")}</p>
            </Checkbox>
          </div>
          <button
            className={styles.addAlertBtn}
            disabled={disable}
            onClick={handleAlert}
          >
            {t("alerts_page.42")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertPopup;
