import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./style.module.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AddRule from "./AddRule";
import { apiRequest } from "util/services";
import Loading from "helperComponents/Loading";
import { LoadingOutlined } from "@ant-design/icons";

const ChildTopicPopup = ({
  setShowChildTopicPopup,
  handleShowRelationPopup,
  setShowDeleteRelationPopup,
  selectedParentName,
  selectedChildInfo,
  setSelectedParentName,
  setSelectedChildInfo,
  setSelectedChildID,
  handleRenameRuleModal,
  handleCheckboxChange,
  allTopicsList,
  type,
  setTopicID,
  renameRuleValue,
  setRenameRuleValue,
  disableBtn,
  addRuleAction,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();
  const history = useHistory();
  const [showAddRulePopup, setShowAddRulePopup] = useState(false);
  const [childInfo, setchildInfo] = useState(false);
  const [popuploading, setPopupLoading] = useState(true);

  //get topic info
  useEffect(() => {
    get_topic_info(selectedChildInfo?.id);
  }, [selectedChildInfo]);

  const get_topic_info = async (id) => {
    setPopupLoading(true);
    const payload = {
      lib_id: id,
    };
    const result = await apiRequest(
      "library/topic_info",
      "POST",
      payload,
      true
    );
    if (!result.error) {
      setchildInfo(result.success);
      setPopupLoading(false);
    } else {
      setPopupLoading(false);
    }
    setPopupLoading(false);
  };

  const handleOpen = (type, id) => {
    handleShowRelationPopup("child", id);
  };

  const handleDeleteChild = (id) => {
    setShowDeleteRelationPopup(true);
    setSelectedChildID(parseInt(id, 10));
  };
  return (
    <div className={styles.childTopicPopup}>
      <div className={styles.header}>
        <div className={styles.childInfo}>
          {selectedParentName && (
            <span>
              <img
                src={
                  theme === "dark"
                    ? "/assets/relation_black.svg"
                    : "/assets/relation.svg"
                }
                alt=""
              />
              {selectedParentName}
            </span>
          )}
          {selectedParentName && <span className={styles.divider}>{">"}</span>}
          <span className={styles.activeChild}>
            <img
              src={
                theme === "dark"
                  ? selectedParentName
                    ? "/assets/relation1_black.svg"
                    : "/assets/relation_black.svg"
                  : selectedParentName
                  ? "/assets/relation1.svg"
                  : "/assets/relation.svg"
              }
              alt=""
            />
            {childInfo?.name}
          </span>
        </div>
        <img
          src={theme === "dark" ? "/assets/x.svg" : "/assets/x_black.svg"}
          alt=""
          onClick={() => {
            setShowChildTopicPopup(false);
            setSelectedChildInfo({});
          }}
        />
      </div>
      {popuploading ? (
        <LoadingOutlined color="white" style={{ color: "white" }} />
      ) : (
        <>
          <div className={styles.childContainer}>
            <div className={styles.topicRuleBlock}>
              {childInfo?.hasModel ? (
                <>
                  <p className={styles.itemContentTitle}>Classifier</p>
                  <div className={styles.ruleBox}>
                    <p className={styles.rule}>{childInfo?.model?.name}</p>
                  </div>
                </>
              ) : (
                <>
                  <p className={styles.itemContentTitle}>{t("others.17")}</p>
                  {childInfo?.query ? (
                    <div className={styles.ruleBox}>
                      <p className={styles.rule}>{childInfo?.query}</p>
                      <div className={styles.actions}>
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/edit.svg"
                              : "/assets/edit_black.svg"
                          }
                          alt=""
                          onClick={() =>
                            handleRenameRuleModal(
                              childInfo?.query,
                              childInfo?.id
                            )
                          }
                        />
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/copy.svg"
                              : "/assets/copy_black.svg"
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={styles.ruleBox}></div>
                      <p className={styles.addRuleBtn}>
                        <span
                          onClick={() => {
                            setShowAddRulePopup(true);
                            setTopicID(childInfo?.id);
                          }}
                        >
                          <i
                            className="fa-solid fa-plus"
                            onClick={handleOpen}
                          ></i>
                          Add your rule
                        </span>
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
            {childInfo?.runOn?.length !== 0 && (
              <div className={styles.currentDashboardsInfo}>
                <p className={styles.heading}>Running On:</p>
                <div className={styles.dashboardWrapper}>
                  {childInfo?.runOn?.map((item) => (
                    <span
                      onClick={() =>
                        history.push(
                          `/console/DashboardData/${item.dashboard_id}`
                        )
                      }
                    >
                      {item.dashboard_name}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {childInfo?.parent && childInfo?.parent?.length > 0 && (
              <div className={styles.tagLabelSmall}>
                <span>Parent's : </span>
                {childInfo?.parent?.map((item) => (
                  <span className={styles.tag}>{item.name}</span>
                ))}
              </div>
            )}
          </div>
          {showAddRulePopup && (
            <AddRule
              showAddRulePopup={showAddRulePopup}
              setShowAddRulePopup={setShowAddRulePopup}
              disableBtn={disableBtn}
              renameRuleValue={renameRuleValue}
              setRenameRuleValue={setRenameRuleValue}
              onClick={addRuleAction}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ChildTopicPopup;
