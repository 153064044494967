import React, { useState, useEffect, useCallback } from "react";
import { notification } from "antd";
import { apiRequest } from "util/services";
import Representation from './Representation';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const TaskModalForJira = ({
  subscriptionStatus,
  showModal,
  onClose,
  user,
  dashboardId,
  getSearchState,
  timeInterval,
  total_num_docs,
  reporter
}) => {
  const [ressource,setRessource] = useState("")
  const [selectedRessource,setSelectedRessource] = useState("");
  const [selectedBoard, setSelectedBoard] = useState("");
  const [boards, setBoards] = useState();
  const [selectedBoardList, setSelectedBoardList] = useState("");
  const [boardList, setBoardList] = useState();
  const [selectedBoardMembers, setSelectedBoardMembers] = useState([]);
  const [boardMembersList, setBoardMembersList] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState();
  const [projectKey,setProjectKey] =useState("");
  const { t } = useTranslation();
  const theme = useSelector(state => state.DashboardReducer.theme);
  const userReducer = useSelector((state) => state.userReducer);


  const clearState = () => {
    setSelectedRessource(null);
    setSelectedBoard(null);
    setSelectedBoardList(null);
    setSelectedBoardMembers(null);
    setTitle(null);
    setDescription(null);
  }

  const sendRequest = useCallback((payload, onSuccess, reqURL, method = 'POST') => {
    apiRequest(
      reqURL,
      method,
      payload,
      true
    ).then(result => {
      if (!result.error) {
        onSuccess(result);
      } else {
        notification.error({
          message: result.error,
        });
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = () => {
    const searchState = getSearchState();
    const payload = {
      user_id: user.uid,
      cloud_id: selectedRessource,
      project_key: selectedBoard,
      summary : title,
      description : description,
      assigned_account_id: selectedBoardMembers,
      due_date: date,
      dashboard_id: dashboardId,
      timeInterval,
      total_num_docs,
      reporter,
      searchState
    }
    const onSuccess = () => {
      notification.success({
        message: t("trello_task.1")
      });
      clearState();
      onClose();
    }
    sendRequest(payload, onSuccess, "dashboards/jira/create-issue")
  }

  useEffect(() => {
    const payload = {
      user_id: user.uid
    }
    if (showModal && userReducer?.jira_status === "active") {
      const onSuccessGettingRessources = (result) => setRessource(result);
      sendRequest(payload, onSuccessGettingRessources, "dashboards/jira/accessible-resources")
    }

  }, [showModal, sendRequest,userReducer, user.uid]);

  useEffect(() => {
    if (ressource?.length > 0 && selectedRessource) {
      const payload = {
        cloud_id: selectedRessource,
        user_id: user.uid
      }
      const onSuccessGettingBoards = (result) => setBoards(result?.values);
      sendRequest(payload, onSuccessGettingBoards, "dashboards/jira/board")

    }
  }, [ressource, selectedRessource, sendRequest, user.uid]);

  useEffect(() =>{
    if (selectedBoard) {
    const payload = {
      cloud_id: selectedRessource,
      user_id: user.uid,
      project_key: selectedBoard
    }
    const onSuccessBoardMembers = (result) => setBoardMembersList(result);
    sendRequest(payload, onSuccessBoardMembers, "dashboards/jira/board-members")
  }
  },[boards, selectedBoard, sendRequest])

  return <Representation
    showModal={showModal}
    onClose={() => { clearState(); onClose(); }}
    save={save}
    ressource={ressource}
    selectedRessource={selectedRessource}
    setSelectedRessource={setSelectedRessource}
    boards={boards}
    selectedBoard={selectedBoard}
    setSelectedBoard={setSelectedBoard}
    boardMembersList={boardMembersList}
    setSelectedBoardMembers={setSelectedBoardMembers}
    selectedBoardMembers={selectedBoardMembers}
    setProjectKey={setProjectKey}
    setDescription={setDescription}
    setTitle={setTitle}
    // showSaveButton={selectedRessource && date && title && description && selectedBoard}
    showSaveButton={selectedRessource && title && description && selectedBoard}
    date={date}
    setDate={setDate}
    theme={theme}
  />
}

export default TaskModalForJira;