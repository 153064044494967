import React, { useState, useEffect } from "react";
import { Table, Checkbox, Spin } from "antd";
import { useTranslation } from "react-i18next";
const CategoriesSelection = ({
  categories,
  selectedCategories,
  setSelectedCategories,
  resetPopup,
  loading,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setSelectAll(selectedCategories?.length === categories?.length);
  }, [selectedCategories, categories]);

  useEffect(() => {
    if (resetPopup) {
      setSelectAll(false);
      setSelectedCategories([]);
    } else {
      setSelectAll(selectedCategories?.length === categories?.length);
    }
  }, [resetPopup]);

  useEffect(() => {
    setSelectAll(
      selectedCategories?.length === categories?.length ||
        (selectedCategories?.length > 0 &&
          selectedCategories?.length < categories?.length)
    );
  }, [selectedCategories, categories]);

  const handleCategoriesSelection = (selectedCategory) => {
    const updatedSelection = [...selectedCategories];

    const categoryIndex = updatedSelection.findIndex(
      (category) => category?.categoryId === selectedCategory?.categoryId
    );

    if (categoryIndex === -1) {
      updatedSelection.push(selectedCategory);
    } else {
      updatedSelection.splice(categoryIndex, 1);
    }
    setSelectedCategories(updatedSelection);
  };

  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedCategories(categories);
  };

  const columns = [
    {
      title: (
        <Checkbox
          color="primary"
          checked={selectAll}
          indeterminate={
            selectedCategories?.length > 0 &&
            selectedCategories?.length < categories?.length
          }
          onChange={handleSelectAll}
        />
      ),
      key: "checkbox",
      render: (record) => (
        <Checkbox
          checked={
            Array.isArray(selectedCategories) &&
            selectedCategories.some(
              (category) => category?.categoryId === record?.categoryId
            )
          }
          onChange={() => handleCategoriesSelection(record)}
        />
      ),
    },
    {
      title: t("all_dashboards_page.34"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },
  ];

  const data = categories?.map((category) => ({
    categoryId: category.categoryId,
    name: category.name,
  }));
  return (
    <div>
      <Spin spinning={loading}>
        <Table columns={columns} dataSource={data} pagination={false} />
      </Spin>
    </div>
  );
};

export default CategoriesSelection;
