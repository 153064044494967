import React from 'react';
import styles from './style.module.scss';

const CustomButton = ({ onClick, text, loading }) => {
   return (
      <div className={styles.customButton}>
         <button onClick={onClick} disabled={loading} className={`${loading ? styles.text : undefined}`}>{text}</button>
         <span className={`${loading ? styles.loader : undefined}`}></span>
      </div>
   )
}

export default CustomButton;